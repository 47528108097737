<template>
    <div class="component game-revised-poll p-3 border-1">
        <div class="v-start justify-content-center">
            <div class="title-box large" v-b-tooltip.hover title="Each week you can choose to play with your Game-Built poll as is or Revise your Game-Built poll for next week's play. Remember to Select the appropriate week’s play button. Players can use replacement teams along with Game-Built poll to revise their poll.">
                <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                {{title}}
            </div>
            <RoundButton class="btn sm ml-3" title="Reset" @click.native="onReset"/>
        </div>
        <div class="v-start position-relative">
            <div class="custom_radio next-play-radio w-100" @click="onSetNextPrimary()">
                <input
                    type="radio"
                    id="primary-revised"
                    name="radio-group"
                    :checked="nextPrimary == 'revised'"
                >
                <label for="primary-revised">For next week's play</label>
            </div>
            <div class="wl-wrapper">
                <div class="regular-sub-title">
                    <div class="f-2 font-weight-bold text-center">W - L</div>
                </div>
                <div :class="(getWinFlags[index] == 1 ? 'wl win-color' : getWinFlags[index] == -1 ? 'wl loss-color':'wl')"
                     v-for="(item, index) in getRevisedPollWinLoss" :key="index">
                    <div v-if="item.win == 0 && item.loss == 0">
                        _
                    </div>
                    <div v-else>
                        {{item.win}} - {{item.loss}}
                    </div>
                </div>
            </div>
            <div class="percent-wrapper ml-1">
                <div class="regular-sub-title v-end">
                    <div class="f-2 font-weight-bold text-center"></div>
                </div>
                <div :class="(index < 10 ? 'percent green-background' : 'percent yellow-background')" v-for="(item, index) in revisedPoll" :key="index">
                    {{100-index*4}}%
                </div>
            </div>
            <div class="teams ml-1">
                <div class="regular-sub-title v-end">
                    <div class="f-2 font-weight-bold text-center">Rank</div>
                </div>
                <drag v-for="(item, index) in revisedPoll"
                      class="drop"
                      :key="index"
                      :transfer-data="{team : item, type: 'revised'}">
                    <drop class="drop" @drop="handleRevisedDrop(index, ...arguments)">
                        <InitRankTeamCell :index="index" :item="item" :init-rank="getInitRank(item)" :win-flag="getWinFlags[index]" />
                    </drop>
                </drag>
            </div>
            <div class="movement-wrapper ml-1">
                <div class="regular-sub-title"
                     v-b-tooltip.hover title="This column displays the numerical movement of teams in the poll based on a player’s revision of where they believe teams should be ranked. Teams advancing or falling in the poll is based solely on the player. A dash (–) indicates that a team ranks the same as the previous ranking."
                >
                    <div class="f-2 font-weight-bold mx-auto">
                        <img class="exclaim-img mr-0" src="@/assets/img/icon_exclaim.png" />
                    </div>
                </div>
                <div
                    class="score-cell v-center justify-content-center"
                    v-for="(item, index) in getMovementTeams"
                    :key="'built_movement_' + index"
                >
                    <img class="trend-icon mr-1"  v-if="item && item.direction == -1" src="@/assets/img/up-arrow.png"/>
                    <img class="trend-icon mr-1"  v-if="item && item.direction == 1" src="@/assets/img/down-arrow.png"/>
                    <div class="f-4" v-if="item">{{item.movement === 0 ? '-' : item.movement}}</div>
                </div>
            </div>
            <div class="conferences ml-1">
                <div class="regular-sub-title justify-content-center w-100">
                    <div class="f-2 font-weight-bold">Conf</div>
                </div>
                <div class="conference-cell" v-for="(item, index) in revisedPoll" :key="index">{{getConferenceNickName(item.conference)}}</div>
            </div>
        </div>
    </div>
</template>

<script>

    import RegularSeasonDragDropTeamCell from "./RegularSeasonDragDropTeamCell";
    import {Drag, Drop} from "vue-drag-drop";
    import {mapState} from "vuex";
    import InitRankTeamCell from "./InitRankTeamCell";
    import RoundButton from "../RoundButton";
    import {REGULARPOLLTYPE} from "../../constants/constants";
    import {getConferenceNickName} from "../../utils/functions";

    export default {
        name: "RegularSeasonRevisedPoll",
        components: {
            InitRankTeamCell,
            Drag,
            Drop,
            RoundButton
        },
        data: () => ({
            title: 'Revised Poll',
        }),
        props: {
            revisedPoll : {type : Array},
            builtPoll: {type: Array},
            nextPrimary: {type: String},
            year: {type: String},
            week: {type: String},
        },
        mounted() {
        },
        computed : {
            ...mapState({
                users: state => state.users,
                teams: state => state.teams,
                conferences: state => state.conferences,
            }),
            getRevisedPollWinLoss(){
                const revisedPollWinLoss = [];
                const year = this.year;
                const week = this.week;
                if (this.teams.matchResult[year]) {
                    const matchResult = this.teams.matchResult[year][week];
                    if (matchResult) {
                        for (var j = 0; j < this.revisedPoll.length; j++) {
                            var winLossObj = this.getTeamWinLossState(this.revisedPoll[j], year, week);
                            revisedPollWinLoss.push( winLossObj );
                        }
                    }
                }
                return revisedPollWinLoss;
            },
            getWinFlags() {
                const winFlags = [];
                const year = this.year;
                const week = this.week;
                if (this.teams.matchResult[year]) {
                    const matchResult = this.teams.matchResult[year][week];
                    if (matchResult) {
                        for (var j = 0; j < this.revisedPoll.length; j++) {
                            winFlags.push( 0 );
                            const firstIndex = matchResult.findIndex(
                                (item) => item.teams[0].school == this.revisedPoll[j].school);
                            const secondIndex = matchResult.findIndex(
                                (item) => item.teams[1].school == this.revisedPoll[j].school);
                            if (firstIndex >= 0) {
                                var homePoints3 = matchResult[firstIndex].teams[0].points;
                                var awayPoints3 = matchResult[firstIndex].teams[1].points;
                                if (homePoints3 >= awayPoints3) {
                                    winFlags[j] = 1;
                                }
                                else {
                                    winFlags[j] = -1;
                                }
                            }
                            if (secondIndex >= 0) {
                                var homePoints4 = matchResult[secondIndex].teams[0].points;
                                var awayPoints4 = matchResult[secondIndex].teams[1].points;
                                if (homePoints4 > awayPoints4) {
                                    winFlags[j] = -1;
                                }
                                else {
                                    winFlags[j] = 1;
                                }
                            }
                        }
                    }
                }
                return winFlags;
            },
            getMovementTeams() {
                const result = [];
                for (let i = 0; i < 25; i++) {
                    if (this.revisedPoll[i] && this.revisedPoll[i].school) {
                        const index = this.teams.myTop25.findIndex((item) => item.school === this.revisedPoll[i].school);
                        if (index >= 0) {
                            let direction = i === index ? 0 : (i < index ? -1 : 1);
                            let movement = i === index ? 0 : (i < index ? index - i : i - index);
                            result.push({
                                direction,
                                movement
                            });
                        } else {
                            result.push({
                                direction: 0,
                                movement: 0
                            });
                        }
                    } else {
                        result.push(null);
                    }
                }
                return result;
            }
        },
        methods : {
            getConferenceNickName,
            handleRevisedDrop(index, data, event) {
                const isFilledBuiltPoll = this.builtPoll.filter((item) => item.school).length === 25;
                if (isFilledBuiltPoll) {
                    if (data && data.type) {
                        if (data.type === 'built' || data.type === 'replacement') {
                            const findIndex = this.revisedPoll.findIndex((team) => team && team.school === data.team.school);
                            if (findIndex >= 0) {
                                this.revisedPoll.splice(findIndex, 1, {});
                            }
                            this.revisedPoll.splice(index, 1, data.team);
                        } else if (data.type === 'revised') {
                            const findIndex = this.revisedPoll.findIndex((team) => team && team.school === data.team.school);
                            this.revisedPoll.splice(findIndex, 1, this.revisedPoll[index] || {} );
                            this.revisedPoll.splice(index, 1, data.team);
                        }
                    }
                }
            },
            onReset() {
                for (let i = 0; i < this.revisedPoll.length; i++) {
                    this.revisedPoll.splice(i, 1, {});
                }
            },
            onSetNextPrimary() {
                this.$emit("change_primary", REGULARPOLLTYPE.REVISED);
            },
            getInitRank(item) {
                const index = this.teams.myTop25.findIndex((team) => team.school == item.school);
                return index + 1;
            },
            getTeamWinLossState(team, year, week) {
                const count = parseInt(week);
                var winLossObj = {
                    win : 0,
                    loss : 0
                };
                for (let i = 1; i <= count; i++) {
                    if (this.teams.matchResult[year]) {
                        const matchResult = this.teams.matchResult[year][i.toString()];
                        if (matchResult) {
                            const firstIndex = matchResult.findIndex(
                                (item) => item.teams[0].school == team.school);
                            const secondIndex = matchResult.findIndex(
                                (item) => item.teams[1].school == team.school);
                            if (firstIndex >= 0) {
                                var homePoints3 = matchResult[firstIndex].teams[0].points;
                                var awayPoints3 = matchResult[firstIndex].teams[1].points;
                                if (homePoints3 > awayPoints3) {
                                    winLossObj.win++;
                                }
                                else {
                                    winLossObj.loss++;
                                }
                            } else if (secondIndex >= 0) {
                                var homePoints4 = matchResult[secondIndex].teams[0].points;
                                var awayPoints4 = matchResult[secondIndex].teams[1].points;
                                if (homePoints4 > awayPoints4) {
                                    winLossObj.loss++;
                                }
                                else {
                                    winLossObj.win++;
                                }
                            }
                            else {
                              if (i == count) {
                                winLossObj.win = null;
                                winLossObj.loss = null;
                              }
                            }
                        }
                    }
                }
                return winLossObj;
            }
        },
    }
</script>

<style lang="scss" scoped>
    .score-cell {
        .trend-icon {
            width: 12px;
            height: 14px;
            object-fit: cover;
        }
    }
</style>
