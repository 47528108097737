<template>
    <div class="page pageHome">
        <BackgroundImage />
        <Navbar :user="users.currentUser" />
        <div class="mainContent">
            <div :class="['grid-item', gameType === 'Game1' ? 'active' : '']" @click="moveGame('Game1')">
                Play The Fantasy Standard Poll Game
            </div>
            <div :class="['grid-item', gameType === 'Game2' ? 'active' : '']" @click="moveGame('Game2')">
                Fantasy Pre-Season Play-in Challenge
            </div>
            <div :class="['grid-item', gameType === 'Game3' ? 'active' : '']" @click="moveGame('Game3')">
                Play Fantasy Poll Re-distribution Game
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Navbar from "../../components/Navbar";
import BackgroundImage from "../../components/BackgroundImage";
import {STATUS_NONE, STATUS_SUCCESS} from "../../constants/constants";
import {currentYear} from "../../utils/functions";
import axios from "axios";

export default {
    name: 'HomeScreen',
    components: {
        Navbar,
        BackgroundImage
    },
    mounted() {
        const data = {
            user: this.users.currentUser._id,
            year: currentYear(),
            week: '3'
        }
        this.getRegularPolls(data);
    },
    computed: {
        ...mapState({
            users: state => state.users,
            teams: state => state.teams,
            conferences: state => state.conferences,
            game_type: state => state.game_type
        }),

        gameType() {
            return this.users.currentUser.gameType;
        }
    },
    methods: {
        ...mapActions([
            'getAllTeams',
            'getAllConferences',
            'getGameType',
            'getRegularPolls'
        ]),
        moveGame: function(type) {
            this.teams.myTop25 = [];
            this.teams.selectedPoll = null;
            this.users.currentUser.gameType = type;
            this.$router.push({name: `${type}Prev`});
        },
    },
    watch: {
        'teams.getAllTeamStatus' : function(status) {
            if (status === STATUS_SUCCESS) {
                const data = {
                    user: this.users.currentUser._id,
                    year: currentYear()
                }
                this.getGameType(data);
            }
        },
        'game_type.getGameTypeStatus' : function(status) {
            if (status === STATUS_SUCCESS) {
                if (this.game_type.data) {
                    const own_team = this.game_type.data.own_team;
                    if (own_team.length) {
                        own_team.forEach((team, index) => {
                            // this.teams.myTop25.splice(index, 1, team);
                        })
                    }
                }
            }
        }
    }
};
</script>

<style lang="scss" scope>
    .pageHome {
        position: relative;

        .mainContent {
            width: 100%;
            height: 100%;
            position: fixed;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            .grid-item {
                border-radius: 50%;
                background-color: white;
                width: 200px;
                height: 200px;
                margin: 40px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                font-size: 16px;
                border: 5px solid blue;
                box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
                cursor: pointer;
                &.active {
                    border: 10px solid #2F60C1;
                }
                &:hover {
                    box-shadow: 0 0 10px rgba(255, 255, 255, 1.0);
                }
            }
        }
    }
</style>
