<template>
    <div class="component regular-season-team-cell v-start">
        <div class="wl-wrapper mt-60">
            <div class="regular-sub-title">
                <div class="f-2 font-weight-bold text-center">W - L</div>
            </div>
            <div :class="(getWinFlags[index] ? 'wl win-color' : 'wl loss-color')" v-for="(item, index) in winLoss" :key="index">{{item.win}} - {{item.loss}}</div>
        </div>
        <div class="percent-wrapper mt-60 ml-1">
            <div class="regular-sub-title v-end">
                <div class="f-2 font-weight-bold text-center"></div>
            </div>
            <div :class="(index < 10 ? 'percent green-background' : 'percent yellow-background')" v-for="(item, index) in winLoss" :key="index">
                {{100-index*4}}%
            </div>
        </div>
        <div class="teams ml-1">
            <div class="title-box">{{title}}</div>
            <div class="regular-sub-title v-end">
                <div class="f-2 font-weight-bold text-center">Rank</div>
            </div>
            <drag v-for="(item, index) in pollTeams"
                  class="drop"
                  :key="index"
                  :transfer-data="{team : item}">
                <drop class="drop" @drop="handleDrop(index, ...arguments)">
                    <InitRankTeamCell :index="index" :item="item" :init-rank="getInitRank(index)" :win-flag="getWinFlags[index]" />
                </drop>
            </drag>

            <!--            <draggable :list="pollTeams" :group="{ name: 'fallingOutTeams' , pull: 'clone', put: false }" @change="changeEvent">-->
            <!--                <InitRankTeamCell v-for="(item, index) in pollTeams" :key="index" :index="index" :item="item" :init-rank="getInitRank(index)" :win-flag="getWinFlags[index]"></InitRankTeamCell>-->
            <!--            </draggable>-->

        </div>
    </div>
</template>

<script>

    import { STATUS_SUCCESS, STATUS_FAILURE } from '../../constants/constants';
    import { mapState, mapActions } from 'vuex'
    import InitRankTeamCell from "./InitRankTeamCell";
    import {Drag, Drop} from 'vue-drag-drop';

    export default {
        name: "RegularSeasonDragDropTeamCell",
        components: {
            InitRankTeamCell,
            Drag,
            Drop,
        },
        data: () => ({
            // myTop25 : [],
        }),
        props: {
            pollTeams: { type: Array },
            title : { type : String },
            winLoss : {type : Array},
        },
        computed : {
            ...mapState({
                users: state => state.users,
                teams: state => state.teams,
            }),

            getWinFlags(){
                const winFlags = [];

                for (var j = 0; j < this.pollTeams.length; j++) {

                    winFlags.push( false );

                    for( var i = 0; i < this.teams.matchResult.length; i++ ) {


                        if ( this.pollTeams[j].school == this.teams.matchResult[i].teams[0].school) {
                            var homePoints3 = this.teams.matchResult[i].teams[0].points;
                            var awayPoints3 = this.teams.matchResult[i].teams[1].points;
                            if (homePoints3 > awayPoints3) {
                                winFlags[j] = true;
                            }
                            else {
                                winFlags[j] = false;
                            }
                        }
                        else if ( this.pollTeams[j].school == this.teams.matchResult[i].teams[1].school ) {
                            var homePoints4 = this.teams.matchResult[i].teams[0].points;
                            var awayPoints4 = this.teams.matchResult[i].teams[1].points;
                            if (homePoints4 > awayPoints4) {
                                winFlags[j] = false;
                            }
                            else {
                                winFlags[j] = true;
                            }
                        }
                    }
                }
                return winFlags;
            },
        },
        methods : {
            getInitRank(index){
                const initRank = [];
                for( var i = 0; i < this.pollTeams.length; i++ ){
                    initRank.push( 0 );
                    for( var j = 0; j < this.teams.myTop25.length; j++ ){
                        if( this.pollTeams[i].school == this.teams.myTop25[j].school ){
                            initRank[i] =  j + 1;
                        }
                    }
                }
                return initRank[index];
            },
            changeEvent: function(evt){
                if( evt.removed ){
                    var index = evt.removed.oldIndex;
                    this.pollTeams.splice(index, 0, evt.removed.element);
                }
            },

            handleDrop(index, data, event) {
                const dragTeamIndex = this.pollTeams.indexOf(data.team);
                const dragTeam = data.team;
                const dropTeamIndex = index;
                const dropTeam = this.pollTeams[index];

                this.pollTeams.splice(dragTeamIndex, 1, dropTeam);
                this.pollTeams.splice(dropTeamIndex, 1, dragTeam);

                this.teams.revisedPollTeams = this.pollTeams;
            }
        },
        mounted() {
            // this.myTop25 = this.pollTeams;
        },
    }
</script>

<style lang="scss" scoped>

</style>
