<template>
	<div class="page-header mt-5">
		<div class="btnLeft v-center">
			<RoundButton class="btn-standard" title="Reset" size="small" @click.native="onReset()"/>
			<RoundButton class="btn-standard ml-2" title="Save" size="small" @click.native="onSave()"/>
		</div>
		<div class="text-center mt-3">
			<p class="f-24 font-weight-bold text-uppercase">{{title}}</p>
			<p class="f-8 font-weight-bold opacity-6 text-uppercase">{{subTitle}}</p>
		</div>
		<div class="btnRight v-center">
			<RoundButton class="btn-standard" title="Back" size="small" v-if="isBack" @click.native="onBack()"/>
			<RoundButton class="btn-standard ml-2" title="Next" size="small" v-if="isNext" @click.native="onNext()"/>
		</div>
	</div>
</template>

<script>
    import RoundButton from "./RoundButton";
    export default {
        name: "PredictionHeader",
        components: {
            RoundButton
        },
        props: {
            title: { type: String },
            subTitle: { type: String },
            isBack: { type: Boolean, default: true },
            isNext: { type: Boolean, default: true },
        },
        methods: {
            onReset(evt) {
                this.$emit("reset_click", evt);
            },
            onSave(evt) {
                this.$emit("save_click", evt);
            },
            onBack(evt) {
                this.$emit("back_click", evt);
            },
            onNext(evt) {
                this.$emit("next_click", evt);
            }
        }
    }
</script>

<style lang="scss" scoped>
	.page-header {
		position: relative;

		.btnLeft {
			position: absolute;
			top: 15px;
			left: 40px;
		}

		.btnRight {
			position: absolute;
			top: 15px;
			right: 40px;
		}

	}
</style>