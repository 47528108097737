<template>
    <div class="team-cell v-center">
        <div class="rank-box" v-if="!isShowLogo">
            <img class="icon"  v-if="initRank" src="@/assets/img/rank.png"/>
            <span class="rank" v-if="rank">{{rank}}</span>
        </div>
        <img class="logoImage" :src="logo" v-if="(logo != null)" alt="" onerror="this.style.display = 'none'"/>
        <span :class="(winFlag ? 'team-name win-color' : 'team-name loss-color')">{{item ? item.school : ''}}</span>
        <span :class="(winFlag ? 'init-pos win-color' : 'init-pos loss-color')" v-if="initRank">({{initRank}})</span>
    </div>
</template>

<script>
    export default {
        name: 'RegularSeasonFallingTeamCell',
        props: {
            index: { type: Number },
            item: { type: Object },
            isShowLogo: { type: Boolean },
            initRank : { type: Number },
            rank : { type : Number },
            winFlag : { type : Boolean },
        },
        data: () => ({

        }),
        computed: {
            logo() {
                if (this.item && this.item.logos && this.item.logos.length > 0) {
                    return this.item.logos[0];
                }
                return null;
            }
        }
    }
</script>
<style lang="scss" scope>

</style>
