import Vue from 'vue'
import Vuex from 'vuex'
import users from './users'
import teams from './teams'
import conferences from './conferences'
import game_type from './game_types'
import pick_six from './pick_six'
import regular_season from './regular_season'
import prediction from './prediction'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    users,
    teams,
    conferences,
    game_type,
    pick_six,
    regular_season,
    prediction
  }
})

export default store
