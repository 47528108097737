<template>
    <div class="page-header mt-5">
        <div class="left-button-group v-center">
            <RoundButton class="btnLeft px-2" title="Pick Six" size="small" @click.native="onPickSix()"/>
            <RoundButton class="btnLeft px-2 ml-2" title="Positional Match" size="small" @click.native="onPositionalMatch()"/>
            <RoundButton class="btnLeft px-2 ml-2" title="Points Team" size="small" @click.native="onStatisticalMatch()"/>
        </div>
        <div class="text-center mt-3">
            <p class="f-24 font-weight-bold text-uppercase">{{title}}</p>
            <p class="f-8 font-weight-bold opacity-6 text-uppercase">{{subTitle}}</p>
        </div>
        <div class="right-button-group v-center">
             <RoundButton class="btnBack btn-standard px-2" title="Back" size="small" @click.native="onBack()" v-if="isBack"/>
            <RoundButton class="btnNext btn-standard px-2 ml-2" title="Next" size="small" @click.native="onNext()"/>
        </div>
    </div>
</template>

<script>
    import RoundButton from "../RoundButton";
    export default {
        name: "RegularSeasonHeader",
        props: {
            title: { type: String },
            subTitle: { type: String },
            isBack: { type: Boolean, default: true },
            isNext: { type: Boolean, default: true },
            isLeft: { type: Boolean, default: false },
            leftButtonText: { type: String }
        },
        components: {
            RoundButton
        },
        methods: {
            onPickSix(evt) {
                this.$emit("pick_six_click", evt);
            },
            onPositionalMatch(evt) {
                this.$emit("positional_match_click", evt);
            },
            onStatisticalMatch(evt) {
                this.$emit("statistical_match_click", evt);
            },
            onBack(evt) {
                this.$emit("back_click", evt);
            },
            onNext(evt) {
                console.log("next_click", evt);
                this.$emit("next_click", evt);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-header {
        position: relative;

        .left-button-group {
            position: absolute;
            top: 15px;
            left: 40px;
        }

        .right-button-group {
            position: absolute;
            top: 15px;
            right: 40px;
        }
    }
</style>
