<template>
	<div class="page regular-season my-top25-page">
		<Navbar :user="users.currentUser" />
    <button class="btn btn-primary my-teams-btn" @click="onClickMyTeam">My Teams</button>
		<div class="center-wrapper mx-auto">
      <div class="text-center my-5 sub-header">
        <p class="f-20">My Top 25 Poll</p>
      </div>
      <div class="regular-season-team-cell v-start justify-content-center">
        <div class="pos-wrapper">
          <div class="pos-cell font-weight-bold" v-for="(item, index) in teams.myTop25" :key="index">{{index + 1}}</div>
        </div>
        <div class="percent-wrapper">
          <div :class="(index < 10 ? 'percent green-background' : 'percent yellow-background')" v-for="(item, index) in getMyTop25WinLoss" :key="index">
            {{100-index*4}}%
          </div>
        </div>
        <div class="teams">
          <drag v-for="(item, index) in teams.myTop25"
                class="mytop25"
                :key="index"
                :transfer-data="{team : item, type: 'mytop25'}">
            <div class="v-center teams-wrapper">
              <img class="logoImage mx-2" :src="getAvatar(item)" alt="" onerror="this.style.display = 'none'"/>
              <TeamCell :item="item" :is-show-logo="true"/>
            </div>
          </drag>
        </div>
        <div class="trend-wrapper">
          <div class="trend-cell" v-for="(item, index) in teams.myTop25Trend" :key="index">
            <img class="trend-icon"  v-if="item.diff == 'up'" src="@/assets/img/up-arrow.png"/>
            <img class="trend-icon"  v-if="item.diff == 'down'" src="@/assets/img/down-arrow.png"/>
            <span v-if="item.diff == '-'">-</span>
            <span class="trend-order ml-2" :class="item.diff" v-if="item.diff != '-'">{{item.order}}</span>
          </div>
        </div>
      </div>
		</div>
    
	</div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import Navbar from "../../components/Navbar";
    import {STATUS_FAILURE, STATUS_SUCCESS} from "../../constants/constants";
    import TeamCell from "../../components/Game/TeamCell";
    import {currentYear} from "../../utils/functions";
    export default {
        name: 'MyTop25',
        components: {
            Navbar,
			TeamCell
        },
        data() {
            return {
                title: 'My Top 25 Poll',
            };
        },
        computed: {
            ...mapState({
                users: state => state.users,
                teams: state => state.teams,
                conferences: state => state.conferences,
                game_type: state => state.game_type
            }),
            getMyTop25WinLoss(){

                const myTop25WinLoss = [];

                for (var j = 0; j < this.teams.myTop25.length; j++) {

                    var winLossObj = {
                        win : 0,
                        loss : 0
                    };
                    myTop25WinLoss.push( winLossObj );

                    for( var i = 0; i < this.teams.matchResult.length; i++ ) {

                        if ( this.teams.myTop25[j].school == this.teams.matchResult[i].teams[0].school) {
                            var homePoints3 = this.teams.matchResult[i].teams[0].points;
                            var awayPoints3 = this.teams.matchResult[i].teams[1].points;
                            if (homePoints3 > awayPoints3) {
                                myTop25WinLoss[j].win++;
                            }
                            else {
                                myTop25WinLoss[j].loss++;
                            }
                        }
                        else if ( this.teams.myTop25[j].school == this.teams.matchResult[i].teams[1].school ) {
                            var homePoints4 = this.teams.matchResult[i].teams[0].points;
                            var awayPoints4 = this.teams.matchResult[i].teams[1].points;
                            if (homePoints4 > awayPoints4) {
                                myTop25WinLoss[j].loss++;
                            }
                            else {
                                myTop25WinLoss[j].win++;
                            }
                        }
                    }
                }
                return myTop25WinLoss;
            },
            getWinFlags(){
                const winFlags = [];

                for (var j = 0; j < this.teams.myTop25.length; j++) {
                    winFlags.push( false );

                    for( var i = 0; i < this.teams.matchResult.length; i++ ) {
                        if ( this.teams.myTop25[j].school == this.teams.matchResult[i].teams[0].school) {
                            var homePoints3 = this.teams.matchResult[i].teams[0].points;
                            var awayPoints3 = this.teams.matchResult[i].teams[1].points;
                            if (homePoints3 > awayPoints3) {
                                winFlags[j] = true;
                            }
                            else {
                                winFlags[j] = false;
                            }
                        }
                        else if ( this.teams.myTop25[j].school == this.teams.matchResult[i].teams[1].school ) {
                            var homePoints4 = this.teams.matchResult[i].teams[0].points;
                            var awayPoints4 = this.teams.matchResult[i].teams[1].points;
                            if (homePoints4 > awayPoints4) {
                                winFlags[j] = false;
                            }
                            else {
                                winFlags[j] = true;
                            }
                        }
                    }
                }
                return winFlags;
            },
        },
        mounted() {
            this.getAllTeams();
            this.getAllConferences();

            const data = {
                user: this.users.currentUser._id,
                year: currentYear()
            }
            this.getGameType(data);
        },
        methods: {
            ...mapActions([
                'getAllTeams',
                'getAllConferences',
                'getRegularPolls',
                'setGameType',
                'getGameType'
            ]),
            getInitRank(index){
                const initRank = [];
                for( var i = 0; i < this.teams.myTop25.length; i++ ){
                    initRank.push( 0 );
                    for( var j = 0; j < this.teams.myTop25.length; j++ ){
                        if( this.teams.myTop25[i].school == this.teams.myTop25[j].school ){
                            initRank[i] =  j + 1;
                        }
                    }
                }
                return initRank[index];
            },
            getAvatar(team) {
                      if (team.logos.length > 0 ) {
                          return team.logos[0];
              }
            },
            onClickMyTeam() {
              this.$router.push({name: 'Game1'});
            },
        },
        watch: {
            'teams.getAllTeamStatus' : function(status) {
                this.isLoading = true;
                if (status === STATUS_SUCCESS) {
                    const data = {
                        user: this.users.currentUser._id,
                        year: currentYear()
                    }
                    this.getRegularPolls({year: currentYear(), week: 11});
                    this.getGameType(data);
                } else if (status === STATUS_FAILURE) {
                    this.isLoading = false;
                    this.$toasted.show(this.teams.errorMessage);
                }
            },
            'game_type.getGameTypeStatus' : function(status) {
                if (status === STATUS_SUCCESS) {
                    const own_team = this.game_type.data.own_team;
                    if (own_team.length) {
                        own_team.forEach((teamId, index) => {
                            const nIndex = this.teams.all.findIndex(item=> item._id === teamId);
                            const team = this.teams.all[nIndex];
                            this.teams.myTop25.splice(index, 1, team);
                        });
                    }
                    this.selectedPoll = this.game_type.data.selected_poll;
                    this.isLoading = false;
                } else if (status === STATUS_FAILURE) {
                    this.isLoading = false;
                    this.$toasted.show(this.game_type.errorMessage);
                }
            }
        }
    };
</script>

<style lang="scss" scope>
	.logoImage {
		width: 30px;
		height: 30px;
		margin-right: 5px;
		object-fit: contain;
	}

	.regular-season.my-top25-page .percent-wrapper .percent:nth-child(21) {
		background: red;
	}

	.regular-season.my-top25-page .pos-wrapper .pos-cell {
		margin-bottom: 0;
		border-radius: 0;
		border-bottom: 1px solid black;
		border-left: 1px solid black;
		border-right: 1px solid black;
		max-height: 36px;
		min-height: 36px;

		&:first-child {
			border-top: 1px solid black;
		}
	}

	.regular-season.my-top25-page .percent-wrapper .percent {
		margin-bottom: 0;
		border-radius: 0;
		border-bottom: 1px solid black;
		border-left: 0;
		border-right: 0;
		max-height: 36px;
		min-height: 36px;

		&:first-child {
			border-top: 1px solid black;
		}
	}

	.regular-season.my-top25-page .trend-wrapper .trend-cell {
		margin-bottom: 0;
		border-radius: 0;
		border-bottom: 1px solid black;
		border-left: 0;
		border-right: 1px solid black;
		max-height: 36px;
		min-height: 36px;
		min-width: 60px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:first-child {
			border-top: 1px solid black;
		}

		.trend-icon {
			width: 12px;
			height: 14px;
			object-fit: cover;
		}

		.trend-order {
			&.up {
				color: green;
			}

			&.down {
				color: red;
			}
		}
	}

	.regular-season.my-top25-page .mytop25 {
		.teams-wrapper {
			border-bottom: 1px solid black;
			border-left: 1px solid black;
			border-right: 1px solid black;
			max-height: 36px;
			min-height: 36px;

			.team-cell {
				border-left: 1px solid black;
				border-radius: 0;
				margin-bottom: 0;
			}
		}

		&:first-child {
			.teams-wrapper {
				border-top: 1px solid black;
			}
		}
	}

	.center-wrapper {
		max-width: 500px;
	}

  .my-teams-btn {
    float: right;
    margin: 30px;
  }
</style>
