<template>
    <div>
        <Navbar :user="users.currentUser" />
        <div>
            <Header
                title="Weekly Schedule"
                sub-title="College Football"
                :is-next="false"
                @back_click="onBack"
            />
        </div>
        <div class="season-wrapper mt-5">
            <v-container>
                <div class="row">
                    <div class="col-md-4 col-12">
                        <div class="mb-1">Season</div>
                        <v-select
                                :items="seasons"
                                :value="selectedSeason"
                                label="season"
                                solo
                                @change="onChangeSeason($event)"
                        ></v-select>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="mb-1">Season Type</div>
                        <v-select
                                :items="seasonTypes"
                                :value="selectedSeasonType"
                                label="season"
                                solo
                                @change="onChangeSeasonType($event)"
                        ></v-select>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="mb-1">Weeks</div>
                        <v-select
                                :items="selectedSeasonType == 'Regular' ? this.regularWeeks : this.postseasonWeeks"
                                :value="selectedWeek"
                                label="season"
                                solo
                                @change="onChangeWeek($event)"
                        ></v-select>
                    </div>
                </div>
                <div class="schedule-tables mt-3">
                    <div class="schedule-table mb-4" v-for="(schedule, index) in scheduleData" :key="index">
                        <div class="schedule-date">{{getDate(schedule.date)}}</div>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">
                                        AWAY
                                    </th>
                                    <th class="text-left">
                                        HOME
                                    </th>
                                    <th class="text-left">
                                        TIME
                                    </th>
                                    <th class="text-left">
                                        VENUE
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        v-for="item in schedule.data"
                                        :key="item.id"
                                >
                                    <td>
                                        <div class="v-center" v-html="getAwayTeam(item)"></div>
                                    </td>
                                    <td>
                                        <div class="v-center" v-html="getHomeTeam(item)"></div>
                                    </td>
                                    <td>{{ getTime(item.start_date) }}</td>
                                    <td>{{ item.venue }}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </div>
            </v-container>
        </div>
        <LoadingOverlay v-if="isLoading"/>
    </div>
</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import Navbar from "../../components/Navbar";
    import Header from "../../components/Header";
    import {currentYear, currentWeek, isFullTeams} from "../../utils/functions";
    import {STATUS_FAILURE, STATUS_SUCCESS} from "../../constants/constants";
    import LoadingOverlay from "../../components/LoadingOverlay";
    import moment from "moment";

    export default {
        name: 'WeeklySchedule',
        order: 1,
        components: {
            Header,
            Navbar,
            LoadingOverlay
        },
        data() {
            return {
                isLoading: false,
                calendar: [],
                schedule: [],
                seasons: ['2020', '2021'],
                seasonTypes: ['Regular', 'Postseason'],
                regularWeeks: [],
                postseasonWeeks: [],
                selectedSeason: '2021',
                selectedSeasonType: 'Regular',
                selectedWeek: 1,
                scheduleData: []
            }
        },
        computed: {
            ...mapState({
                users: state => state.users,
                teams: state => state.teams,
                conferences: state => state.conferences,
                game_type: state => state.game_type
            }),
        },
        mounted() {
            const data = {
                year: currentYear(),
            }
            this.getCalendar(data);
            this.loadSchedule();
        },
        methods: {
            ...mapActions([
                'getAllTeams',
                'getAllConferences',
                'getCalendar',
                'getSchedule'
            ]),

            fetchCalendar() {
                if (this.calendar && this.calendar.length > 0) {
                    this.schedule['regular'] = [];
                    this.schedule['postseason'] = [];
                    for (const calendar of this.calendar) {
                        if (calendar.seasonType == 'regular') {
                            this.regularWeeks.push(calendar.week);
                        } else if (calendar.seasonType == 'postseason') {
                            this.postseasonWeeks.push(calendar.week);
                        }
                    }
                    this.selectedSeasonType = 'Regular';
                    this.selectedWeek = this.regularWeeks[0];
                }
            },

            fetchSchedule() {
                this.scheduleData = [];
                if (this.schedule && this.schedule.length > 0) {
                    for (const schedule of this.schedule) {
                        const index = this.scheduleData.findIndex((item) => this.getDate(item['date']) == this.getDate(schedule.start_date));
                        if (index >= 0) {
                            this.scheduleData[index]['data'].push(schedule);
                        } else {
                            this.scheduleData.push({
                                date: schedule.start_date,
                                data: [schedule]
                            });
                        }
                    }
                    this.scheduleData.sort((a,b) => (moment(a.date) > moment(b.date)) ? 1 : 0);
                    console.log("schedule ============>", this.scheduleData);
                }
            },

            loadSchedule() {
                const data = {
                    year: parseInt(this.selectedSeason, 10),
                    seasonType: this.selectedSeasonType.toLowerCase(),
                    week: this.selectedWeek
                };
                this.getSchedule(data);
            },

            getDate(date) {
                if (date) {
                    return moment(date).format('dddd, MMMM DD YYYY');
                }
                return '';
            },

            getTime(date) {
                if (date) {
                    return moment(date).format('hh:mm a');
                }
                return '';
            },

            getAwayTeam(match) {
                let avatar = '';
                let html = '<div class="v-center">';
                const index = this.teams.all.findIndex((item) => item.school == match.away_team);
                if (index >= 0) {
                    avatar = this.teams.all[index].logos[0];
                    html = `<img class="team-logo mr-1" src="${avatar}">`;
                }
                html += `<div>${match.away_team}</div>`
                if (match.away_points != null) {
                    html += `<div class="ml-1">(${match.away_points})</div>`
                }
                html += '</div>';
                return html;
            },

            getHomeTeam(match) {
                let avatar = '';
                let html = '<div class="v-center">';
                const index = this.teams.all.findIndex((item) => item.school == match.home_team);
                if (index >= 0) {
                    avatar = this.teams.all[index].logos[0];
                    html = `<img class="team-logo mr-1" src="${avatar}">`;
                }
                html += `<div>${match.home_team}</div>`
                if (match.home_points != null) {
                    html += `<div class="ml-1">(${match.home_points})</div>`
                }
                html += '</div>';
                return html;
            },

            onChangeSeason($event) {
                if ($event) {
                    this.selectedSeason = $event;
                    this.loadSchedule();
                }
            },

            onChangeSeasonType($event) {
                if ($event) {
                    this.selectedSeasonType = $event;
                    this.loadSchedule();
                }
            },

            onChangeWeek($event) {
                if ($event) {
                    this.selectedWeek = $event;
                    this.loadSchedule();
                }
            },

            onBack() {
                this.$router.back();
            }

        },
        watch: {
            'teams.getCalendarStatus' : function(status) {
                this.isLoading = true;
                if (status === STATUS_SUCCESS) {
                    this.isLoading = false;
                    this.calendar = this.teams.calendar;
                    this.fetchCalendar();
                } else if (status === STATUS_FAILURE) {
                    this.isLoading = false;
                    this.$toasted.show(this.teams.errorMessage);
                }
            },
            'teams.getScheduleStatus' : function(status) {
                this.isLoading = true;
                if (status === STATUS_SUCCESS) {
                    this.isLoading = false;
                    this.schedule = this.teams.schedule;
                    this.fetchSchedule();
                } else if (status === STATUS_FAILURE) {
                    this.isLoading = false;
                    this.$toasted.show(this.teams.errorMessage);
                }
            },
        }
    };
</script>
<style lang="scss" scope>
    .schedule-table {
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

        tr th {
            background-color: #eeeeee;
        }

        .schedule-date {
            padding: 1rem;
            font-weight: bold;
            font-size: 16px;
        }

        .team-logo {
            width: 24px;
            height: 24px;
        }
    }
</style>
