<template>
    <div class="blue-overlay">
        <v-img src="@/assets/img/wireframe/background.png" cover/>
    </div>
</template>

<script>
  export default {
    name: 'BackgroundImage',
    props: {
        data: {
            type: Array
        }
    },
    data: () => ({

    }),
  }
</script>
