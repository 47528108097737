<template>
    <div class="page regular-season">
        <Navbar :user="users.currentUser"/>
        <Header
                title="Double Points Match Scoring"
                :sub-title="'WEEK ' + regular_season.currentSeason"
                :is-left = false
                @back_click="onBack"
                @next_click="onNext"
        />
        <div class="page-wrapper v-start m-4">
            <div class="match-description m-4">
                <div class="v-center w-100">
                    <div class="header font-weight-bold">Date:</div>
                    <div class="value ml-3 text-decoration-underline">{{date}}</div>
                </div>
                <div class="v-center mt-3 w-100">
                    <div class="header font-weight-bold">Poll Owner:</div>
                    <div class="value ml-3 text-decoration-underline">{{username}}</div>
                </div>
                <div class="v-center mt-3">
                    <div class="header font-weight-bold">League Division:</div>
                    <div class="value ml-3 text-decoration-underline">{{leagueName}}</div>
                </div>
                <div class="v-center mt-3">
                    <div class="header font-weight-bold">Team Name:</div>
                    <div class="value ml-3 text-decoration-underline">{{teamName}}</div>
                </div>
                <div class="v-center mt-3">
                    <div class="header font-weight-bold">Total Points:</div>
                    <div class="value ml-3 text-decoration-underline">{{totalPoints}}</div>
                </div>
                <div class="v-center mt-3">
                    <div class="header font-weight-bold">Week:</div>
                    <div class="value ml-3 text-decoration-underline">{{regular_season.currentSeason}}</div>
                </div>
                <div class="v-center mt-3">
                    <div class="header font-weight-bold">Account Balance:</div>
                    <div class="value ml-3 text-decoration-underline">${{accountBalance}}</div>
                </div>
                <div class="mt-30 font-weight-bold text-center">$20.00 minimum initial deposit</div>
                <table class="table init-deposit-table text-center mt-2">
                    <tr>
                        <th>$1</th>
                        <th>$2</th>
                        <th>$5</th>
                    </tr>
                    <tr>
                        <td>Play</td>
                        <td>for</td>
                        <td>cash</td>
                    </tr>
                </table>
                <div class="mt-30 font-weight-bold text-center">Positional Matches</div>
                <table class="table match-deposit-table mt-2">
                    <tr>
                        <th>6-9</th>
                        <th>$1.00</th>
                        <th>$4.00</th>
                        <th>$8.00</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>10-12</th>
                        <th>$3.00</th>
                        <th>$8.00</th>
                        <th>$15.00</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>13-16</th>
                        <th>$5.00</th>
                        <th>$12.00</th>
                        <th>$20.00</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>17-20</th>
                        <th>$8.00</th>
                        <th>$15.00</th>
                        <th>$25.00</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>21-25</th>
                        <th>$12.00</th>
                        <th>$20.00</th>
                        <th>$30.00</th>
                    </tr>
                </table>
                <div class="mt-30 font-weight-bold text-center">Match Points</div>
                <div class="mt-2">
                    0.5 pt awarded for none exact match of player's lower
                    tier conference teams that is ranked anywhere in any
                    top 25 game poll.<br/>
                    <b>0.5 pts are not counted as a positional match</b><br/>
                    <b>1.0</b> pt  for any exact top 25 position match<br/>
                    <b>2.0</b> pts for exact CFFB.app poll match<br/>
                    <b>4.0</b> pts for exact Selection Committee poll match<br/>
                    <b>5.0</b> pts for each Top 25 shut out<br/>
                    <b>25.0</b> pts for upset pick of the week<br/>
                </div>
            </div>
            <div class="match-contents page-wrapper m-3">
                <div class="polls v-start">
                    <div class="revised-poll border-1 m-3 p-3">
                        <RegularSeasonTeamCell
                                title="Game-Built Poll"
                                :poll-teams="teams.builtPoll"
                                :win-loss="getBuiltPollWinLoss"
                                :win-flags="getBuiltPollWinFlags"
                                between="small"
                        />
                    </div>
                    <div class="revised-poll border-1 m-3 ml-0 p-3">
                        <RegularSeasonTeamCell
                                title="Revised Poll"
                                :poll-teams="teams.revisedPoll"
                                :win-loss="getRevisedPollWinLoss"
                                :win-flags="getRevisedPollWinFlags"
                                between="small"
                        />
                    </div>
                    <div class="prediction-polls-wrapper v-start border-1 my-4 p-3">
                        <div class="prediction-poll">
                            <div class="f-6 font-weight-bold text-center mb-40">AP Poll</div>
                            <div class="v-center" v-for="(item, index) in teams.apPoll" :key="item.id" >
                                <div class="score-cell mr-1">{{apPollPoints[index].built}}</div>
                                <div class="score-cell mr-1">{{apPollPoints[index].revised}}</div>
                                <TeamCell  :item="item" :index="index"/>
                            </div>
                            <div class="f-4 c-blue text-center mt-5">Trending<span class="ml-3">{{points.polls[0].built * 100 / 25}}% / {{points.polls[0].revised * 100 / 25}}%</span></div>
                        </div>
                        <div class="prediction-poll ml-3">
                            <div class="f-6 font-weight-bold text-center mb-40">Coaches Poll</div>
                            <div class="v-center" v-for="(item, index) in teams.coachesPoll" :key="item.id" >
                                <div class="score-cell mr-1">{{coachesPollPoints[index].built}}</div>
                                <div class="score-cell mr-1">{{coachesPollPoints[index].revised}}</div>
                                <TeamCell  :item="item" :index="index"/>
                            </div>
                            <div class="f-4 c-blue text-center mt-5">{{points.polls[1].built * 100 / 25}}% / {{points.polls[1].revised * 100 / 25}}%</div>
                        </div>
                        <div class="prediction-poll ml-3">
                            <div class="f-6 font-weight-bold text-center mb-40">CFFB Poll</div>
                            <div class="v-center" v-for="(item, index) in teams.cffbPoll" :key="item.id" >
                                <div class="score-cell mr-1">{{cffbPollPoints[index].built}}</div>
                                <div class="score-cell mr-1">{{cffbPollPoints[index].revised}}</div>
                                <TeamCell  :item="item" :index="index"/>
                            </div>
                            <div class="f-4 c-blue text-center mt-5">{{points.polls[2].built * 100 / 25}}% / {{points.polls[2].revised * 100 / 25}}%</div>
                        </div>
                        <div class="prediction-poll ml-3">
                            <div class="f-6 font-weight-bold text-center mb-40">ESPN Power Index Poll</div>
                            <div class="v-center" v-for="(item, index) in teams.espnPoll" :key="item.id" >
                                <div class="score-cell mr-1">{{espnPollPoints[index].built}}</div>
                                <div class="score-cell mr-1">{{espnPollPoints[index].revised}}</div>
                                <TeamCell  :item="item" :index="index"/>
                            </div>
                            <div class="f-4 c-blue text-center mt-5">{{points.polls[3].built * 100 / 25}}% / {{points.polls[3].revised * 100 / 25}}%</div>
                        </div>
                        <div class="prediction-poll ml-3">
                            <div class="f-6 font-weight-bold text-center mb-40">CBSSports.com Poll</div>
                            <div class="v-center" v-for="(item, index) in teams.cbsSportsPoll" :key="item.id" >
                                <div class="score-cell mr-1">{{cbsPollPoints[index].built}}</div>
                                <div class="score-cell mr-1">{{cbsPollPoints[index].revised}}</div>
                                <TeamCell  :item="item" :index="index"/>
                            </div>
                            <div class="f-4 c-blue text-center mt-5">{{points.polls[4].built * 100 / 25}}% / {{points.polls[4].revised * 100 / 25}}%</div>
                        </div>
                    </div>
                </div>
                <div class="score-area v-start mx-5 mb-3">
                    <div class="shut-outs">
                        <div class="v-end">
                            <div class="c-blue f-4">Shut outs<span class="text-decoration-underline ml-3">{{points.shutout}}</span></div>
                            <div class="team-wrapper mx-3">
                                <div class="label f-3 c-blue text-center">Upset pick of the week</div>
                                <div class="team text-center f-3 border-radius-5 bgc-white mt-1">{{teams.upsetTeam ? teams.upsetTeam.school : ''}}</div>
                            </div>
                            <div class="c-blue f-4">
                                {{points.upset}}
                            </div>
                        </div>
<!--                        <div class="mt-4">-->
<!--                            <div class="font-weight-bold text-center">Top Conferences this week</div>-->
<!--                            <table class="table conference-rank-table mt-2 ml-3">-->
<!--                                <thead>-->
<!--                                <tr>-->
<!--                                    <th class="rank-col">Rk</th>-->
<!--                                    <th class="team-col">Conferences</th>-->
<!--                                    <th>#</th>-->
<!--                                </tr>-->
<!--                                </thead>-->
<!--                                <tbody>-->
<!--                                <tr v-for="(item, index) in topConferences" :key="index">-->
<!--                                    <td class="rank-col">{{index + 1}}</td>-->
<!--                                    <td class="team-col">-->
<!--                                        <drop :key="index" :item="item" :index="index"-->
<!--                                              class="mytop25"-->
<!--                                              @drop="handleTopConferenceDrop(index, ...arguments)">-->
<!--                                            <TeamCell :item="topConferences[index]" :win-flag="false" :is-show-logo="true"/>-->
<!--                                        </drop>-->
<!--                                    </td>-->
<!--                                    <td>0</td>-->
<!--                                </tr>-->
<!--                                </tbody>-->
<!--                            </table>-->
<!--                        </div>-->
                    </div>
                    <div class="state-point d-flex align-items-end justify-content-center ml-5">
                        <div class="team-wrapper mx-3">
                            <div class="f-3 text-center">State Match Points Team</div>
                            <div class="team text-center f-3 border-radius-5 bgc-white mt-1">{{teams.pointsTeam ? teams.pointsTeam.school : ''}}</div>
                        </div>
                        <div class="c-blue f-4">
                            {{points.matchPoint}}
                        </div>
                    </div>
                    <div class="points c-blue">Pts.</div>
                    <div class="score-payments v-start">
                        <div class="poll-score-wrapper">
                            <div class="v-center">
                                <div class="point f-4 c-blue text-decoration-underline text-center">{{matchTotalPoint(0).built}}</div>
                                <div class="point f-4 c-blue text-decoration-underline text-center ml-2">{{matchTotalPoint(0).revised}}</div>
                            </div>
                            <div class="v-center">
                                <div class="total-price f-2 text-center bgc-white">${{balance.polls[0].built}}</div>
                                <div class="total-price f-2 text-center bgc-white border-left-none">${{balance.polls[0].revised}}</div>
                            </div>
                            <div class="v-start mt-3">
                                <div>
                                    <div class="balance-price border-bottom-none f-2 bgc-white text-center">$1</div>
                                    <div class="balance-price border-bottom-none f-2 bgc-white text-center">$2</div>
                                    <div class="balance-price f-2 bgc-white text-center">$5</div>
                                </div>
                                <div>
                                    <div class="balance-price border-left-none border-bottom-none f-2 bgc-white text-center">$1</div>
                                    <div class="balance-price border-left-none border-bottom-none f-2 bgc-white text-center">$2</div>
                                    <div class="balance-price border-left-none f-2 bgc-white text-center">$5</div>
                                </div>
                            </div>
                        </div>
                        <div class="poll-score-wrapper">
                            <div class="v-center">
                                <div class="point f-4 c-blue text-decoration-underline text-center">{{matchTotalPoint(1).built}}</div>
                                <div class="point f-4 c-blue text-decoration-underline text-center ml-2">{{matchTotalPoint(1).revised}}</div>
                            </div>
                            <div class="v-center">
                                <div class="total-price f-2 text-center bgc-white">${{balance.polls[1].built}}</div>
                                <div class="total-price f-2 text-center bgc-white border-left-none">${{balance.polls[1].revised}}</div>
                            </div>
                            <div class="v-start mt-3">
                                <div>
                                    <div class="balance-price border-bottom-none f-2 bgc-white text-center">$1</div>
                                    <div class="balance-price border-bottom-none f-2 bgc-white text-center">$2</div>
                                    <div class="balance-price f-2 bgc-white text-center">$5</div>
                                </div>
                                <div>
                                    <div class="balance-price border-left-none border-bottom-none f-2 bgc-white text-center">$1</div>
                                    <div class="balance-price border-left-none border-bottom-none f-2 bgc-white text-center">$2</div>
                                    <div class="balance-price border-left-none f-2 bgc-white text-center">$5</div>
                                </div>
                            </div>
                        </div>
                        <div class="poll-score-wrapper">
                            <div class="v-center">
                                <div class="point f-4 c-blue text-decoration-underline text-center">{{matchTotalPoint(2).built}}</div>
                                <div class="point f-4 c-blue text-decoration-underline text-center ml-2">{{matchTotalPoint(2).revised}}</div>
                            </div>
                            <div class="v-center">
                                <div class="total-price f-2 text-center bgc-white">${{balance.polls[2].built}}</div>
                                <div class="total-price f-2 text-center bgc-white border-left-none">${{balance.polls[2].revised}}</div>
                            </div>
                            <div class="v-start mt-3">
                                <div>
                                    <div class="balance-price border-bottom-none f-2 bgc-white text-center">$1</div>
                                    <div class="balance-price border-bottom-none f-2 bgc-white text-center">$2</div>
                                    <div class="balance-price f-2 bgc-white text-center">$5</div>
                                </div>
                                <div>
                                    <div class="balance-price border-left-none border-bottom-none f-2 bgc-white text-center">$1</div>
                                    <div class="balance-price border-left-none border-bottom-none f-2 bgc-white text-center">$2</div>
                                    <div class="balance-price border-left-none f-2 bgc-white text-center">$5</div>
                                </div>
                            </div>
                        </div>
                        <div class="poll-score-wrapper">
                            <div class="v-center">
                                <div class="point f-4 c-blue text-decoration-underline text-center">{{matchTotalPoint(3).built}}</div>
                                <div class="point f-4 c-blue text-decoration-underline text-center ml-2">{{matchTotalPoint(3).revised}}</div>
                            </div>
                            <div class="v-center">
                                <div class="total-price f-2 text-center bgc-white">${{balance.polls[3].built}}</div>
                                <div class="total-price f-2 text-center bgc-white border-left-none">${{balance.polls[3].revised}}</div>
                            </div>
                            <div class="v-start mt-3">
                                <div>
                                    <div class="balance-price border-bottom-none f-2 bgc-white text-center">$1</div>
                                    <div class="balance-price border-bottom-none f-2 bgc-white text-center">$2</div>
                                    <div class="balance-price f-2 bgc-white text-center">$5</div>
                                </div>
                                <div>
                                    <div class="balance-price border-left-none border-bottom-none f-2 bgc-white text-center">$1</div>
                                    <div class="balance-price border-left-none border-bottom-none f-2 bgc-white text-center">$2</div>
                                    <div class="balance-price border-left-none f-2 bgc-white text-center">$5</div>
                                </div>
                            </div>
                        </div>
                        <div class="poll-score-wrapper w-auto">
                            <div class="v-center">
                                <div class="point f-4 c-blue text-decoration-underline text-center">{{matchTotalPoint(4).built}}</div>
                                <div class="point f-4 c-blue text-decoration-underline text-center ml-2">{{matchTotalPoint(4).revised}}</div>
                            </div>
                            <div class="v-center">
                                <div class="total-price f-2 text-center bgc-white">${{balance.polls[4].built}}</div>
                                <div class="total-price f-2 text-center bgc-white border-left-none">${{balance.polls[4].revised}}</div>
                            </div>
                            <div class="v-start mt-3">
                                <div>
                                    <div class="balance-price border-bottom-none f-2 bgc-white text-center">$1</div>
                                    <div class="balance-price border-bottom-none f-2 bgc-white text-center">$2</div>
                                    <div class="balance-price f-2 bgc-white text-center">$5</div>
                                </div>
                                <div>
                                    <div class="balance-price border-left-none border-bottom-none f-2 bgc-white text-center">$1</div>
                                    <div class="balance-price border-left-none border-bottom-none f-2 bgc-white text-center">$2</div>
                                    <div class="balance-price border-left-none f-2 bgc-white text-center">$5</div>
                                </div>
                            </div>
                        </div>
                        <div class="total-points-wrapper ml-30">
                            <div class="d-flex align-items-start">
                                <div class="v-center">
                                    <div class="pick-six f-4 c-blue">
                                        Pick Six
                                    </div>
                                    <span class="total-point text-decoration-underline c-blue">{{teams.pickSixPoint}}</span>
                                </div>
                                <div class="text-center ml-5">
                                    <span class="total-point f-6 text-decoration-underline c-blue">{{teams.pickSixPoint + getTotalPoints()}}</span>
                                    <div class="f-4 c-blue">Total</div>
                                </div>
                            </div>

                            <div class="mt-5 justify-content-end v-center">
                                <div class="f-6">Total</div>
                                <div class="total-score-box text-center bgc-white ml-3">${{accountBalance}}</div>
                            </div>
                            <!-- <RoundButton @click.native="onBack" class="mt-30 btn-submit" size="small" title="Submit"/> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        GROUP5CONFERENCE,
        BEGINNER_LEVEL_LOWER_SCORE,
        BEGINNER_LEVEL_HIGHER_SCORE,
        BEGINNER_LEVEL_PAYMENT,
        AMATEUR_LEVEL_LOWER_SCORE,
        AMATEUR_LEVEL_HIGHER_SCORE,
        AMATEUR_LEVEL_PAYMENT,
        PROFESSIONAL_LEVEL_LOWER_SCORE,
        PROFESSIONAL_LEVEL_HIGHER_SCORE,
        PROFESSIONAL_LEVEL_PAYMENT,
        TOPSTAR_LEVEL_LOWER_SCORE,
        TOPSTAR_LEVEL_HIGHER_SCORE,
        TOPSTAR_LEVEL_PAYMENT,
        SUPERSTAR_LEVEL_LOWER_SCORE,
        SUPERSTAR_LEVEL_HIGHER_SCORE,
        SUPERSTAR_LEVEL_PAYMENT,
        DEPOSIT_LEVEL_LOW,
        DEPOSIT_LEVEL_NORMAL,
        DEPOSIT_LEVEL_HIGH,
    } from '../../constants/constants';
    import {mapActions, mapState} from "vuex";
    import TeamCell from '../../components/Game/TeamCell';
    import RegularSeasonTeamCell from '../../components/RegularSeason/RegularSeasonTeamCell';
    import Header from "../../components/Header";
    import Navbar from "../../components/Navbar";
    import {Drag, Drop} from 'vue-drag-drop';
    import {currentYear} from "../../utils/functions";

    export default {
        name: "RegularSeasonDoubleMatchScoring",
        components: {
            Navbar,
            Header,
            TeamCell,
            // Drop,
            RegularSeasonTeamCell
        },
        data: () => ({
            year: currentYear(),
            week: '9',
            date : 'Aug 31',
            leagueName : 'SEC East',
            teamName : 'StrikeForce1',
            totalPoints : 0.0,
            accountBalance: 0.0,

            apPollPoints : [],
            coachesPollPoints : [],
            cffbPollPoints : [],
            espnPollPoints : [],
            cbsPollPoints : [],

            points : {
                shutout : 0.0,
                upset : 0.0,
                matchPoint: 0,
                polls: [
                    {built: 0.0, revised: 0.0},
                    {built: 0.0, revised: 0.0},
                    {built: 0.0, revised: 0.0},
                    {built: 0.0, revised: 0.0},
                    {built: 0.0, revised: 0.0}
                ],
                pickSix : 0.0,
                count: [
                    {built: 0, revised: 0},
                    {built: 0, revised: 0},
                    {built: 0, revised: 0},
                    {built: 0, revised: 0},
                    {built: 0, revised: 0}
                ],
            },

            balance : {
                polls: [],
                total: 0.0
            },

            topConferences : [{}, {}, {}]
        }),

        props: {
            ownerName : { type: String },
            week: { type: Number }
        },
        async created() {
            if (this.$route.query && this.$route.query.season) {
                this.regular_season.currentSeason = Number(this.$route.query.season);
                this.week = this.$route.query.season;
            }
            for (let i = 0; i < 5; i++) {
                this.points.polls.push({
                    built: 0,
                    revised: 0
                });
                this.balance.polls.push({
                    built: 0,
                    revised: 0
                });
            }

            for (let i = 0; i < 5; i++) {
                this.points.polls.push({
                    built: 0,
                    revised: 0
                });
            }
            for (let i = 0; i < 25; i++) {
                this.apPollPoints.push({
                    built: 0,
                    revised: 0
                });
                this.coachesPollPoints.push({
                    built: 0,
                    revised: 0
                });
                this.cffbPollPoints.push({
                    built: 0,
                    revised: 0
                });
                this.espnPollPoints.push({
                    built: 0,
                    revised: 0
                });
                this.cbsPollPoints.push({
                    built: 0,
                    revised: 0
                });
                if (this.teams.builtPoll[i]) {
                    if (this.teams.builtPoll[i].school == this.teams.apPoll[i].school) {
                        this.apPollPoints[i].built = 1;
                    }
                    if (this.teams.builtPoll[i].school == this.teams.coachesPoll[i].school) {
                        this.coachesPollPoints[i].built = 1;
                    }
                    if (this.teams.revisedPoll[i].school == this.teams.cffbPoll[i].school) {
                        this.cffbPollPoints[i].built = 2;
                    }
                    if (this.teams.builtPoll[i].school == this.teams.espnPoll[i].school) {
                        this.espnPollPoints[i].built = 1;
                    }
                    if (this.teams.builtPoll[i].school == this.teams.cbsSportsPoll[i].school) {
                        this.cbsPollPoints[i].built = 1;
                    }
                }
                if (this.teams.revisedPoll[i]) {
                    if (this.teams.revisedPoll[i].school == this.teams.apPoll[i].school) {
                        this.apPollPoints[i].revised = 1;
                    }
                    if (this.teams.revisedPoll[i].school == this.teams.coachesPoll[i].school) {
                        this.coachesPollPoints[i].revised = 1;
                    }
                    if (this.teams.revisedPoll[i].school == this.teams.cffbPoll[i].school) {
                        this.cffbPollPoints[i].revised = 2;
                    }
                    if (this.teams.revisedPoll[i].school == this.teams.espnPoll[i].school) {
                        this.espnPollPoints[i].revised = 1;
                    }
                    if (this.teams.revisedPoll[i].school == this.teams.cbsSportsPoll[i].school) {
                        this.cbsPollPoints[i].revised = 1;
                    }
                }
            }


            for (let i = 0; i < 5; i ++) {
                this.matchTotalPoint(i);
                this.matchBalance(i);
                this.matchTrending(i);
            }

            this.getTotalPoints();
            this.getTotalBalance();


            await this.matchResult();

            const revisedPollTeams = this.teams.revisedPoll;
            const conferences = revisedPollTeams.map(team => team.conference);

            if (this.teams.topConferences && this.teams.topConferences.length) {
                this.topConferences = this.teams.topConferences;
            }
        },
        async mounted() {

        },

        updated() {
        },

        computed: {
            ...mapState({
                users: state => state.users,
                teams: state => state.teams,
                regular_season: state => state.regular_season
            }),

            getBuiltPollWinLoss(){
                const builtPollWinLoss = [];
                const year = this.regular_season.currentYear;
                const week = this.regular_season.currentWeek;
                if (this.teams.matchResult[year] && this.teams.matchResult[week]) {
                    const matchResult = this.teams.matchResult[year][week];
                    for (var j = 0; j < this.teams.builtPoll.length; j++) {

                        var winLossObj = {
                            win : 0,
                            loss : 0
                        };
                        builtPollWinLoss.push( winLossObj );

                        for( var i = 0; i < matchResult.length; i++ ) {


                            if ( this.teams.builtPoll[j].school === matchResult[i].teams[0].school) {
                                var homePoints3 = matchResult[i].teams[0].points;
                                var awayPoints3 = matchResult[i].teams[1].points;
                                if (homePoints3 > awayPoints3) {
                                    builtPollWinLoss[j].win++;
                                }
                                else {
                                    builtPollWinLoss[j].loss++;
                                }
                            }
                            else if ( this.teams.builtPoll[j].school === matchResult[i].teams[1].school ) {
                                var homePoints4 = matchResult[i].teams[0].points;
                                var awayPoints4 = matchResult[i].teams[1].points;
                                if (homePoints4 > awayPoints4) {
                                    builtPollWinLoss[j].loss++;
                                }
                                else {
                                    builtPollWinLoss[j].win++;
                                }
                            }
                        }
                    }
                }
                return builtPollWinLoss;
            },

            getRevisedPollWinLoss(){
                const revisedPollWinLoss = [];
                const year = this.regular_season.currentYear;
                const week = this.regular_season.currentWeek;
                if (this.teams.matchResult[year] && this.teams.matchResult[week]) {
                    const matchResult = this.teams.matchResult[year][week];
                    for (var j = 0; j < this.teams.revisedPoll.length; j++) {

                        var winLossObj = {
                            win : 0,
                            loss : 0
                        };
                        revisedPollWinLoss.push( winLossObj );

                        for( var i = 0; i < matchResult.length; i++ ) {


                            if ( this.teams.revisedPoll[j].school === matchResult[i].teams[0].school) {
                                var homePoints3 = matchResult[i].teams[0].points;
                                var awayPoints3 = matchResult[i].teams[1].points;
                                if (homePoints3 > awayPoints3) {
                                    revisedPollWinLoss[j].win++;
                                }
                                else {
                                    revisedPollWinLoss[j].loss++;
                                }
                            }
                            else if ( this.teams.revisedPoll[j].school === matchResult[i].teams[1].school ) {
                                var homePoints4 = matchResult[i].teams[0].points;
                                var awayPoints4 = matchResult[i].teams[1].points;
                                if (homePoints4 > awayPoints4) {
                                    revisedPollWinLoss[j].loss++;
                                }
                                else {
                                    revisedPollWinLoss[j].win++;
                                }
                            }
                        }
                    }
                }
                return revisedPollWinLoss;
            },

            getBuiltPollWinFlags(){
                const winFlags = [];
                const year = this.regular_season.currentYear;
                const week = this.regular_season.currentWeek;
                if (this.teams.matchResult[year] && this.teams.matchResult[week]) {
                    const matchResult = this.teams.matchResult[year][week];
                    if (matchResult) {
                        for (var j = 0; j < this.teams.builtPoll.length; j++) {
                            winFlags.push( false );
                            const firstIndex = matchResult.findIndex(
                                (item) => item.teams[0].school == this.teams.builtPoll[j].school);
                            const secondIndex = matchResult.findIndex(
                                (item) => item.teams[1].school == this.teams.builtPoll[j].school);
                            if (firstIndex >= 0) {
                                var homePoints3 = matchResult[firstIndex].teams[0].points;
                                var awayPoints3 = matchResult[firstIndex].teams[1].points;
                                if (homePoints3 >= awayPoints3) {
                                    winFlags[j] = true;
                                }
                                else {
                                    winFlags[j] = false;
                                }
                            }
                            if (secondIndex >= 0) {
                                var homePoints4 = matchResult[secondIndex].teams[0].points;
                                var awayPoints4 = matchResult[secondIndex].teams[1].points;
                                if (homePoints4 > awayPoints4) {
                                    winFlags[j] = false;
                                }
                                else {
                                    winFlags[j] = true;
                                }
                            }
                        }
                    }
                }
                return winFlags;
            },

            getRevisedPollWinFlags(){
                const winFlags = [];
                const year = this.regular_season.currentYear;
                const week = this.regular_season.currentWeek;
                if (this.teams.matchResult[year] && this.teams.matchResult[week]) {
                    const matchResult = this.teams.matchResult[year][week];
                    if (matchResult) {
                        for (var j = 0; j < this.teams.revisedPoll.length; j++) {
                            winFlags.push( false );
                            const firstIndex = matchResult.findIndex(
                                (item) => item.teams[0].school == this.teams.revisedPoll[j].school);
                            const secondIndex = matchResult.findIndex(
                                (item) => item.teams[1].school == this.teams.revisedPoll[j].school);
                            if (firstIndex >= 0) {
                                var homePoints3 = matchResult[firstIndex].teams[0].points;
                                var awayPoints3 = matchResult[firstIndex].teams[1].points;
                                if (homePoints3 >= awayPoints3) {
                                    winFlags[j] = true;
                                }
                                else {
                                    winFlags[j] = false;
                                }
                            }
                            if (secondIndex >= 0) {
                                var homePoints4 = matchResult[secondIndex].teams[0].points;
                                var awayPoints4 = matchResult[secondIndex].teams[1].points;
                                if (homePoints4 > awayPoints4) {
                                    winFlags[j] = false;
                                }
                                else {
                                    winFlags[j] = true;
                                }
                            }
                        }
                    }
                }
                return winFlags;
            },

            'username': function() {
                var name = "";
                if (this.users.currentUser) {
                    name = this.users.currentUser.firstName + " " + this.users.currentUser.lastName;
                }
                return name;
            },

        },
        methods: {
            ...mapActions([
                'getAllTeams',
                'updateUser',
                'getMatchResult'
            ]),

            onBack() {
                this.$router.back();
                // this.$router.push({name: 'RegularSeasonWk1Schedule'});
            },

            onNext() {
                this.teams.topConferences = this.topConferences;
                this.teams.myTop25 = [...this.teams.revisedPoll];
                if (this.regular_season.currentSeason == 2) {
                    this.$router.push({name: 'ConferencePredictionWk3'});
                } else if (this.regular_season.currentSeason == 7) {
                    this.$router.push({name: 'ConferencePredictionWk8'});
                } else {
                    this.regular_season.currentSeason += 1;
                    this.$router.push({name: `RegularSeasonWk${this.regular_season.currentSeason}Schedule`});
                }

                // this.$router.back();
            },

            async matchResult () {
                const data = {
                    user: this.users.currentUser._id,
                    year: this.year,
                    week: this.week
                }
                await this.getMatchResult(data);
            },

            onResetUpset() {

            },

            // matchBalance(cid) {
            //     const builtCount = this.points.polls[cid].built;
            //     const revisedCount = this.points.polls[cid].revised;
            //     const deposit = 2;
            //     if (deposit === 2 ) {
            //         if (builtCount > 20)
            //             this.balance.polls[cid].built = 20;
            //         else if (builtCount > 16)
            //             this.balance.polls[cid].built = 15;
            //         else if (builtCount > 12)
            //             this.balance.polls[cid].built = 12;
            //         else if (builtCount > 9)
            //             this.balance.polls[cid].built = 8;
            //         else if (builtCount > 5)
            //             this.balance.polls[cid].built = 4;
            //         else
            //             this.balance.polls[cid].built = 0;

            //         if (revisedCount > 20)
            //             this.balance.polls[cid].revised = 20;
            //         else if (revisedCount > 16)
            //             this.balance.polls[cid].revised = 15;
            //         else if (revisedCount > 12)
            //             this.balance.polls[cid].revised = 12;
            //         else if (revisedCount > 9)
            //             this.balance.polls[cid].revised = 8;
            //         else if (revisedCount > 5)
            //             this.balance.polls[cid].revised = 4;
            //         else
            //             this.balance.polls[cid].revised = 0;
            //     }
            // },

            matchTotalPoint(cid) {
                let i;
                let sumBuilt = 0;
                let sumRevised = 0;
                if (cid === 0) {
                    for (i = 0; i < 25; i++) {
                        sumBuilt += this.apPollPoints[i].built;
                        sumRevised += this.apPollPoints[i].revised;
                    }
                    this.points.polls[cid].built = sumBuilt;
                    this.points.polls[cid].revised = sumRevised;
                    return this.points.polls[cid];
                }
                if (cid === 1) {
                    for (i = 0; i < 25; i++) {
                        sumBuilt += this.coachesPollPoints[i].built;
                        sumRevised += this.coachesPollPoints[i].revised;
                    }
                    this.points.polls[cid].built = sumBuilt;
                    this.points.polls[cid].revised = sumRevised;
                    return this.points.polls[cid];
                }
                if (cid === 2) {
                    for (i = 0; i < 25; i++) {
                        sumBuilt += this.cffbPollPoints[i].built;
                        sumRevised += this.cffbPollPoints[i].revised;
                    }
                    this.points.polls[cid].built = sumBuilt;
                    this.points.polls[cid].revised = sumRevised;
                    return this.points.polls[cid];
                }
                if (cid === 3) {
                    for (i = 0; i < 25; i++) {
                        sumBuilt += this.espnPollPoints[i].built;
                        sumRevised += this.espnPollPoints[i].revised;
                    }
                    this.points.polls[cid].built = sumBuilt;
                    this.points.polls[cid].revised = sumRevised;
                    return this.points.polls[cid];
                }
                if (cid === 4) {
                    for (i = 0; i < 25; i++) {
                        sumBuilt += this.cbsPollPoints[i].built;
                        sumRevised += this.cbsPollPoints[i].revised;
                    }
                    this.points.polls[cid].built = sumBuilt;
                    this.points.polls[cid].revised = sumRevised;
                    return this.points.polls[cid];
                }
            },

            getTotalPoints() {
                let sum = 0;
                for (let i = 0; i < 5; i ++) {
                    sum += this.points.polls[i].built;
                    sum += this.points.polls[i].revised;
                }
                this.totalPoints = sum;
                return sum;
            },

            matchBalance(cid) {
                // const count = this.points.count[cid];
                const deposit = DEPOSIT_LEVEL_NORMAL;
                // this.balancePerDeposit(deposit, cid)
                return this.balancePerDeposit(deposit, cid);
            },

            balancePerDeposit(deposit, cid) {
                let i;
                let built_sum = 0;
                let revised_sum = 0;
                if (cid === 0) {
                    for (i = 0; i < 25; i++) {
                        if (this.apPollPoints[i].built > 0.5) {
                            built_sum += 1;
                        }
                        if (this.apPollPoints[i].revised > 0.5) {
                            revised_sum += 1;
                        }
                    }
                    this.balance.polls[cid].built = this.calculateBalance(built_sum, deposit);
                    this.balance.polls[cid].revised = this.calculateBalance(revised_sum, deposit);
                    return this.balance.polls[cid];
                }
                else if (cid === 1) {
                    for (i = 0; i < 25; i++) {
                        if (this.coachesPollPoints[i].built > 0.5) {
                            built_sum += 1;
                        }
                        if (this.coachesPollPoints[i].revised > 0.5) {
                            revised_sum += 1;
                        }
                    }
                    this.balance.polls[cid].built = this.calculateBalance(built_sum, deposit);
                    this.balance.polls[cid].revised = this.calculateBalance(revised_sum, deposit);
                    return this.balance.polls[cid];
                } 
                else if (cid === 2) {
                    for (i = 0; i < 25; i++) {
                        if (this.cffbPollPoints[i].built > 0.5) {
                            built_sum += 1;
                        }
                        if (this.cffbPollPoints[i].revised > 0.5) {
                            revised_sum += 1;
                        }
                    }
                    this.balance.polls[cid].built = this.calculateBalance(built_sum, deposit);
                    this.balance.polls[cid].revised = this.calculateBalance(revised_sum, deposit);
                    return this.balance.polls[cid];
                }
                else if (cid === 3) {
                    for (i = 0; i < 25; i++) {
                        if (this.espnPollPoints[i].built > 0.5) {
                            built_sum += 1;
                        }
                        if (this.espnPollPoints[i].revised > 0.5) {
                            revised_sum += 1;
                        }
                    }
                    this.balance.polls[cid].built = this.calculateBalance(built_sum, deposit);
                    this.balance.polls[cid].revised = this.calculateBalance(revised_sum, deposit);
                    return this.balance.polls[cid];
                }
                else if (cid === 4) {
                    for (i = 0; i < 25; i++) {
                        if (this.cbsPollPoints[i].built > 0.5) {
                            built_sum += 1;
                        }
                        if (this.cbsPollPoints[i].revised > 0.5) {
                            revised_sum += 1;
                        }
                    }
                    this.balance.polls[cid].built = this.calculateBalance(built_sum, deposit);
                    this.balance.polls[cid].revised = this.calculateBalance(revised_sum, deposit);
                    return this.balance.polls[cid];
                }
                return 0
            },

            calculateBalance(sum, deposit) {
                if (deposit == DEPOSIT_LEVEL_LOW) {
                    if (sum >= BEGINNER_LEVEL_LOWER_SCORE && sum <= BEGINNER_LEVEL_HIGHER_SCORE) {
                        return BEGINNER_LEVEL_PAYMENT[0];
                    }
                    else if (sum >= AMATEUR_LEVEL_LOWER_SCORE && sum <= AMATEUR_LEVEL_HIGHER_SCORE) {
                        return AMATEUR_LEVEL_PAYMENT[0]; 
                    }
                    else if (sum >= PROFESSIONAL_LEVEL_LOWER_SCORE && sum <= PROFESSIONAL_LEVEL_HIGHER_SCORE) {
                        return PROFESSIONAL_LEVEL_PAYMENT[0];
                    }
                    else if (sum >= TOPSTAR_LEVEL_LOWER_SCORE && sum <= TOPSTAR_LEVEL_HIGHER_SCORE) {
                        return TOPSTAR_LEVEL_PAYMENT[0];
                    }
                    else if (sum >= SUPERSTAR_LEVEL_LOWER_SCORE && sum <= SUPERSTAR_LEVEL_HIGHER_SCORE) {
                        return SUPERSTAR_LEVEL_PAYMENT[0];
                    }
                }
                else if (deposit == DEPOSIT_LEVEL_NORMAL) {
                    if (sum >= BEGINNER_LEVEL_LOWER_SCORE && sum <= BEGINNER_LEVEL_HIGHER_SCORE) {
                        return BEGINNER_LEVEL_PAYMENT[1];
                    }
                    else if (sum >= AMATEUR_LEVEL_LOWER_SCORE && sum <= AMATEUR_LEVEL_HIGHER_SCORE) {
                        return AMATEUR_LEVEL_PAYMENT[1]; 
                    }
                    else if (sum >= PROFESSIONAL_LEVEL_LOWER_SCORE && sum <= PROFESSIONAL_LEVEL_HIGHER_SCORE) {
                        return PROFESSIONAL_LEVEL_PAYMENT[1];
                    }
                    else if (sum >= TOPSTAR_LEVEL_LOWER_SCORE && sum <= TOPSTAR_LEVEL_HIGHER_SCORE) {
                        return TOPSTAR_LEVEL_PAYMENT[1];
                    }
                    else if (sum >= SUPERSTAR_LEVEL_LOWER_SCORE && sum <= SUPERSTAR_LEVEL_HIGHER_SCORE) {
                        return SUPERSTAR_LEVEL_PAYMENT[1];
                    }
                }
                else if (deposit == DEPOSIT_LEVEL_HIGH) {
                    if (sum >= BEGINNER_LEVEL_LOWER_SCORE && sum <= BEGINNER_LEVEL_HIGHER_SCORE) {
                        return BEGINNER_LEVEL_PAYMENT[2];
                    }
                    else if (sum >= AMATEUR_LEVEL_LOWER_SCORE && sum <= AMATEUR_LEVEL_HIGHER_SCORE) {
                        return AMATEUR_LEVEL_PAYMENT[2]; 
                    }
                    else if (sum >= PROFESSIONAL_LEVEL_LOWER_SCORE && sum <= PROFESSIONAL_LEVEL_HIGHER_SCORE) {
                        return PROFESSIONAL_LEVEL_PAYMENT[2];
                    }
                    else if (sum >= TOPSTAR_LEVEL_LOWER_SCORE && sum <= TOPSTAR_LEVEL_HIGHER_SCORE) {
                        return TOPSTAR_LEVEL_PAYMENT[2];
                    }
                    else if (sum >= SUPERSTAR_LEVEL_LOWER_SCORE && sum <= SUPERSTAR_LEVEL_HIGHER_SCORE) {
                        return SUPERSTAR_LEVEL_PAYMENT[2];
                    }
                }
                return 0;
            },

            matchTrending(cid) {
              let i;
              let built_sum = 0;
              let revised_sum = 0;
              if (cid === 0) {
                  for (i = 0; i < 25; i++) {
                      if (this.apPollPoints[i].built > 0.5) {
                          built_sum += 4;
                      }
                      if (this.apPollPoints[i].revised > 0.5) {
                          revised_sum += 4;
                      }
                  }
                  this.points.count[cid].built = built_sum;
                  this.points.count[cid].revised = revised_sum;
                  return this.points.count[cid];
              }
              if (cid === 1) {
                  for (i = 0; i < 25; i++) {
                      if (this.coachesPollPoints[i].built > 0) {
                          built_sum += 4;
                      }
                      if (this.coachesPollPoints[i].revised > 0) {
                          revised_sum += 4;
                      }
                  }
                  this.points.count[cid].built = built_sum;
                  this.points.count[cid].revised = revised_sum;
                  return this.points.count[cid];
              }
              if (cid === 2) {
                  for (i = 0; i < 25; i++) {
                      if (this.cffbPollPoints[i].built > 0) {
                          built_sum += 4;
                      }
                      if (this.cffbPollPoints[i].revised > 0) {
                          revised_sum += 4;
                      }
                  }
                  this.points.count[cid].built = built_sum;
                  this.points.count[cid].revised = revised_sum;
                  return this.points.count[cid];
              }
              if (cid === 3) {
                  for (i = 0; i < 25; i++) {
                      if (this.espnPollPoints[i].built > 0) {
                          built_sum += 4;
                      }
                      if (this.espnPollPoints[i].revised > 0) {
                          revised_sum += 4;
                      }
                  }
                  this.points.count[cid].built = built_sum;
                  this.points.count[cid].revised = revised_sum;
                  return this.points.count[cid];
              }
              if (cid === 4) {
                  for (i = 0; i < 25; i++) {
                      if (this.cbsPollPoints[i].built > 0) {
                          built_sum += 4;
                      }
                      if (this.cbsPollPoints[i].revised > 0) {
                          revised_sum += 4;
                      }
                  }
                  this.points.count[cid].built = built_sum;
                  this.points.count[cid].revised = revised_sum;
                  return this.points.count[cid];
              }
              return 0;
            },

            getTotalBalance() {
                let sum = 0;
                for (let i = 0; i < 5; i ++) {
                    sum += this.balance.polls[i].built;
                    sum += this.balance.polls[i].revised;
                }
                this.accountBalance = sum;
            },

            handleTopConferenceDrop(index, data, event) {
                this.topConferences.splice(index, 1, data.team);
            },

        }
    }
</script>

<style lang="scss" scoped="scoped">
    .regular-season {
        font-family: ArvoRegular;

        .page-wrapper {
            overflow: auto;
        }

        .match-description {
            width: 270px;

            .header, .value {
                width: 150px;
            }

            .init-deposit-table{
                border: 1px solid black;
                tr th{
                    background: #c5cae9;
                    padding: 0px 10px;
                    border: 1px solid black;
                }

                tr td{
                    background: #ffffff;
                    padding: 0px 10px;
                    border: 1px solid black;
                }
            }

            .match-deposit-table{
                border: 1px solid black;

                tr th{
                    background: #c5cae9;
                    border: 1px solid black;
                    padding: 0px 10px;

                }
                tr td{
                    background: #ffffff;
                    padding: 0px 10px;
                    border: 1px solid black;
                    height: 20px;
                }

            }
        }

        .match-contents {
            .score-area {
                .shut-outs {
                    min-width: 280px;
                    .team-wrapper {
                        min-width: 170px;
                        .team {
                            width: 100%;
                            height: 30px;
                            padding: 0.25rem;
                        }
                    }
                    .conference-rank-table {
                        border: 1px solid black;
                        font-size: 13px;
                        width: 250px;

                        tr th, td{
                            border: 1px solid black;
                            text-align: center;
                            padding: 0.25rem;
                            vertical-align: middle;
                        }

                        .rank-col {
                            width: 40px;
                        }
                        .team-col {
                            width: 150px;

                            .team-cell {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                .state-point {
                    min-width: 280px;
                    .team-wrapper {
                        min-width: 170px;
                        .team {
                            width: 100%;
                            height: 30px;
                            padding: 0.25rem;
                        }
                    }
                }

                .points{
                    margin-top: 30px;
                    margin-left: 0px;
                    font-size: 16px;
                }

                .score-payments{
                    margin-top: 30px;
                    margin-left: 15px;
                    font-size: 14px;

                    .poll-score-wrapper {
                        width: 240px;

                        .point{
                            width: 30px;
                        }

                        .total-price {
                            width: 35px;
                            border: 1px solid black;
                        }

                        .balance-price {
                            width: 35px;
                            border: 1px solid black;
                        }
                    }

                    .total-points-wrapper{
                        margin-right: 20px;

                        .pick-six {
                            width: 60px;
                        }

                        .total-score-box{
                            border: 1px solid black;
                            width: 50px;
                        }

                        .btn-submit{
                            width: 120px;
                        }

                    }
                }
            }
        }
    }
</style>
