<template>
    <div class="pos-team-cell v-center justify-content-between">
        <div class="rank-box v-center" v-if="!isShowLogo">
            <div class="shield-mark">
                <img class="icon" src="@/assets/img/rank.png"/>
                <span class="rank">{{index + 1}}</span>
            </div>
            <img class="logoImage" :src="logo" v-if="(logo != null)" alt="" onerror="this.style.display = 'none'"/>
            <span class="team-name" :class="(winFlag == true || winFlag == 1 ? 'win-color' : ( winFlag == false || winFlag == -1 ? 'loss-color':''))">{{item ? item.school : ''}}</span>
        </div>
        <span class="pos" :class="(winFlag == true || winFlag == 1 ? 'win-color' : ( winFlag == false || winFlag == -1 ? 'loss-color':''))" v-if="initRank">({{initRank}})</span>
    </div>
</template>

<script>
    export default {
        name: 'InitRankTeamCell',
        props: {
            index: { type: Number },
            item: { type: Object },
            isShowLogo: { type: Boolean },
            initRank : { type: Number },
            winFlag : { type : Number || Boolean },
        },
        data: () => ({

        }),
        computed: {
            logo() {
                if (this.item && this.item.logos && this.item.logos.length > 0) {
                    return this.item.logos[0];
                }
                return null;
            }
        }
    }
</script>
<style lang="scss" scope>
    .pos-team-cell {
        font-size: 12px;
        margin-bottom: 5px;
        background-color: white;
        border-radius: 5px;
        padding: 7px;
        height: 34px;
        max-width: 150px;
        min-width: 150px;
        cursor: pointer;

        .team-name {
            margin-left: 5px;
            max-width: 55px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            //-ms-text-overflow: ellipsis;
            //text-overflow: ellipsis;
        }

        .logoImage {
            width: 20px;
            margin-right: 5px;
            object-fit: contain;
        }

        .pos {
            margin-left: 10px;
        }
        .rank-box {
            position: relative;

            .shield-mark {
                position: relative;
                margin-right: 5px;
            }

            .icon {
                width: 15px;
                height: 17px;
                margin-top: 2px;
            }

            .rank {
                color: white;
                position: absolute;
                top: 4px;
                left: 3px;
                font-size: 8px;
                text-align: center;
                width: 10px;
            }
        }
    }
</style>
