<template>
    <div>
        <v-toolbar dense>
            <v-app-bar-nav-icon color="white" @click="onToggleDrawer" v-if="users.currentUser"></v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <div class="conference-logos">
                <div class="group mr-5">
                    <img class="navbar-logo" src="../assets/img/conferences/aac.png"
                           @click="redirectConference('AAC')" />
                    <img class="navbar-logo fill" src="../assets/img/conferences/acc.png"
                           @click="redirectConference('ACC')" />
                    <img class="navbar-logo fill" src="../assets/img/conferences/big10.png"
                           @click="redirectConference('Big 10')" />
                    <img class="navbar-logo fill" src="../assets/img/conferences/big12.png"
                           @click="redirectConference('Big 12')" />
                    <img class="navbar-logo fill" src="../assets/img/conferences/cusa.png"
                           @click="redirectConference('CUSA')" />
                    <img class="navbar-logo" src="../assets/img/conferences/div1.png"
                           @click="redirectConference('Div 1')" />
                </div>
                <img class="navbar-logo" src="../assets/img/wireframe/logo_new.png"
                       @click="moveHome()" />
                <div class="group ml-5">
                    <img class="navbar-logo" src="../assets/img/conferences/mac.png"
                           @click="redirectConference('MAC')" />
                    <img class="navbar-logo" src="../assets/img/conferences/mw.png"
                           @click="redirectConference('MW')" />
                    <img class="navbar-logo" src="../assets/img/conferences/pac12.png"
                           @click="redirectConference('PAC 12')" />
                    <img class="navbar-logo" src="../assets/img/conferences/sec.png"
                           @click="redirectConference('SEC')" />
                    <img class="navbar-logo fill" src="../assets/img/conferences/sbc.png"
                           @click="redirectConference('SBC')" />
                </div>
            </div>
            <v-spacer></v-spacer>
            <div class="v-center right-menu-item" @click="moveProfile()">
                <v-avatar class="navbar-avatar mr-3" size="36">
                    <img :src="user.avatar ? user.avatar : defaultAvatar" alt="Avatar" />
                </v-avatar>
                <p color="white">{{username}}</p>
            </div>
        </v-toolbar>

        <!-- Left Menu List. -->
        <v-navigation-drawer permanent v-if="drawer === false">
            <v-list dense nav>
                <v-list-item
                    v-for="item in menuItems"
                    :key="item.id"
                    class="list-item mt-5"
                    @click="onClickMenuItem(item)"
                >
                    <v-list-item-icon class="sidebar-icon">
                        <v-icon color="white">{{item.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="sidebar-title" v-html="item.title"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import { isFullTeams } from "@/utils/functions";
import {MESSAGES} from "@/utils/Messages";


export default {
    name: "Navbar",
    components: {
    },
    props: {
        user: { type: Object },
    },
    mounted() {
        this.menuItems = [
            {
                id: 0,
                title: "Edit Profile",
                icon: "mdi-star",
                link: "Profile",
            },
            {
              id: 1,
              title: "Play The Fantasy Standard Poll Game",
              icon: "mdi-star",
              link: "Game1Prev",
            },
            {
              id: 2,
              title: "Fantasy Pre-Season Play-in Challenge",
              icon: "mdi-star",
              link: "Game2Prev",
            },
            {
              id: 3,
              title: "Play Fantasy Poll Re-distribution Game",
              icon: "mdi-star",
              link: "Game3Prev",
            },
            {
                id: 4,
                title: "My Top 25 Poll",
                icon: "mdi-star",
                link: "MyTop25Poll",
            },
            {
                id: 5,
                title: this.getRegularSeasonPages,
                icon: "mdi-star",
                link: "RegularSeasonWk1Schedule",
            },
            {
                id: 6,
                title: this.getPositionalMatchPages,
                icon: "mdi-star",
                link: "RegularSeasonWkMatchScoring",
            },
            {
                id: 7,
                title: "Championship Predictions (Week3)",
                icon: "mdi-star",
                link: "ConferencePredictionsWk3",
            },
            {
                id: 8,
                title: "College Football Weekly Schedule",
                icon: "mdi-star",
                link: "WeeklySchedule",
            },
            {
                id: 9,
                title: "League Divisions",
                icon: "mdi-star",
                link: "MyTop25Poll",
            },
            {
                id: 10,
                title: "Log out",
                icon: "mdi-star",
                link: "logout",
            }
        ];
    },
    computed: {
        ...mapState({
            users: state => state.users,
            teams: state => state.teams,
            regular_season: state => state.regular_season
        }),
        'username': function() {
            var name = "";
            if (this.user) {
                name = this.user.firstName + " " + this.user.lastName;
            }
            return name;
        },
        getRegularSeasonPages() {
            let result = `<div>Regular Season Schedule</div><div style="margin-top: 5px;">`;
            for (let i = 1; i <= 15; i++ ) {
                if (i == this.regular_season.currentSeason) {
                    result += `<a style="color: red; margin: 0px 1px;" href="/RegularSeasonWk${i}Schedule">${i}</a>`
                } else {
                    result += `<a style="color: white; margin: 0px 1px;" href="/RegularSeasonWk${i}Schedule">${i}</a>`
                }
                if (i != 15) {
                    result += `<span style="color: white; margin: 0px 1px">·</span>`
                }
            }
            result += `</div>`;
            return result;
        },
        getPositionalMatchPages() {
            let result = `<div>Positional Match Scoring</div><div style="margin-top: 5px;">`;
            for (let i = 1; i <= 15; i++ ) {
                if (i == this.regular_season.currentSeason) {
                    if (i == 9 || i == 15) {
                        result += `<a style="color: red; margin: 0px 1px;" href="/RegularSeasonDoubleMatchScoring?season=${i}">${i}</a>`
                    } else {
                        result += `<a style="color: red; margin: 0px 1px;" href="/RegularSeasonWkMatchScoring?season=${i}">${i}</a>`
                    }
                } else {
                    if (i == 9 || i == 15) {
                        result += `<a style="color: white; margin: 0px 1px;" href="/RegularSeasonDoubleMatchScoring?season=${i}">${i}</a>`
                    } else {
                        result += `<a style="color: white; margin: 0px 1px;" href="/RegularSeasonWkMatchScoring?season=${i}">${i}</a>`
                    }
                }
                if (i != 15) {
                    result += `<span style="color: white; margin: 0px 1px">·</span>`
                }
            }
            result += `</div>`;
            return result;
        }
    },
    data(){
        return {
            drawer: true,
            isShowRightMenu: false,
            defaultAvatar: require('../assets/img/default_avatar.png'),
            menuItems: [],
            conferenceLinks: [
                {
                    conference: 'AAC',
                    link: 'https://theamerican.org/index.aspx?path=football'
                },
                {
                    conference: 'ACC',
                    link: 'https://theacc.com/index.aspx?path=football'
                },
                {
                    conference: 'Big 10',
                    link: 'https://bigten.org/index.aspx?path=football'
                },
                {
                    conference: 'Big 12',
                    link: 'https://big12sports.com/index.aspx?path=football'
                },
                {
                    conference: 'CUSA',
                    link: 'https://conferenceusa.com/index.aspx?path=football'
                },
                {
                    conference: 'Div 1',
                    link: 'https://www.ncaa.com/standings/football/fbs/independents-fbs'
                },
                {
                    conference: 'MAC',
                    link: 'https://gomacsports.com/?path=football'
                },
                {
                    conference: 'MW',
                    link: 'https://themw.com/index.aspx?path-fball'
                },
                {
                    conference: 'PAC 12',
                    link: 'https://pac-12.com/sport/football'
                },
                {
                    conference: 'SEC',
                    link: 'https://www.secsports.com/clubhouse/football'
                },
                {
                    conference: 'SBC',
                    link: 'https://sunbeltsports.org/index.aspx?path=football'
                },
            ]
        }
    },
    methods: {
        onToggleDrawer() {
          this.drawer = !this.drawer;
        },
        moveProfile() {
            this.$router.push({name: 'Profile'});
        },

        moveHome() {
            const currentPath = this.$router.currentRoute.path;
            if (currentPath !== "/home") {
                this.$router.push({name: 'HomeScreen'});
            }
        },

        redirectConference(conference) {
            const index = this.conferenceLinks.findIndex((item) => item.conference === conference);
            if (index >= 0) {
                window.open(this.conferenceLinks[index].link, '_blank');
            }

        },

        onClickMenuItem(item) {
            if (item.link === 'MyTop25Poll') {
                if (isFullTeams(this.teams.myTop25)) {
                  this.$router.push({name: item.link});
                } else {
                  this.$toasted.show(MESSAGES.INVALID_MY_TOP25_TEAMS);
                }
                this.drawer = !this.drawer;
            } else if (item.link === "logout") {
                localStorage.removeItem('token');
                localStorage.removeItem('currentUser');
                this.$router.push({ path: '/' });
            } else if (item.link.includes('RegularSeason')) {
                this.getRegularSeasonPages();
                console.log('redirect =========>', item.link);
                this.$router.push({name: item.link});
            } else {
                this.$router.push({name: item.link});
            }
        },
    },
}
</script>

<style lang="scss" scoped>
    header{
        background: #2F60C1 !important;
        color: white !important;
    }
    .navbar-logo{
        width: 40px !important;
        height: 40px !important;
        cursor: pointer;
        margin-right: 30px;
        object-fit: cover;

        &.fill {
            height: 30px !important;
            object-fit: fill;
            margin-top: 5px;
        }
    }
    .navbar-avatar{
        cursor: pointer;
    }
    aside{
        position: absolute;
        top: 48px !important;
        z-index: 99 !important;
        height: calc(100% - 48px) !important;
        width: 320px !important;
        background: #2F60C1 !important;
        color: white !important;
    }
    .sidebar-title{
        color: white !important;
        margin-left: 0px !important;
    }
    .list-item{
        border-bottom: 1px solid white;
    }

    .sidebar-icon{
        margin-right: 5px !important;
    }

    .conference-logos {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .group {
            display: flex;
        }
    }

    .right-menu-item {
        cursor: pointer;
    }

</style>
