<template>
    <div class="pos-team-cell">
        <div class="rank-box v-center" v-if="!isShowLogo">
            <img class="icon" src="@/assets/img/rank.png"/>
            <span class="rank">{{index + 1}}</span>
        </div>

        <img class="logoImage" :src="logo" v-if="(logo != null) && isShowLogo" alt="" onerror="this.style.display = 'none'"/>
        <span class="pos" :class="(winFlag == 1 ? 'win-color' : ( winFlag == -1 ? 'loss-color':''))">{{item ? item.school : ''}}</span>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: 'WinLossTeamCell',
        props: {
            index: { type: Number },
            item: { type: Object },
            isShowLogo: { type: Boolean },
            winFlag : {type: Number},
        },
        data: () => ({

        }),
        computed: {
            ...mapState({
                teams: state => state.teams,
            }),
            logo() {
                if (this.item && this.item.logos && this.item.logos.length > 0) {
                    return this.item.logos[0];
                }
                if (this.isShowLogo && this.item) {
                    const index = this.teams.all.findIndex((item) => item.school === this.item.school);
                    if (index >= 0) {
                        if (this.teams.all[index].logos && this.teams.all[index].logos.length > 0) {
                            return this.teams.all[index].logos[0];
                        }
                    }
                }
                return null;
            }
        }
    }
</script>
<style lang="scss" scope>
    .pos-team-cell {
        display: flex;
        align-items: center;

        .pos {
            max-width: 120px;
            white-space: nowrap;
            overflow: hidden;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
            height: 18px;
        }
        .win-color {
            color: blue;
        }
        .loss-color {
            color: red;
        }
    }
</style>
