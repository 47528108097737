<template>
    <div class="viewGameOnboard">
        <div>
            <img :src="boardImage"/>
            <RoundButton class="btnPlay" title="Click To Play" @click.native="onPlay"/>
        </div>        
    </div>
</template>

<script>

import RoundButton from "../RoundButton";
export default {
    name: 'GameOnboard',
    props: {
        type: { type: String },
        onPlay: { type: Function },
    },
    components: {
        RoundButton
    },
    computed: {
        boardImage() {
            if (this.type === "Game1") {
                return require("@/assets/img/wireframe/Game1Overview.png");
            } else if (this.type === "Game2") {
                return require("@/assets/img/wireframe/Game2Overview.png");
            } else if (this.type === "Game3") {
                return require("@/assets/img/wireframe/Game3Overview.png");
            }
            return require("@/assets/img/wireframe/Game1Overview.png");
        }
    },
    data: () => ({
      
    }),
}
</script>
<style lang="scss" scope>
    .viewGameOnboard {
        position: fixed;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .btnPlay {
            margin: auto;
            margin-top: 30px;
            width: 300px;
        }
    }
</style>
