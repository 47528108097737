<template>
    <div class="own-top-team-board">
        <div class="wrapper">
            <div class="title-box" :class="[this.isSelected  ? 'active' : '']" @click="titleClick">My CFFB Top 25</div>
            <draggable v-if="gameType=='game3'" :list="teams" group="people" @change="changeEvent">
                <TeamCell v-for="(item, index) in teams" :key="index" :item="item" :index="index"
                    :color="(index >= 20) ? 'red' : 'blue'"
                />
            </draggable>
            <draggable v-else :list="teams" group="people" @change="changeEvent">
                <TeamCell v-for="(item, index) in teams" :key="index" :item="item" :index="index" />
            </draggable>
        </div>
    </div>
</template>

<script>
    import TeamCell from "./TeamCell";
    import draggable from "vuedraggable";

    export default {
        name: "OwnTopTeamsBoard",
        components: {
            TeamCell,
            draggable
        },
        props: {
            teams: { type: Array },
            gameType: {type: String},
            isSelected: {type: Boolean}
        },
        data: () => ({
            keyword: '',
        }),
        computed: {

        },
        mounted() {

        },
        methods: {
            titleClick() {
                this.$emit('title-click');
            },
            changeEvent() {
                this.$emit('update-rank');
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
