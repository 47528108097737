<template>
    <div class="component game-replacement-teams p-3 border-1">
        <div class="title-box text-center w-100" v-b-tooltip.hover title="Replacement teams are not listed in ranking order; they are listed by wins and losses. Click and drag replacement teams into open positions in your Game-Built poll only. Replacement teams cannot be dragged into the Revised poll column until the Game-Built poll is filled, once Game-Built poll is filled, you are able to revise your poll.">
            <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
            {{title}}
        </div>
        <div class="replacement-titles v-center">
            <div class="regular-sub-title pos-title">
                <div class="f-2 font-weight-bold text-center">Pos</div>
            </div>
            <div class="regular-sub-title wl-title ml-1">
                <div class="f-2 font-weight-bold text-center">W - L</div>
            </div>
            <div class="regular-sub-title team-title ml-1 v-end">
                <div class="f-2 font-weight-bold text-center"></div>
            </div>
            <div class="regular-sub-title conf-title justify-content-center ml-1">
                <div class="f-2 font-weight-bold">Conf</div>
            </div>
            <div class="regular-sub-title ml-1"  v-b-tooltip.hover title=" Identifies teams who are not scheduled to play during the upcoming week.">
                <div class="f-2 font-weight-bold text-center">
                    <img class="exclaim-img mr-0" src="@/assets/img/icon_exclaim.png" />
                    Bye
                </div>
            </div>
        </div>
        <div class="v-start replacement-teams-wrapper overflow-y-auto">
            <div class="pos-wrapper">
                <div class="pos-cell" v-for="(item, index) in getWinLoss" :key="index">{{index + 1}}</div>
            </div>
            <div class="wl-wrapper ml-1">
                <div :class="(getWinFlags[index] == 1 ? 'wl win-color' : getWinFlags[index] == -1 ? 'wl loss-color':'wl')"
                     v-for="(item, index) in getWinLoss" :key="index">
                    <div v-if="item.win == 0 && item.loss == 0">
                        _
                    </div>
                    <div v-else>
                        {{item.win}} - {{item.loss}}
                    </div>
                </div>
                <div class="wl"></div>
            </div>
            <div class="teams ml-1">
                <drag v-for="(item, index) in replacementTeams"
                      class="replacement"
                      :key="index"
                      :draggable="true"
                      :transfer-data="{team : item, type: 'replacement'}">
                    <WinLossTeamCell
                        :index="index"
                        :item="item"
                        :win-flag="getWinFlags[index]"
                        :isShowLogo="true"
                    />
                </drag>
            </div>
            <div class="conferences ml-1">
                <div class="conference-cell" v-for="(item, index) in replacementTeams" :key="index">{{getConferenceNickName(item.conference)}}</div>
            </div>
            <div class="pos-wrapper teams-count-cell ml-1">
                <div class="pos-cell" v-for="(item, index) in getConferenceTeamsCount" :key="index"></div>
<!--                <div class="pos-cell" v-for="(item, index) in getConferenceTeamsCount" :key="index">{{item}}</div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import WinLossTeamCell from "./WinLossTeamCell";
    import {Drag, Drop} from 'vue-drag-drop';
    import {getConferenceNickName} from "../../utils/functions";

    export default {
        name: "RegularSeasonReplacement",
        components: {
            WinLossTeamCell,
            Drag
        },
        data() {
            return {
                title: 'Replacement Teams',
            };
        },
        props: {
            teams: {type: Object},
            builtPoll: {type: Array},
            replacementTeams : {type: Array},
            year: {type: String},
            week: {type: String},
            isGameStarted: {type: Boolean},
            isNextWeekStarted: {type: Boolean},
        },
        computed : {

            getWinFlags(){
                const winFlags = [];
                const year = this.year;
                const week = this.week;
                if (this.teams.matchResult[year]) {
                    const matchResult = this.teams.matchResult[year][week];
                    if (matchResult) {
                        for (var j = 0; j < this.replacementTeams.length; j++) {
                            winFlags.push( 0 );
                            const firstIndex = matchResult.findIndex(
                                (item) => item.teams[0].school == this.replacementTeams[j].school);
                            const secondIndex = matchResult.findIndex(
                                (item) => item.teams[1].school == this.replacementTeams[j].school);
                            if (firstIndex >= 0) {
                                var homePoints3 = matchResult[firstIndex].teams[0].points;
                                var awayPoints3 = matchResult[firstIndex].teams[1].points;
                                if (homePoints3 >= awayPoints3) {
                                    winFlags[j] = 1;
                                }
                                else {
                                    winFlags[j] = -1;
                                }
                            }
                            if (secondIndex >= 0) {
                                var homePoints4 = matchResult[secondIndex].teams[0].points;
                                var awayPoints4 = matchResult[secondIndex].teams[1].points;
                                if (homePoints4 > awayPoints4) {
                                    winFlags[j] = -1;
                                }
                                else {
                                    winFlags[j] = 1;
                                }
                            }
                        }
                    }
                }
                return winFlags;
            },

            getWinLoss(){
                const winLoss = [];
                const year = this.year;
                const week = this.week;
                if (this.teams.matchResult[year]) {
                    const matchResult = this.teams.matchResult[year][week];
                    if (matchResult) {
                        for (var j = 0; j < this.replacementTeams.length; j++) {
                            var winLossObj = this.getTeamWinLossState(this.replacementTeams[j], year, week);
                            winLoss.push( winLossObj );
                        }
                    }
                }
                return winLoss;
            },

            getConferenceTeamsCount(){

                const replacementTeams = this.replacementTeams;

                const teamsCountList = [];
                const conferences = [];

                for( var i = 0; i < replacementTeams.length; i++ ){
                    if( replacementTeams[i] )
                        conferences.push( replacementTeams[i].conference );
                }

                var map = conferences.reduce(function(prev, cur) {
                    prev[cur] = (prev[cur] || 0) + 1;
                    return prev;
                }, {});

                var result = [];

                for(var j in map)
                    result.push([j, map [j]]);

                for( var x = 0; x < conferences.length; x++ ){
                    teamsCountList.push( '' );
                    for( var y = 0; y < result.length; y++ ){
                        if( conferences[x] == result[y][0] ) {
                            teamsCountList[x] = result[y][1].toString();
                            result.splice( y, 1 );
                        }
                    }
                }

                return teamsCountList;
            },

            isFilledBuiltPoll() {
                return this.builtPoll.filter((item) => item.school).length === 25;
            }
        },
        methods : {
            getConferenceNickName,
            getTeamWinLossState(team, year, week) {
                const count = parseInt(week);
                var winLossObj = {
                    win : 0,
                    loss : 0
                };
                for (let i = 1; i <= count; i++) {
                    if (this.teams.matchResult[year]) {
                        const matchResult = this.teams.matchResult[year][i.toString()];
                        if (matchResult) {
                            const firstIndex = matchResult.findIndex(
                                (item) => item.teams[0].school == team.school);
                            const secondIndex = matchResult.findIndex(
                                (item) => item.teams[1].school == team.school);
                            if (firstIndex >= 0) {
                                var homePoints3 = matchResult[firstIndex].teams[0].points;
                                var awayPoints3 = matchResult[firstIndex].teams[1].points;
                                if (homePoints3 > awayPoints3) {
                                    winLossObj.win++;
                                }
                                else {
                                    winLossObj.loss++;
                                }
                            } else if (secondIndex >= 0) {
                                var homePoints4 = matchResult[secondIndex].teams[0].points;
                                var awayPoints4 = matchResult[secondIndex].teams[1].points;
                                if (homePoints4 > awayPoints4) {
                                    winLossObj.loss++;
                                }
                                else {
                                    winLossObj.win++;
                                }
                            } 
                            else {
                              if (i === count) {
                                winLossObj.win = null;
                                winLossObj.loss = null;
                              }
                            }
                        }
                    }
                }
                return winLossObj;
            }
        },
        mounted() {
            // console.log("replacement teams ============>", this.replacementTeams);
        },
    }
</script>

<style lang="scss" scoped>
    .replacement-teams-wrapper {
        max-height: 975px;
    }

    .teams-count-cell {
        width: 40px;
    }
    .regular-sub-title {
        &.pos-title {
            width: 27px;
        }

        &.wl-title {
            width: 50px;
        }

        &.team-title {
            width: 150px;
        }

        &.conf-title {
            width: 60px;
        }
    }
    .pos-team-cell {
        display: flex;
        align-items: center;

        .pos {
            max-width: 120px;
            white-space: nowrap;
            overflow: hidden;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
            height: 18px;
            font-weight: bold;
        }
    }
</style>
