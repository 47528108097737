<template>
    <div>
        <Navbar></Navbar>
        <p class="title">THE FANTASY CHAMPIONSHIP SERIES OF COLLEGE FOOTBALL</p>
        <v-img class="mt-5" src="../assets/img/wireframe/BCSComputerFinalScore.png" height="70vh" contain></v-img>
        <v-btn class="page-link-button next-button" @click="moveBCSComputerPickNationalChampion()">
            Computer Pick
        </v-btn>
    </div>
</template>

<script>
    import Navbar from "../components/Navbar";
    export default {
        name: "BCSComputerFinalScore",
        components: {
            Navbar
        },
        data: () => ({

        }),
        mounted() {

        },
        methods: {
            moveBCSComputerPickNationalChampion: function() {
                this.$router.push({name: 'BCSComputerPickNationalChampion'});
            },
        },
    }
</script>

<style scoped>

</style>