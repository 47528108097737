<template>
    <div class="introduction-box wrapper text-center">
      <div class="game-type-1" v-if="type === 'game1'">
        <div class="f-5 c-dark">So you don't like where teams are ranked!</div>
        <div class="f-20 font-weight-bold my-5">Create your own Top 25 Poll!</div>
        <div class="f-5 c-dark my-3">You'll only have yourself to blame!!</div>
        <div class="my-3">
          <div class="f-8 font-weight-bold">Instructions</div>
          <div class="f-5 c-dark mt-3">Click and drag teams from the scroll box to create your own top 25 poll and then click next to activate your poll and advance to <b>Regular Season Week 1 Schedule.</b></div>
        </div>
        <div class="my-3">
          <div class="f-8 font-weight-bold">Or</div>
          <div class="f-5 c-dark mt-3">Select one of the <b>Top 25 game polls</b> shown above for play. Click on the poll name of your choice to activate poll then click next to advance to <b>Regular Season Week 1 Schedule.</b></div>
        </div>
        <div class="my-3">
          <div class="f-8 font-weight-bold">Note</div>
          <div class="f-5 c-dark mt-3">In this game you act as an Independent pollster in the league, your ability to rank each team during the course of the  season is critical to positioning the right teams into position to play for the <b>National Championship.</b></div>
        </div>
      </div>
      <div class="game-typ-2" v-if="type === 'game2'">
        <div class="f-5 c-dark">So you don't like where teams are ranked!</div>
        <div class="f-20 font-weight-bold my-5">Create your own Top 25 Poll!</div>
        <div class="f-5 c-dark my-3">You'll only have yourself to blame!!</div>
        <div class="my-3">
          <div class="f-8 font-weight-bold">Instructions</div>
          <div class="f-5 c-dark mt-3">
            Click and drag teams from the scroll box to create your own top 25 poll and then click next to activate your poll and advance to
            <b>"The Fantasy Play-in Challenge".</b>
          </div>
        </div>
        <div class="my-3">
          <div class="f-8 font-weight-bold">Or</div>
          <div class="f-5 c-dark my-3">Select one of the <b>Top 25 game polls</b> shown above for play. Click on the poll name of your choice to activate poll then click next to advance to <b>"The Fantasy Play-in Challenge".</b></div>
        </div>
        <div class="my-3">
          <div class="f-8 font-weight-bold">Note</div>
          <div class="f-5 c-dark my-3">In this game you act as an Independent pollster in the league, your ability to rank each team during the course of the  season is critical to positioning the right teams into position to play for the <b>National Championship.</b></div>
        </div>
      </div>
      <div class="game-type-2-chanllenge" v-if="type === 'game2PlayinChallenge'">
        <div class="f-20 font-weight-bold my-5">
          Fantasy Pre-Season play-in Challenge<br>
          "The Look Test"
        </div>
        <div class="f-5 c-dark">
          This is a hypothetical matchup between the top twenty teams in your poll, it will determine the top 10 teams going into the regular season.<br>
          Your judgment will be <b>"critical"</b> to how teams are ranked going into the season.<br>
          Using the fantasy element of <b>"Projection"</b> or <b>(What if)</b>, you get to determine who the winners and losers of these head-to-head match-ups are.
        </div>
        <div class="my-3">
          <div class="f-8 font-weight-bold">Instructions</div>
          <div class="f-5 c-dark">
            Place an X in the box of the team you believe will win in this head-to-head matchup. <br>Click next to see the projected winner results and your new CFFB Top 25 poll.
          </div>
        </div>
      </div>

      <div class="game-type-2-cffb" v-if="type === 'game2NewCFFBPoll'">
        <div class="f-20 font-weight-bold my-5">
          Fantasy Pre-Season play-in Challenge<br>
          "The Look Test"
        </div>
        <div class="f-5 c-dark">
          This is a hypothetical matchup between the top twenty teams in your poll, it will determine the top 10 teams going into the regular season.
        </div>
        <div class="mt-2 f-5 c-dark">
          Your judgment will be <b>"critical"</b> to how teams are ranked going into the season.<br>
        </div>
        <div class="mt-2 f-5 c-dark">
          Using the fantasy element of <b>"Projection"</b> or <b>(What if)</b>, you get to determine who the winners and losers of these head-to-head match-ups are.
        </div>
        <div class="mt-5">
          <div class="f-8 font-weight-bold">Instructions</div>
          <div class="mt-2 f-5 c-dark">
            <b>1.</b>	Place an X in the box of the team you feel will win in a head-to-head matchup. Watch as each selection changes the ranking lineup of teams in your new CFFB Top 25 Poll<br/><br/>
            <b>2.</b>	Once all projected winners are selected, click on the “New CFFB Top 25 Poll” to activate your poll, then click next to advance to Regular Season Week 1 Schedule.<br/><br/>
            <b>3.</b>	If you choose not to activate the <b>New CFFB Top 25 poll</b> by clicking the heading, clicking next allows you to play with your original <b>“My CFFB Top 25 poll”</b> as listed.
          </div>
        </div>
      </div>

      <div class="game-type-3" v-if="type === 'game3'">
        <div class="f-5 c-dark">So you don't like where teams are ranked!</div>
        <div class="f-20 font-weight-bold my-5">Create your own Top 25 Poll!</div>
        <div class="f-5 c-dark">You'll only have yourself to blame!!</div>
        <div class="my-3">
          <div class="f-8 font-weight-bold">Instructions</div>
          <div class="f-5 c-dark">
            Click and drag teams from the scroll box to create your own top 25 poll and then click next to activate your poll and advance to week 1
          </div>
        </div>
        <div class="my-3">
          <div class="f-8 font-weight-bold">Or</div>
          <div class="f-5 c-dark">Select one of the <b>Top 25 game polls</b> shown above for play. Click on the poll name of your choice to activate poll then click next to advance to week 1 <b>"Fantasy Re-Distribution Game".</b></div>
        </div>
        <div class="my-3">
          <div class="f-8 font-weight-bold">Note</div>
          <div class="f-5 c-dark">In this game you act as an Independent pollster in the league, your ability to rank each team during the course of the  season is critical to positioning the right teams into position to play for the <b>National Championship.</b></div>
        </div>
      </div>
      <div class="game-type-3-redistribution" v-if="type === 'gm3Redistribution'">
        <div class="f-5 c-dark"><b>Designed to help lesser tier schools or the lower ranked teams in positions 21-25 have an opportunity to compete for the playoffs.</b></div>
        <div class="mt-2 f-5 c-dark">Players are limited to only  moving teams 21-25, with the exception of the teams they are replacing.</div>
        <div class="mt-5 f-8 font-weight-bold">HOW WILL YOU CHOOSE TO RE-POSITION TEAMS 21-25?</div>
        <div class="mt-3">
          <div class="f-8 font-weight-bold">Instructions</div>
          <div class="mt-3 f-5 c-dark">
            <b>You have (3) options from which to choose</b>
          </div>
        </div>
        <div class="mt-2">
          <div class="f-5 c-dark"><b>Option 1: Mid-Pack positioning, </b>when selected, will display poll with computer inserting teams 21-25 into positions 11 through 15, taking teams 11-15 and put in positions 16-20, then teams 16-20 into positions 21 through 25.</div>
          <div class="mt-2 f-5 c-dark">
            Click <b>"Next"</b> to activate poll and advance to week 1 regular season play.<br>
          </div>
          <div class="mt-2 f-5 c-dark"><b>Option 2: Incremental positioning,</b> when selected, will display poll with computer inserting teams 21-25 into positions 11, 13, 15, 17 and 19. Teams in positions 11, 13, 15, 17 and 19 are inserted in positions 21 through 25.  </div>
          <div class="mt-2 f-5 c-dark">Click "Next" to activate poll and advance to week 1 regular season play.</div>
            <div class="mt-2 f-5 c-dark"><b>Option 3:</b> Allow click and drag for only teams <b>21-25,</b> do not allow click and drag for all other teams, other teams will move up or down poll based on where team <b>21-25</b> are positioned.</div>
          <div class="mt-2 f-5 c-dark">Click <b>"Next"</b> to activate poll and advance to week 1 regular season play.</div>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    name: 'InstructionBox',
    props: {
        type: { type: String },
    },
    data: () => ({

    }),
  }
</script>
<style scope>
</style>
