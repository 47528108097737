<template>
    <div class="page pageGame1">
        <Navbar :user="users.currentUser" />
        <GameOnboard :type="users.currentUser.gameType" :onPlay="onPlayGame" />
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import Navbar from "../../components/Navbar";
import GameOnboard from "../../components/Game/GameOnboard";

export default {
    name: 'Game1Prev',
    order: 1,
    components: {
        Navbar,
        GameOnboard,
    },
    mounted() {
      this.users.currentUser.gameType = 'Game1';
      this.users.currentUser = {...this.users.currentUser};
    },
    computed: {
        ...mapState({
            users: state => state.users,
        }),
    },
    methods: {

        onPlayGame() {
            this.$router.push({name: 'Game1'});
        },
    },
};
</script>
