import axios from 'axios'
import { API_URL } from "../config";
import {
    STATUS_NONE,
    STATUS_SUCCESS,
    STATUS_FAILURE,
    PICKSIX
} from '../constants/constants'

const PickSix = {
    state: {
        data: null,
        getPickSixStatus: null,
        setPickSixStatus: null,
    },
    actions: {
        getPickSix({state}, data) {
            state.getPickSixStatus = STATUS_NONE;
            axios.post( API_URL + PICKSIX.GET, data )
                .then(function (res) {
                    state.data = res.data.data;
                    state.getPickSixStatus = STATUS_SUCCESS;
                })
                .catch( err => {
                    state.getPickSixStatus = STATUS_FAILURE;
                    state.errorMessage = err.error;
                })
        },

        setPickSix({state}, data) {
            state.setPickSixStatus = STATUS_NONE;
            axios.post( API_URL + PICKSIX.SET, data)
                .then(function (res) {
                    state.data = res.data.data;
                    state.setPickSixStatus = STATUS_SUCCESS;
                })
                .catch( err => {
                    state.setPickSixStatus = STATUS_FAILURE;
                    state.errorMessage = err.error;
                });
        }
    }
}

export default PickSix
