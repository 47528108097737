import axios from 'axios'
import { API_URL } from "../config";
import {
    STATUS_NONE,
    STATUS_SUCCESS,
    STATUS_FAILURE,
    REGULARSEASON
} from '../constants/constants'

const RegularSeason = {
    state: {
        data: null,
        currentSeason: 1,
        currentYear: '',
        currentWeek: '',
        getRegularSeasonStatus: null,
        setRegularSeasonStatus: null,
    },
    actions: {
        // Loading Regular Season Data User already Passed.
        getRegularSeason({state}, data) {
            state.getRegularSeasonStatus = STATUS_NONE;
            axios.post( API_URL + REGULARSEASON.GET_SEASON, data )
                .then(function (res) {
                    if (res.data && res.data.result) {
                        state.data = res.data.data;
                        state.getRegularSeasonStatus = STATUS_SUCCESS;
                    }
                })
                .catch( err => {
                    state.getRegularSeasonStatus = STATUS_FAILURE;
                    state.errorMessage = err.error;
                })
        },
        setRegularSeason({state}, data) {
            state.setRegularSeasonStatus = STATUS_NONE;
            axios.post( API_URL + REGULARSEASON.SET_SEASON, data)
                .then(function (res) {
                    state.data = res.data.data;
                    state.setRegularSeasonStatus = STATUS_SUCCESS;
                })
                .catch( err => {
                    state.setRegularSeasonStatus = STATUS_FAILURE;
                    state.errorMessage = err.error;
                })
        },
    }
}

export default RegularSeason
