<template>
    <div class="viewRightTeamCell">

        <!-- <img class="logoImage" :src="logo" v-if="(logo != null) && isShowLogo" alt="" onerror="this.style.display = 'none'"/> -->
        <span class="loss-team-name" v-if="color == 'red'">{{item ? item.school : ''}}</span>
        <span class="win-team-name" v-else-if="color == 'blue'">{{item ? item.school : ''}}</span>
        <span class="team-name" v-else>{{item ? item.school : ''}}</span>
        <img class="logoImage" :src="logo" v-if="(logo != null) && showBrand" alt="" onerror="this.style.display = 'none'"/>
        <div class="rankBox" v-if="!isShowLogo">
            <img v-if="color == 'red'" class="rankIcon" src="@/assets/img/rank_red.png"/>
            <img v-else class="rankIcon" src="@/assets/img/rank.png"/>
            <span class="rankText">{{index + 1}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RightTeamCell',
        props: {
            index: { type: Number },
            item: { type: Object },
            isShowLogo: { type: Boolean },
            showBrand: {type: Boolean, default: true},
            color: { type: String }
        },
        data: () => ({

        }),
        computed: {
            logo() {
                if (this.item && this.item.logos && this.item.logos.length > 0) {
                    return this.item.logos[0];
                }
                if (this.isShowLogo && this.item) {
                    const index = this.teams.all.findIndex((item) => item.school === this.item.school);
                    if (index >= 0) {
                        if (this.teams.all[index].logos && this.teams.all[index].logos.length > 0) {
                            return this.teams.all[index].logos[0];
                        }
                    }
                }
                return null;
            }
        }
    }
</script>
<style lang="scss" scope>
    .viewRightTeamCell {
        font-size: 12px;
        display: flex;
        margin-bottom: 5px;
        background-color: white;
        border-radius: 5px;
        padding: 7px 10px;
        height: 34px;
        align-items: center;

        .team-name {
            margin-left: 5px;
            max-width: 85px;
            min-width: 85px;
            white-space: nowrap;
            overflow: hidden;
            color: black;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
        }

        .loss-team-name {
            margin-left: 5px;
            max-width: 85px;
            min-width: 85px;
            white-space: nowrap;
            overflow: hidden;
            color: red;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
        }

        .win-team-name {
            margin-left: 5px;
            max-width: 85px;
            min-width: 85px;
            white-space: nowrap;
            overflow: hidden;
            color: blue;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
        }

        .logoImage {
            width: 20px;
            margin-right: 5px;
            object-fit: contain;
        }

        .rankBox {
            position: relative;

            .rankIcon {
                width: 15px;
                height: 17px;
                margin-top: 2px;
            }

            .rankText {
                color: white;
                position: absolute;
                top: 3px;
                left: 3px;
                font-size: 8px;
                text-align: center;
                width: 10px;
            }
        }
    }
</style>
