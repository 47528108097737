<template>
    <div class="game2-right-teams">
        <div class="wrapper">
            <div class="position-relative">
                <div class="title-box">Teams</div>
                <RoundButton class="reset-button btn sm" title="Reset" @click.native="onReset"/>
            </div>
            <div class="mt-40 mb-30">
                <CheckTeamCell
                    :firstIndex="2"
                    :secondIndex="17"
                    :teams="teams"
                    :checks="checks"
                    :check-type="'right'"
                    @set-winner="setWinner"
                />
            </div>
            <div class="mb-30">
                <CheckTeamCell
                    :firstIndex="6"
                    :secondIndex="13"
                    :teams="teams"
                    :checks="checks"
                    :check-type="'right'"
                    @set-winner="setWinner"
                />
            </div>
            <div class="mb-30">
                <CheckTeamCell
                    :firstIndex="9"
                    :secondIndex="10"
                    :teams="teams"
                    :checks="checks"
                    :check-type="'right'"
                    @set-winner="setWinner"
                />
            </div>
            <div class="mb-30">
                <CheckTeamCell
                    :firstIndex="14"
                    :secondIndex="5"
                    :teams="teams"
                    :checks="checks"
                    :check-type="'right'"
                    @set-winner="setWinner"
                />
            </div>
            <div class="mb-30">
                <CheckTeamCell
                    :firstIndex="18"
                    :secondIndex="1"
                    :teams="teams"
                    :checks="checks"
                    :check-type="'right'"
                    @set-winner="setWinner"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import CheckTeamCell from "./CheckTeamCell";
    import RoundButton from "../RoundButton";
    export default {
        name: "Gm2LeftTeams",
        components: {
            CheckTeamCell,
            RoundButton
        },
        props: {
            teams: { type: Array },
            checks: { type: Array }
        },
        data: () => ({
            keyword: '',
        }),
        methods : {
            setWinner(index) {
                this.$emit("update-winner", index);
            },
            onReset() {
                this.$emit('reset');
            }
        }

    }
</script>

<style lang="scss" scoped>
    .reset-button {
        position: absolute;
        top: 3px;
        right: 10px;
    }
</style>
