<template>
    <div class="page authContainer">
        <div class="blue-overlay">
            <v-img src="@/assets/img/wireframe/background.png" cover/>
        </div>

        <div class="content">
            <div class="title-wrapper mx-auto">
                <v-img src="@/assets/img/wireframe/home_title1_app.png" cover/>
                <v-img class="title-sm mx-auto mt-3" src="@/assets/img/wireframe/home_title3.png" cover/>
                <v-img class="title-lg mx-auto mt-3" src="@/assets/img/wireframe/home_title2.png" cover/>
                <v-img class="title-logo mx-auto mt-3" src="@/assets/img/wireframe/logo_new.png" cover/>
            </div>

            <!-- Login Form -->
            <div class="login-form" v-if="isLoginForm === true">
                <h2 class="form-title">Welcome To The FCSCF</h2>
                <FormInput
                    type="email"
                    placeholder="Email Address"
                    v-model="loginUser.email"
                    :error="loginUser.emailError"
                />
                <FormInput
                    type="password"
                    placeholder="Password"
                    v-model="loginUser.password"
                    :error="loginUser.passwordError"
                />
                <RoundButton title="Sign In" style="margin-top: 30px;" @click.native="onLogin"/>
                <p class="errorMessage" v-if="loginErrorMessage">{{loginErrorMessage}}</p>
                <p class="form-footer">Not Registered? <span @click="isLoginForm=false" >Sign Up</span></p>
            </div>

            <!-- Register Form -->
            <div class="login-form" v-if="isLoginForm === false">
                <h2 class="form-title">Create a Free FCSCF Account</h2>
                <div class="avatar">
                    <img :src="registerUser.avatarUrl" @click="onClickAvatar()" />
                    <input type="file" ref="avatarFile" accept="image/*" @change="onAvatarFileChange" style="display: none">
                </div>
                <FormInput
                    type="text"
                    placeholder="First Name"
                    v-model="registerUser.firstName"
                    :error="registerUser.firstNameError"
                />
                <FormInput
                    type="text"
                    placeholder="Last Name"
                    v-model="registerUser.lastName"
                    :error="registerUser.lastNameError"
                />
                <FormInput
                    type="email"
                    placeholder="Email Address"
                    v-model="registerUser.email"
                    :error="registerUser.emailError"
                />
                <FormInput
                        type="text"
                        placeholder="Phone"
                        v-model="registerUser.phone"
                        :error="registerUser.phoneError"
                />
                <div class="formField">
                    <div class="formInput">
                        <vue-google-autocomplete
                                id="map"
                                placeholder="Address"
                                classname="input-address"
                                v-on:placechanged="getAddressData"
                        />
                    </div>
                    <div class="formError" v-if="registerUser.addressError">
                        {{registerUser.addressError}}
                    </div>
                </div>
                <FormInput
                    type="password"
                    placeholder="Password"
                    v-model="registerUser.password"
                    :error="registerUser.passwordError"
                />
                <FormInput
                    type="password"
                    placeholder="Confirm Password"
                    v-model="registerUser.confirmPassword"
                    :error="registerUser.confirmPasswordError"
                />
                <RoundButton title="Sign Up" style="margin-top: 30px;" @click.native="onRegister"/>
                <p class="errorMessage" v-if="registerErrorMessage">{{registerErrorMessage}}</p>
                <p class="form-footer">Already have an account? <span @click="isLoginForm=true">Sign In</span></p>
            </div>
        </div>
        <LoadingOverlay v-if="isLoading"/>
    </div>
</template>

<script>

import { MESSAGES } from '../../utils/Messages';
import { STATUS_SUCCESS, STATUS_FAILURE } from '../../constants/constants';
import { mapState, mapActions } from 'vuex'
import FormInput from '../../components/FormInput'
import RoundButton from '../../components/RoundButton'
import LoadingOverlay from '../../components/LoadingOverlay'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
    name: 'AuthScreen',
    components: {
        FormInput,
        RoundButton,
        LoadingOverlay,
        VueGoogleAutocomplete
    },
    data () {
        return {
            isLoginForm: true,
            isLoading: false,
            loginErrorMessage: null,
            registerErrorMessage: null,
            loginUser: {
                email: '',
                password: '',

                emailError: null,
                passwordError: null,
            },
            registerUser: {
                firstName: '',
                lastName: '',
                avatarUrl: require('../../assets/img/default_avatar.png'),
                avatarFile: '',
                email: '',
                address: '',
                phone: '',
                password: '',
                confirmPassword: '',

                firstNameError: '',
                lastNameError: '',
                emailError: '',
                phoneError: '',
                addressError: '',
                passwordError: '',
                confirmPasswordError: '',
            }
        }
    },
    mounted() {

    },
    computed: {
        ...mapState({
            users: state => state.users,
        }),
    },
    methods: {
        ...mapActions([
            'login',
            'register',
        ]),

        getAddressData: function (addressData, placeResultData, id) {
            const address = addressData.route + ", " + addressData.locality + ", " + addressData.country;
            this.registerUser.address = address;
            this.registerUser.addressError = "";
        },

        moveSelectGame: function() {
            this.$router.push({name: 'HomeScreen'});
        },

        // Clicked Login Button.
        onLogin: function() {
            if (this.isLoading) return;
            this.loginErrorMessage = null;

            const user = this.loginUser;
            var isValid = true;
            if (!(user.email && user.email.length > 0)) {
                user.emailError = MESSAGES.INVALID_EMAIL;
                isValid = false;
            }

            if (!(user.password && user.password.length > 0)) {
                user.passwordError = MESSAGES.INVALID_PASSWORD;
                isValid = false;
            }

            if (isValid) {
                this.isLoading = true;
                this.login(user);
            }
        },

        onClickAvatar: function() {
            this.$refs.avatarFile.click();
        },

        onAvatarFileChange: function(e) {
            const file = e.target.files[0];
            const url = URL.createObjectURL(file);
            this.registerUser.avatarUrl = url;
            this.registerUser.avatarFile = file;
        },

        // Clicked Register Button.
        onRegister: function() {
            if (this.isLoading) return;
            this.registerErrorMessage = null;

            const user = this.registerUser;
            var isValid = true;

            // First Name.
            if (!(user.firstName && user.firstName.length > 0)) {
                user.firstNameError = MESSAGES.INVALID_FIRSTNAME;
                isValid = false;
            }

            // Last Name.
            if (!(user.lastName && user.lastName.length > 0)) {
                user.lastNameError = MESSAGES.INVALID_LASTNAME;
                isValid = false;
            }

            // Email.
            if (!(user.email && user.email.length > 0)) {
                user.emailError = MESSAGES.INVALID_EMAIL;
                isValid = false;
            }

            if (!(user.phone && user.phone.length > 0)) {
                user.phoneError = MESSAGES.INVALID_PHONE;
                isValid = false;
            }

            // Address.
            if (!(user.address && user.address.length > 0)) {
                user.addressError = MESSAGES.INVALID_ADDRESS;
                isValid = false;
            }

            // Password.
            if (!(user.password && user.password.length > 0)) {
                user.passwordError = MESSAGES.INVALID_PASSWORD;
                isValid = false;
            }

            // Confirm Password.
            if (!(user.confirmPassword && user.confirmPassword.length > 0)) {
                user.confirmPasswordError = MESSAGES.INVALID_CONFIRM_PASSWORD;
                isValid = false;
            }

            // Password Match.
            if (user.password && user.confirmPassword && user.password != user.confirmPassword) {
                user.passwordError = MESSAGES.NOT_MATCH_PASSWORD;
                isValid = false;
            }

            if (isValid) {
                let formData = new FormData();

                this.isLoading = true;
                this.register(user);
            }
        }
    },
    watch: {
        // Checking validation for login form.
        'loginUser.email': function(val) {
            if (val && val.length > 0) {
                this.loginUser.emailError = null;
            }
        },
        'loginUser.password': function(val) {
            if (val && val.length > 0) {
                this.loginUser.passwordError = null;
            }
        },

        // Checking validation for register form.
        'registerUser.firstName': function(val) {
            if (val && val.length > 0) {
                this.registerUser.firstNameError = null;
            }
        },
        'registerUser.lastName': function(val) {
            if (val && val.length > 0) {
                this.registerUser.lastNameError = null;
            }
        },
        'registerUser.address': function(val) {
            if (val && val.length > 0) {
                this.registerUser.addressError = null;
            }
        },
        'registerUser.email': function(val) {
            if (val && val.length > 0) {
                this.registerUser.emailError = null;
            }
        },
        'registerUser.password': function(val) {
            if (val && val.length > 0) {
                this.registerUser.passwordError = null;
            }
        },
        'registerUser.confirmPassword': function(val) {
            if (val && val.length > 0) {
                this.registerUser.confirmPasswordError = null;
            }
        },

        // Login
        'users.loginStatus': function(val) {
            if (val == STATUS_SUCCESS) {
                this.isLoading = false;
                this.moveSelectGame();
            } else if (val == STATUS_FAILURE){
                this.isLoading = false;
                this.loginErrorMessage = this.users.errorMessage;
            }
        },

        // Register.
        'users.registerStatus': function(val) {
            if (val == STATUS_SUCCESS) {
                this.isLoading = false;
                this.moveSelectGame();
            } else if (val == STATUS_FAILURE){
                this.isLoading = false;
                this.registerErrorMessage = this.users.errorMessage;
            }
        }
    }
};
</script>
<style lang="scss" scope>
    .content {
        position: relative;
        text-align: center;
        color: white;
    }

    .title-wrapper {
        position: relative;
        width: 80%;
        margin-top: 100px;

        .title-sm {
            width: 200px;
        }

        .title-lg {
            width: 60%;
        }

        .title-logo {
            width: 100px;
        }
    }

    .login-form {
        max-width: 450px;
        margin: auto;
        background-color: white;
        color: black;
        padding: 40px 40px 20px 40px;
        margin-top: 30px;
        margin-bottom: 50px;
        border-radius: 10px;
        border-top: 5px solid #3263b3;
    }

    .login-form .form-title {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .login-form .form-footer {
        margin-top: 20px;
        color: #6f6f6f;
    }

    .login-form .form-footer span {
        color: #8ba6da;
        cursor: pointer;
    }

    .authContainer .errorMessage {
        margin-top: 10px;
    }

    .avatar {
        width: 100px;
        height: 100px;
        position: relative;
        margin: 20px auto;


        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }
</style>
