<template>
    <div class="loadingOverlay">
        <div class="overlay" />
        <ThreeDots />
    </div>
</template>

<script>
import {ThreeDots} from 'vue-loading-spinner'
export default {
    name: 'LoadingOverlay',
    components: {        
        ThreeDots
    },
    props: {
        title: { type: String },
        theme: { type: String },
    },
    data: () => ({
      
    }),
}
</script>
<style scope>
    .loadingOverlay {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;    
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loadingOverlay .overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
    }
    
    
</style>
