import { render, staticRenderFns } from "./Gm3Redistribution.vue?vue&type=template&id=e1f1874a&scoped=true&"
import script from "./Gm3Redistribution.vue?vue&type=script&lang=js&"
export * from "./Gm3Redistribution.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1f1874a",
  null
  
)

export default component.exports