<template>
    <div class="page regular-season game-redistribution">
        <Navbar :user="users.currentUser" />
        <div>
            <Header
                    title="Game 3"
                    sub-title="Game 3 Fantasy Re-distribution"
                    :is-left=false
                    @back_click="onBack"
                    @next_click="onNext"
            />
            <div class="game-type-page bgc-poll">
                <div class="polls d-flex">
                    <div class="wrapper" @click="setOption(0)">
                        <div class="title-box" :class="option == 0 ? 'active' : ''">My CFFB Top25</div>
                        <TeamCell
                          v-for="(item, index) in myTop25"
                          :key="index"
                          :item="item"
                          :index="index"
                          :color="(index >= 20) ? 'red' : 'blue'"
                        />
                    </div>
                    <div class="wrapper">
                        <div class="title-box custom_radio" @click="setOption(1)">
                            <input type="radio" id="option1" name="radio-group" :checked="this.option == 1">
                            <label for="option1">Option1</label>
                        </div>
                        <TeamCell
                            v-for="(item, index) in option1Teams"
                            :key="index"
                            :item="item"
                            :index="index"
                            :color="(index >= 10 && index <= 14) ? 'red' : 'blue'"
                        />
                        <div class="text-center f-3 font-weight-bold">Your New Poll</div>
                    </div>
                    <div class="wrapper">
                        <div class="title-box custom_radio" @click="setOption(2)">
                            <input type="radio" id="option2" name="radio-group" :checked="this.option == 2">
                            <label for="option2">Option2</label>
                        </div>
                        <TeamCell
                                v-for="(item, index) in option2Teams" :key="index" :item="item" :index="index"
                                :color="(index == 10 || index == 12 || index == 14 || index == 16 || index == 18) ? 'red' : 'blue' "
                        />
                        <div class="text-center f-3 font-weight-bold">Your New Poll</div>
                    </div>
                    <div class="wrapper">
                        <div class="title-box custom_radio" @click="setOption(3)">
                            <input type="radio" id="option3" name="radio-group" :checked="this.option == 3">
                            <label for="option3">Option3</label>
                        </div>

                        <draggable :list="option3Teams" group="peoplex" :move="checkMove">
                            <TeamCell
                                v-for="(item, index) in option3Teams" :key="index" :item="item" :index="index"
                                :color="checkLastTeam(item) ? 'red' : 'blue' "
                            />
                        </draggable>
                        <div class="text-center f-3 font-weight-bold">Your New Poll</div>
                    </div>
                </div>
                <!-- Instruction -->
                <div class="description">
                    <InstructionBox type="gm3Redistribution" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import draggable from "vuedraggable";
    import Navbar from "../../components/Navbar";
    import InstructionBox from "../../components/Game/InstructionBox";
    // import OwnTopTeamsBoard from "../../components/Game/OwnTopTeamsBoard";
    import TeamCell from "../../components/Game/TeamCell";
    import {currentYear} from "../../utils/functions";
    import Header from "../../components/Header";
    export default {
        name: 'Gm3Redistribution',
        order: 5,
        components: {
            TeamCell,
            // OwnTopTeamsBoard,
            Navbar,
            Header,
            InstructionBox,
            draggable,
        },
        computed: {
            ...mapState({
                users: state => state.users,
                teams: state => state.teams,
                game_type: state => state.game_type
            }),
        },
        data() {
            return {
                myTop25: [],
                keyword: '',
                option1Teams: [],
                option2Teams: [],
                option3Teams: [],
                option: 0
            };
        },
        mounted() {
            this.myTop25 = [...this.teams.myTop25];

            // this.option = this.teams.redistributionSelected;

            // init option teams
            for (var i = 0; i < 25; i++) {
                this.option1Teams.push({});
                this.option2Teams.push({});
                this.option3Teams.push({});
            }

            // // restore option teams
            // if (this.teams.game3Option1Teams.length > 0) {
            //     this.option1Teams = [...this.teams.game3Option1Teams];
            // } else {
            //     for (var i = 0; i < 25; i++) {
            //         this.option1Teams.push({});
            //     }
            // }
            //
            // if (this.teams.game3Option2Teams.length > 0) {
            //     this.option2Teams = [...this.teams.game3Option2Teams];
            // } else {
            //     for (var j = 0; j < 25; j++) {
            //         this.option2Teams.push({});
            //     }
            // }
            //
            // if (this.teams.game3Option3Teams.length > 0) {
            //     this.option3Teams = [...this.teams.game3Option3Teams];
            // } else {
            //     for (var k = 0; k < 25; k++) {
            //         this.option3Teams.push({});
            //     }
            // }
        },
        methods: {
            ...mapActions([
                'getAllTeams',
                'setGameType',
            ]),

            setOption(index) {
                this.option = index;
                if (index == 0) {
                    this.clearOption1Teams();
                    this.clearOption2Teams();
                    this.clearOption3Teams();
                } else if (index == 1) {
                    if (!this.option1Teams[0].school) {
                        this.initOption1Teams();
                    }
                    this.clearOption2Teams();
                    this.clearOption3Teams();
                } else if (index == 2) {
                    if (!this.option2Teams[0].school) {
                        this.initOption2Teams();
                    }
                    this.clearOption1Teams();
                    this.clearOption3Teams();
                } else if (index == 3) {
                    if (!this.option3Teams[0].school) {
                        this.initOption3Teams();
                    }
                    this.clearOption1Teams();
                    this.clearOption2Teams();
                }
            },

            initOption1Teams() {
                for( let k = 0; k < 25; k++){
                    this.option1Teams.splice(k, 1, this.myTop25[k] );
                }
                for( let i = 10; i < 15; i++ ){
                    this.option1Teams.splice(i, 1, this.myTop25[i+10]);
                }

                for( let j = 15; j < 25; j++ ){
                    this.option1Teams.splice(j, 1, this.myTop25[j-5]);
                }
            },

            initOption2Teams() {
                for( let k = 0; k < 25; k++){
                    this.option2Teams.splice(k, 1, this.myTop25[k]);
                }
                console.log("init option2 teams ============>", this.option2Teams, this.myTop25);
                this.option2Teams.splice(10, 1, this.myTop25[20]);
                this.option2Teams.splice(12, 1, this.myTop25[21]);
                this.option2Teams.splice(14, 1, this.myTop25[22]);
                this.option2Teams.splice(16, 1, this.myTop25[23]);
                this.option2Teams.splice(18, 1, this.myTop25[24]);
                this.option2Teams.splice(20, 1, this.myTop25[10]);
                this.option2Teams.splice(21, 1, this.myTop25[12]);
                this.option2Teams.splice(22, 1, this.myTop25[14]);
                this.option2Teams.splice(23, 1, this.myTop25[16]);
                this.option2Teams.splice(24, 1, this.myTop25[18]);
            },

            initOption3Teams() {
                for( let k = 0; k < 25; k++){
                    this.option3Teams.splice(k, 1, this.myTop25[k]);
                }
            },

            clearOption1Teams() {
                for( let k = 0; k < 25; k++){
                    this.option1Teams.splice(k, 1, {});
                }
            },

            clearOption2Teams() {
                for( let k = 0; k < 25; k++){
                    this.option2Teams.splice(k, 1, {});
                }
            },

            clearOption3Teams() {
                for( let k = 0; k < 25; k++){
                    this.option3Teams.splice(k, 1, {});
                }
            },

            onBack() {
                this.$router.push({name: 'Game3'});
            },

            onNext() {
                var option1 = document.getElementById("option1").checked;
                var option2 = document.getElementById("option2").checked;
                var option3 = document.getElementById("option3").checked;

                if( option1 ){
                    this.teams.myTop25 = this.option1Teams;
                }
                else if( option2 ){
                    this.teams.myTop25 = this.option2Teams;
                }
                else if( option3 ){
                    this.teams.myTop25 = this.option3Teams;
                }

                this.teams.game3Option1Teams = this.option1Teams;
                this.teams.game3Option2Teams = this.option2Teams;
                this.teams.game3Option3Teams = this.option3Teams;
                this.teams.redistributionSelected = this.option;

                const own_team = [];
                this.myTop25.forEach((team) => {
                    const index = this.teams.all.findIndex(item => item.school === team.school);
                    if (index >= 0) {
                        const id = this.teams.all[index]._id;
                        own_team.push(id);
                    }
                });

                const option3_ids = [];
                this.option3Teams.forEach((team) => {
                    const index = this.teams.all.findIndex(item => item.school === team.school);
                    if (index >= 0) {
                        const id = this.teams.all[index]._id;
                        option3_ids.push(id);
                    }
                });

                const data = {
                    user: this.users.currentUser._id,
                    year: currentYear(),
                    own_team: own_team,
                    type: 'Game3',
                    redistribution_option: this.option,
                    redistribution_team: option3_ids
                };

                this.setGameType(data);
                this.$router.push({name: 'RegularSeasonWk1Schedule'});
                // this.teams.revisedPoll = this.teams.myTop25;
                // this.$router.push({name: 'RegularSeasonWkMatchScoring'});
            },

            checkLastTeam(team) {
                return this.myTop25.indexOf(team) > 19;
            },

            changeEvent: function(evt){

            },

            checkMove: function(evt){
                if (evt) {
                    const team = this.option3Teams[evt.draggedContext.index];
                    if (this.checkLastTeam(team)) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        },
    };
</script>

<style lang="scss" scoped>

</style>
<style>

</style>
