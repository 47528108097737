<template>
  <div class="page regular-season">
    <Navbar :user="users.currentUser" />
    <div>
      <Header
        title="Game 1"
        sub-title="Pre-season polls"
        :is-left="true"
        left-button-text="Reset"
        @left_click="onReset"
        @back_click="onBack"
        @next_click="onNext"
      />
      <div
        class="game-type-page bgc-poll"
        v-if="teams.all.length && conferences.all.length"
      >
        <div class="polls">
          <GameTypeBoard
            :teams="teams"
            :conferences="conferences"
            :selected-poll="selectedPoll"
            :isStartGame="false"
            @set-poll="setPoll"
          />
        </div>
        <div class="description">
          <InstructionBox type="game1" />
        </div>
      </div>
    </div>
    <LoadingOverlay v-if="isLoading" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Navbar from "../../components/Navbar";
import InstructionBox from "../../components/Game/InstructionBox";
import Header from "../../components/Header";
import { currentYear, currentWeek, isFullTeams, getStartDate, isGameStarted } from "../../utils/functions";
import GameTypeBoard from "../../components/Game/GameTypeBoard";
import { STATUS_FAILURE, STATUS_SUCCESS } from "../../constants/constants";
import LoadingOverlay from "../../components/LoadingOverlay";
import { MESSAGES } from "../../utils/Messages";

export default {
  name: "Game1",
  order: 1,
  components: {
    GameTypeBoard,
    Header,
    Navbar,
    InstructionBox,
    LoadingOverlay,
  },
  data() {
    return {
      selectedPoll: "0",
      isLoading: false,
      isGameStarted: false,
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.users,
      teams: (state) => state.teams,
      conferences: (state) => state.conferences,
      game_type: (state) => state.game_type,
    }),
  },
  mounted() {
    this.getAllTeams();
    this.getAllConferences();

    // init my top25
    for (let i = 0; i < 25; i++) {
      this.teams.myTop25.splice(i, 1, {});
    }

    // restore my top25
    // if (this.teams.myTop25.length <= 0) {
    //   for (let i = 0; i < 25; i++) {
    //     this.teams.myTop25.splice(i, 1, {});
    //   }
    //   this.teams.myTop25.splice(24, 1,  this.teams.all.filter( team => team.school === 'Oklahoma State' )[0] );
    // } else {
    //   const data = {
    //     user: this.users.currentUser._id,
    //     year: currentYear()
    //   }
    //   this.getGameType(data);
    //   for (let i = 0; i < 25; i++) {
    //     if (this.teams.myTop25[i]._id) {
    //       const index = this.teams.all.findIndex((team) => team._id === this.teams.myTop25[i]._id);
    //       if (index >= 0) {
    //         this.teams.myTop25.splice(i, 1, this.teams.all[index]);
    //       }
    //     }
    //   }
    // }
  },

  methods: {
    ...mapActions([
      "getAllTeams",
      "getAllConferences",
      "getRegularPolls",
      "setGameType",
      "getGameType",
      "getCalendar",
    ]),

    setPoll(index) {
      this.selectedPoll = index;
    },

    onReset() {
      for (let i = 0; i < 24; i++) {
        this.teams.myTop25.splice(i, 1, {});
      }
    },
    onBack() {
      this.$router.push({ name: "HomeScreen" });
    },
    onNext() {
        if (this.selectedPoll == "1") {
          this.teams.myTop25 = this.teams.apPoll;
        } else if (this.selectedPoll == "2") {
          this.teams.myTop25 = this.teams.coachesPoll;
        } else if (this.selectedPoll == "3") {
          this.teams.myTop25 = this.teams.cffbPoll;
        } else if (this.selectedPoll == "4") {
          this.teams.myTop25 = this.teams.espnPoll;
        } else if (this.selectedPoll == "5") {
          this.teams.myTop25 = this.teams.cbsSportsPoll;
        }
        
      if (isFullTeams(this.teams.myTop25)) {
        const own_team = [];
        this.teams.myTop25.forEach((team) => {
          const index = this.teams.all.findIndex((item) => item.school === team.school);
          if (index >= 0) {
            const id = this.teams.all[index]._id;
            own_team.push(id);
          }
        });
        this.teams.selectedPoll = this.selectedPoll;
        const data = {
          user: this.users.currentUser._id,
          year: currentYear(),
          type: "Game1",
          own_team: own_team,
          selected_poll: this.selectedPoll,
        };
        this.setGameType(data);
        this.$router.push({ name: "RegularSeasonWk1Schedule"});
      } else {
        this.$toasted.show(MESSAGES.INVALID_FULL_TEAMS);
      }
    },
  },
  watch: {
    "teams.getCalendarStatus": function(status) {
      this.isLoading = true;
      if (status === STATUS_SUCCESS) {

        var startDate = getStartDate(this.teams.calendar, 0);
        if (!startDate) {
          this.isLoading = false;
          return;
        }

        this.teams.gameStartDate = startDate;
        this.isGameStarted = isGameStarted(startDate);

        this.isLoading = false;
      } else if (status === STATUS_FAILURE) {
        this.isLoading = false;
      }
    },

    "teams.getAllTeamStatus": function (status) {
      this.isLoading = true;
      if (status === STATUS_SUCCESS) {
        const data = {
          user: this.users.currentUser._id,
          year: currentYear(),
        };
        this.getRegularPolls({ year: currentYear(), week: 8 });
        this.getGameType(data);
        this.getCalendar({year: currentYear()});
        this.isLoading = false;
      } else if (status === STATUS_FAILURE) {
        this.isLoading = false;
        this.$toasted.show(this.teams.errorMessage);
      }
    },

    "game_type.getGameTypeStatus": function (status) {
      this.isLoading = true;
      if (status === STATUS_SUCCESS) {
        if (this.game_type.data) {
          const own_team = this.game_type.data.own_team || [];

          // restore my top25
          // if (own_team.length) {
          //   for (let i = 0; i < 25; i++) {
          //     this.teams.myTop25.splice(i, 1, {});
          //   }
          //   this.teams.myTop25.splice(24, 1, this.teams.all.filter((team) => team.school === "Oklahoma State")[0]);
          // } else {
          //   for (let i = 0; i < 25; i++) {
          //     this.teams.myTop25.splice(i, 1, {});
          //   }
          //   this.teams.myTop25.splice(24, 1, this.teams.all.filter((team) => team.school === "Oklahoma State")[0]);
          // }

          // this.selectedPoll = this.game_type.data.selected_poll;
        }
        this.isLoading = false;
      } else if (status === STATUS_FAILURE) {
        this.isLoading = false;
        this.$toasted.show(this.game_type.errorMessage);
      }
    },
  },
};
</script>
<style lang="scss" scope></style>
