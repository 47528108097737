import axios from 'axios'
import { API_URL } from "../config";
import {
    STATUS_NONE,
    STATUS_SUCCESS,
    STATUS_FAILURE,
    CONFERENCE
} from '../constants/constants'

const Conferences = {
    state: {
        all: [],
        getAllConferenceStatus: null,
        getConferenceListStatus: null,
        matchResultStatus: null,
        pollRankingsStatus: null,
        revisedPollTeams : [],
        selectedPoll: 0
    },
    actions: {
        // Get All Conferences.
        getAllConferences({state}) {
            state.getAllTeamStatus = STATUS_NONE;
            axios.get( API_URL + CONFERENCE.GET_ALL_CONFERENCE )
                .then(function (res) {
                    state.all = [...res.data.data];
                    state.getAllConferenceStatus = STATUS_SUCCESS;
                })
                .catch( err => {
                    state.getAllConferenceStatus = STATUS_FAILURE;
                    state.errorMessage = err.error;
                })
        }
    }
}

export default Conferences
