<template>
    <div>
        <v-img src="../assets/img/wireframe/signup_background.png" width="100vw" height="100vh" cover></v-img>
        <div class="login-form">
            <p class="title">CREATE A NEW ACCOUNT</p>
            <v-text-field label="Email" hint="Please enter your email" hide-details="auto" />
            <v-text-field class="mt-5" label="Password" :type="'password'" hint="At least 5 characters" />
            <v-text-field label="Confirm Password" :type="'password'" hint="At least 5 characters" />
            <v-checkbox label="By Clicking, Sign Up. You are agreeing to our terms of service" />
            <v-btn class="page-link-button login" large @click="console.log('test')">SIGN UP</v-btn>
            <div class="sign-in mt-3">
                <span>Already have an account? </span>
                <span>
                    <v-btn text small color="primary" @click="moveLogin()">Sign in</v-btn>
                </span>
            </div>

        </div>
    </div>
</template>

<script>

    export default {
        name: 'Signup',
        components: {
        },
        data () {
            return {
                show1: false,
                show2: true,
                show3: false,
                show4: false,
                password: 'Password',
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters',
                    emailMatch: () => ('The email and password you entered don\'t match'),
                },
            }
        },
        mounted() {
        },
        methods: {
            moveLogin: function() {
                this.$router.push({name: 'HomeScreen'});
            },
            onRegister: function() {
                console.log("signup");
            }
        },
    };
</script>
<style>
</style>
<style scope>

</style>