<template>
    <div>
        <v-img src="../assets/img/wireframe/background.png" width="100vw" height="100vh" cover></v-img>
        <div v-if="!isEdit">
            <div class="profile-card">
                <v-img class="avatar-img" :src="users.currentUser.avatar ? users.currentUser.avatar : defaultAvatar" alt="Avatar" width="200px" height="200px"
                       cover></v-img>
                <p class="profile-name mt-3">{{users.currentUser.firstName + ' ' + users.currentUser.lastName}}</p>
                <div class="profile-info mt-3">
                    <v-icon class="icon" color="white">mdi-email</v-icon>
                    <p class="label mx-3">{{users.currentUser.email}}</p>
                </div>
                <div class="profile-info mt-3">
                    <v-icon class="icon" color="white">mdi-phone</v-icon>
                    <p class="label mx-3">{{users.currentUser.phone}}</p>
                </div>
                <div class="profile-info mt-3">
                    <v-icon class="icon" color="white">mdi-home</v-icon>
                    <p class="label mx-3">{{users.currentUser.address}}</p>
                </div>
                <RoundButton
                    class="edit-button"
                    title="Edit Profile"
                    @click.native="onEditProfile()"
                />
            </div>

        </div>
        <div v-if="isEdit">
            <!-- Register Form -->
            <div class="update-form">
                <h2 class="form-title text-center">Edit Your Profile</h2>
                <div class="avatar">
                    <img :src="registerUser.avatarUrl" @click="onClickAvatar()" />
                    <input type="file" ref="avatarFile" accept="image/*" @change="onAvatarFileChange" style="display: none">
                </div>
                <FormInput
                        type="text"
                        placeholder="First Name"
                        v-model="registerUser.firstName"
                        :error="registerUser.firstNameError"
                />
                <FormInput
                        type="text"
                        placeholder="Last Name"
                        v-model="registerUser.lastName"
                        :error="registerUser.lastNameError"
                />
                <FormInput
                        type="email"
                        placeholder="Email Address"
                        v-model="registerUser.email"
                        :error="registerUser.emailError"
                />
                <FormInput
                        type="text"
                        placeholder="Phone"
                        v-model="registerUser.phone"
                        :error="registerUser.phoneError"
                />
                <div class="formField">
                    <div class="formInput">
                        <vue-google-autocomplete
                                id="map"
                                ref="address"
                                placeholder="Address"
                                classname="input-address"
                                v-on:placechanged="getAddressData"
                        />
                    </div>
                    <div class="formError" v-if="registerUser.addressError">
                        {{registerUser.addressError}}
                    </div>
                </div>
                <RoundButton class="update-button" title="Submit" @click.native="onUpdateProfile()"/>
                <RoundButton title="Cancel" @click.native="onEditCancel()"/>
                <p class="errorMessage" v-if="registerErrorMessage">{{registerErrorMessage}}</p>
            </div>
        </div>
        <LoadingOverlay v-if="isLoading"/>
    </div>

</template>

<script>
    import {mapActions, mapState} from "vuex";
    import RoundButton from "../components/RoundButton";
    import {MESSAGES} from "../utils/Messages";
    import {STATUS_FAILURE, STATUS_SUCCESS} from "../constants/constants";
    import FormInput from "../components/FormInput";
    import VueGoogleAutocomplete from 'vue-google-autocomplete'
    import LoadingOverlay from "../components/LoadingOverlay";

    export default {
        name: 'Profile',
        components: {
            RoundButton,
            FormInput,
            VueGoogleAutocomplete,
            LoadingOverlay
        },
        data () {
            return {
                defaultAvatar: require('../assets/img/default_avatar.png'),
                isEdit: false,
                isLoading: false,
                registerErrorMessage: null,
                registerUser: {
                    user_id: null,
                    firstName: '',
                    lastName: '',
                    avatarUrl: '',
                    avatarFile: null,
                    email: '',
                    address: '',
                    phone: '',

                    firstNameError: '',
                    lastNameError: '',
                    emailError: '',
                    phoneError: '',
                    addressError: '',
                }
            }
        },
        mounted() {
          console.log('current user =======>', this.registerUser, this.users);
        },
        computed: {
            ...mapState({
                users: state => state.users,
            }),
        },
        methods: {
            ...mapActions([
                'updateUser',
            ]),

            onEditProfile() {
                this.isEdit = true;
                if (this.users && this.users.currentUser) {
                    this.registerUser.user_id = this.users.currentUser._id;
                    this.registerUser.firstName = this.users.currentUser.firstName;
                    this.registerUser.lastName = this.users.currentUser.lastName;
                    this.registerUser.email = this.users.currentUser.email;
                    this.registerUser.address = this.users.currentUser.address;
                    this.registerUser.phone = this.users.currentUser.phone;
                    this.registerUser.avatarUrl = this.users.currentUser.avatar || this.defaultAvatar;

                    setTimeout(() => {
                      this.$refs.address.update(this.users.currentUser.address);
                    }, 300);
                }
            },
            getAddressData: function (addressData, placeResultData, id) {
                const address = addressData.route + ", " + addressData.locality + ", " + addressData.country;
                this.registerUser.address = address;
                this.registerUser.addressError = "";
            },
            onClickAvatar: function() {
                this.$refs.avatarFile.click();
            },

            onAvatarFileChange: function(e) {
                const file = e.target.files[0];
                const url = URL.createObjectURL(file);
                this.registerUser.avatarUrl = url;
                this.registerUser.avatarFile = file;
            },

            onEditCancel() {
                this.isEdit = false;
            },
            // Clicked Register Button.
            onUpdateProfile: function() {
                if (this.isLoading) return;
                this.registerErrorMessage = null;

                const user = this.registerUser;
                var isValid = true;

                // First Name.
                if (!(user.firstName && user.firstName.length > 0)) {
                    user.firstNameError = MESSAGES.INVALID_FIRSTNAME;
                    isValid = false;
                }

                // Last Name.
                if (!(user.lastName && user.lastName.length > 0)) {
                    user.lastNameError = MESSAGES.INVALID_LASTNAME;
                    isValid = false;
                }

                // Email.
                if (!(user.email && user.email.length > 0)) {
                    user.emailError = MESSAGES.INVALID_EMAIL;
                    isValid = false;
                }

                if (!(user.phone && user.phone.length > 0)) {
                    user.phoneError = MESSAGES.INVALID_PHONE;
                    isValid = false;
                }

                // Address.
                if (!(user.address && user.address.length > 0)) {
                    user.addressError = MESSAGES.INVALID_ADDRESS;
                    isValid = false;
                }

                if (isValid) {
                    this.isLoading = true;
                    this.updateUser(user);
                }
            }
        },
        watch: {

            // Checking validation for register form.
            'registerUser.firstName': function(val) {
                if (val && val.length > 0) {
                    this.registerUser.firstNameError = null;
                }
            },
            'registerUser.lastName': function(val) {
                if (val && val.length > 0) {
                    this.registerUser.lastNameError = null;
                }
            },
            'registerUser.address': function(val) {
                if (val && val.length > 0) {
                    this.registerUser.addressError = null;
                }
            },
            'registerUser.email': function(val) {
                if (val && val.length > 0) {
                    this.registerUser.emailError = null;
                }
            },
            'registerUser.password': function(val) {
                if (val && val.length > 0) {
                    this.registerUser.passwordError = null;
                }
            },
            'registerUser.confirmPassword': function(val) {
                if (val && val.length > 0) {
                    this.registerUser.confirmPasswordError = null;
                }
            },

            // Register.
            'users.updateUserStatus': function(val) {
                if (val == STATUS_SUCCESS) {
                    this.isLoading = false;
                    this.isEdit = false;
                } else if (val == STATUS_FAILURE){
                    this.isLoading = false;
                    this.registerErrorMessage = this.users.errorMessage;
                }
            }
        }
    };
</script>
<style>
</style>
<style lang="scss" scope>
    .profile-card{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 450px;
        text-align: center;

        .avatar-img{
            border-radius: 100% !important;
            margin: 0 auto;
        }
        .profile-name{
            text-align: center;
            font-size: 30px;
            color: white;
        }
        .profile-info{
            display: flex;
            cursor: pointer;
            align-items: flex-start;

            .label{
                color: white;
                margin-bottom: 0px !important;
                text-align: left;
            }
        }

        .edit-button {
            margin: 30px auto;
            box-shadow: unset;
            width: 80%;
        }
    }

    .update-form {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 450px;
        width: 450px;
        padding: 40px 40px 20px 40px;
        margin-top: 30px;
        margin-bottom: 50px;
        border-radius: 10px;
        border-top: 5px solid #3263b3;
        background-color: white;

        .form-title {
            font-size: 24px;
            margin-bottom: 20px;
        }

        .update-button {
            margin-top: 20px;
            margin-bottom: 10px;
        }
    }


</style>
