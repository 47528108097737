<template>
    <div class="formField">
        <div class="formInput">
            <input :type="type" :placeholder="placeholder" v-bind:value="value" v-on:input="updateValue($event.target.value)"/>
        </div>
        <div class="formError" v-if="error">{{error}}</div>
    </div>
</template>

<script>
  export default {
    name: 'FormInput',
    props: {
        type: { type: String },
        error: { type: String },
        placeholder: { type: String },
        value: { type: String }
    },
    data: () => ({

    }),
    methods: {
        updateValue: function (value) {
            this.$emit('input', value);
        }
    }
  }
</script>
<style scope>
    .formField {
        margin-bottom: 15px;
    }

    .formInput {
        border: 1px solid #b6b6b6;
        text-align: left;
        border-radius: 5px;
        height: 50px;
        display: flex;
    }

    .formInput input {
        width: 100%;
        height: 100%;
        padding: 0px 20px;
        font-size: 14px;
    }

    .formError {
        color: red;
        font-style: italic;
        font-size: 11px;
        text-align: left;
        margin-top: 3px;
    }
</style>
