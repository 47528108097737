import axios from 'axios'
import { API_URL } from "../config";
import {
    STATUS_NONE,
    STATUS_SUCCESS,
    STATUS_FAILURE,
    PREDICTION
} from '../constants/constants'

const Prediction = {
    state: {
        data: null,
        getPredictionStatus: null,
        setPredictionStatus: null,
        resetPredictionStatus: null
    },
    actions: {
        getPrediction({state}, data) {
            state.getPredictionStatus = STATUS_NONE;
            axios.post( API_URL + PREDICTION.GET, data )
                .then(function (res) {
                  // console.log("prediction ===========>", res.data);
                    state.data = res.data.data;
                    state.getPredictionStatus = STATUS_SUCCESS;
                })
                .catch( err => {
                    state.getPredictionStatus = STATUS_FAILURE;
                    state.errorMessage = err.error;
                })
        },

        setPrediction({state}, data) {
            state.setPredictionStatus = STATUS_NONE;
            axios.post( API_URL + PREDICTION.SET, data)
                .then(function (res) {
                    state.data = res.data.data;
                    state.setPredictionStatus = STATUS_SUCCESS;
                })
                .catch( err => {
                    state.setPredictionStatus = STATUS_FAILURE;
                    state.errorMessage = err.error;
                });
        },

        resetPrediction({state}, data) {
            state.resetPredictionStatus = STATUS_NONE;
            axios.post( API_URL + PREDICTION.RESET, data)
                .then(function (res) {
                    state.data = res.data.data;
                    state.resetPredictionStatus = STATUS_SUCCESS;
                })
                .catch( err => {
                    state.resetPredictionStatus = STATUS_FAILURE;
                    state.errorMessage = err.error;
                });
        }
    }
}

export default Prediction
