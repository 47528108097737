import axios from 'axios'
import { API_URL } from "../config";
import {
    STATUS_NONE,
    STATUS_SUCCESS,
    STATUS_FAILURE,
    TEAMS,
    POLLS,
    AP_POLL_TEAMS,
    COACHES_POLL_TEAMS,
    ESPN_POWER_INDEX_POLL_TEAMS,
    CFFB_POLL_TEAMS,
    CBSSPORTS_POLL_TEAMS,
    SELECTION_COMMITTEE_TEAMS
} from '../constants/constants'
const CORSHeader = {
    headers: {
        'Authorization': 'Bearer OoxJJCIqpWttxpp65s6e2Nmk5ody2TYjChA6WLsFLDi/JS/dos6spABTKKvamvax',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Credentials': true
    }
};

const Teams = {
    state: {
        all: [],
        apPoll: [],
        coachesPoll: [],
        cffbPoll: [],
        espnPoll: [],
        cbsSportsPoll: [],
        selectionCommittee: [],
        errorMessage: '',
        myTop25: [],
        teamsByConference: [],
        matchResult : {},
        statistical: [],
        builtPoll: [],
        revisedPoll: [],
        topConferences: [],
        myTop25Trend: [],
        upsetTeam: null,
        pointsTeam: null,
        calendar: [],
        schedule: [], 
        gameStartDate: null,
        upsetPickScore: 0,

        getAllTeamStatus: null,
        getPollsStatus: null,
        matchResultStatus: null,
        getStatisticalStatus: null,
        getCalendarStatus: null,
        getScheduleStatus: null,


        game2ProjectedWinners: [],
        game2Checks: [],
        game3Option1Teams:[],
        game3Option2Teams:[],
        game3Option3Teams:[],
        redistributionSelected: 0,

        fallingOutTeams : [],
        topConferenceTeams: [],
        replacementTeams : [],
        getConferenceListStatus: null,
        pollRankingsStatus: null,
        revisedPollTeams : [],
        selectedPoll: null,
        pickSixPoint: 0,

        draftSeasonData: null
    },
    actions: {
        // Loading All teams.
        getAllTeams({state}) {
            state.getAllTeamStatus = STATUS_NONE;
            axios.get(API_URL + TEAMS.GET_ALL_TEAMS )
            .then(function (res) {
                const allTeams = [...res.data.data];

                // change Connecticut team's conference to FBS Independents
                let index = allTeams.findIndex((item) => item.school === 'Connecticut');
                if (index >= 0) {
                    allTeams[index].conference = 'FBS Independents';
                }
                index = allTeams.findIndex((item) => item.school === 'Marshall');
                if (index >= 0) {
                    allTeams[index].conference = 'Sun Belt';
                }
                index = allTeams.findIndex((item) => item.school === 'Southern Mississippi');
                if (index >= 0) {
                    allTeams[index].conference = 'Sun Belt';
                }
                index = allTeams.findIndex((item) => item.school === 'Old Dominion');
                if (index >= 0) {
                    allTeams[index].conference = 'Sun Belt';
                }
                index = allTeams.findIndex((item) => item.school === 'Georgia Southern');
                if (index >= 0) {
                    allTeams[index].conference = 'Sun Belt';
                }

                state.all = [...allTeams];
                state.getAllTeamStatus = STATUS_SUCCESS;
            })
            .catch(function (error) {
                state.getAllTeamStatus = STATUS_FAILURE;
                state.errorMessage = error;
            });
        },

        // AP Poll, Coaches Poll and get cbs polls (order in cbs poll, diff in cbs poll)
        getRegularPolls({state}, data) {
            state.getPollsStatus = STATUS_NONE;

            const apPoll = [];
            const coachesPoll = [];
            const espnPoll = [];
            const cffbPoll = [];
            const cbsSportsPoll = [];
            const selectionCommittee = [];

            for (const team of AP_POLL_TEAMS) {
                const index = state.all.findIndex((item) => item.school === team);
                if (index >= 0) {
                    apPoll.push(state.all[index]);
                }
            }

            for (const team of COACHES_POLL_TEAMS) {
                const index = state.all.findIndex((item) => item.school === team);
                if (index >= 0) {
                    coachesPoll.push(state.all[index]);
                }
            }

            for (const team of ESPN_POWER_INDEX_POLL_TEAMS) {
                const index = state.all.findIndex((item) => item.school === team);
                if (index >= 0) {
                    espnPoll.push(state.all[index]);
                }
            }

            for (const team of CFFB_POLL_TEAMS) {
                const index = state.all.findIndex((item) => item.school === team);
                if (index >= 0) {
                    cffbPoll.push(state.all[index]);
                }
            }

            for (const team of CBSSPORTS_POLL_TEAMS) {
                const index = state.all.findIndex((item) => item.school === team);
                if (index >= 0) {
                    cbsSportsPoll.push(state.all[index]);
                }
            }

            for (const team of SELECTION_COMMITTEE_TEAMS) {
                const index = state.all.findIndex((item) => item.school === team);
                if (index >= 0) {
                    selectionCommittee.push(state.all[index]);
                }
            }

            state.apPoll = apPoll;
            state.coachesPoll = coachesPoll;
            state.espnPoll = espnPoll;
            state.cffbPoll = cffbPoll;
            state.cbsSportsPoll = cbsSportsPoll;
            state.selectionCommittee = selectionCommittee;

            state.getPollsStatus = STATUS_SUCCESS;

            // axios.post(API_URL + TEAMS.GET_RANKING, data )
            //     .then(function (res) {
            //         if (res) {
            //             console.log("polls ====>", res.data);
            //             const polls = res.data.polls.polls;
            //             const cbsPoll = polls.length > 0 ? polls[polls.length - 1] : [];
            //             state.myTop25Trend = [];
            //             for (var i = 0; i < polls.length; i ++) {
            //                 if (polls[i].poll == POLLS.AP) {
            //                     state.apPoll = polls[i].ranks;
            //                 }
            //                 else if (polls[i].poll == POLLS.COACHES) {
            //                     state.coachesPoll = polls[i].ranks;
            //                 }
            //                 else if (polls[i].poll == POLLS.ESPN) {
            //                   state.espnPoll = polls[i].ranks;
            //                 }
            //                 if (state.espnPoll.length == 0) {
            //                     state.espnPoll = [...state.apPoll];
            //                 }
            //             }
            //             state.cffbPoll = [...state.apPoll];
            //
            //             if (cbsPoll && cbsPoll.length > 0) {
            //                 const newCBSPoll = [];
            //
            //                 if (state.all.length) {
            //
            //                     for (let i = 0; i < cbsPoll.length; i++) {
            //                         state.all.forEach((team) => {
            //                             const fullSchoolName = getFullSchoolName(cbsPoll[i]);
            //                             if(( team.school === fullSchoolName ) ||(team.abbreviation === fullSchoolName))
            //                             {
            //                                 newCBSPoll.push(team);
            //                             }
            //                         })
            //
            //                     }
            //                 }
            //                 state.cbsSportsPoll = newCBSPoll;
            //             } else {
            //                 state.cbsSportsPoll = [...state.coachesPoll];
            //             }
            //
            //             state.getPollsStatus = STATUS_SUCCESS;
            //         }
            //
            //     })
            //     .catch(function (error) {
            //         state.getPollsStatus = STATUS_FAILURE;
            //         state.errorMessage = error.error;
            //     });
        },

        // Match result for selected Week. (for example, Regular Session 3WK Schedule)
        getMatchResult({state}, data){
            const year = data.year;
            const week = data.week;
            state.matchResultStatus = STATUS_NONE;
            axios.post(API_URL + TEAMS.GET_MATCH_RESULT, data )
                .then(function (response) {
                    if (response.data && response.data.data) {
                        const result = [];
                        for (const match of response.data.data) {
                            const data = {
                                ...match,
                                teams: [
                                    {
                                        school: match.home_team,
                                        points: match.home_points
                                    },
                                    {
                                        school: match.away_team,
                                        points: match.away_points
                                    }
                                ]
                            }
                            result.push(data);
                        }

                        if (state.matchResult[year]) {
                            state.matchResult[year][week] = result;
                        } else {
                            state.matchResult[year] = {};
                            state.matchResult[year][week] = result;
                        }
                        state.matchResultStatus = STATUS_SUCCESS;
                    }
                })
                .catch(function (error) {
                    state.matchResultStatus = STATUS_FAILURE;
                    state.errorMessage = error.data.error;
                });
        },
        getStatistical({state}, data){
            const year = data.year;
            const week = data.week;
            const teamName = data.team;
            state.getStatisticalStatus = STATUS_NONE;
            axios.post(API_URL + TEAMS.GET_STATISTICAL, data )
                .then(function (response) {
                    if (response.data.data) {
                        console.log("Get Statistical ======>", response.data.data);
                        const data = response.data.data[0].teams;
                        state.statistical = data;
                        if (data.length === 2) {
                            if (data[0].school === teamName) {
                                state.statistical = data[0].stats;
                            } else {
                                state.statistical = data[1].stats;
                            }
                        }
                        state.getStatisticalStatus = STATUS_SUCCESS;
                    }
                })
                .catch(function (error) {
                    state.getStatisticalStatus = STATUS_FAILURE;
                    state.errorMessage = error.data.error;
                });
        },
        getCalendar({state}, data){
            state.getCalendarStatus = STATUS_NONE;
            axios.post(API_URL + TEAMS.GET_CALENDAR, data )
                .then(function (response) {
                    if (response.data.data) {
                        state.calendar = response.data.data;
                        state.getCalendarStatus = STATUS_SUCCESS;
                    }
                })
                .catch(function (error) {
                    state.getCalendarStatus = STATUS_FAILURE;
                    state.errorMessage = error.data.error;
                });
        },
        getSchedule({state}, data){
            state.getScheduleStatus = STATUS_NONE;
            axios.post(API_URL + TEAMS.GET_SCHEDULE, data )
                .then(function (response) {
                    if (response.data.data) {
                        console.log("Get Schedule Inside Teams ====>", response.data.data);
                        state.schedule = response.data.data;
                        state.getScheduleStatus = STATUS_SUCCESS;
                    }
                })
                .catch(function (error) {
                    state.getScheduleStatus = STATUS_FAILURE;
                    state.errorMessage = error.data.error;
                });
        },
    },
}

function getRandom25(list) {
    const result = [];
    for (var i = 0; i < 25; i++) {
        const item = list[Math.round(Math.random() * (list.length - 1))];
        var isExisting = false;
        result.forEach(element => {
            if (element.id === item.id) {
                isExisting = true;
                return;
            }
        });

        if (!isExisting) {
            result.push(item);
        }
    }

    return result;
}
// ACC, Big 10, Big 12, CUSA, MAC, MWC, PAC 12, SEC, Sun Belt
function getNickName(conferenceName) {
    switch (conferenceName) {
        case 'ACC':
        case 'Big 12':
        case 'SEC':
        case 'Sun Belt':
            return conferenceName;
        case 'American Athletic':
            return 'AAC';
        case 'Big Ten':
            return 'Big 10';
        case 'Pac-12':
            return 'PAC 12';
        case 'Conference USA':
            return 'CUSA';
        case 'Mid-American':
            return 'MAC';
        case 'Mountain West':
            return 'MWC';
        case 'FBS Independents':
            return 'Independents';
        default:
            return '';
    }
}

function getFullSchoolName(name) {
    let fullname = name;
    if (fullname.includes('San Jose St.')) {
        return fullname.replace('San Jose St.', 'San José State');
    } else if (fullname.includes("Okla. St.")) {
        return fullname.replace("Okla. St.", 'Oklahoma State');
    } else if (fullname.includes('St.')) {
        return fullname.replace('St.', 'State');
    } else if (fullname.includes('C.')) {
        return fullname.replace('C.', 'Coastal');
    } else if (fullname.includes(' (Fla.)')) {
        return fullname.replace(' (Fla.)', '');
    } else if (fullname.includes('Jose')) {
        return fullname.replace('Jose', 'José');
    }
    return fullname;
}

export default Teams
