<template>
    <div>
        <Navbar></Navbar>
        <p class="title">Game2 Projected Winners</p>
        <v-img class="mt-5" src="../assets/img/wireframe/Gm2ProjectedWinners.png" height="70vh" contain></v-img>
        <v-btn class="page-link-button next-button" @click="moveGm2NewCFFBPoll()">
            Next
        </v-btn>
    </div>
</template>

<script>
    import Navbar from "../components/Navbar";
    export default {
        name: "Gm2ProjectedWinners",
        components: {
            Navbar
        },
        data: () => ({

        }),
        mounted() {

        },
        methods: {
            moveGm2NewCFFBPoll: function() {
                this.$router.push({name: 'Gm2NewCFFBPoll'});
            },
        },
    }
</script>

<style scoped>

</style>