<template>
    <div>
        <Navbar></Navbar>
        <p class="title">THE FANTASY CHAMPIONSHIP SERIES OF COLLEGE FOOTBALL</p>
        <p class="subtitle-1">(Semifinal)</p>
        <v-img class="mt-5" src="../assets/img/wireframe/BCSComputerPickSemifinal.png" height="70vh" contain></v-img>
        <v-btn class="page-link-button next-button" @click="moveBCSPlayerPickNationalChampion()">
            Player Pick
        </v-btn>
    </div>
</template>

<script>
    import Navbar from "../components/Navbar";
    export default {
        name: "BCSComputerPickSemifinal",
        components: {
            Navbar
        },
        data: () => ({

        }),
        mounted() {

        },
        methods: {
            moveBCSPlayerPickNationalChampion: function() {
                this.$router.push({name: 'BCSPlayerPickNationalChampion'});
            },
        },
    }
</script>

<style scoped>

</style>