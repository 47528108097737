<template>
    <div class="check-team-cell">
        <div class="cell-item mb-2">
            <div class="check" v-if="checks[firstIndex] && checkType=='left'" @click="changeSelected(firstIndex)">X</div>
            <div class="check" v-else-if="!checks[firstIndex] && checkType=='left'" @click="changeSelected(firstIndex)"></div>
            <div class="cell" :class="checks[firstIndex] == false ? 'checked' : ''">
                <TeamCell
                  :index="firstIndex"
                  :item="teams[firstIndex]"
                  :color="checks[firstIndex] != true ? 'red' : 'blue'"
                  v-if="checkType=='left'"
                />
                <RightTeamCell
                  :index="firstIndex"
                  :item="teams[firstIndex]"
                  :color="checks[firstIndex] != true ? 'red' : 'blue'"
                  v-if="checkType=='right'"
                />
            </div>
            <div class="check" v-if="checks[firstIndex] && checkType=='right'" @click="changeSelected(firstIndex)">X</div>
            <div class="check" v-else-if="!checks[firstIndex] && checkType=='right'" @click="changeSelected(firstIndex)"></div>
        </div>
        <div class="cell-item mb-2">
            <div class="check" v-if="checks[secondIndex] && checkType=='left'" @click="changeSelected(secondIndex)">X</div>
            <div class="check" v-else-if="!checks[secondIndex] && checkType=='left'" @click="changeSelected(secondIndex)"></div>
            <div class="cell" :class="checks[secondIndex] == false ? 'checked' : ''">
                <TeamCell
                  :index="secondIndex"
                  :item="teams[secondIndex]"
                  :color="checks[secondIndex] != true ? 'red' : 'blue'"
                  v-if="checkType=='left'"
                />
                <RightTeamCell
                  :index="secondIndex"
                  :item="teams[secondIndex]"
                  :color="checks[secondIndex] != true ? 'red' : 'blue'"
                  v-if="checkType=='right'"
                />
            </div>
            <div class="check" v-if="checks[secondIndex] && checkType=='right'" @click="changeSelected(secondIndex)">X</div>
            <div class="check" v-else-if="!checks[secondIndex] && checkType=='right'" @click="changeSelected(secondIndex)"></div>
        </div>
    </div>
</template>

<script>
    import TeamCell from "./TeamCell";
    import RightTeamCell from "./RightTeamCell";

    export default {
        name: "CheckTeamCell",
        components: {
            RightTeamCell,
            TeamCell,
        },
        data: () => ({
            checked : false,
        }),
        props: {
            firstIndex: { type: Number },
            secondIndex: { type: Number },
            teams : { type : Array },
            checkType : { type : String },
            checks: { type: Array }
        },
        methods : {
            changeSelected(index){
                this.$emit("set-winner", index);
            }
        },
        mounted() {
        },
    }
</script>

<style lang="scss" scoped>
    .check-team-cell{
        color: red;
        .check{
            display: inline-block;
            width: 34px;
            height: 34px;
            background: white;
            color: black;
            vertical-align: top;
            border-radius: 5px;
            font-size: 16px;
            padding: 5px 11px;
            cursor: pointer;
        }
        .cell{
            display: inline-block;
            width: 150px;
            margin: 0px 5px;
            vertical-align: top;

            &.checked {
                color: black;
            }
        }
    }
</style>
