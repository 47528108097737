<template>
    <div class="page regular-season pageGame3">
        <Navbar :user="users.currentUser" />
        <div>
            <Header
                    title="Game 3"
                    sub-title="Pre-season polls"
                    :is-left=true
                    left-button-text="Reset"
                    @left_click="onReset"
                    @back_click="onBack"
                    @next_click="onNext"
            />
            <div class="game-type-page bgc-poll" v-if="teams.all.length && conferences.all.length && teams.myTop25.length">
                <div class="polls">
                    <GameTypeBoard
                            :teams="teams"
                            :conferences="conferences"
                            :selected-poll="selectedPoll"
                            @set-poll="setPoll"
                    />
                </div>
                <div class="description">
                    <InstructionBox type="game3" />
                </div>
            </div>
        </div>
        <LoadingOverlay v-if="isLoading"/>
    </div>
</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import Navbar from "../../components/Navbar";
    import InstructionBox from "../../components/Game/InstructionBox";
    import GameTypeBoard from "../../components/Game/GameTypeBoard";
    import Header from "../../components/Header";
    import LoadingOverlay from "../../components/LoadingOverlay";
    import {STATUS_FAILURE, STATUS_SUCCESS} from "../../constants/constants";
    import {currentYear, currentWeek, isFullTeams} from "../../utils/functions";
    import {MESSAGES} from "../../utils/Messages";


    export default {
        name: 'Game3',
        order: 5,
        components: {
            GameTypeBoard,
            Navbar,
            InstructionBox,
            Header,
            LoadingOverlay
        },
        data: () => ({
            selectedPoll: '0',
            isLoading: false
        }),
        computed: {
            ...mapState({
                users: state => state.users,
                teams: state => state.teams,
                conferences: state => state.conferences,
                game_type: state => state.game_type
            }),
        },
        mounted() {
            this.getAllTeams();
            this.getAllConferences();

            if (this.teams.myTop25.length <= 0) {
                for (let i = 0; i < 25; i++) {
                    this.teams.myTop25.splice(i, 1, {});
                }
            } else {
                const data = {
                    user: this.users.currentUser._id,
                    year: currentYear()
                }
                this.getGameType(data);
                for (let i = 0; i < 25; i++) {
                    if (this.teams.myTop25[i]._id) {
                        const index = this.teams.all.findIndex((team) => team._id === this.teams.myTop25[i]._id);
                        if (index >= 0) {
                            this.teams.myTop25.splice(i, 1, this.teams.all[index]);
                        }
                    }
                }
            }
        },
        methods: {
            ...mapActions([
                'getAllTeams',
                'getAllConferences',
                'getRegularPolls',
                'setGameType',
                'getGameType'
            ]),

            setPoll(index){
                this.selectedPoll = index;
            },

            onReset() {
                for (let i = 0; i < 25; i++) {
                    this.teams.myTop25.splice(i, 1, {});
                }
            },
            onBack() {
                this.$router.push({name: 'HomeScreen'});
            },

            onNext() {
                if (this.selectedPoll == "1") {
                  this.teams.myTop25 = this.teams.apPoll;
                } else if (this.selectedPoll == "2") {
                  this.teams.myTop25 = this.teams.coachesPoll;
                } else if (this.selectedPoll == "3") {
                  this.teams.myTop25 = this.teams.cffbPoll;
                } else if (this.selectedPoll == "4") {
                  this.teams.myTop25 = this.teams.espnPoll;
                } else if (this.selectedPoll == "5") {
                  this.teams.myTop25 = this.teams.cbsSportsPoll;
                }

                if (isFullTeams(this.teams.myTop25)) {
                    if (this.selectedPoll == 1) {
                        this.teams.myTop25 = this.teams.apPoll;
                    } else if (this.selectedPoll == 2) {
                        this.teams.myTop25 = this.teams.coachesPoll;
                    } else if (this.selectedPoll == 3) {
                        this.teams.myTop25 = this.teams.cffbPoll;
                    } else if (this.selectedPoll ==4) {
                        this.teams.myTop25 = this.teams.espnPoll;
                    } else if (this.selectedPoll == 5) {
                        this.teams.myTop25 = this.teams.cbsSportsPoll;
                    }

                    const own_team = [];
                    this.teams.myTop25.forEach((team) => {
                        const index = this.teams.all.findIndex(item => item.school === team.school);
                        if (index >= 0) {
                            const id = this.teams.all[index]._id;
                            own_team.push(id);
                        }
                    });

                    const data = {
                        user: this.users.currentUser._id,
                        year: currentYear(),
                        type: 'Game3',
                        own_team: own_team,
                        selected_poll: this.selectedPoll
                    }
                    this.setGameType(data);
                    this.$router.push({name: 'Gm3Redistribution'});
                } else {
                    this.$toasted.show(MESSAGES.INVALID_FULL_TEAMS);
                }
            }
        },
        watch: {
            'teams.getAllTeamStatus' : function(status) {
                this.isLoading = true;
                if (status === STATUS_SUCCESS) {
                    const data = {
                        user: this.users.currentUser._id,
                        year: currentYear()
                    }
                    this.getRegularPolls({year: currentYear(), week: 11});
                    this.getGameType(data);
                    this.isLoading = false;
                } else if (status === STATUS_FAILURE) {
                    this.isLoading = false;
                    this.$toasted.show(this.teams.errorMessage);
                }
            },
            'game_type.getGameTypeStatus' : function(status) {
                this.isLoading = true;
                if (status === STATUS_SUCCESS) {
                    if (this.game_type.data) {
                      const own_team = this.game_type.data.own_team || [];
                      if (own_team.length) {
                        for (let i = 0; i < 25; i++) {
                          this.teams.myTop25.splice(i, 1, {});
                        }
                      } else {
                        for (let i = 0; i < 25; i++) {
                          this.teams.myTop25.splice(i, 1, {});
                        }
                      }
                      // this.selectedPoll = this.game_type.data.selected_poll;
                    }
                    this.isLoading = false;
                } else if (status === STATUS_FAILURE) {
                    this.isLoading = false;
                    this.$toasted.show(this.game_type.errorMessage);
                }
            }
        }
    };
</script>
<style lang="scss" scope>
    .pageGame3 {
        .pageHeader {
            position: relative;

            .btnReset {
                position: absolute;
                width: 110px;
                top: 15px;
                left: 40px;
            }

            .pageTitle {
                text-transform: uppercase;
                text-align: center;
                margin-top: 30px;
                font-size: 30px;

                p{
                    margin: 0px;
                }

                .subTitle{
                    font-size: 20px;
                }
            }

            .btnNext {
                position: absolute;
                width: 110px;
                top: 15px;
                right: 40px;
            }

            .btnBack {
                position: absolute;
                width: 110px;
                top: 15px;
                right: 160px;
            }
        }


        .pageContent {
            margin: 30px;
            display: flex;
            border: 1px solid gray;
            border-radius: 5px;
            background-color: #f1f1f1;

            .leftPanel {
                width: 70%;
            }

            .rightPanel {
                width: 30%;
            }
        }
    }
</style>
