<template>
    <div :class="['btnRound', size === 'small' ? 'btnSmall' : '']">
      {{title}}
    </div>
</template>

<script>
  export default {
    name: 'RoundButton',
    props: {
        title: { type: String },
        theme: { type: String },
        size: { type: String },
    },
    data: () => ({

    }),
  }
</script>
<style lang="scss" scope>
    .btnRound {
        background-color: #3263be;
        color: white;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        box-shadow: 0px 0px 12px #888888;
        cursor: pointer;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;

        font-family: 'ArvoBold';
        text-transform: uppercase;
        letter-spacing: 2px;

        &.btnSmall {
            height: 40px;
            box-shadow: 0px 0px 6px #888888;
            font-size: 14px;
        }
    }

</style>
