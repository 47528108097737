export const MESSAGES = {
    INVALID_FIRSTNAME: "Please input a valid first name.",
    INVALID_LASTNAME: "Please input a valid last name.",
    INVALID_ADDRESS: "Please input a valid address.",
    INVALID_EMAIL: "Please input a valid email address.",
    INVALID_PASSWORD: "Please input a valid password.",
    INVALID_PHONE: "Please input a valid phone number.",
    INVALID_CONFIRM_PASSWORD: "Please input a valid confirm password.",
    NOT_MATCH_PASSWORD: "Password does not match. Please re-enter a new password again.",
    INVALID_FULL_TEAMS: "Please select 25 teams or one Poll.",
    DUPLICATE_TEAMS: "Please select a team not to be duplicated.",
    INVALID_MY_TOP25_TEAMS: 'Please select my top 25 teams in Game1, Game2 or Game3.',

    ALERT_WARNING_MESSAGE: `You have to fill 25 teams of poll choose with 'For next week's play'.`,
    ALERT_DOUBLE_WARNING_MESSAGE: "You mush have 25 teams in both of Game-Built Poll and Revised Poll for Double Points Match Scoring",
    ALERT_WARNING_DRAG_MESSAGE: "After week game is started. You can't drag or drop teams.",
    ALERT_WARNING_REST_MESSAGE: "You can't reset it because the game is started.",

    AP_POLL: 'AP Poll – is a weekly ranking of the top 25 college football teams in division one sports. The rankings are compiled by polling 65 sportswriters and broadcasters from across the nation. Each voter provides his own ranking of the top 25 teams, and the individual rankings are then combined to produce the national ranking by giving a team 25 points for a first-place vote, 24 for a second-place vote, and so on down to 1 point for a twenty-fifth-place vote. Ballots of the voting members in the AP Poll are made public.',
    COACHES_POLL: 'Coaches Poll - is a weekly ranking of the top 25 college football teams in division one sports using a panel of head coaches at FBS schools. The 62 panel of coaches is chosen by random draw, conference by conference plus independents, from a pool of coaches who have indicated to the American Football Coaches Association their willingness to participate. Each coach submits a Top 25 with a first-place vote worth 25 points, second place 24, and so on down to one point for 25th.',
    CFFB_POLL: 'CFFB Poll – is a weekly ranking of the top 25 college football teams in division one sports. The rankings are computer-generated ratings based on results of all games played between FBS and FCS schools and the following criteria: (1) win/loss record of all teams, (2) strength of schedule, (3) assessment of team play over the previous week with an assigned score of 1 to 10 (1 being the worst, 5 being average and 10 being very good), (4) not ranking Power Five teams with 3 losses ahead of any Group of Five conference teams with a better win loss record. ',
    CBSSPORTS_POLL: 'CBSSports.com 130 Poll - assigns a numerical ranking to each of the 130 teams competing at the FBS level. The list is updated each week during the season and occasionally during the off-season to reflect coaching changes, player transfers, recruiting and injuries. The computer-generated ratings take into consideration the results of all games played between FBS and FCS schools. Each team is also assigned a "Predictor" value that serves as a tool to forecast the outcome of future games between any two given teams.',
    ESPN_FOOTBALL_POWER_INDEX_POLL: 'ESPN Football Power Index Poll - Measures team\'s true strength on net points scale; expected point margin vs average opponent on neutral field and is based on strength of schedule, strength of record and game control. The panel rate college football\'s top teams from 1 to 10 (1 being the worst, 10 being elite and 5 being average) in five different categories: (1) coaching, (2) current talent, (3) recruiting,      (4) title path and (5) program foundation.',
    SELECTION_COMMITTEE_POLL: 'Selection Committee Poll - The committee selects the teams using a process that considers conference championships won, strength of schedule, head-to-head competition, comparative outcomes of common opponents (without incenting margin of victory) and other relevant factors that may have affected a team’s performance during the season or likely will affect its postseason performance, the committee members then vote the teams into the rankings.'
};
