import axios from 'axios'
import { API_URL } from "../config";
import {
    STATUS_NONE,
    STATUS_SUCCESS,
    STATUS_FAILURE,
    GAMETYPE
} from '../constants/constants'

const GameType = {
    state: {
        data: null,
        getGameTypeStatus: STATUS_NONE,
        setGameTypeStatus: STATUS_NONE,
        errorMessage: ''
    },
    actions: {
        // Get Game Type (1.RegularSeason, 2.PreSeason, 3.BowlSeason )
        getGameType({state}, data) {
            state.getGameTypeStatus = STATUS_NONE;
            axios.post( API_URL + GAMETYPE.GET_TYPE, data )
                .then(function (res) {
                    state.data = res.data.data;
                    state.getGameTypeStatus = STATUS_SUCCESS;
                })
                .catch( err => {
                    state.getGameTypeStatus = STATUS_FAILURE;
                    state.errorMessage = err.error;
                })
        },
        setGameType({state}, data) {
            state.setGameTypeStatus = STATUS_NONE;
            axios.post( API_URL + GAMETYPE.SET_TYPE, data)
                .then(function (res) {
                    state.data = res.data.data;
                    state.setGameTypeStatus = STATUS_SUCCESS;
                })
                .catch( err => {
                    state.setGameTypeStatus = STATUS_FAILURE;
                    state.errorMessage = err.error;
                })
        }
    }
}

export default GameType
