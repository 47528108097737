<template>
    <div class="regular-season-team-cell v-start" :class="between">
        <div class="wl-wrapper mt-60" :class="between">
            <div class="regular-sub-title">
                <div class="f-2 font-weight-bold text-center">W - L</div>
            </div>
            <div :class="(winFlags[index] == 1 || winFlags[index] == true ? 'wl win-color' : (winFlags[index] == -1 || winFlags[index] == false ? 'wl loss-color': 'wl'))" v-for="(item, index) in winLoss" :key="index">{{item.win}} - {{item.loss}}</div>
        </div>
        <div class="percent-wrapper mt-60 ml-1" :class="between">
            <div class="regular-sub-title" v-b-tooltip.hover title="Percentage Quotient identifies the top teams’ chances of playing for the National Championship based on favorable seeding within the playoff format.">
                <img class="exclaim-img mx-auto" src="@/assets/img/icon_exclaim.png" />
            </div>
            <div :class="(index < 10 ? 'percent green-background' : 'percent yellow-background')" v-for="(item, index) in winLoss" :key="index">
                {{100-index*4}}%
            </div>
        </div>
        <div class="teams ml-1">
            <div class="title-box" :class="smallTitle ? 'small' : ''">{{title}}</div>
            <div class="regular-sub-title justify-content-between w-100">
                <div class="f-2 font-weight-bold text-center">Rank</div>
                <div class="d-flex align-items-start"
                     v-b-tooltip.hover title="Tracks the start of a player’s Initial Ranking of their Top 25 teams and will display how that team will rise or fall in the poll throughout the season."
                >
                    <img class="exclaim-img small d-block mx-auto mt-0" src="@/assets/img/icon_exclaim.png" />
                    <div class="f-2 font-weight-bold ml-1 mr-1">
                        IR<br/>
                        ▼
                    </div>
                </div>
            </div>
            <drag v-for="(item, index) in pollTeams"
                  class="mytop25"
                  :key="index"
                  :transfer-data="{team : item, type: 'mytop25'}">
                <InitRankTeamCell :index="index" :item="item" :init-rank="getInitRank(index)" :win-flag="winFlags[index]" />
            </drag>
<!--            <draggable :list="pollTeams" :group="{ name: 'fallingOutTeams' , pull: 'clone', put: false }" @change="changeEvent">-->
<!--                <InitRankTeamCell v-for="(item, index) in pollTeams" :key="index" :index="index" :item="item" :init-rank="getInitRank(index)" :win-flag="getWinFlags[index]"></InitRankTeamCell>-->
<!--            </draggable>-->

        </div>
    </div>
</template>

<script>

    import { STATUS_SUCCESS, STATUS_FAILURE } from '../../constants/constants';
    import { mapState, mapActions } from 'vuex'
    import InitRankTeamCell from "./InitRankTeamCell";
    import {Drag, Drop} from 'vue-drag-drop';

    export default {
        name: "RegularSeasonTeamCell",
        components: {
            InitRankTeamCell,
            Drag,
            // Drop,
        },
        data: () => ({
            // myTop25 : [],
        }),
        props: {
            pollTeams: { type: Array },
            title : { type : String },
            winLoss : {type : Array},
            winFlags: {type: Array},
            between: {type: String},
            smallTitle: {type: Boolean},
        },
        computed : {
            ...mapState({
                users: state => state.users,
                teams: state => state.teams,
            }),
        },
        methods : {
            getInitRank(index){
                const initRank = [];
                for( var i = 0; i < this.pollTeams.length; i++ ){
                    initRank.push( 0 );
                    for( var j = 0; j < this.teams.myTop25.length; j++ ){
                        if( this.pollTeams[i].school == this.teams.myTop25[j].school ){
                            initRank[i] =  j + 1;
                        }
                    }
                }
                return initRank[index];
            },
            changeEvent: function(evt){
                if( evt.removed ){
                    var index = evt.removed.oldIndex;
                    this.pollTeams.splice(index, 0, evt.removed.element);
                }
            },
        },
        mounted() {
            // this.myTop25 = this.pollTeams;
        },
    }
</script>

<style lang="scss" scoped>
    .small {
        margin-top: 14px;
    }

    .regular-season-team-cell {
        &.small {
            margin-top: 0px;
            margin-bottom: 44px;

            .wl-wrapper {
                margin-top: 30px;
            }
            .percent-wrapper {
                margin-top: 30px;
            }
        }
    }
    .regular-season .regular-sub-title {
        &.small {
            margin-top: -46px;
        }
    }
</style>
