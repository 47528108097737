<template>
    <div class="game-type-board d-flex align-items-start">
        <div class="team-select-wrapper wrapper d-flex bgc-team-select" v-if="myTop25.length">
            <div class="own-teams">
                <div class="title-box" :class="[selectedPoll == '0'  ? 'active' : '']" @click="updateSelectedPoll('0')">Create Own Top 25 Poll</div>
                <drop v-for="(item, index) in myTop25" :key="index" :item="item" :index="index"
                      class="drop"
                      @drop="handleDrop(index, ...arguments)">
                    <!-- <TeamCell :key="item.id" :item="item" :index="index" :is-show-logo="false"/> -->
                    <TeamCell  :item="item" :index="index" :is-show-logo="false"/>
                </drop>
            </div>

            <div class="team-selector ml-3">
                <div class="title-box font-weight-bold">Teams</div>
                <div class="search-box">
                    <input class="search-box" type="text" placeholder="Conferences" v-model="keyword"/>
                </div>
                <div class="teams-wrapper mt-2">
                    <div class="conference"
                         v-for="conference in teams.teamsByConference"
                         :key = "conference.name">
                        <div class="my-1 f-4 font-weight-bold text-center">
                            {{conference.name}}
                        </div>
                        <drag 
                          v-for="team in getFilteredTeams(conference)"
                          :key="team.id"
                          :transfer-data="{ team: team }"
                          @dragend="handleDragEnd"
                          @dragstart="handleDragStart"
                          :draggable="isStartGame?false:true"
                              class="drag">
                            <TeamCell :is-show-logo="true" :item="team" v-if="team"/>
                        </drag>
                    </div>
                </div>
            </div>
        </div>
        <div class="wrapper d-flex">
            <RankList title="AP Poll" :teams="teams.apPoll" :is-selected="this.selectedPoll == '1'" @update-selected-poll="updateSelectedPoll('1')"/>
            <RankList title="Coaches Poll" :teams="teams.coachesPoll" :is-selected="this.selectedPoll == '2'" @update-selected-poll="updateSelectedPoll('2')"/>
            <RankList title="CFFB Poll" :teams="teams.cffbPoll" :is-selected="this.selectedPoll == '3'" @update-selected-poll="updateSelectedPoll('3')"/>
            <RankList title="ESPN Power Index Poll" :teams="teams.espnPoll" :is-selected="this.selectedPoll == '4'" @update-selected-poll="updateSelectedPoll('4')"/>
            <RankList title="CBSSports.com Poll" :teams="teams.cbsSportsPoll" :is-selected="this.selectedPoll == '5'" @update-selected-poll="updateSelectedPoll('5')"/>
        </div>
    </div>
</template>

<script>

    import RankList from "./RankList";
    import TeamCell from "./TeamCell";
    import {MESSAGES} from "../../utils/Messages";
    import {Drag, Drop} from 'vue-drag-drop';
    import {getConferenceNickName} from "../../utils/functions";

    export default {
        name: 'GameTypeBoard',
        components: {
            RankList,
            TeamCell,
            Drag,
            Drop
        },
        props: {
            teams: {type: Object},
            conferences: {type: Object},
            selectedPoll: {type: String, default: '0'},
            isStartGame: {type: Boolean}
        },
        data: () => ({
            keyword: '',
            myTop25: [],
            poll: '0'
        }),
        computed: {

        },
        watch: {
          
        },
        mounted() {

            // // restore my top25
            // if (this.teams.myTop25.length) {
            //     this.myTop25 = [...this.teams.myTop25];
            //
            // } else {
            //     for (var i = 0; i < 24; i++) {
            //         this.myTop25.push({});
            //     }
            //     this.myTop25.push( this.teams.all.filter( team => team.school === 'Oklahoma State' )[0] );
            // }

            // init my top25
            for (var i = 0; i < 25; i++) {
                this.myTop25.push({});
            }

            // this.teams.all.forEach(team=> {
            //        console.log(team.abbreviation+"  :  "+team.id);
            //     });

            // Arrange All Teams by Conferences.
            if (!this.teams.teamsByConference.length && this.teams.all.length && this.conferences.all.length) {
                const filterByConference = this.teams.all.sort((a,b) => (a.conference > b.conference) ? 1 : ((b.conference > a.conference) ? -1 : 0));
                const conferences = [];
                filterByConference.forEach( (team) => {
                    const index = conferences.findIndex( item => item.name === getConferenceNickName(team.conference));
                    if (index >= 0) {
                        conferences[index].teams.push(team);
                    } else {
                        const teams = [];
                        teams.push(team);
                        conferences.push({
                            name: getConferenceNickName(team.conference),
                            teams: teams
                        });
                    }
                });

                this.poll = this.selectedPoll;
                this.teams.teamsByConference = conferences.sort((a, b) => a.name < b.name ? -1 : 1);
            }
        },
        methods: {
            changeEvent: function (evt) {
                if (evt.added) {
                    var index = evt.added.newIndex;
                    
                    this.myTop25[index] = evt.added.element;
                    delete this.myTop25.splice(index + 1, 1);
                }
                this.teams.myTop25 = this.myTop25;
            },
            handleDrop(index, data, event) {
                for (const [i, team] of this.myTop25.entries()){
                    if (team==data.team){
                        this.myTop25.splice(i, 1, null);        
                    }
                }
                this.myTop25.splice(index, 1, data.team);        
                this.teams.myTop25 = this.myTop25;
            },

            handleDragStart(){
              
                //alert("DragStart");
            },
            handleDragEnd(){
                //alert("DragEnd");
            },
            onPollSelect(poll) {
                // alert(poll);
            },
            updateSelectedPoll(index){
                this.poll = index;
                this.$emit('set-poll', index);
            },
            getFilteredTeams(conference) {
              if (this.keyword) {
                const filteredTeams = conference.teams.filter((item) => item.school && item.school.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0);
                return filteredTeams;
              }
              return conference.teams;
            }
        }
    }
</script>
<style lang="scss" scope>
    .game-type-board {
        overflow-x: scroll;

        .team-select-wrapper {
            .team-selector {
                .teams-wrapper {
                    width: 170px;
                    max-height: calc(100vh - 40px);
                    overflow-y: auto;
                    padding-right: 7px;
                }
            }
        }
    }

    .search-box::-webkit-input-placeholder {
        font-weight: bold;
        text-align: center;
        font-size: 14px;
        color: black;
    }
    .search-box::-moz-placeholder {
        font-weight: bold;
        text-align: center;
        font-size: 14px;
        color: black;
    }
    .search-box:-ms-input-placeholder {
        font-weight: bold;
        text-align: center;
        font-size: 14px;
        color: black;
    }
    .search-box:-o-input-placeholder {
        font-weight: bold;
        text-align: center;
        font-size: 14px;
        color: black;
    }
</style>
