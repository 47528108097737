<template>
    <div class="page regular-season">
        <Navbar :user="users.currentUser" />
        <v-alert
            :value="alert"
            type="warning"
            transition="fade-transition"
        >
            {{alertContent}}
        </v-alert>
        <RegularSeasonHeader
                title="Regular Season Schedule"
                sub-title="Week 5"
		:is-back="true"
                @pick_six_click="onPickSix"
                @positional_match_click="onPositionalMatch"
                @statistical_match_click="onStatisticalMatch"
                @back_click="onBack"
                @next_click="onNext"
        />
        <div class="page-wrapper m-4">
            <div class="season-area border-1 border-radius-5 m-3">
                <div class="team-area v-start justify-content-center m-3">
                    <div class="myTop25-area">
                        <RegularSeasonMytop25Match
                                :teams = "teams"
                                :upset="upsetIndex"
                                @upset_updated="updateUpset"
                                :year="year"
                                :week="week"
                                v-if="teams.matchResult[year] && teams.matchResult[year][week]"
                        />
                    </div>
                    <div class="game-build-area ml-2">
                        <RegularSeasonGameBuildPoll
                                :teams = "teams"
                                :built-poll="builtPoll"
                                :next-primary="nextPrimary"
                                @change_primary="onChangePrimary"
                                :year="year"
                                :week="week"
                                v-if="teams.matchResult[year] && teams.matchResult[year][week]"
                        />
                    </div>
                    <div class="revised-area ml-2">
                        <RegularSeasonRevisedPoll
                                :teams = "teams"
                                :revised-poll="revisedPoll"
                                :built-poll="builtPoll"
                                :next-primary="nextPrimary"
                                @change_primary="onChangePrimary"
                                :year="year"
                                :week="week"
                                v-if="teams.matchResult[year] && teams.matchResult[year][week]"
                        />
                    </div>
                    <div class="replacement-area ml-2">
                        <RegularSeasonReplacement
                                :teams = "teams"
                                :replacement-teams="getReplacementTeams25"
                                :built-poll="builtPoll"
                                :year="year"
                                :week="week"
                                v-if="teams.matchResult[year] && teams.matchResult[year][week]"
                        />
                    </div>
                </div>
                <div class="bottom-area v-start px-5 mb-3">
                    <div class="shut-wrapper">
                        <div class="v-center f-5 font-weight-bold ml-3" v-b-tooltip.hover title="Five points per team is awarded when any of your Top 25 poll teams does not allow the opposing team to score a point.">
                            <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                            Shut outs
<!--                            <div class="upset-points ml-3"></div>-->
                            <input
                                class="simple-text-input"
                                type="text"
                                v-bind:value="shutoutScore"
                                v-on:input="updateValue('shutout', $event.target.value)"
                            />
                        </div>
                        <v-img class="logo" src="../../assets/img/wireframe/logo_new.png"></v-img>
                    </div>
                    <div class="box-wrapper ml-4">
                        <div class="f-4 font-weight-bold" v-b-tooltip.hover title="Choose any team from your Top 25 poll that you feel can be upset by the opposing team they play. You will be awarded 25 points if your Top 25 team is upset.">
                            <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                            Upset Pick of the week
                        </div>
                        <div class="upset-team-points v-center mt-2">
                            <drop class="mytop25" @drop="handleUpsetDrop">
                                <TeamCell :item="upsetTeam" :win-flag="false" :is-show-logo="true"></TeamCell>
                            </drop>
<!--                            <team-cell :win-flag="false" :is-show-logo="true"  :item="teams.myTop25[upsetIndex]"></team-cell>-->
                            <input
                                class="simple-text-input"
                                type="text"
                                v-bind:value="upsetPickScore"
                                v-on:input="updateValue('upset', $event.target.value)"
                            />
                            <RoundButton class="btn sm mb-2 ml-2" title="Reset" @click.native="onResetUpsetPick()"/>
                        </div>
                    </div>
                    <div class="box-wrapper ml-5">
                        <div class="f-4 font-weight-bold" v-b-tooltip.hover title="Select any FBS team that you feel will help you to gain the most points each week. Team does not have to be in your Top 25 Poll. You will be awarded all statistical points for the team selected.">
                            <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                            Points Team
                        </div>
                        <div class="v-center mt-2">
                            <drop class="mytop25" @drop="handlePointsDrop">
                                <TeamCell :item="pointsTeam" :win-flag="false" :is-show-logo="true"></TeamCell>
                            </drop>
                            <input
                                class="simple-text-input"
                                type="text"
                                v-bind:value="pointTeamScore"
                                v-on:input="updateValue('point', $event.target.value)"
                            />
                            <RoundButton class="btn sm mb-2 ml-2" title="Reset" @click.native="onResetPointTeam()"/>
                        </div>
                    </div>
                    <div class="box-wrapper ml-5" v-b-tooltip.hover title="You can change your points team each week. A change fee of $2.00 is required each time a team change is made.">
                        <div class="f-4 font-weight-bold">
                            <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                            Team
                        </div>
                        <button class="f-3 mt-2 button f-2">Change</button>
                    </div>
                    <div class="box-wrapper" v-b-tooltip.hover title="Teams falling out of Top 25 will be listed in the column below, which will be managed by computer. Teams will return to Replacement Team panel for future choosing.">
                        <div class="f-4 font-weight-bold">
                            <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                            Teams falling out of Top 25
                        </div>
                        <div class="d-flex align-items-end mt-2">
                            <div>
                                <drop v-for="(item, index) in fallingOut" :key="index" :item="item" :index="index"
                                      class="mytop25"
                                      @drop="handleFallingOutDrop(index, ...arguments)">
                                    <RegularSeasonFallingTeamCell :item="fallingOut[index]" :win-flag="false" :rank="fallingOutRankList[index]" :init-rank="fallingOutInitRankList[index]"/>
                                </drop>
                            </div>
                            <RoundButton class="btn sm mb-2 ml-2" title="Reset" @click.native="onResetFallingTeam"/>
                        </div>
                    </div>
                    <div class="box-wrapper ml-5" v-b-tooltip.hover title="Displays the top three conferences in your poll based on the number of teams from the same conference.">
                        <div class="f-4 font-weight-bold">
                            <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                            Your Top Conferences this Week
                        </div>
                        <div class="d-flex align-items-end mt-2">
                            <div>
                                <drop v-for="(item, index) in topConferences" :key="index" :item="item" :index="index"
                                      class="mytop25"
                                      @drop="handleTopConferenceDrop(index, ...arguments)">
                                    <RegularSeasonFallingTeamCell :item="topConferences[index]" :win-flag="true" :rank="topConferencesRankList[index]" :init-rank="topConferencesInitRankList[index]"/>
                                </drop>
                            </div>
                            <RoundButton class="btn sm mb-2 ml-2" title="Reset" @click.native="onResetTopConferenceTeams"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <LoadingOverlay v-if="isLoading"/>
    </div>
</template>
<script>
    import {STATUS_SUCCESS, STATUS_FAILURE, REGULARPOLLTYPE} from '../../constants/constants';
    import {MESSAGES} from '../../utils/Messages';
    import { mapState, mapActions } from 'vuex';
    import moment from 'moment';
    import Navbar from "../../components/Navbar";
    import RegularSeasonMytop25Match from '../../components/RegularSeason/RegularSeasonMytop25Match';
    import RegularSeasonGameBuildPoll from "../../components/RegularSeason/RegularSeasonGameBuildPoll";
    import RegularSeasonReplacement from "../../components/RegularSeason/RegularSeasonReplacement";
    import RegularSeasonRevisedPoll from "../../components/RegularSeason/RegularSeasonRevisedPoll";
    import RegularSeasonFallingTeamCell from "../../components/RegularSeason/RegularSeasonFallingTeamCell";
    import {Drag, Drop} from 'vue-drag-drop';
    import TeamCell from "../../components/Game/TeamCell";
    import Gm3Redistribution from "../GameSelect/Gm3Redistribution";
    import RegularSeasonHeader from "../../components/RegularSeason/RegularSeasonHeader";
    import {currentWeek, currentYear} from "../../utils/functions";
    import LoadingOverlay from "../../components/LoadingOverlay";
    import RoundButton from "../../components/RoundButton";

    export default {
        name: "RegularSeasonWk5Schedule",
        components: {
            RegularSeasonHeader,
            TeamCell,
            RegularSeasonGameBuildPoll,
            Navbar,
            RegularSeasonMytop25Match,
            RegularSeasonRevisedPoll,
            RegularSeasonReplacement,
            RegularSeasonFallingTeamCell,
            Drop,
            LoadingOverlay,
            RoundButton
        },
        data: () => ({
            upsetIndex : -1,
            pointsTeam : null,
            upsetTeam: null,
            builtPoll: [],
            revisedPoll: [],
            fallingOut: [],
            fallingOutInitRankList: [],
            fallingOutRankList : [],
            fallingOutWinFlags : [],
            topConferences: [],
            topConferencesInitRankList: [],
            topConferencesRankList: [],
            topConferencesWinFlags: [],
            isLoading: false,
            nextPrimary: REGULARPOLLTYPE.BUILT,
            year: currentYear(),
            week: '5',
            alert: false,
            alertContent: null,
            shutoutScore: '',
            upsetPickScore: '',
            pointTeamScore: ''
        }),
        props: {
        },
        created() {
            this.regular_season.currentSeason = 5;
            this.getAllTeams();
            this.getAllConferences();
        },
        mounted() {
            for( let i = 0; i < 5; i++ ){
                this.fallingOut.push({});
                this.fallingOutInitRankList.push(0);
                this.fallingOutRankList.push(0);
                this.fallingOutWinFlags.push(false);
            }

            for( let i = 0; i < 3; i++ ){
                this.topConferences.push({});
                this.topConferencesInitRankList.push(0);
                this.topConferencesRankList.push(0);
                this.topConferencesWinFlags.push(false);
            }

            for (let i = 0; i < 25; i++) {
                this.builtPoll.splice(i, 1, {});
                this.revisedPoll.splice(i, 1, {});
            }

            const data = {
                user: this.users.currentUser._id,
                year: this.year,
                week: this.week
            }

            setTimeout(() => {
                const data = {
                    user: this.users.currentUser._id,
                    year: this.year,
                    week: this.week
                }

                // this.getGameType(data);
                // this.getMatchResult(data);
                this.getRegularSeason(data);
            }, 300);

            this.regular_season.currentYear = this.year;
            this.regular_season.currentWeek = this.week;

            if (this.users.currentUser.watchTeam) {
                this.pointsTeam = this.users.currentUser.watchTeam;
            }
            if (this.users.currentUser.upsetTeam) {
                this.upsetTeam = this.users.currentUser.upsetTeam;
            }
        },
        computed: {
            ...mapState({
                users: state => state.users,
                teams: state => state.teams,
                conferences: state => state.conferences,
                game_type: state => state.game_type,
                regular_season: state => state.regular_season
            }),
            getReplacementTeams25(){
                const replacementTeams = [];
                const year = this.year;
                const week = this.week;
                if (this.teams.matchResult[year]) {
                    const matchResult = this.teams.matchResult[year][week];
                    if (matchResult) {
                        for( var k = 0; k < matchResult.length; k++ ){
                            var isFound = false;
                            for( var l = 0; l < this.teams.myTop25.length; l++ ){
                                if( (this.teams.myTop25[l].school != matchResult[k].teams[0].school) &&
                                    (this.teams.myTop25[l].school != matchResult[k].teams[1].school) ){
                                    continue;
                                }
                                else{
                                    isFound = true;
                                    break;
                                }
                            }
                            if( !isFound ){
                                if( matchResult[k].teams[0].points > matchResult[k].teams[1].points ){
                                    const team = matchResult[k].teams[0];
                                    const index = this.teams.all.findIndex((item) => item.school === team.school);
                                    if (index >= 0) {
                                        replacementTeams.push( this.teams.all[index] );
                                    }

                                }
                                else{
                                    const team = matchResult[k].teams[1];
                                    const index = this.teams.all.findIndex((item) => item.school === team.school);
                                    if (index >= 0) {
                                        replacementTeams.push( this.teams.all[index] );
                                    }
                                }
                            }
                        }
                    }
                }
                return replacementTeams;
            },
        },
        methods: {
            ...mapActions([
                'getAllTeams',
                'getAllConferences',
                'getGameType',
                'getRegularPolls',
                'getMatchResult',
                'setRegularSeason',
                'getRegularSeason',
                'updateUser'
            ]),

            onBack() {
                this.regular_season.currentSeason--;
                this.$router.back();
            },

            onNext() {
                if (this.saveDraftData(true)) {
                    this.users.currentUser.watchTeam = null;
                    this.users.currentUser.upsetTeam = null;
                    this.onPositionalMatch();
                }
            },

            onPickSix() {
                this.saveDraftData();
                this.$router.push({name: 'RegularSeasonWkPickSix'});
            },

            onPositionalMatch() {
                let isNotFilled = false;
                if (this.nextPrimary == REGULARPOLLTYPE.BUILT) {
                    isNotFilled = this.builtPoll.filter((item) => item && item._id == null).length > 0;
                } else {
                    isNotFilled = this.revisedPoll.filter((item) => item && item._id == null).length > 0;
                }

                if (isNotFilled) {
                    this.alert = true;
                    this.alertContent = MESSAGES.ALERT_WARNING_MESSAGE;
                    setTimeout(() => {
                        this.alert = false
                    }, 2000);
                } else {
                    if (this.nextPrimary == REGULARPOLLTYPE.BUILT) {
                        this.teams.revisedPoll = this.builtPoll;
                    } else {
                        this.teams.revisedPoll = this.revisedPoll;
                    }

                    this.teams.myTop25 = [...this.teams.revisedPoll];

                    if (this.topConferences && this.topConferences.length) {
                        this.teams.topConferences = this.topConferences;
                    }
                    this.$router.push({name: 'RegularSeasonWkMatchScoring'});
                }
            },

            onStatisticalMatch() {
                this.saveDraftData();
                this.$router.push({name: 'RegularSeasonWkStatistical'});
            },

            updateUpset(index) {
                this.upsetIndex = index;
                this.upsetTeam = this.teams.myTop25[index];
                this.users.currentUser.upsetTeam = this.upsetTeam;
            },

            allowDrop: function(event) {
                event.preventDefault();
            },

            changeEvent: function(evt){
                if( evt.added ){
                    var index = evt.added.newIndex;
                    this.fallingOut[index] = evt.added.element;
                    var fallingOutTeam = evt.added.element.school;
                    const matchResult = this.teams.matchResult[this.year][this.week];
                    for( var i = 0; i < matchResult.length; i++ ) {

                        if ( fallingOutTeam == matchResult[i].teams[0].school) {
                            var homePoints3 = matchResult[i].teams[0].points;
                            var awayPoints3 = matchResult[i].teams[1].points;
                            if (homePoints3 > awayPoints3) {
                                this.fallingOutWinFlags[index] = true;
                            }
                            else {
                                this.fallingOutWinFlags[index] = false;
                            }
                        }
                        else if ( fallingOutTeam == matchResult[i].teams[1].school ) {
                            var homePoints4 = matchResult[i].teams[0].points;
                            var awayPoints4 = matchResult[i].teams[1].points;
                            if (homePoints4 > awayPoints4) {
                                this.fallingOutWinFlags[index] = false;
                            }
                            else {
                                this.fallingOutWinFlags[index] = true;
                            }
                        }
                    }
                    delete this.fallingOut.splice(index+1, 1);
                    this.teams.fallingOutTeams = this.fallingOut;
                }
            },

            handleFallingOutDrop(index, data, event) {
                this.fallingOut.splice(index, 1, data.team);
                var fallingOutTeam = data.team.school;
                let _index = this.builtPoll.findIndex((item) => (item.school == data.team.school) === true);

                if (_index >= 0) {
                  this.builtPoll.splice(_index, 1, {});
                }

                const matchResult = this.teams.matchResult[this.year][this.week];
                for( var i = 0; i < matchResult.length; i++ ) {

                    if ( fallingOutTeam == matchResult[i].teams[0].school) {
                        var homePoints3 = matchResult[i].teams[0].points;
                        var awayPoints3 = matchResult[i].teams[1].points;
                        if (homePoints3 > awayPoints3) {
                            this.fallingOutWinFlags[index] = true;
                        }
                        else {
                            this.fallingOutWinFlags[index] = false;
                        }
                    }
                    else if ( fallingOutTeam == matchResult[i].teams[1].school ) {
                        var homePoints4 = matchResult[i].teams[0].points;
                        var awayPoints4 = matchResult[i].teams[1].points;
                        if (homePoints4 > awayPoints4) {
                            this.fallingOutWinFlags[index] = false;
                        }
                        else {
                            this.fallingOutWinFlags[index] = true;
                        }
                    }
                }
                this.teams.fallingOutTeams = this.fallingOut;
            },

            handleTopConferenceDrop(index, data, event) {
                this.topConferences.splice(index, 1, data.team);
                var topConferenceTeam = data.team.school;
                let _index = this.builtPoll.findIndex((item) => (item.school == data.team.school) === true);

                if (_index >= 0) {
                    this.builtPoll.splice(_index, 1, {});
                }

                const matchResult = this.teams.matchResult[this.year][this.week];
                for( var i = 0; i < matchResult.length; i++ ) {

                    if ( topConferenceTeam == matchResult[i].teams[0].school) {
                        var homePoints3 = matchResult[i].teams[0].points;
                        var awayPoints3 = matchResult[i].teams[1].points;
                        if (homePoints3 > awayPoints3) {
                            this.topConferencesWinFlags[index] = true;
                        }
                        else {
                            this.topConferencesWinFlags[index] = false;
                        }
                    }
                    else if ( topConferenceTeam == matchResult[i].teams[1].school ) {
                        var homePoints4 = matchResult[i].teams[0].points;
                        var awayPoints4 = matchResult[i].teams[1].points;
                        if (homePoints4 > awayPoints4) {
                            this.topConferencesWinFlags[index] = false;
                        }
                        else {
                            this.topConferencesWinFlags[index] = true;
                        }
                    }
                }
                this.teams.topConferenceTeams = this.topConferences;
            },

            handlePointsDrop(data, event) {
                this.pointsTeam = data.team;
                this.users.currentUser.watchTeam = data.team;
                this.teams.pointsTeam = data.team;
            },

            handleUpsetDrop(data, event) {
                this.upsetTeam = data.team;
                this.users.currentUser.upsetTeam = this.upsetTeam;
                this.teams.upsetTeam = this.upsetTeam;
                const index = this.teams.myTop25.findIndex((item) => item._id === data.team._id);
                if (index >= 0) {
                    this.upsetIndex = index;
                }
            },

            onChangePrimary(value) {
                this.nextPrimary = value;
            },

            onResetFallingTeam() {
                for (let i = 0; i < this.fallingOut.length; i++) {
                    this.fallingOut.splice(i, 1, {});
                }
            },

            onResetTopConferenceTeams() {
                for (let i = 0; i < this.topConferences.length; i++) {
                    this.topConferences.splice(i, 1, {});
                }
            },

            onResetUpsetPick() {
                this.upsetTeam = null;
            },

            onResetPointTeam() {
                this.pointsTeam = null;
            },

            saveDraftData(checkFull = false) {
                const my_poll = [];
                const built_poll = [];
                const revised_poll = [];
                let upset_pick;
                let point_team;
                const falling_team = [];
                const top_conferences = [];
                let built_poll_full = true;

                for (let i = 0; i < 25; i++) {
                    my_poll.push(this.teams.myTop25[i] ? this.teams.myTop25[i]._id : null);
                    built_poll.push(this.builtPoll[i] ? this.builtPoll[i]._id : null);
                    revised_poll.push(this.revisedPoll[i] ? this.revisedPoll[i]._id : null);

                    if (this.nextPrimary == REGULARPOLLTYPE.BUILT) {
                        if (!built_poll[i]) built_poll_full = false;
                    } else {
                        if (!revised_poll[i]) built_poll_full = false;
                    }
                }

                upset_pick = this.upsetIndex >= 0 ? this.upsetTeam._id : null;
                point_team = this.pointsTeam;

                for (let i = 0; i < 5; i++) {
                    falling_team.push(this.fallingOut[i] ? this.fallingOut[i]._id : null);
                }
                for (let i = 0; i < 3; i++) {
                    top_conferences.push(this.topConferences[i] ? this.topConferences[i]._id : null);
                }
                const data = {
                    user: this.users.currentUser._id,
                    year: this.year,
                    week: this.week,
                    my_poll,
                    built_poll,
                    revised_poll,
                    upset_pick: this.upsetTeam,
                    point_team,
                    falling_team,
                    positional_match_poll: this.nextPrimary,
                    top_conferences,
                    shutoutScore: this.shutoutScore,
                    upsetPickScore: this.upsetPickScore,
                    pointTeamScore: this.pointTeamScore,
                };

                if (checkFull) {
                    if (built_poll_full) {
                        this.setRegularSeason(data);
                    } else {
                        this.alert = true;
                        this.alertContent = MESSAGES.ALERT_WARNING_MESSAGE;
                        setTimeout(() => {
                            this.alert = false
                        }, 2000);
                        return false;
                    }
                } else {
                    this.setRegularSeason(data);
                }
                return true;
            },
            updateValue(type, value) {
                if (type === 'shutout') {
                    this.shutoutScore = value;
                } else if (type === 'upset') {
                    this.upsetPickScore = value;
                } else if (type === 'point') {
                    this.pointTeamScore = value;
                }
            }
        },
        watch: {
            'teams.getAllTeamStatus' : function(status) {
                this.isLoading = true;
                if (status === STATUS_SUCCESS) {
                    const data = {
                        user: this.users.currentUser._id,
                        year: currentYear()
                    }
                    this.getGameType(data);
                    // this.getRegularPolls({year: this.year, week: this.week});
                } else if (status === STATUS_FAILURE) {
                    this.isLoading = false;
                    this.$toasted.show(this.teams.errorMessage);
                }
            },
            'game_type.getGameTypeStatus' : function(status) {
                if (status === STATUS_SUCCESS) {
                    const data = {
                        user: this.users.currentUser._id,
                        year: currentYear(),
                        week: this.week
                    };
                    if (this.game_type.data) {
                        this.getMatchResult(data);
                    }
                } else if (status === STATUS_FAILURE) {
                    this.isLoading = false;
                    this.$toasted.show(this.game_type.errorMessage);
                }
            },
            'teams.matchResultStatus': function(status) {
                this.isLoading = true;
                if (status === STATUS_SUCCESS) {
                    this.isLoading = false;
                    const data = {
                        user: this.users.currentUser._id,
                        year: this.year,
                        week: this.week
                    }
                    this.getRegularSeason(data);
                } else if (status === STATUS_FAILURE) {
                    this.isLoading = false;
                    this.$toasted.show(this.teams.errorMessage);
                }
            },
            'regular_season.getRegularSeasonStatus' : function(status) {
                this.isLoading = true;
                if (status === STATUS_SUCCESS) {
                    if (this.$route.params && this.$route.params.restore) {
                        if (this.regular_season && this.regular_season.data.built_poll) {
                            for (let i = 0; i < this.regular_season.data.built_poll.length; i++){
                                if (this.regular_season.data.built_poll[i]) {
                                    const index = this.teams.all.findIndex((item) => item._id === this.regular_season.data.built_poll[i]);
                                    if (index >= 0) {
                                        this.builtPoll.splice(i, 1, this.teams.all[index]);
                                    }
                                }
                            }
                        }

                        if (this.regular_season.data.revised_poll) {
                            for (let i = 0; i < this.regular_season.data.revised_poll.length; i++){
                                if (this.regular_season.data.revised_poll[i]) {
                                    const index = this.teams.all.findIndex((item) => item._id === this.regular_season.data.revised_poll[i]);
                                    if (index >= 0) {
                                        this.revisedPoll.splice(i, 1, this.teams.all[index]);
                                    }
                                }
                            }
                        }
                        if (this.regular_season.data.upset_pick) {
                            this.upsetTeam = this.regular_season.data.upset_pick;
                        }
                        if (this.regular_season.data.point_team) {
                            this.pointsTeam = this.regular_season.data.point_team;
                        }
                        if (this.regular_season.data.falling_team) {
                            for (let i = 0; i < this.regular_season.data.falling_team.length; i++){
                                if (this.regular_season.data.falling_team[i]) {
                                    this.fallingOut.splice(i, 1, this.regular_season.data.falling_team[i])
                                }
                            }
                        }
                        if (this.regular_season.data.top_conferences) {
                            for (let i = 0; i < this.regular_season.data.top_conferences.length; i++){
                                if (this.regular_season.data.top_conferences[i]) {
                                    this.topConferences.splice(i, 1, this.regular_season.data.top_conferences[i]);
                                }
                            }
                            this.topConferences = this.regular_season.data.top_conferences;
                        }
                        if (this.regular_season.data.shutoutScore) {
                            this.shutoutScore = this.regular_season.data.shutoutScore;
                        }
                        if (this.regular_season.data.upsetPickScore) {
                            this.upsetPickScore = this.regular_season.data.upsetPickScore;
                        }
                        if (this.regular_season.data.pointTeamScore) {
                            this.pointTeamScore = this.regular_season.data.pointTeamScore;
                        }
                        this.onChangePrimary(this.regular_season.data.positional_match_poll);
                    }
                    this.isLoading = false;
                } else if (status === STATUS_FAILURE) {
                    this.isLoading = false;
                    this.$toasted.show(this.teams.errorMessage);
                }
            }
        }
    }
</script>


<style lang="scss" scoped="scoped">

    .regular-season{
        .page-wrapper {
            .season-area {
                overflow: auto;
                .team-area {
                    display: flex;
                    width: 1780px;
                }

                .bottom-area {
                    width: 1780px;
                    .shut-wrapper {
                        width: 200px;

                        .logo {
                            width: 100px;
                            object-fit: contain;
                            margin: 0 auto;
                        }
                    }

                    .box-wrapper {
                        width: 280px;
                    }

                    .upset-points {
                        width: 50px;
                        height: 30px;
                        border: 1px solid black;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
</style>
