<template>
    <div class="page regular-season">
        <Navbar :user="users.currentUser"/>
        <Header
                title="Positional Match Scoring"
                :sub-title="'WEEK ' + regular_season.currentSeason"
                :is-left="false"
                :is-back="true"
                @back_click="onBack"
                @next_click="onNext"
        />
        <div class="page-wrapper v-start m-4">
            <div class="match-description m-4">
                <div class="v-center w-100">
                    <div class="header font-weight-bold">Date:</div>
                    <div class="value ml-3 text-decoration-underline">{{date}}</div>
                </div>
                <div class="v-center mt-3 w-100">
                    <div class="header font-weight-bold">Poll Owner:</div>
                    <div class="value ml-3 text-decoration-underline">{{username}}</div>
                </div>
                <div class="v-center mt-3">
                    <div class="header font-weight-bold">League Division:</div>
                    <div class="value ml-3 text-decoration-underline">{{leagueName}}</div>
                </div>
                <div class="v-center mt-3">
                    <div class="header font-weight-bold">Team Name:</div>
                    <div class="value ml-3 text-decoration-underline">{{teamName}}</div>
                </div>
                <div class="v-center mt-3">
                    <div class="header font-weight-bold">Total Points:</div>
                    <div class="value ml-3 text-decoration-underline">{{teams.pickSixPoint + getTotalPoints()}}</div>
                </div>
                <div class="v-center mt-3">
                    <div class="header font-weight-bold">Week:</div>
                    <div class="value ml-3 text-decoration-underline">{{regular_season.currentSeason}}</div>
                </div>
                <div class="v-center mt-3">
                    <div class="header font-weight-bold">Account Balance:</div>
                    <div class="value ml-3 text-decoration-underline">${{accountBalance}}</div>
                </div>
                <div class="mt-30 font-weight-bold text-center">$20.00 minimum initial deposit</div>
                <table class="table init-deposit-table text-center mt-2">
                    <tr>
                        <th>$1</th>
                        <th>$2</th>
                        <th>$5</th>
                    </tr>
                    <tr>
                        <td>Play</td>
                        <td>for</td>
                        <td>cash</td>
                    </tr>
                </table>
                <div class="mt-30 font-weight-bold text-center">Positional Matches</div>
                <table class="table match-deposit-table mt-2">
                    <tr>
                        <th>6-9</th>
                        <th>$1.00</th>
                        <th>$4.00</th>
                        <th>$10.00</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>10-12</th>
                        <th>$3.00</th>
                        <th>$8.00</th>
                        <th>$15.00</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>13-16</th>
                        <th>$5.00</th>
                        <th>$12.00</th>
                        <th>$20.00</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>17-20</th>
                        <th>$8.00</th>
                        <th>$15.00</th>
                        <th>$25.00</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>21-25</th>
                        <th>$12.00</th>
                        <th>$20.00</th>
                        <th>$30.00</th>
                    </tr>
                </table>
                <div class="mt-30 font-weight-bold text-center">Play For Cash Option</div>
                <div class="mt-1 font-weight-bold text-center c-green f-4">The More Cash Played the Higher the Payout!</div>
                <div class="mt-2">
                    Cash is awarded based on the amount played and the number of positional matches made between a player's poll and the game polls played.
                </div>
                <div class="mt-2">
                    <b>Positional Matches are identified by whole points only.</b><br/>
                    <b>0.5 or half points do not qualify as a positional match.</b>
                </div>
                <div class="mt-30 font-weight-bold text-center">Match Points</div>
                <div class="mt-2">
                    <div class="match-point-text" v-b-tooltip.hover title="0.5 or half points are awarded for an inexact positional match of any Group of Five teams as long as they are ranked anywhere in any Top 25 game poll.">
                        <b>0.5</b> <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                    </div>
                    <div class="match-point-text" v-b-tooltip.hover title="1.0 pt awarded for exact positional match of any Top 25 game poll.">
                        <b>1.0</b> <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                    </div>
                    <div class="match-point-text" v-b-tooltip.hover title="1.5 pts awarded for exact positional match of any Group of Five teams to any Top 25 game poll.">
                        <b>1.5</b> <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                    </div>
                    <div class="match-point-text" v-b-tooltip.hover title="2.0 pts awarded for exact positional match of the CFFB game poll only.">
                        <b>2.0</b> <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                    </div>
                    <div class="match-point-text" v-b-tooltip.hover title="2.5 pts awarded for exact positional match of any Group of Five teams to the CFFB game poll.">
                        <b>2.5</b> <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                    </div>
                    <div class="match-point-text" v-b-tooltip.hover title="4.0 pts awarded for exact positional match of Selection Committee game poll.">
                        <b>4.0</b> <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                    </div>
                </div>
            </div>
            <div class="match-contents page-wrapper m-3">
                <div class="polls v-start">
                    <div class="revised-poll p-3">
                        <div class="border-1 p-3">
                            <RegularSeasonTeamCell
                                title="My Top 25 Poll"
                                :small-title="true"
                                :poll-teams="teams.revisedPoll"
                                :win-loss="getMyTop25WinLoss"
                                :win-flags="getWinFlags"
                                between="small"
                            />
                        </div>
                        <div class="score-area v-start my-3">
                            <div class="shut-outs">
                                <div class="v-end">
                                    <div class="v-center c-blue f-4">
                                        <div>Shut outs</div>
                                        <div class="text-decoration-underline ml-1">{{points.shutout}}</div>
                                    </div>
                                    <div class="v-center c-blue f-4 mx-5">
                                        <div class="text-center">Upset pick of the week</div>
                                        <div class="text-decoration-underline">{{teams.upsetPickScore || 0}}</div>
                                    </div>
                                    <div class="v-center c-blue f-4">
                                        <div>Point Team</div>
                                        <div class="text-decoration-underline ml-1">{{points.upset}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="prediction-polls-wrapper my-4">
                        <div class="prediction-polls v-start border-1 p-3">
                            <div class="prediction-poll">
                                <div
                                    class="f-6 font-weight-bold text-center mb-55"
                                    v-b-tooltip.hover :title="getTooltip('AP Poll')"
                                >
                                    <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                                    AP Poll
                                </div>
                                <div class="v-center" v-for="(item, index) in teams.apPoll" :key="item.id" >
                                    <div class="score-cell mr-1">{{apPollPoints[index]}}</div>
                                    <TeamCell  :item="item" :index="index"/>
                                </div>
                                <div class="f-4 c-blue text-center mt-5">Trending<span class="ml-3">{{points.count[0]}}%</span></div>
                            </div>
                            <div class="prediction-poll ml-3">
                                <div class="f-6 font-weight-bold text-center mb-55"
                                     v-b-tooltip.hover :title="getTooltip('Coaches Poll')"
                                >
                                    <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                                    Coaches Poll
                                </div>
                                <div class="v-center" v-for="(item, index) in teams.coachesPoll" :key="item.id" >
                                    <div class="score-cell mr-1">{{coachesPollPoints[index]}}</div>
                                    <TeamCell  :item="item" :index="index"/>
                                </div>
                                <div class="f-4 c-blue text-center mt-5">{{points.count[1]}}%</div>
                            </div>
                            <div class="prediction-poll ml-3">
                                <div class="f-6 font-weight-bold text-center mb-55"
                                     v-b-tooltip.hover :title="getTooltip('CFFB Poll')"
                                >
                                    <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                                    CFFB Poll
                                </div>
                                <div class="v-center" v-for="(item, index) in teams.cffbPoll" :key="item.id" >
                                    <div class="score-cell mr-1">{{cffbPollPoints[index]}}</div>
                                    <TeamCell  :item="item" :index="index"/>
                                </div>
                                <div class="f-4 c-blue text-center mt-5">{{points.count[2]}}%</div>
                            </div>
                            <div class="prediction-poll ml-3">
                                <div class="f-6 font-weight-bold text-center mb-30"
                                     v-b-tooltip.hover :title="getTooltip('ESPN Power Index Poll')"
                                >
                                    <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                                    ESPN Power Index Poll
                                </div>
                                <div class="v-center" v-for="(item, index) in teams.espnPoll" :key="item.id" >
                                    <div class="score-cell mr-1">{{espnPollPoints[index]}}</div>
                                    <TeamCell  :item="item" :index="index"/>
                                </div>
                                <div class="f-4 c-blue text-center mt-5">{{points.count[3]}}%</div>
                            </div>
                            <div class="prediction-poll ml-3">
                                <div class="f-6 font-weight-bold text-center mb-55"
                                     v-b-tooltip.hover :title="getTooltip('CBSSports.com Poll')"
                                >
                                    <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                                    CBSSports.com Poll
                                </div>
                                <div class="v-center" v-for="(item, index) in teams.cbsSportsPoll" :key="item.id" >
                                    <div class="score-cell mr-1">{{cbsPollPoints[index]}}</div>
                                    <TeamCell  :item="item" :index="index"/>
                                </div>
                                <div class="f-4 c-blue text-center mt-5">{{points.count[4]}}%</div>
                            </div>
                            <div class="prediction-poll ml-3">
                                <div class="f-6 font-weight-bold text-center mb-30"
                                     v-b-tooltip.hover :title="getTooltip('Selection Committee')"
                                >
                                    <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
                                    Selection Committee
                                </div>
                                <div class="v-center" v-for="(item, index) in teams.selectionCommittee" :key="item.id" >
                                    <div class="score-cell mr-1">{{selectionCommitteePoints[index]}}</div>
                                    <TeamCell  :item="item" :index="index"/>
                                </div>
                                <div class="f-4 c-blue text-center mt-5">{{points.count[5]}}%</div>
                            </div>
                        </div>
                        <div class="score-area v-start mx-5 mb-3">
                            <div class="points c-blue">Pts.</div>
                            <div class="score-payments v-start">
                                <div class="poll-score-wrapper">
                                    <div class="point v-center f-4 c-blue text-decoration-underline text-center ml-30">
                                        {{matchTotalPoint(0)}}
                                    </div>
                                    <div class="total-price f-2 text-center bgc-white border-1 ml-30 mt-5">${{matchBalance(0)}}</div>
                                    <div class="v-start mt-2">
                                        <div class="balance-price low f-2 bgc-white text-center">$1</div>
                                        <div class="balance-price middle f-2 bgc-white text-center">$2</div>
                                        <div class="balance-price high f-2 bgc-white text-center">$5</div>
                                    </div>
                                </div>
                                <div class="poll-score-wrapper">
                                    <div class="point f-4 c-blue text-decoration-underline text-center ml-30">{{matchTotalPoint(1)}}</div>
                                    <div class="total-price f-2 text-center bgc-white border-1 ml-30 mt-5">${{matchBalance(1)}}</div>
                                    <div class="v-start mt-2">
                                        <div class="balance-price low f-2 bgc-white text-center">$1</div>
                                        <div class="balance-price middle f-2 bgc-white text-center">$2</div>
                                        <div class="balance-price high f-2 bgc-white text-center">$5</div>
                                    </div>
                                </div>
                                <div class="poll-score-wrapper">
                                    <div class="point f-4 c-blue text-decoration-underline text-center ml-30">{{matchTotalPoint(2)}}</div>
                                    <div class="total-price f-2 text-center bgc-white border-1 ml-30 mt-5">${{matchBalance(2)}}</div>
                                    <div class="v-start mt-2">
                                        <div class="balance-price low f-2 bgc-white text-center">$1</div>
                                        <div class="balance-price middle f-2 bgc-white text-center">$2</div>
                                        <div class="balance-price high f-2 bgc-white text-center">$5</div>
                                    </div>
                                </div>
                                <div class="poll-score-wrapper">
                                    <div class="point f-4 c-blue text-decoration-underline text-center ml-30">{{matchTotalPoint(3)}}</div>
                                    <div class="total-price f-2 text-center bgc-white border-1 ml-30 mt-5">${{matchBalance(3)}}</div>
                                    <div class="v-start mt-2">
                                        <div class="balance-price low f-2 bgc-white text-center">$1</div>
                                        <div class="balance-price middle f-2 bgc-white text-center">$2</div>
                                        <div class="balance-price high f-2 bgc-white text-center">$5</div>
                                    </div>
                                </div>
                                <div class="poll-score-wrapper">
                                    <div class="point f-4 c-blue text-decoration-underline text-center ml-30">{{matchTotalPoint(4)}}</div>
                                    <div class="total-price f-2 text-center bgc-white border-1 ml-30 mt-5">${{matchBalance(4)}}</div>
                                    <div class="v-start mt-2">
                                        <div class="balance-price low f-2 bgc-white text-center">$1</div>
                                        <div class="balance-price middle f-2 bgc-white text-center">$2</div>
                                        <div class="balance-price high f-2 bgc-white text-center">$5</div>
                                    </div>
                                </div>
                                <div class="poll-score-wrapper w-auto">
                                    <div class="point f-4 c-blue text-decoration-underline text-center ml-30">{{matchTotalPoint(5)}}</div>
                                    <div class="total-price f-2 text-center bgc-white border-1 ml-30 mt-5">${{matchBalance(5)}}</div>
                                    <div class="v-start mt-2">
                                        <div class="balance-price low f-2 bgc-white text-center">$1</div>
                                        <div class="balance-price middle f-2 bgc-white text-center">$2</div>
                                        <div class="balance-price high f-2 bgc-white text-center">$5</div>
                                    </div>
                                </div>
                                <div class="total-points-wrapper ml-30">
                                    <div class="d-flex align-items-start">
                                        <div class="v-center">
                                            <div class="pick-six f-4 c-blue">
                                                Poll Pts
                                            </div>
                                            <span class="total-point text-decoration-underline c-blue">{{getTotalPoints()}}</span>
                                        </div>
                                        <div class="v-center ml-5">
                                            <div class="pick-six f-4 c-blue">
                                                Pick Six
                                            </div>
                                            <span class="total-point text-decoration-underline c-blue">{{teams.pickSixPoint}}</span>
                                        </div>
                                        <div class="text-center ml-5">
                                            <span class="total-point f-6 text-decoration-underline c-blue">{{teams.pickSixPoint + getTotalPoints()}}</span>
                                            <div class="f-4 c-blue total-text">Total</div>
                                        </div>
                                    </div>

                                    <div class="justify-content-end v-center">
                                        <div class="f-4">Total</div>
                                        <div class="total-score-box text-center bgc-white ml-3">${{accountBalance}}</div>
                                    </div>
                                    <!--                            <RoundButton @click.native="onBack" class="mt-30 btn-submit" size="small" title="Submit"/>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        GROUP5CONFERENCE,
        BEGINNER_LEVEL_LOWER_SCORE,
        BEGINNER_LEVEL_HIGHER_SCORE,
        BEGINNER_LEVEL_PAYMENT,
        AMATEUR_LEVEL_LOWER_SCORE,
        AMATEUR_LEVEL_HIGHER_SCORE,
        AMATEUR_LEVEL_PAYMENT,
        PROFESSIONAL_LEVEL_LOWER_SCORE,
        PROFESSIONAL_LEVEL_HIGHER_SCORE,
        PROFESSIONAL_LEVEL_PAYMENT,
        TOPSTAR_LEVEL_LOWER_SCORE,
        TOPSTAR_LEVEL_HIGHER_SCORE,
        TOPSTAR_LEVEL_PAYMENT,
        SUPERSTAR_LEVEL_LOWER_SCORE,
        SUPERSTAR_LEVEL_HIGHER_SCORE,
        SUPERSTAR_LEVEL_PAYMENT,
        DEPOSIT_LEVEL_LOW,
        DEPOSIT_LEVEL_NORMAL,
        DEPOSIT_LEVEL_HIGH,
    } from '../../constants/constants';
    import {mapActions, mapState} from "vuex";
    import TeamCell from '../../components/Game/TeamCell';
    import RegularSeasonTeamCell from '../../components/RegularSeason/RegularSeasonTeamCell';
    import Header from "../../components/Header";
    import Navbar from "../../components/Navbar";
    import {Drag, Drop} from 'vue-drag-drop';
    import {MESSAGES} from "../../utils/Messages";
    import {currentYear} from "../../utils/functions";

    export default {
        name: "RegularSeasonWkMatchScoring",
        components: {
            Navbar,
            Header,
            TeamCell,
            // Drop,
            RegularSeasonTeamCell
        },
        data: () => ({
            date : 'Aug 31',
            leagueName : 'SEC East',
            teamName : 'StrikeForce1',
            totalPoints : 0.0,
            accountBalance: 0.0,

            apPollPoints : [],
            coachesPollPoints : [],
            cffbPollPoints : [],
            espnPollPoints : [],
            cbsPollPoints : [],
            selectionCommitteePoints: [],

            points : {
                shutout : 0.0,
                upset : 0.0,
                count : [0, 0, 0, 0, 0, 0],
                polls : [0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                pickSix : 0.0,
            },

            balance : {
                poll: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                total: 0.0
            },

            topConferences : [{}, {}, {}]
        }),

        props: {
            ownerName : { type: String },
            week: { type: Number }
        },
        created() {
            if (this.$route.query && this.$route.query.season) {
                this.regular_season.currentSeason = Number(this.$route.query.season);
            }
            this.getRegularPolls({ year: currentYear(), week: 8 });
            if (this.teams.revisedPoll.length < 25) {
                for (let i = 0; i < 25; i++) {
                    this.teams.revisedPoll.push({});
                }
            }
        },
        mounted() {
            for (let i = 0; i < 25; i++) {
                if (this.teams.revisedPoll[i]) {
                    if (this.teams.revisedPoll[i].school == this.teams.apPoll[i].school) {
                        this.apPollPoints.push(1);
                    } else {
                        this.apPollPoints.push(0);
                    }
                    if (this.teams.revisedPoll[i].school == this.teams.coachesPoll[i].school) {
                        this.coachesPollPoints.push(1);
                    } else {
                        this.coachesPollPoints.push(0);
                    }
                    if (this.teams.revisedPoll[i].school == this.teams.cffbPoll[i].school) {
                        this.cffbPollPoints.push(2);
                    } else {
                        this.cffbPollPoints.push(0);
                    }
                    if (this.teams.revisedPoll[i].school == this.teams.espnPoll[i].school) {
                        this.espnPollPoints.push(1);
                    } else {
                        this.espnPollPoints.push(0);
                    }
                    if (this.teams.revisedPoll[i].school == this.teams.cbsSportsPoll[i].school) {
                        this.cbsPollPoints.push(1);
                    } else {
                        this.cbsPollPoints.push(0);
                    }
                    if (this.teams.revisedPoll[i].school == this.teams.selectionCommittee[i].school) {
                        this.selectionCommitteePoints.push(4);
                    } else {
                        this.selectionCommitteePoints.push(0);
                    }
                } else {
                    this.apPollPoints.push(0);
                    this.coachesPollPoints.push(0);
                    this.cffbPollPoints.push(0);
                    this.espnPollPoints.push(0);
                    this.cbsPollPoints.push(0);
                    this.selectionCommitteePoints.push(0);
                }
            }

            for (let i = 0; i < 6; i ++) {
                const cid = i;
                let sum = 0;
                for (let j = 0; j < 25; j ++) {
                    const tid = j;
                    const revisedPollTeams = this.teams.revisedPoll
                    if (revisedPollTeams[tid]) {
                        let index = GROUP5CONFERENCE.indexOf(revisedPollTeams[tid].conference);

                        if (cid === 0) {
                            let teamIndex = this.teams.apPoll.findIndex(team => (team.school === revisedPollTeams[tid].school) === true);
                            if (teamIndex > -1 && index > -1)
                                this.apPollPoints[teamIndex] += 0.5;

                        }
                        else if (cid === 1) {
                            let teamIndex = this.teams.coachesPoll.findIndex(team => (team.school === revisedPollTeams[tid].school) === true);
                            if (teamIndex > -1 && index > -1)
                                this.coachesPollPoints[teamIndex] += 0.5;
                        }
                        else if (cid === 2) {
                            let teamIndex = this.teams.cffbPoll.findIndex(team => (team.school === revisedPollTeams[tid].school) === true);
                            if (teamIndex > -1 && index > -1)
                                this.cffbPollPoints[teamIndex] += 0.5;
                        }
                        else if (cid === 3) {
                            let teamIndex = this.teams.espnPoll.findIndex(team => (team.school === revisedPollTeams[tid].school) === true);
                            if (teamIndex > -1 && index > -1)
                                this.espnPollPoints[teamIndex] += 0.5;
                        }
                        else if (cid === 4) {
                            let teamIndex = this.teams.cbsSportsPoll.findIndex(team => (team.school === revisedPollTeams[tid].school) === true);
                            if (teamIndex > -1 && index > -1)
                                this.cbsPollPoints[teamIndex] += 0.5;
                        }
                        else if (cid === 4) {
                            let teamIndex = this.teams.selectionCommittee.findIndex(team => (team.school === revisedPollTeams[tid].school) === true);
                            if (teamIndex > -1 && index > -1)
                                this.selectionCommitteePoints[teamIndex] += 0.5;
                        }
                    }
                }

                this.matchTotalPoint(i);
                // this.matchPointCounts(i);
                this.matchBalance(i);
                this.matchTrending(i);
            }

            this.getTotalPoints();
            this.getTotalBalance();

            const revisedPollTeams = this.teams.revisedPoll;
            const conferences = revisedPollTeams.map(team => team.conference);

            if (this.teams.topConferences && this.teams.topConferences.length) {
                this.topConferences = this.teams.topConferences;
            }
        },

        updated() {
        },

        computed: {
            ...mapState({
                users: state => state.users,
                teams: state => state.teams,
                regular_season: state => state.regular_season
            }),
            getMyTop25WinLoss(){

                const myTop25WinLoss = [];
                const year = this.regular_season.currentYear;
                const week = this.regular_season.currentWeek;
                if (this.teams.matchResult && this.teams.matchResult[year] && this.teams.matchResult[year][week]) {
                    const matchResult = this.teams.matchResult[year][week];
                    for (var j = 0; j < this.teams.revisedPoll.length; j++) {

                        var winLossObj = {
                            win : 0,
                            loss : 0
                        };
                        myTop25WinLoss.push( winLossObj );

                        for( var i = 0; i < matchResult.length; i++ ) {


                            if ( this.teams.revisedPoll[j].school === matchResult[i].teams[0].school) {
                                var homePoints3 = matchResult[i].teams[0].points;
                                var awayPoints3 = matchResult[i].teams[1].points;
                                if (homePoints3 > awayPoints3) {
                                    myTop25WinLoss[j].win++;
                                }
                                else {
                                    myTop25WinLoss[j].loss++;
                                }
                            }
                            else if ( this.teams.revisedPoll[j].school === matchResult[i].teams[1].school ) {
                                var homePoints4 = matchResult[i].teams[0].points;
                                var awayPoints4 = matchResult[i].teams[1].points;
                                if (homePoints4 > awayPoints4) {
                                    myTop25WinLoss[j].loss++;
                                }
                                else {
                                    myTop25WinLoss[j].win++;
                                }
                            }
                        }
                    }
                } else {
                    for (let j = 0; j < this.teams.revisedPoll.length; j++) {

                        let winLossObj = {
                            win: 0,
                            loss: 0
                        };
                        myTop25WinLoss.push(winLossObj);
                    }
                }

                return myTop25WinLoss;
            },
            getWinFlags(){
                const winFlags = [];
                const year = this.regular_season.currentYear;
                const week = this.regular_season.currentWeek;
                if (this.teams.matchResult[year]) {
                    const matchResult = this.teams.matchResult[year][week];
                    if (matchResult) {
                        for (var j = 0; j < this.teams.revisedPoll.length; j++) {
                            winFlags.push( false );
                            const firstIndex = matchResult.findIndex(
                                (item) => item.teams[0].school == this.teams.revisedPoll[j].school);
                            const secondIndex = matchResult.findIndex(
                                (item) => item.teams[1].school == this.teams.revisedPoll[j].school);
                            if (firstIndex >= 0) {
                                var homePoints3 = matchResult[firstIndex].teams[0].points;
                                var awayPoints3 = matchResult[firstIndex].teams[1].points;
                                if (homePoints3 >= awayPoints3) {
                                    winFlags[j] = true;
                                }
                                else {
                                    winFlags[j] = false;
                                }
                            }
                            if (secondIndex >= 0) {
                                var homePoints4 = matchResult[secondIndex].teams[0].points;
                                var awayPoints4 = matchResult[secondIndex].teams[1].points;
                                if (homePoints4 > awayPoints4) {
                                    winFlags[j] = false;
                                }
                                else {
                                    winFlags[j] = true;
                                }
                            }
                        }
                    }
                }
                console.log('match result ======>', this.teams.matchResult[year], winFlags)
                return winFlags;
            },

            'username': function() {
                var name = "";
                if (this.users.currentUser) {
                    name = this.users.currentUser.firstName + " " + this.users.currentUser.lastName;
                }
                return name;
            },

        },
        methods: {
            ...mapActions([
                'getAllTeams',
                'updateUser',
                'getRegularPolls'
            ]),

            onBack() {
                // this.$router.back();
                this.$router.push({name: 'RegularSeasonWk' + this.regular_season.currentSeason + 'Schedule', params: {restore: true}});
            },

            onNext() {
                this.teams.topConferences = this.topConferences;

                this.teams.myTop25 = [...this.teams.revisedPoll];

                if (this.regular_season.currentSeason == 2) {
                    this.$router.push({name: 'ConferencePredictionsWk3'});
                } else if (this.regular_season.currentSeason == 7) {
                    this.$router.push({name: 'ConferencePredictionsWk8'});
                } else {
                    this.regular_season.currentSeason += 1;
                    this.$router.push({name: `RegularSeasonWk${this.regular_season.currentSeason}Schedule`});
                }
            },

            onResetUpset() {

            },

            matchTotalPoint(cid) {
                let i;
                let sum = 0;
                if (cid === 0) {
                    for (i = 0; i < 25; i++) {
                        if (this.apPollPoints[i] > 0) {
                            sum += this.apPollPoints[i];
                        }
                    }
                    this.points.polls[cid] = sum;
                    return sum;
                }
                if (cid === 1) {
                    for (i = 0; i < 25; i++) {
                        if (this.coachesPollPoints[i] > 0)
                            sum += this.coachesPollPoints[i];
                    }
                    this.points.polls[cid] = sum;
                    return sum;
                }
                if (cid === 2) {
                    for (i = 0; i < 25; i++) {
                        if (this.cffbPollPoints[i] > 0)
                            sum += this.cffbPollPoints[i];
                    }
                    this.points.polls[cid] = sum;
                    return sum;
                }
                if (cid === 3) {
                    for (i = 0; i < 25; i++) {
                        if (this.espnPollPoints[i] > 0)
                            sum += this.espnPollPoints[i];
                    }
                    this.points.polls[cid] = sum;
                    return sum;
                }
                if (cid === 4) {
                    for (i = 0; i < 25; i++) {
                        if (this.cbsPollPoints[i] > 0)
                            sum += this.cbsPollPoints[i];
                    }
                    this.points.polls[cid] = sum;
                    return sum;
                }
                if (cid === 5) {
                    for (i = 0; i < 25; i++) {
                        if (this.selectionCommitteePoints[i] > 0)
                            sum += this.selectionCommitteePoints[i];
                    }
                    this.points.polls[cid] = sum;
                    return sum;
                }
                return 0;
            },

            matchBalance(cid) {
                // const count = this.points.count[cid];
                const deposit = DEPOSIT_LEVEL_NORMAL;
                // this.balancePerDeposit(deposit, cid)
                return this.balancePerDeposit(deposit, cid);
            },

            balancePerDeposit(deposit, cid) {
                let i;
                let sum = 0;
                if (cid === 0) {
                    for (i = 0; i < 25; i++) {
                        if (this.apPollPoints[i] > 0.5) {
                            sum += 1;
                        }
                    }
                    this.balance.poll[cid] = this.calculateBalance(sum, deposit); 
                    return this.balance.poll[cid];
                }
                else if (cid === 1) {
                    for (i = 0; i < 25; i++) {
                        if (this.coachesPollPoints[i] > 0.5) {
                            sum += 1;
                        }
                    }
                    this.balance.poll[cid] = this.calculateBalance(sum, deposit); 
                    return this.balance.poll[cid];
                } 
                else if (cid === 2) {
                    for (i = 0; i < 25; i++) {
                        if (this.cffbPollPoints[i] > 0.5) {
                            sum += 1;
                        }
                    }
                    this.balance.poll[cid] = this.calculateBalance(sum, deposit); 
                    return this.balance.poll[cid];
                }
                else if (cid === 3) {
                    for (i = 0; i < 25; i++) {
                        if (this.espnPollPoints[i] > 0.5) {
                            sum += 1;
                        }
                    }
                    this.balance.poll[cid] = this.calculateBalance(sum, deposit); 
                    return this.balance.poll[cid];
                }
                else if (cid === 4) {
                    for (i = 0; i < 25; i++) {
                        if (this.cbsPollPoints[i] > 0.5) {
                            sum += 1;
                        }
                    }
                    this.balance.poll[cid] = this.calculateBalance(sum, deposit); 
                    return this.balance.poll[cid];
                }
                else if (cid === 5) {
                    for (i = 0; i < 25; i++) {
                        if (this.selectionCommitteePoints[i] > 0.5) {
                            sum += 1;
                        }
                    }
                    this.balance.poll[cid] = this.calculateBalance(sum, deposit);
                    return this.balance.poll[cid];
                }
                return 0
            },

            calculateBalance(sum, deposit) {
                if (deposit == DEPOSIT_LEVEL_LOW) {
                    if (sum >= BEGINNER_LEVEL_LOWER_SCORE && sum <= BEGINNER_LEVEL_HIGHER_SCORE) {
                        return BEGINNER_LEVEL_PAYMENT[0];
                    }
                    else if (sum >= AMATEUR_LEVEL_LOWER_SCORE && sum <= AMATEUR_LEVEL_HIGHER_SCORE) {
                        return AMATEUR_LEVEL_PAYMENT[0]; 
                    }
                    else if (sum >= PROFESSIONAL_LEVEL_LOWER_SCORE && sum <= PROFESSIONAL_LEVEL_HIGHER_SCORE) {
                        return PROFESSIONAL_LEVEL_PAYMENT[0];
                    }
                    else if (sum >= TOPSTAR_LEVEL_LOWER_SCORE && sum <= TOPSTAR_LEVEL_HIGHER_SCORE) {
                        return TOPSTAR_LEVEL_PAYMENT[0];
                    }
                    else if (sum >= SUPERSTAR_LEVEL_LOWER_SCORE && sum <= SUPERSTAR_LEVEL_HIGHER_SCORE) {
                        return SUPERSTAR_LEVEL_PAYMENT[0];
                    }
                }
                else if (deposit == DEPOSIT_LEVEL_NORMAL) {
                    if (sum >= BEGINNER_LEVEL_LOWER_SCORE && sum <= BEGINNER_LEVEL_HIGHER_SCORE) {
                        return BEGINNER_LEVEL_PAYMENT[1];
                    }
                    else if (sum >= AMATEUR_LEVEL_LOWER_SCORE && sum <= AMATEUR_LEVEL_HIGHER_SCORE) {
                        return AMATEUR_LEVEL_PAYMENT[1]; 
                    }
                    else if (sum >= PROFESSIONAL_LEVEL_LOWER_SCORE && sum <= PROFESSIONAL_LEVEL_HIGHER_SCORE) {
                        return PROFESSIONAL_LEVEL_PAYMENT[1];
                    }
                    else if (sum >= TOPSTAR_LEVEL_LOWER_SCORE && sum <= TOPSTAR_LEVEL_HIGHER_SCORE) {
                        return TOPSTAR_LEVEL_PAYMENT[1];
                    }
                    else if (sum >= SUPERSTAR_LEVEL_LOWER_SCORE && sum <= SUPERSTAR_LEVEL_HIGHER_SCORE) {
                        return SUPERSTAR_LEVEL_PAYMENT[1];
                    }
                }
                else if (deposit == DEPOSIT_LEVEL_HIGH) {
                    if (sum >= BEGINNER_LEVEL_LOWER_SCORE && sum <= BEGINNER_LEVEL_HIGHER_SCORE) {
                        return BEGINNER_LEVEL_PAYMENT[2];
                    }
                    else if (sum >= AMATEUR_LEVEL_LOWER_SCORE && sum <= AMATEUR_LEVEL_HIGHER_SCORE) {
                        return AMATEUR_LEVEL_PAYMENT[2]; 
                    }
                    else if (sum >= PROFESSIONAL_LEVEL_LOWER_SCORE && sum <= PROFESSIONAL_LEVEL_HIGHER_SCORE) {
                        return PROFESSIONAL_LEVEL_PAYMENT[2];
                    }
                    else if (sum >= TOPSTAR_LEVEL_LOWER_SCORE && sum <= TOPSTAR_LEVEL_HIGHER_SCORE) {
                        return TOPSTAR_LEVEL_PAYMENT[2];
                    }
                    else if (sum >= SUPERSTAR_LEVEL_LOWER_SCORE && sum <= SUPERSTAR_LEVEL_HIGHER_SCORE) {
                        return SUPERSTAR_LEVEL_PAYMENT[2];
                    }
                }
                return 0;
            },

            matchTrending(cid) {
              let i;
              let sum = 0;
              if (cid === 0) {
                  for (i = 0; i < 25; i++) {
                      if (this.apPollPoints[i] > 0.5) {
                          sum += 4;
                      }
                  }
                  this.points.count[cid] = sum;
                  return sum;
              }
              if (cid === 1) {
                  for (i = 0; i < 25; i++) {
                      if (this.coachesPollPoints[i] > 0.5)
                          sum += 4;
                  }
                  this.points.count[cid] = sum;
                  return sum;
              }
              if (cid === 2) {
                  for (i = 0; i < 25; i++) {
                      if (this.cffbPollPoints[i] > 0.5)
                          sum += 4;
                  }
                  this.points.count[cid] = sum;
                  return sum;
              }
              if (cid === 3) {
                  for (i = 0; i < 25; i++) {
                      if (this.espnPollPoints[i] > 0.5)
                          sum += 4;
                  }
                  this.points.count[cid] = sum;
                  return sum;
              }
              if (cid === 4) {
                  for (i = 0; i < 25; i++) {
                      if (this.cbsPollPoints[i] > 0.5)
                          sum += 4;
                  }
                  this.points.count[cid] = sum;
                  return sum;
              }
                if (cid === 5) {
                    for (i = 0; i < 25; i++) {
                        if (this.selectionCommitteePoints[i] > 0.5)
                            sum += 4;
                    }
                    this.points.count[cid] = sum;
                    return sum;
                }
              return 0;
            },

            getTotalPoints() {
                let sum = 0;
                for (let i = 0; i < 6; i ++) {
                    sum += this.points.polls[i];
                }
                this.totalPoints = sum;
                return sum;
            },

            getTotalBalance() {
                let sum = 0;
                for (let i = 0; i < 6; i ++) {
                    sum += this.balance.poll[i];
                }
                this.accountBalance = sum;
                return sum;
            },

            handleTopConferenceDrop(index, data, event) {
                this.topConferences.splice(index, 1, data.team);
            },

            getTooltip(type) {
                if (type === 'AP Poll') {
                    return MESSAGES.AP_POLL;
                } else if (type === 'Coaches Poll') {
                    return MESSAGES.COACHES_POLL;
                } else if (type === 'CFFB Poll') {
                    return MESSAGES.CFFB_POLL;
                } else if (type === 'ESPN Power Index Poll') {
                    return MESSAGES.ESPN_FOOTBALL_POWER_INDEX_POLL;
                } else if (type === 'CBSSports.com Poll') {
                    return MESSAGES.CBSSPORTS_POLL;
                } else if (type === 'Selection Committee') {
                    return MESSAGES.SELECTION_COMMITTEE_POLL;
                }
            }
        },
    }
</script>

<style lang="scss" scoped="scoped">
    .regular-season {
        font-family: ArvoRegular;

        .match-description {
            width: 320px;

            .header, .value {
                width: 150px;
            }

            .init-deposit-table{
                border: 1px solid black;
                tr th{
                    background: #c5cae9;
                    padding: 0px 10px;
                    border: 1px solid black;
                }

                tr td{
                    background: #ffffff;
                    padding: 0px 10px;
                    border: 1px solid black;
                }
            }

            .match-deposit-table{
                border: 1px solid black;

                tr th{
                    background: #c5cae9;
                    border: 1px solid black;
                    padding: 0px 10px;

                }
                tr td{
                    background: #ffffff;
                    padding: 0px 10px;
                    border: 1px solid black;
                    height: 20px;
                }

            }
        }

        .match-contents {
            .score-area {
                .shut-outs {
                    width: 280px;
                    .team {
                        width: 120px;
                        height: 30px;
                        padding: 0.25rem;
                    }
                    .conference-rank-table {
                        border: 1px solid black;
                        font-size: 13px;
                        width: 250px;

                        tr th, td{
                            border: 1px solid black;
                            text-align: center;
                            padding: 0.25rem;
                            vertical-align: middle;
                        }

                        .rank-col {
                            width: 40px;
                        }
                        .team-col {
                            width: 150px;

                            .team-cell {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                .points{
                    margin-top: 30px;
                    margin-left: 0px;
                    font-size: 16px;
                }

                .score-payments{
                    margin-top: 30px;
                    margin-left: 30px;
                    font-size: 14px;

                    .poll-score-wrapper {
                        width: 200px;

                        .point{
                            width: 30px;
                        }

                        .total-price {
                            width: 31px;
                            border: 1px solid black;
                        }

                        .balance-price {
                            width: 30px;
                            border: 1px solid black;

                            &.low, &.middle {
                                border-right-width: 0px;
                            }
                        }
                    }

                    .total-points-wrapper{
                        margin-right: 20px;

                        .pick-six {
                            width: 60px;
                        }

                        .state-match {
                            width: 100px;
                        }

                        .total-score-box{
                            border: 1px solid black;
                            width: 50px;
                        }

                        .btn-submit{
                            width: 120px;
                        }

                    }

                    .total-text {
                        margin-top: -5px;
                    }
                }
            }
        }

        .match-point-text {
            width: 50px;
        }

        .prediction-polls-wrapper {
            overflow: auto;
        }

        .prediction-polls {
            width: fit-content;
        }

        .page-wrapper {
            overflow: hidden;
        }
    }
</style>
