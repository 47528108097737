<template>
  <v-app>
    <v-content>
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
export default {
  name: 'App',
  computed: {
      ...mapState({
          users: state => state.users,
      }),
  },
  created() {
    this.getAllTeams();
    this.getAllConferences();
  },
  mounted() {
    // Check Authentification.
    const currentPath = this.$router.currentRoute.path;
    const token = localStorage.getItem('token');
    if (token && token.length > 0) {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.users.currentUser = currentUser;

      if (currentPath === "/") {
        this.$router.push({ path: 'home' });
      }
    } else {
      if (currentPath !== "/") {
        this.$router.push({ path: '/' });
      }
    }
  },
  methods: {
    ...mapActions([
      'getAllTeams',
      'getAllConferences',
      'getRegularPolls',
    ]),
  }
};
</script>
