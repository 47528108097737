import moment from "moment";
export function currentYear() {
    // return moment().year();
    return '2019';
}

export function currentWeek() {
    // return moment().week();
    return '1';
}

export function getStartDate(calendar, index) {

  if (calendar.length > 1) {
    var startDate = calendar[index].firstGameStart;
    return startDate;
  }
  return null;
}

export function isGameStarted(startDate) {
  // var today = new Date();
  // var gameStartDay = new Date(startDate);
  //
  // if (today > gameStartDay) {
  //   return true;
  // }
  return false;
}

export function calcGameScore(myVictory, winLossObj) {
  if (myVictory) {
      if (!winLossObj.win) {
        winLossObj.win = 1;
        winLossObj.loss = 0;
      } else {
        winLossObj.win++;
      }
  }
  else
  {
      if (!winLossObj.loss) {
        winLossObj.loss = 1;
        winLossObj.win = 0;
      } else {
        winLossObj.loss++;
      }
  }
  return winLossObj;
}

export function isFullTeams(teams) {
    // console.log(teams);

    if (teams.length) {       
        let noFullTeam=false;

        for (let team of teams){
            if (typeof(team) == "undefined") {
                noFullTeam=true;                
            }
            else if(!('school' in team)) {
                noFullTeam=true;                
            }
        }        
        return !noFullTeam;
    }
    return false;
}

export function getMatchState(matchResult, teams) {
    if (Array.isArray(teams)) {
        let matchStates;
        const opposingTeams = [];
        const wins = [];
        for (let i = 0; i < teams.length; i++ ) {
            if (teams[i].school) {
                let opposingTeam;
                let win;
                for (let j = 0; j < matchResult.length; j++ ) {
                    if (teams[i].school === matchResult[j].teams[0].school) {
                        opposingTeam = matchResult[j].teams[1];
                        win = matchResult[j].teams[0].points > opposingTeam.points;
                        opposingTeams.push(opposingTeam);
                        wins.push(win);
                        break;
                    } else if (teams[i].school === matchResult[j].teams[1].school) {
                        opposingTeam = matchResult[j].teams[0];
                        win = matchResult[j].teams[1].points > opposingTeam.points;
                        opposingTeams.push(opposingTeam);
                        wins.push(win);
                        break;
                    }
                }
            } else {
                opposingTeams.push({});
                wins.push(false);
            }
        }
        matchStates = {
            opposing_team: opposingTeams,
            win_state: wins
        };
        return matchStates;
    } else {
        let matchState;
        let opposingTeam;
        let win;
        for (let j = 0; j < matchResult.length; j++ ) {
            if (teams.school === matchResult[j].teams[0].school) {
                opposingTeam = matchResult[j].teams[1];
                win = matchResult[j].teams[0].points > opposingTeam.points;
                break;
            } else if (teams.school === matchResult[j].teams[1].school) {
                opposingTeam = matchResult[j].teams[0];
                win = matchResult[j].teams[1].points > opposingTeam.points;
                break;
            }
        }
        matchState = {
            opposing_team: opposingTeam,
            win_state: win
        };

        return matchState;
    }
}

export function getPredictionResult(allTeams, data) {

    var matchScore;
    const correctSelectedPoint = [0, 0, 0];
    const correctSelectedTeams = [];
    const correctWinnerTeams = [];
    const playoffTeams = [];
    const runnerTeams = [];

    const correctTeamsId = [[], [], [], [], [], []];

    var championshipTeam = [];
    var playoffPoint = 0;
    var runnerPoint = 0;
    var championshipPoint = 0;
    var totalPoint = 0;
    var teamIndex = -1;

    if (Array.isArray(data.group_teams)) {
      for (let i = 0; i < 10; i++) {

        teamIndex = randomPickFromAllTeams(allTeams[i].division1.teams);
        let firstWinner = allTeams[i].division1.teams[teamIndex]._id;
        correctTeamsId[0].push(firstWinner);

        if (teamIndex > -1 && allTeams[i].division1.teams[teamIndex]._id === data.group_teams[i].team1) {
          correctSelectedPoint[0] += 25;
        } 

        teamIndex = randomPickFromAllTeams(allTeams[i].division2.teams);
        let secondWinner = allTeams[i].division2.teams[teamIndex]._id;
        correctTeamsId[1].push(secondWinner);

        if (teamIndex > -1 && allTeams[i].division2.teams[teamIndex]._id === data.group_teams[i].team2) {
          correctSelectedPoint[1] += 25;
        } 

        let winnerTeams = [];
        winnerTeams.push(firstWinner);
        winnerTeams.push(secondWinner);
        correctSelectedTeams.push(winnerTeams);

        teamIndex = randomPickFromAllTeams(winnerTeams);
        correctTeamsId[2].push(winnerTeams[teamIndex]);

        if (teamIndex > -1 && winnerTeams[teamIndex] === data.group_teams[i].winner) {
          correctSelectedPoint[2] += 50;
        }
      }
    }

    // 4 play off teams.
    correctTeamsId[3].push(correctTeamsId[2][0]);
    correctTeamsId[3].push(correctTeamsId[2][3]);
    correctTeamsId[3].push(correctTeamsId[2][7]);
    correctTeamsId[3].push(correctTeamsId[2][9]);
    
    if (Array.isArray(data.play_off_teams)) {
      for (let i = 0; i < 4; i++) {
        if (data.play_off_teams[i].winner) {
          let index = correctTeamsId[3].indexOf(data.play_off_teams[i].winner);
          if (index > -1) {
            playoffPoint += 50;
          }
        }
      }
    }

    // 2 teams for Championship Game Prediction
    correctTeamsId[4].push(correctTeamsId[3][0]);
    correctTeamsId[4].push(correctTeamsId[3][2]);
    if (Array.isArray(data.runner_teams)) {
      for (let i = 0; i < 2; i++) {
        if (data.runner_teams[i].winner) {
          let index = correctTeamsId[4].indexOf(data.runner_teams[i].winner);
          if (index > -1) {
            runnerPoint += 50;
          }
        }
      }
    }

    // championship
    correctTeamsId[5].push(correctTeamsId[4][1]);

    if (correctTeamsId[5][0] === data.champion_team.winner) {
      championshipPoint += 200;
    }
    // total point
    totalPoint = correctSelectedPoint[0] + correctSelectedPoint[1] + correctSelectedPoint[2] + playoffPoint + runnerPoint + championshipPoint;
    console.log("totalPoint ====>", totalPoint);

    matchScore = {
      div1Point: correctSelectedPoint[0],
      div2Point: correctSelectedPoint[1],
      winnerPoint: correctSelectedPoint[2],
      playoffPoint,
      runnerPoint,
      championshipPoint,
      totalPoint
    };

    let result = {
      matchScore,
      correctPos: correctTeamsId,
    }

    return result;
}

function randomPickFromAllTeams(teams) {
  let index = -1;
  if (Array.isArray(teams)) {
    index = Math.floor(Math.random() * teams.length);
  }
  return index;
}

function randomPickFromWinner(teams) {
  let index = -1;
  let tmpIndex = Math.floor(Math.random() * 10);
  // Object.keys[teams].map((item, i) => {

  // })
}

// ACC, Big 10, Big 12, CUSA, MAC, MWC, PAC 12, SEC, Sun Belt
export function getConferenceNickName(conferenceName) {
    switch (conferenceName) {
        case 'ACC':
            return conferenceName;
        case 'Big 12':
            return conferenceName;
        case 'SEC':
            return conferenceName;
        case 'Sun Belt':
            return 'SBC';
        case 'American Athletic':
            return 'AAC';
        case 'Big Ten':
            return 'Big 10';
        case 'Pac-12':
            return 'PAC-12';
        case 'Conference USA':
            return 'C-USA';
        case 'Mid-American':
            return 'MAC';
        case 'Mountain West':
            return 'MWC';
        case 'FBS Independents':
            return 'FBS Independents';
        default:
            return '';
    }
}