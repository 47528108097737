<template>
    <div class="page regular-season game-new-cffb-poll">
        <Navbar :user="users.currentUser" />
        <div>
            <Header
                title="Game 2"
                sub-title="New CFFB Poll"
                :is-left=false
                @back_click="onBack"
                @next_click="onNext"
            />
            <div class="game-type-page bgc-poll">
                <div class="polls d-flex">
                    <OwnTopTeamsBoard
                        :teams="teams.myTop25"
                        @update-rank="updateRank"
                        :is-selected = "this.selectedPoll == 0"
                        @title-click="changeSelectPoll(0)"
                    />
                    <Gm2LeftTeams
                      :teams="teams.myTop25"
                      :checks="checks"
                      @update-winner="updateWinner"
                      @reset="resetLeft"
                    />
                    <div class="wrapper">
                        <div class="title-box">Projected Winners</div>
                        <div class="winners-wrapper">
                            <div class="winners">
                                <TeamCell
                                  :item="projectedWinners[0]"
                                  :index="getIndex(projectedWinners[0])"
                                  :color="'blue'"
                                />
                            </div>
                            <div class="winners">
                                <TeamCell
                                  :item="projectedWinners[1]"
                                  :index="getIndex(projectedWinners[1])"
                                  :color="'blue'"
                                />
                            </div>
                            <div class="winners">
                                <TeamCell
                                  :item="projectedWinners[2]"
                                  :index="getIndex(projectedWinners[2])"
                                  :color="'blue'"
                                />
                            </div>
                            <div class="winners">
                                <TeamCell
                                  :item="projectedWinners[3]"
                                  :index="getIndex(projectedWinners[3])"
                                  :color="'blue'"
                                />
                            </div>
                            <div class="winners">
                                <TeamCell
                                  :item="projectedWinners[4]"
                                  :index="getIndex(projectedWinners[4])"
                                  :color="'blue'"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="wrapper">
                        <div class="title-box">Projected Winners</div>
                        <div class="winners-wrapper">
                            <div class="winners">
                                <TeamCell
                                  :item="projectedWinners[5]"
                                  :index="getIndex(projectedWinners[5])"
                                  :color="'blue'"
                                />
                            </div>
                            <div class="winners">
                                <TeamCell
                                  :item="projectedWinners[6]"
                                  :index="getIndex(projectedWinners[6])"
                                  :color="'blue'"
                                />
                            </div>
                            <div class="winners">
                                <TeamCell
                                  :item="projectedWinners[7]"
                                  :index="getIndex(projectedWinners[7])"
                                  :color="'blue'"
                                />
                            </div>
                            <div class="winners">
                                <TeamCell
                                  :item="projectedWinners[8]"
                                  :index="getIndex(projectedWinners[8])"
                                  :color="'blue'"
                                />
                            </div>
                            <div class="winners">
                                <TeamCell
                                  :item="projectedWinners[9]"
                                  :index="getIndex(projectedWinners[9])"
                                  :color="'blue'"
                                />
                            </div>
                        </div>
                    </div>
                    <Gm2RightTeams
                      :teams="teams.myTop25"
                      :checks="checks"
                      @update-winner="updateWinner"
                      @reset="resetRight"
                    />
                    <RankList
                            title="New CFFB Top 25"
                            :is-selected="selectedPoll == 1"
                            :teams="myTop25"
                            :loss-teams="lossTeams"
                            :win-teams="winTeams"
                            :game-type="'game2'"
                            :show-exclaim="false"
                            @update-selected-poll="changeSelectPoll(1)"
                    />
                </div>
                <!-- Instruction -->
                <div class="description">
                    <InstructionBox type="game2NewCFFBPoll" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import Navbar from "../../components/Navbar";
    import InstructionBox from "../../components/Game/InstructionBox";
    import Header from "../../components/Header";
    import OwnTopTeamsBoard from "../../components/Game/OwnTopTeamsBoard";
    import Gm2LeftTeams from "../../components/Game/Gm2LeftTeams";
    import Gm2RightTeams from "../../components/Game/Gm2RightTeams";
    import TeamCell from "../../components/Game/TeamCell";
    import RankList from "../../components/Game/RankList";
    import {currentYear} from "../../utils/functions";

    export default {
        name: 'Gm2NewCFFBPoll',
        order: 4,
        components: {
            RankList,
            TeamCell,
            OwnTopTeamsBoard,
            Navbar,
            InstructionBox,
            Header,
            Gm2LeftTeams,
            Gm2RightTeams,
        },
        computed: {
            ...mapState({
                users: state => state.users,
                teams: state => state.teams,
                game_type: state => state.game_type
            }),
        },
        data: () => ({
            myTop25: [],
            checks: [],
            projectedWinners: [],
            selectedPoll: '0',
            lossTeams: [],
            winTeams: []
        }),
        mounted() {

            for (let i = 0; i < 20; i++) {
                this.checks.push('');
            }

            if (this.game_type &&
                this.game_type.data &&
                this.game_type.data.challenge &&
                this.game_type.data.challenge.length) {

                // // restore check winner teams
                // for (let i = 0; i < 20; i++) {
                //     let value = '';
                //     if (this.game_type.data.challenge[i] === 'true') {
                //         value = true;
                //     } else if (this.game_type.data.challenge[i] === 'false') {
                //         value = false;
                //     }
                //     this.checks.splice(i, 1, value);
                // }
                this.rebuildNewCFFBPoll();
            }

            for (let i = 0; i < this.teams.myTop25.length; i++) {
                this.myTop25.push(this.teams.myTop25[i]);
            }

            // init projected winners
            for (let i = 0; i < 10; i++) {
                this.projectedWinners.push({});
            }

            // // restore projected winners
            // if (this.teams.game2ProjectedWinners.length > 0) {
            //     this.projectedWinners = this.teams.game2ProjectedWinners;
            // } else {
            //     for (let i = 0; i < 10; i++) {
            //         this.projectedWinners.push({});
            //     }
            // }

            this.rebuildNewCFFBPoll();
        },
        methods: {
            ...mapActions([
                'setGameType',
            ]),
            oppositeIndex(index) {
                return 19 - parseInt(index);
            },
            rebuildNewCFFBPoll() {
                const opposingTeamIndexes = [];
                const winTeamIndexes = [];
                const unConfirmedIndexes = [];
                this.lossTeams = [];
                this.winTeams = [];

                this.myTop25 = [];
                for (let i = 0; i < 25; i++) {
                    this.myTop25.push({});
                }
                for (let i = 0; i < 20; i++) {
                    if (this.checks[i] === false) {
                        opposingTeamIndexes.push({pos: i + 4, source: i});
                    } else if (this.checks[i] === true ) {
                        winTeamIndexes.push({pos: i - 4, source: i});
                    } else {
                        unConfirmedIndexes.push({pos: i, source: i});
                    }
                }
                const constantIndexes = [];
                for (let i = 20; i < 25; i++) {
                    constantIndexes.push({pos: i, source: i});
                }
                let indexes = [...opposingTeamIndexes, ...winTeamIndexes, ...unConfirmedIndexes, ...constantIndexes];
                indexes = indexes.sort((a, b) => a.pos < b.pos ? -1 : 1);
                for (let i = 0; i < indexes.length; i++) {
                    this.myTop25[i] = this.teams.myTop25[indexes[i].source];
                }
                this.myTop25 = [...this.myTop25];
                this.lossTeams = [];
                this.winTeams = [];

                for (let i = 0; i < opposingTeamIndexes.length; i++) {
                    const pos = indexes.findIndex((item) => item.source === opposingTeamIndexes[i].source);
                    this.lossTeams.push(pos);
                }
                for (let i = 0; i < winTeamIndexes.length; i++) {
                    const pos = indexes.findIndex((item) => item.source === winTeamIndexes[i].source);
                    this.winTeams.push(pos);
                }
            },
            updateWinner(index) {
                const oppositeIndex = this.oppositeIndex(index);
                const checked = this.checks[index] ? true : false;
                this.checks.splice(index, 1, !checked);
                this.checks.splice(oppositeIndex, 1, checked);
                let projectedIndex;
                if (index === 0 || index === 19) {
                    projectedIndex = 0;
                } else if (index === 4 || index === 15) {
                    projectedIndex = 1;
                } else if (index === 8 || index === 11) {
                    projectedIndex = 2;
                } else if (index === 12 || index === 7) {
                    projectedIndex = 3;
                } else if (index === 16 || index === 3) {
                    projectedIndex = 4;
                } else if (index === 2 || index === 17) {
                    projectedIndex = 5;
                } else if (index === 13 || index === 6) {
                    projectedIndex = 6;
                } else if (index === 9 || index === 10) {
                    projectedIndex = 7;
                } else if (index === 14 || index === 5) {
                    projectedIndex = 8;
                } else if (index === 18 || index === 1) {
                    projectedIndex = 9;
                }
                if (checked) {
                    this.projectedWinners.splice(projectedIndex, 1, this.teams.myTop25[oppositeIndex]);
                } else {
                    this.projectedWinners.splice(projectedIndex, 1, this.teams.myTop25[index]);
                }
                this.rebuildNewCFFBPoll();
            },
            updateRank() {
                this.rebuildNewCFFBPoll();
            },
            getIndex(team) {
                if (team) {
                    return this.teams.myTop25.findIndex((item) => item.school === team.school);
                }
                return null;
            },
            changeSelectPoll(index) {
                this.selectedPoll = index;
            },
            onNext() {
                const own_team = [];
                if (this.selectedPoll == 1) {
                    this.teams.myTop25 = [ ...this.myTop25 ];
                }
                this.teams.myTop25.forEach((team) => {
                    const index = this.teams.all.findIndex(item => item.school === team.school);
                    if (index >= 0) {
                        const id = this.teams.all[index]._id;
                        own_team.push(id);
                    }
                });

                this.teams.game2ProjectedWinners = this.projectedWinners;
                this.teams.game2Checks = this.checks;

                const data = {
                    user: this.users.currentUser._id,
                    year: currentYear(),
                    own_team: own_team,
                    type: 'Game2',
                    challenge: this.checks,
                    selected_poll: this.selectedPoll
                };

                this.setGameType(data);
                this.$router.push({name: 'RegularSeasonWk1Schedule'});
                // this.teams.revisedPoll = this.myTop25;
                // this.$router.push({name: 'RegularSeasonWkMatchScoring'});
            },

            onBack() {
                this.$router.push({name: 'Game2'});
            },

            resetLeft() {
                this.checks.splice(0, 1, '');
                this.checks.splice(19, 1, '');
                this.checks.splice(4, 1, '');
                this.checks.splice(15, 1, '');
                this.checks.splice(8, 1, '');
                this.checks.splice(11, 1, '');
                this.checks.splice(12, 1, '');
                this.checks.splice(7, 1, '');
                this.checks.splice(16, 1, '');
                this.checks.splice(3, 1, '');
                for (let i = 0; i < 5; i++) {
                    this.projectedWinners.splice(i, 1, {});
                }
                this.rebuildNewCFFBPoll();
            },

            resetRight() {
                this.checks.splice(2, 1, '');
                this.checks.splice(17, 1, '');
                this.checks.splice(13, 1, '');
                this.checks.splice(6, 1, '');
                this.checks.splice(9, 1, '');
                this.checks.splice(10, 1, '');
                this.checks.splice(14, 1, '');
                this.checks.splice(5, 1, '');
                this.checks.splice(18, 1, '');
                this.checks.splice(1, 1, '');
                for (let i = 5; i < 10; i++) {
                    this.projectedWinners.splice(i, 1, {});
                }
                this.rebuildNewCFFBPoll();
            }
        },
    };
</script>
<style lang="scss" scope>
    .winners-wrapper {
        margin-top: 70px;

        .winners {
            margin-bottom: 80px;
        }
    }
</style>
