<template>
    <div class="game2-lef-teams ">
        <div class="wrapper">
            <div class="position-relative">
                <div class="title-box">Teams</div>
                <RoundButton class="reset-button btn sm" title="Reset" @click.native="onReset"/>
            </div>
            <div class="mt-40 mb-30">
                <CheckTeamCell
                        :firstIndex="0"
                        :secondIndex="19"
                        :teams="teams"
                        :checks="checks"
                        :check-type="'left'"
                        @set-winner="setWinner"
                />
            </div>
            <div class="mb-30">
                <CheckTeamCell
                        :firstIndex="4"
                        :secondIndex="15"
                        :teams="teams"
                        :checks="checks"
                        :check-type="'left'"
                        @set-winner="setWinner"
                />
            </div>
            <div class="mb-30">
                <div class="mb-3">
                    <CheckTeamCell
                            :firstIndex="8"
                            :secondIndex="11"
                            :teams="teams"
                            :checks="checks"
                            :check-type="'left'"
                            @set-winner="setWinner"
                    />
                </div>
            </div>
            <div class="mb-30">
                <CheckTeamCell
                    :firstIndex="12"
                    :secondIndex="7"
                    :teams="teams"
                    :checks="checks"
                    :check-type="'left'"
                    @set-winner="setWinner"
                />
            </div>
            <div class="mb-30">
                <CheckTeamCell
                    :firstIndex="16"
                    :secondIndex="3"
                    :teams="teams"
                    :checks="checks"
                    :check-type="'left'"
                    @set-winner="setWinner"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import CheckTeamCell from "./CheckTeamCell";
    import RoundButton from "../RoundButton";
    export default {
        name: "Gm2LeftTeams",
        components: {
            CheckTeamCell,
            RoundButton
        },
        props: {
            teams: { type: Array },
            checks: { type: Array }
        },
        data: () => ({
            keyword: ''
        }),
        methods : {
            setWinner(index) {
                this.$emit("update-winner", index);
            },
            onReset() {
                this.$emit("reset");
            }
        },
        mounted() {

        }
    }
</script>

<style lang="scss" scoped>
    .reset-button {
        position: absolute;
        top: 3px;
        right: 10px;
    }
</style>
