<template>
    <div class="page-header mt-5">
        <RoundButton class="btnLeft btn-standard" :title="leftButtonText" size="small" v-if="isLeft" @click.native="onLeft()"/>
        <div class="text-center mt-3">
            <p class="f-24 font-weight-bold text-uppercase">{{title}}</p>
            <p class="f-8 font-weight-bold opacity-6 text-uppercase">{{subTitle}}</p>
        </div>
         <RoundButton class="btnBack btn-standard" title="Back" size="small" v-if="isBack" @click.native="onBack()"/>
        <RoundButton class="btnNext btn-standard" title="Next" size="small" v-if="isNext" @click.native="onNext()"/>
    </div>
</template>

<script>
    import RoundButton from "./RoundButton";
    export default {
        name: "Header",
        props: {
            title: { type: String },
            subTitle: { type: String },
            isBack: { type: Boolean, default: false },
            isNext: { type: Boolean, default: true },
            isLeft: { type: Boolean, default: false },
            leftButtonText: { type: String }
        },
        components: {
            RoundButton
        },
        methods: {
            onLeft(evt) {
                this.$emit("left_click", evt);
            },
            onBack(evt) {
                this.$emit("back_click", evt);
            },
            onNext(evt) {
                this.$emit("next_click", evt);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-header {
        position: relative;

        .btnLeft {
            position: absolute;
            top: 15px;
            left: 40px;
        }

        .btnNext {
            position: absolute;
            top: 15px;
            right: 40px;
        }

        .btnBack{
            position: absolute;
            top: 15px;
            right: 160px;
        }
    }
</style>
