<template>
    <div class="page regular-season">
        <Navbar :user="users.currentUser" />
        <PredictionHeader
            title="Championship Predictions"
            sub-title="WEEK 3"
            @reset_click="onReset"
            @save_click="onSave"
            @back_click="onBack"
            @next_click="onNext"
        />
        <div class="page-wrapper m-4">
            <div class="championship v-start border-1 border-radius-5 text-center m-3 p-2">
                <div class="border-1 w-100">
                    <div class="v-center">
                        <div class="group-title f-6 font-weight-bold text-center p-2">
                            Conference<br/>
                            ChampionShip
                        </div>
                        <div class="p-2 ml-5 text-center">
                            <b>Select the two teams you believe will play for their conference championship, then select the team you believe will win.</b>
                            <br>Players will be awarded 25 points for each correct team that makes the conference championship and 50 points for the team you believe will become the Conference Champion.
                            <b>1100 Points max</b>
                        </div>
                    </div>
                    <div class="champion-select-wrapper v-center justify-content-around p-3">
                        <div class="teams" v-for="(conf, index) in conferenceList" :key="'conference_list_' + index">
                            <table class="table team-select-table f-3">
                                <tr>
                                    <td class="champion-table-header">
                                        {{conf.name}}<br>Championship
                                    </td>
                                </tr>
                                <tbody>
                                <tr>
                                    <td class="bgc-white">
                                        <div class="v-center px-2">
                                            <div class="division-name">{{conf.division1.name}}:</div>
                                            <div class="team-select-wrapper ml-2">
                                                <v-select :items="conf.division1.teams"
                                                  label="Standard"
                                                  v-model="selectedFirstTeams[index]"
                                                  item-disabled="disable"
                                                  item-value="school"
                                                  single-line
                                                >
                                                    <template v-slot:selection="{ item }">
                                                        <div class="v-center">
                                                            <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                                            <div class="team-name-text">{{ item.school }}</div>
                                                        </div>
                                                    </template>
                                                    <template v-slot:item="{ item }">
                                                        <div class="v-center">
                                                            <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                                            <div class="team-name-text">{{ item.school }}</div>
                                                        </div>
                                                    </template>
                                                </v-select>
<!--                                                <select class="border-radius-5 border-1"-->
<!--                                                  :class="correctTeam(0, index, 'div1')" -->
<!--                                                  v-model="selectedFirstTeams[index]" -->
<!--                                                >-->
<!--                                                    <option disabled value="">Div Winner</option>-->
<!--                                                    <option v-for="team in conf.division1.teams" :key="team._id">{{team.school}}</option>-->
<!--                                                </select>-->
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bgc-white">
                                        <div class="v-center px-2">
                                            <div class="division-name">{{conf.division2.name || 'West'}}:</div>
                                            <div class="team-select-wrapper ml-2">
                                                <v-select :items="conf.division2.teams"
                                                          label="Standard"
                                                          v-model="selectedSecondTeams[index]"
                                                          item-disabled="disable"
                                                          item-value="school"
                                                          single-line
                                                >
                                                    <template v-slot:selection="{ item }">
                                                        <div class="v-center">
                                                            <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                                            <div class="team-name-text">{{ item.school }}</div>
                                                        </div>
                                                    </template>
                                                    <template v-slot:item="{ item }">
                                                        <div class="v-center">
                                                            <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                                            <div class="team-name-text">{{ item.school }}</div>
                                                        </div>
                                                    </template>
                                                </v-select>
<!--                                                <select class="border-radius-5 border-1" :class="correctTeam(1, index, 'div2')" v-model="selectedSecondTeams[index]" >-->
<!--                                                    <option disabled value="">Div Winner</option>-->
<!--                                                    <option v-for="team in conf.division2.teams" :key="team._id">{{team.school}}</option>-->
<!--                                                </select>-->
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="champion-table-header">Champion</td>
                                </tr>
                                <tr>
                                    <td class="px-2">
                                        <v-select :items="championshipInConference(index)"
                                          label="Standard"
                                          v-model="selectedWinTeams[index]"
                                          item-disabled="disable"
                                          item-value="school"
                                          single-line
                                        >
                                            <template v-slot:selection="{ item }">
                                                <div class="v-center">
                                                    <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                                    <div class="team-name-text">{{ item.school }}</div>
                                                </div>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <div class="v-center">
                                                    <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                                    <div class="team-name-text">{{ item.school }}</div>
                                                </div>
                                            </template>
                                        </v-select>
<!--                                        <select class="border-radius-5 border-1" :class="correctTeam(2, index, 'winner')" v-model="selectedWinTeams[index]" >-->
<!--                                            <option disabled value="">Select a winner</option>-->
<!--                                            <option v-for="team in championshipInConference(index)" :key="team._id">{{team}}</option>-->
<!--                                        </select>-->
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="score-wrapper">
                            <table class="champion-point-table">
                                <thead>
                                    <tr>
                                        <th>Pts.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="blank-field"></td>
                                    </tr>
                                    <tr>
                                        <td>{{predictionPoints.div1Point}}</td>
                                    </tr>
                                    <tr>
                                        <td class="blank-field"></td>
                                    </tr>
                                    <tr>
                                        <td class="blank-field"></td>
                                    </tr>
                                    <tr>
                                        <td>{{predictionPoints.div2Point}}</td>
                                    </tr>
                                    <tr>
                                        <td class="blank-field"></td>
                                    </tr>
                                    <tr>
                                        <td class="blank-field"></td>
                                    </tr>
                                    <tr>
                                        <td class="blank-field"></td>
                                    </tr>
                                    <tr>
                                        <td>{{predictionPoints.winnerPoint}}</td>
                                    </tr>
                                    <tr>
                                        <td class="blank-field"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="my-2">
                        <div class="font-weight-bold">An additional ten points per team will be awarded if the original Conference Champion picked in week 3 becomes the actual conference Champion.</div>
                        <div class="v-center justify-content-end mt-3 mr-5">
                            <div class="font-weight-bold">Total:</div>
                            <div class="total-point-box ml-3">{{(predictionPoints.div1Point + predictionPoints.div2Point + predictionPoints.winnerPoint) || 0}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="championship border-1 border-radius-5 text-center m-3 p-2">
                <div class="border-1 w-100">
                    <div class="v-start">
                        <div class="group-title f-6 font-weight-bold text-center p-2">
                            4 Team Playoff
                        </div>
                        <div class="text-center w-100 p-2 ml-5 mt-2">
                            <b>Select the four teams you feel will play for the National Championship.</b>
                            <br>If player selection matches Selection Committee's selection, players will be awarded 50 points per team match.
                            <b>300 Points max</b>
                        </div>
                    </div>
                    <div class="champion-select-wrapper v-center justify-content-around p-3">
                        <div class="v-center f-3">
                            <v-select
                                class="full"
                                :items="playoffTeams()"
                                  label="Standard"
                                  v-model="quarterFinalTeams[0]"
                                  item-disabled="disable"
                                  item-value="school"
                                  single-line
                            >
                                <template v-slot:selection="{ item }">
                                    <div class="v-center">
                                        <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                        <div class="team-name-text">{{ item.school }}</div>
                                    </div>
                                </template>
                                <template v-slot:item="{ item }">
                                    <div class="v-center">
                                        <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                        <div class="team-name-text">{{ item.school }}</div>
                                    </div>
                                </template>
                            </v-select>
<!--                            <select class="p-1 border-radius-5 border-1" :class="correctTeam(3, 0, 'playoff')" v-model="quarterFinalTeams[0]" >-->
<!--                                <option disabled value="">Select a playoff team</option>-->
<!--                                <option v-for="(team, index) in selectedWinTeams" :key="'quarter_final_1_' + index">-->
<!--                                  {{team}}-->
<!--                                </option>-->
<!--                            </select>-->
                        </div>
                        <div class="v-center f-3">
                            <v-select :items="playoffTeams()"
                                      class="full"
                                      label="Standard"
                                      v-model="quarterFinalTeams[1]"
                                      item-disabled="disable"
                                      item-value="school"
                                      single-line
                            >
                                <template v-slot:selection="{ item }">
                                    <div class="v-center">
                                        <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                        <div class="team-name-text">{{ item.school }}</div>
                                    </div>
                                </template>
                                <template v-slot:item="{ item }">
                                    <div class="v-center">
                                        <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                        <div class="team-name-text">{{ item.school }}</div>
                                    </div>
                                </template>
                            </v-select>
<!--                            <select class="p-1 border-radius-5 border-1" :class="correctTeam(3, 1, 'playoff')" v-model="quarterFinalTeams[1]" >-->
<!--                                <option disabled value="">Select a playoff team</option>-->
<!--                                <option v-for="(team, index) in selectedWinTeams" :key="'quarter_final_2_' + index">{{team}}</option>-->
<!--                            </select>-->
                        </div>
                        <div class="v-center f-3">
                            <v-select :items="playoffTeams()"
                                      class="full"
                                      label="Standard"
                                      v-model="quarterFinalTeams[2]"
                                      item-disabled="disable"
                                      item-value="school"
                                      single-line
                            >
                                <template v-slot:selection="{ item }">
                                    <div class="v-center">
                                        <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                        <div class="team-name-text">{{ item.school }}</div>
                                    </div>
                                </template>
                                <template v-slot:item="{ item }">
                                    <div class="v-center">
                                        <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                        <div class="team-name-text">{{ item.school }}</div>
                                    </div>
                                </template>
                            </v-select>
<!--                            <select class="p-1 border-radius-5 border-1" :class="correctTeam(3, 2, 'playoff')" v-model="quarterFinalTeams[2]" >-->
<!--                                <option disabled value="">Select a playoff team</option>-->
<!--                                <option v-for="(team, index) in selectedWinTeams" :key="'quarter_final_3_' + index">{{team}}</option>-->
<!--                            </select>-->
                        </div>
                        <div class="v-center f-3">
                            <v-select :items="playoffTeams()"
                                      class="full"
                                      label="Standard"
                                      v-model="quarterFinalTeams[3]"
                                      item-disabled="disable"
                                      item-value="school"
                                      single-line
                            >
                                <template v-slot:selection="{ item }">
                                    <div class="v-center">
                                        <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                        <div class="team-name-text">{{ item.school }}</div>
                                    </div>
                                </template>
                                <template v-slot:item="{ item }">
                                    <div class="v-center">
                                        <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                        <div class="team-name-text">{{ item.school }}</div>
                                    </div>
                                </template>
                            </v-select>
<!--                            <select class="p-1 border-radius-5 border-1" :class="correctTeam(3, 3, 'playoff')" v-model="quarterFinalTeams[3]" >-->
<!--                                <option disabled value="">Select a playoff team</option>-->
<!--                                <option v-for="(team, index) in selectedWinTeams" :key="'quarter_final_4_' + index">{{team}}</option>-->
<!--                            </select>-->
                        </div>
                    </div>
                    <div class="mt-3">
                        <b>An additional 25 points each will be awarded if the original teams predicted in week 3 matches any teams selected by the Selection Committee.</b>
                    </div>
                    <div class="my-2">
                        <div class="v-center justify-content-end mt-3 mr-5">
                            <p class="font-weight-bold">Total:</p>
                            <p class="total-point-box ml-3">{{predictionPoints.playOffPoint || 0}}</p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="championship border-1 border-radius-5 text-center m-3 p-2">
                <div class="border-1 w-100">
                    <div class="v-start">
                        <div class="group-title f-6 font-weight-bold text-center p-2">National Championship Game</div>
                        <div class="text-center w-100 p-2 ml-5 mt-2">
                            <b>Select the two teams you feel will play for the National Championship.</b>
                            <br>100 points each will be awarded if player's team matches teams playing for National Championship.
                            <b>200 Points max</b>
                        </div>
                    </div>
                    <div class="champion-select-wrapper v-center justify-content-around p-3">
                        <div class="v-center f-3">
                            <v-select :items="getQuaterFinalTeams()"
                                      class="full"
                                      label="Standard"
                                      v-model="semiFinalTeams[0]"
                                      item-disabled="disable"
                                      item-value="school"
                                      single-line
                            >
                                <template v-slot:selection="{ item }">
                                    <div class="v-center">
                                        <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                        <div class="team-name-text">{{ item.school }}</div>
                                    </div>
                                </template>
                                <template v-slot:item="{ item }">
                                    <div class="v-center">
                                        <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                        <div class="team-name-text">{{ item.school }}</div>
                                    </div>
                                </template>
                            </v-select>
<!--                            <select class="p-1 border-radius-5 border-1" :class="correctTeam(4, 0, 'runner')" v-model="semiFinalTeams[0]" >-->
<!--                                <option disabled value="">Select a play team</option>-->
<!--                                <option v-for="(team, index) in quarterFinalTeams" :key="'semi_final_1_' + index">{{team}}</option>-->
<!--                            </select>-->
                        </div>
                        <div class="v-center f-3">
                            <v-select :items="getQuaterFinalTeams()"
                                      class="full"
                                      label="Standard"
                                      v-model="semiFinalTeams[1]"
                                      item-disabled="disable"
                                      item-value="school"
                                      single-line
                            >
                                <template v-slot:selection="{ item }">
                                    <div class="v-center">
                                        <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                        <div class="team-name-text">{{ item.school }}</div>
                                    </div>
                                </template>
                                <template v-slot:item="{ item }">
                                    <div class="v-center">
                                        <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                        <div class="team-name-text">{{ item.school }}</div>
                                    </div>
                                </template>
                            </v-select>
<!--                            <select class="p-1 border-radius-5 border-1" :class="correctTeam(4, 1, 'runner')" v-model="semiFinalTeams[1]" >-->
<!--                                <option disabled value="">Select a play team</option>-->
<!--                                <option v-for="(team, index) in quarterFinalTeams" :key="'semi_final_2_' + index">{{team}}</option>-->
<!--                            </select>-->
                        </div>
                    </div>
                    <div class="my-2">
                        <div class="v-center justify-content-end mt-3 mr-5">
                            <p class="font-weight-bold">Total:</p>
                            <p class="total-point-box ml-3">{{predictionPoints.runnerPoint}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="championship border-1 border-radius-5 text-center m-3 p-2">
                <div class="border-1 w-100">
                    <div class="v-start">
                        <div class="group-title f-6 font-weight-bold text-center p-2">National Champion</div>
                        <div class="text-center w-100 p-2 ml-5 mt-2">
                            <b>Select the team you feel will win the National Championship.</b>
                            <br>If player selection matches the winner of the National Championship, 200 points will be awarded to player's final point score.
                            <b>200 Points max</b>
                        </div>
                    </div>
                    <div class="champion-select-wrapper v-center justify-content-around p-3">
                        <div class="v-center f-3">
                            <v-select :items="getSemiFinalTeams()"
                                      class="full"
                                      label="Standard"
                                      v-model="championshipWinner"
                                      item-disabled="disable"
                                      item-value="school"
                                      single-line
                            >
                                <template v-slot:selection="{ item }">
                                    <div class="v-center">
                                        <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                        <div class="team-name-text">{{ item.school }}</div>
                                    </div>
                                </template>
                                <template v-slot:item="{ item }">
                                    <div class="v-center">
                                        <img class="team-logo" :src="getTeamLogo(item)" v-if="getTeamLogo(item)">
                                        <div class="team-name-text">{{ item.school }}</div>
                                    </div>
                                </template>
                            </v-select>
<!--                            <select class="p-1 border-radius-5 border-1" :class="correctTeam(5, 0, 'championship')" v-model="championshipWinner" >-->
<!--                                <option disabled value="">Select a champion team</option>-->
<!--                                <option v-for="(team, index) in semiFinalTeams" :key="'champion_' + index">{{team}}</option>-->
<!--                            </select>-->
                        </div>
                    </div>
                    <div class="my-2">
                        <div class="v-center justify-content-end mt-3 mr-5">
                            <p class="font-weight-bold">Total:</p>
                            <p class="total-point-box ml-3">{{predictionPoints.championshipPoint}}</p>
                        </div>
                    </div>
                    <div class="mt-3 mb-1 font-weight-bold text-center">
                        Press Save to save selections
                    </div>
                </div>
            </div>
        </div>
        <LoadingOverlay v-if="isLoading"/>
    </div>
</template>

<script>
    import Navbar from "../../components/Navbar";
    import {mapActions, mapState} from "vuex";
    import {PREDICTIONCONFERENCE, STATUS_FAILURE, STATUS_SUCCESS} from "../../constants/constants";
    import {getPredictionResult} from "../../utils/functions";
    import {currentYear} from "../../utils/functions";
    import LoadingOverlay from "../../components/LoadingOverlay";
    import PredictionHeader from "../../components/PredictionHeader";
    export default {
        name: "ConferencePredictionsWk3",
        components: {
            PredictionHeader,
            Navbar,
            LoadingOverlay
        },

        data: () => ({
            conferenceList: [],
            conferenceChampionship: {},
            selectedFirstTeams: [],
            selectedSecondTeams: [],
            selectedWinTeams: [],
            quarterFinalTeams: ["Select a playoff team", "Select a playoff team", "Select a playoff team", "Select a playoff team"],
            semiFinalTeams: ["Select a champion team", "Select a champion team"],
            championshipWinner: "Select a champion team",
            predictionPoints: {},
            selectResult: [],
            isLoading: false,
        }),

        mounted() {
            for (let i = 0; i < 10; i++) {
                this.selectedFirstTeams.push("Div Winner");
                this.selectedSecondTeams.push("Div Winner");
                this.selectedWinTeams.push("Div Winner");
            }

            if (!this.teams.all.length) {
                this.getAllTeams();
            } else {
                for (const conference of PREDICTIONCONFERENCE) {
                    this.conferenceList.push({
                        name: conference.name.label,
                        id: conference.name.id,
                        division1: {
                            name: conference.division1.label,
                            id: conference.division1.id,
                            teams: []
                        },
                        division2: {
                            name: conference.division2.label,
                            id: conference.division2.id,
                            teams: []
                        }
                    });
                }

                for (const team of this.teams.all) {
                    for (let i = 0; i < PREDICTIONCONFERENCE.length; i++) {
                        const conference = PREDICTIONCONFERENCE[i];
                        // if (conference.name.label === 'CUSA') {
                        //     const index = PREDICTIONCONFERENCE.findIndex((item) => item.name.label === "Sun Belt");
                        //     if (team.school === "Marshall") {
                        //         this.conferenceList[index].division1.teams.push(team);
                        //     }
                        //     if (team.school === 'Southern Mississippi') {
                        //         this.conferenceList[index].division2.teams.push(team);
                        //     }
                        //     if (team.school === 'Old Dominion') {
                        //         this.conferenceList[index].division1.teams.push(team);
                        //     }
                        //     continue;
                        // }
                        if (conference.name.label === "Sun Belt") {
                            if (team.school === "Appalachian State") {
                                this.conferenceList[i].division1.teams.push(team);
                            }
                            if (team.school === "Coastal Carolina") {
                                this.conferenceList[i].division1.teams.push(team);
                            }
                            if (team.school === "Georgia State") {
                                this.conferenceList[i].division1.teams.push(team);
                            }
                            if (team.school === 'Arkansas State') {
                                this.conferenceList[i].division2.teams.push(team);
                            }
                            if (team.school === 'Louisiana') {
                                this.conferenceList[i].division2.teams.push(team);
                            }
                            if (team.school === 'Louisiana Monroe') {
                                this.conferenceList[i].division2.teams.push(team);
                            }
                            if (team.school === 'Texas State') {
                                this.conferenceList[i].division2.teams.push(team);
                            }
                            if (team.school === 'South Alabama') {
                                this.conferenceList[i].division2.teams.push(team);
                            }
                            if (team.school === 'Troy') {
                                this.conferenceList[i].division2.teams.push(team);
                            }
                            if (team.school === 'Texas State') {
                                this.conferenceList[i].division2.teams.push(team);
                            }
                            if (team.school === "Marshall") {
                                this.conferenceList[i].division1.teams.push(team);
                            }
                            if (team.school === 'Southern Mississippi') {
                                this.conferenceList[i].division2.teams.push(team);
                            }
                            if (team.school === 'Old Dominion') {
                                this.conferenceList[i].division1.teams.push(team);
                            }
                            if (team.school === 'Georgia Southern') {
                                this.conferenceList[i].division1.teams.push(team);
                            }
                            if (team.school === 'James Madison') {
                                this.conferenceList[i].division1.teams.push(team);
                            }
                            continue;
                        }
                        if (conference.name.label === "AAC" ||
                            conference.name.label === "C-USA" ||
                            conference.name.label === "PAC 12" ||
                            conference.name.label === "Big 12"
                        ) {
                            if (team.conference === conference.name.id) {
                                this.conferenceList[i].division1.teams.push(team);
                                this.conferenceList[i].division2.teams.push(team);
                            }
                        } else {
                            if (team.conference === conference.name.id) {
                                if (team.division === conference.division1.id) {
                                    this.conferenceList[i].division1.teams.push(team);
                                }
                                if (team.division === conference.division2.id) {
                                    this.conferenceList[i].division2.teams.push(team);
                                }
                            }
                        }
                    }
                }
                for (let i = 0; i < this.conferenceList.length; i++) {
                    this.conferenceList[i].division1.teams.unshift({school: 'Div Winner', disable: true});
                    this.conferenceList[i].division2.teams.unshift({school: 'Div Winner', disable: true});
                }
                const data = {
                    user: this.users.currentUser._id,
                    year: currentYear(),
                    week: '3'
                };
                this.getPrediction(data);
            }
        },

        computed: {
            ...mapState({
                users: state => state.users,
                teams: state => state.teams,
                prediction: state => state.prediction,
                regular_season: state => state.regular_season
            }),

        },
        methods: {
          ...mapActions([
            'getAllTeams',
                'getPrediction',
                'setPrediction',
                'resetPrediction'
            ]),

            correctTeam (resIndex, i, fromWhere) {
              let correct;
              if (this.selectResult.length > 0 && this.selectResult[resIndex].length > 0) {
                if (fromWhere == 'div1') {
                  let teamId = this.getTeamIdFromSchool(this.selectedFirstTeams[i])
                  let index = this.selectResult[resIndex].indexOf(teamId);
                  correct = (index > -1) ? 'correct-team' : 'incorrect-team'; 
                } 
                else if (fromWhere == 'div2') {
                  let teamId = this.getTeamIdFromSchool(this.selectedSecondTeams[i])
                  let index = this.selectResult[resIndex].indexOf(teamId);
                  correct = (index > -1) ? 'correct-team' : 'incorrect-team'; 
                }
                else if (fromWhere == 'winner') {
                  let teamId = this.getTeamIdFromSchool(this.selectedWinTeams[i])
                  let index = this.selectResult[resIndex].indexOf(teamId);
                  correct = (index > -1) ? 'correct-team' : 'incorrect-team'; 
                }
                else if (fromWhere == 'playoff') {
                  let teamId = this.getTeamIdFromSchool(this.quarterFinalTeams[i])
                  let index = this.selectResult[resIndex].indexOf(teamId);
                  correct = (index > -1) ? 'correct-team' : 'incorrect-team'; 
                }
                else if (fromWhere == 'runner') {
                  let teamId = this.getTeamIdFromSchool(this.semiFinalTeams[i])
                  let index = this.selectResult[resIndex].indexOf(teamId);
                  correct = (index > -1) ? 'correct-team' : 'incorrect-team'; 
                }
                else if (fromWhere == 'championship') {
                  let teamId = this.getTeamIdFromSchool(this.championshipWinner)
                  let index = this.selectResult[resIndex].indexOf(teamId);
                  correct = (index > -1) ? 'correct-team' : 'incorrect-team'; 
                }
              }
              return correct;
            },
            
            championshipInConference(index) {
                return [this.getTeamFromSchool(this.selectedFirstTeams[index]), this.getTeamFromSchool(this.selectedSecondTeams[index])];
            },

            playoffTeams() {
              const teams = [];
              for (let i = 0; i < this.selectedWinTeams.length; i++) {
                  teams.push(this.getTeamFromSchool(this.selectedWinTeams[i]));
              }
              return teams;
            },

            getQuaterFinalTeams() {
                const teams = [];
                for (let i = 0; i < this.quarterFinalTeams.length; i++) {
                    teams.push(this.getTeamFromSchool(this.quarterFinalTeams[i]));
                }
                return teams;
            },

            getSemiFinalTeams() {
                const teams = [];
                for (let i = 0; i < this.semiFinalTeams.length; i++) {
                    teams.push(this.getTeamFromSchool(this.semiFinalTeams[i]));
                }
                return teams;
            },

            getTeamIdFromSchool(school) {
                const index = this.teams.all.findIndex((team) => team.school === school);
                if (index >= 0) {
                    return this.teams.all[index]._id;
                }
                return null;
            },

            getTeamFromSchool(school) {
                const index = this.teams.all.findIndex((team) => team.school === school);
                if (index >= 0) {
                    return this.teams.all[index];
                }
                return null;
            },

            getSchoolFromTeamId(id, mode = "division") {
                const index = this.teams.all.findIndex((team) => team._id === id);
                if (index >= 0) {
                    return this.teams.all[index].school;
                }
                if (mode === 'division') {
                    return 'Div Winner';
                } else if (mode === 'play-off') {
                    return 'Select a playoff team';
                } else {
                    return 'Select a champion team';
                }
            },

            onReset() {
                this.selectedFirstTeams = [];
                this.selectedSecondTeams = [];
                this.selectedWinTeams = [];
                this.predictionPoints = {};

                for (let i = 0; i < 10; i++) {
                    this.selectedFirstTeams.push("Div Winner");
                    this.selectedSecondTeams.push("Div Winner");
                    this.selectedWinTeams.push("Div Winner");
                }
                this.quarterFinalTeams = ["Div Winner", "Div Winner", "Div Winner", "Div Winner"];
                this.semiFinalTeams = ["Div Winner", "Div Winner"];
                this.championshipWinner = "Div Winner";
                
                if (this.predictionPoints['div1Point']) {
                  Object.keys(this.predictionPoints).map((item) => {
                    this.predictionPoints[item] = 0;
                  });
                }
                
                const data = {
                    user: this.users.currentUser._id,
                    year: currentYear(),
                    week: '3'
                }
                this.resetPrediction(data);
            },

            onSave() {
                const group_teams = [];
                const play_off_teams = [];
                const runner_teams = [];
                this.initialPredictionPoints();

                for (let i = 0; i < 10; i++) {
                    group_teams.push({
                        team1: this.getTeamIdFromSchool(this.selectedFirstTeams[i]),
                        team2: this.getTeamIdFromSchool(this.selectedSecondTeams[i]),
                        winner: this.getTeamIdFromSchool(this.selectedWinTeams[i]),
                        score: '0'
                    });
                }
                for (let i = 0; i < 4; i++) {
                    play_off_teams.push({
                        winner: this.getTeamIdFromSchool(this.quarterFinalTeams[i]),
                        score: '0'
                    });
                }
                for (let i = 0; i < 2; i++) {
                    runner_teams.push({
                        winner: this.getTeamIdFromSchool(this.semiFinalTeams[i]),
                        score: '0'
                    });
                }
                const champion_team = {
                    winner: this.getTeamIdFromSchool(this.championshipWinner),
                    score: '0'
                }
                const data = {
                    user: this.users.currentUser._id,
                    year: currentYear(),
                    week: '3',
                    group_teams,
                    play_off_teams,
                    runner_teams,
                    champion_team,
                    total: '0',
                    predictionPoints: this.predictionPoints,
                };
                
                let points = getPredictionResult(this.conferenceList , data);

                data.predictionPoints.div1Point = points.matchScore.div1Point;
                data.predictionPoints.div2Point = points.matchScore.div2Point;
                data.predictionPoints.winnerPoint = points.matchScore.winnerPoint;
                data.predictionPoints.playOffPoint = points.matchScore.playoffPoint;
                data.predictionPoints.runnerPoint = points.matchScore.runnerPoint;
                data.predictionPoints.championshipPoint = points.matchScore.championshipPoint;
                data.predictionPoints.totalPoint = points.matchScore.totalPoint;

                this.selectResult = points.correctPos;
                this.setPrediction(data);

            },

            initialPredictionPoints() {
              this.predictionPoints['div1Point'] = 0;
              this.predictionPoints['div2Point'] = 0;
              this.predictionPoints['winnerPoint'] = 0;
              this.predictionPoints['playOffPoint'] = 0;
              this.predictionPoints['runnerPoint'] = 0;
              this.predictionPoints['championshipPoint'] = 0;
              this.predictionPoints['totalPoint'] = 0;
            },

            getTeamLogo(team) {
                if (team.logos && team.logos.length > 0) {
                    return team.logos[0];
                }
                return null;
            },

            onBack() {
                this.$router.back();
            },

            onNext() {
                this.regular_season.currentSeason = 3;
                this.$router.push({name: 'RegularSeasonWk3Schedule'});
            }
        },
        watch: {

            'teams.getAllTeamStatus': function (status) {
                this.isLoading = true;
                if (status === STATUS_SUCCESS) {
                    this.isLoading = false;
                    for (const conference of PREDICTIONCONFERENCE) {
                        this.conferenceList.push({
                            name: conference.name.label,
                            id: conference.name.id,
                            division1: {
                                name: conference.division1.label,
                                id: conference.division1.id,
                                teams: []
                            },
                            division2: {
                                name: conference.division2.label,
                                id: conference.division2.id,
                                teams: []
                            }
                        });
                    }
                    for (const team of this.teams.all) {
                        for (let i = 0; i < PREDICTIONCONFERENCE.length; i++) {
                            const conference = PREDICTIONCONFERENCE[i];
                            // if (conference.name.label === 'CUSA') {
                            //     const index = PREDICTIONCONFERENCE.findIndex((item) => item.name.label === "Sun Belt");
                            //     if (team.school === "Marshall") {
                            //         this.conferenceList[index].division1.teams.push(team);
                            //     }
                            //     if (team.school === 'Southern Mississippi') {
                            //         this.conferenceList[index].division2.teams.push(team);
                            //     }
                            //     if (team.school === 'Old Dominion') {
                            //         this.conferenceList[index].division1.teams.push(team);
                            //     }
                            //     continue;
                            // }
                            if (conference.name.label === "Sun Belt") {
                                if (team.school === "Appalachian State") {
                                    this.conferenceList[i].division1.teams.push(team);
                                }
                                if (team.school === "Coastal Carolina") {
                                    this.conferenceList[i].division1.teams.push(team);
                                }
                                if (team.school === "Georgia State") {
                                    this.conferenceList[i].division1.teams.push(team);
                                }
                                if (team.school === 'Arkansas State') {
                                    this.conferenceList[i].division2.teams.push(team);
                                }
                                if (team.school === 'Louisiana') {
                                    this.conferenceList[i].division2.teams.push(team);
                                }
                                if (team.school === 'Louisiana Monroe') {
                                    this.conferenceList[i].division2.teams.push(team);
                                }
                                if (team.school === 'South Alabama') {
                                    this.conferenceList[i].division2.teams.push(team);
                                }
                                if (team.school === 'Troy') {
                                    this.conferenceList[i].division2.teams.push(team);
                                }
                                if (team.school === 'Texas State') {
                                    this.conferenceList[i].division2.teams.push(team);
                                }
                                if (team.school === "Marshall") {
                                    this.conferenceList[i].division1.teams.push(team);
                                }
                                if (team.school === 'Southern Mississippi') {
                                    this.conferenceList[i].division2.teams.push(team);
                                }
                                if (team.school === 'Old Dominion') {
                                    this.conferenceList[i].division1.teams.push(team);
                                }
                                if (team.school === 'Georgia Southern') {
                                    this.conferenceList[i].division1.teams.push(team);
                                }
                                if (team.school === 'James Madison') {
                                    this.conferenceList[i].division1.teams.push(team);
                                }
                                continue;
                            }
                            if (conference.name.label === "AAC" ||
                                conference.name.label === "C-USA" ||
                                conference.name.label === "PAC 12") {
                                if (team.conference === conference.name.id) {
                                    this.conferenceList[i].division1.teams.push(team);
                                    this.conferenceList[i].division2.teams.push(team);
                                }
                            } else {
                                if (team.conference === conference.name.id) {
                                    if (team.division === conference.division1.id) {
                                        this.conferenceList[i].division1.teams.push(team);
                                    }
                                    if (team.division === conference.division2.id) {
                                        this.conferenceList[i].division2.teams.push(team);
                                    }
                                }
                            }
                        }
                    }
                    const data = {
                        user: this.users.currentUser._id,
                        year: currentYear(),
                        week: '3'
                    };
                    this.getPrediction(data);
                } else if (status === STATUS_FAILURE) {
                    this.isLoading = false;
                    this.$toasted.show(this.teams.errorMessage);
                }
            },
            'prediction.getPredictionStatus': function (status) {
                // console.log("GET PREDICTION STATUS ====>", status);
                this.isLoading = true;
                if (status === STATUS_SUCCESS) {
                  
                    this.isLoading = false;
                    if (this.prediction.data) {
                        const group_teams = this.prediction.data.group_teams;
                        const play_off_teams = this.prediction.data.play_off_teams;
                        const runner_teams = this.prediction.data.runner_teams;
                        const champion_team = this.prediction.data.champion_team;
                        const total = this.prediction.data.total;
                        this.predictionPoints = this.prediction.data.prediction_points;
                        if (!this.predictionPoints) {
                          this.predictionPoints = {};
                          this.initialPredictionPoints();
                        }
                        for (let i = 0; i < group_teams.length; i++) {
                            this.selectedFirstTeams.splice(i, 1, this.getSchoolFromTeamId(group_teams[i].team1));
                            this.selectedSecondTeams.splice(i, 1, this.getSchoolFromTeamId(group_teams[i].team2));
                            this.selectedWinTeams.splice(i, 1, this.getSchoolFromTeamId(group_teams[i].winner));
                        }
                        for (let i = 0; i < play_off_teams.length; i++) {
                            this.quarterFinalTeams.splice(i, 1, this.getSchoolFromTeamId(play_off_teams[i].winner, 'play-off'));
                        }
                        for (let i = 0; i < runner_teams.length; i++) {
                            this.semiFinalTeams.splice(i, 1, this.getSchoolFromTeamId(runner_teams[i].winner, 'champion'));
                        }
                        this.championshipWinner = this.getSchoolFromTeamId(champion_team.winner, 'champion');
                    }
                } else if (status === STATUS_FAILURE) {
                    this.isLoading = false;
                    this.$toasted.show(this.game_type.errorMessage);
                }
            },
            'prediction.setPredictionStatus': function (status) {
                this.isLoading = true;
                if (status === STATUS_SUCCESS) {
                    this.isLoading = false;
                } else if (status === STATUS_FAILURE) {
                    this.isLoading = false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .regular-season {
        font-family: ArvoRegular;

        .page-wrapper {
            .championship {
                .group-title {
                    width: 150px;
                    background-color: sandybrown;
                }

                select{
                    cursor: pointer;
                    width: 100%;
                    text-align: center;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    word-break: break-all;
                }

                .champion-select-wrapper {
                    .teams {
                        margin: 1px 1px;
                        width: 100%;

                        .team-select-table {
                            width: 100%;
                            border-collapse: collapse;
                            border: 1px solid #ffffff;

                            .champion-table-header{
                                background-color: #2F60C1;
                                color: #ffffff;
                                font-weight: bold;
                                line-height: 18px;
                                padding: 5px 0px;
                            }

                            td {
                                border: 1px solid black;
                                padding: 3px 0px;

                                .division-name {
                                    width: 40px;
                                    text-align: left;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    word-break: break-all;
                                    margin-top: 8px;
                                }

                                .team-select-wrapper {
                                    width: calc(100% - 40px);
                                }
                            }
                        }
                    }

                    .score-wrapper {
                        margin: 0px 20px;
                        width: 40px;
                        display: flex;
                        align-items: center;
                        .champion-point-table{
                            border-collapse: collapse;
                            border: 0px;
                            tr th{
                                font-weight: bold;
                                vertical-align: bottom;
                                text-align: center;
                                height: 40px;
                            }

                            tr td{
                                vertical-align: bottom;
                                text-align: center;
                                border-bottom: 1px solid black;
                                height: 25px;

                                &.blank-field{
                                    border: 0px;
                                    height: 20px;
                                }
                            }
                        }
                    }

                    .total-point-box{
                        border: 1px solid black;
                        width: 50px;
                        height: 20px;
                        background: white;
                        line-height: 18px;
                        font-size: 14px;
                    }

                    .quarterFinalTeam {
                        border: 1px solid gray;
                        background: white;
                        height: 30px;
                        width: 200px;
                        padding: 3px 0px;
                        font-size: 14px;
                    }

                    .semiFinalTeam {
                        border: 1px solid gray;
                    }

                    .championshipWinner {
                        border: 1px solid gray;
                    }
                }

            }
        }

        .correct-team {
          background-color: grey;
        }
        .incorrect-team {
          background-color: white;
        }
    }

    .team-logo {
        width: 18px;
        height: 18px;
    }

    .team-name-text {
        text-overflow: ellipsis;
        overflow: hidden;
        height: 20px;
        white-space: nowrap;
        margin-left: 3px;
        width: 75px;
        max-width: 75px;
        min-width: 75px;
        font-size: 13px;
    }

    .full {
        .team-name-text {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
        }
    }

</style>
