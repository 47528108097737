<template>
    <div class="page statistic-page">
        <Navbar :user="users.currentUser"/>
        <Header
                title="Statistical Match Scoring"
                :sub-title="'WEEK ' + regular_season.currentWeek"
                :is-left = true
                :is-next = false
                left-button-text="Back"
                @left_click="onBack"
        />
        <div class="page-contents mt-5" v-if="this.users.currentUser.watchTeam">
            <div class="f-6 font-weight-bold">
                Points Team:
                <span class="f-8 text-decoration-underline ml-3">{{this.users.currentUser.watchTeam.school}}</span>
            </div>
            <div class="statistical-area v-start f-2">
                <div class="panel">
                    <table class="mb-3">
                        <tbody>
                        <tr>
                            <td class="table-head">RUSING</td>
                            <td class="value-cell table-head">YARDS</td>
                            <td class="value-cell table-head">TD</td>
                            <td class="value-cell empty-cell"></td>
                        </tr>
                        <tr>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td class="top-left-cell">Total</td>
                            <td>{{statistical.rushingYards}}</td>
                            <td>{{statistical.rushingTDs}}</td>
                            <td class="top-right-cell">PTS</td>
                        </tr>
                        <tr>
                            <td class="head">Rushing and TDs</td>
                            <td class="val">{{calcPts().rushingYards}}</td>
                            <td class="val">{{calcPts().rushingTDs}}</td>
                            <td>{{calcPts().rushingPts}}</td>
                        </tr>
                        <tr>
                            <td class="empty-cell"></td>
                            <td class="empty-cell" colspan="3">1 pts per 10 yds Rushing</td>
                        </tr>
                        </tbody>
                    </table>
                    <table class="mb-3">
                        <tbody>
                        <tr>
                            <td class="table-head">PASSING</td>
                            <td class="value-cell table-head">YARDS</td>
                            <td class="value-cell table-head">TD</td>
                            <td class="empty-cell value-cell"></td>
                        </tr>
                        <tr>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td class="top-left-cell">Total</td>
                            <td>{{statistical.netPassingYards}}</td>
                            <td>{{statistical.passingTDs}}</td>
                            <td class="top-right-cell">PTS</td>
                        </tr>
                        <tr>
                            <td class="head">Passing and TDs</td>
                            <td class="val">{{calcPts().netPassingYards}}</td>
                            <td class="val">{{calcPts().passingTDs}}</td>
                            <td>{{calcPts().passingPts}}</td>
                        </tr>
                        <tr>
                            <td class="empty-cell"></td>
                            <td class="empty-cell" colspan="3">2 pts per 25 yds Passing</td>
                        </tr>
                        </tbody>
                    </table>
                    <table class="mb-3">
                        <tbody>
                        <tr>
                            <td class="table-head">RECEIVING</td>
                            <td class="value-cell table-head">YARDS</td>
                            <td class="value-cell table-head">TD</td>
                            <td class="empty-cell value-cell"></td>
                        </tr>
                        <tr>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td class="top-left-cell">Total</td>
                            <td>{{statistical.netPassingYards}}</td>
                            <td>{{statistical.passingTDs}}</td>
                            <td class="top-right-cell">PTS</td>
                        </tr>
                        <tr>
                            <td class="head">Receiving and TDs</td>
                            <td class="val">{{calcPts().receivingYards}}</td>
                            <td class="val">{{calcPts().receivingTDs}}</td>
                            <td>{{calcPts().receivingPts}}</td>
                        </tr>
                        <tr>
                            <td class="empty-cell"></td>
                            <td class="empty-cell" colspan="3">1 pt per 10 yds Receiving</td>
                        </tr>
                        </tbody>
                    </table>
                    <table class="mb-3">
                        <tbody>
                        <tr>
                            <td class="table-head">KICK RETURN</td>
                            <td class="value-cell table-head">YARDS</td>
                            <td class="value-cell table-head">TD</td>
                            <td class="empty-cell value-cell"></td>
                        </tr>
                        <tr>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td class="top-left-cell">Total</td>
                            <td>{{statistical.kickReturnYards}}</td>
                            <td>{{statistical.kickReturnTDs}}</td>
                            <td class="top-right-cell">PTS</td>
                        </tr>
                        <tr>
                            <td class="head">Team Kick Return</td>
                            <td class="val">{{calcPts().kickReturnYards}}</td>
                            <td class="val">{{calcPts().kickReturnTDs}}</td>
                            <td>{{calcPts().kickReturnPts}}</td>
                        </tr>
                        <tr>
                            <td class="empty-cell"></td>
                            <td class="empty-cell" colspan="3">1 pt per 10 yds</td>
                        </tr>
                        </tbody>
                    </table>
                    <table class="mb-3">
                        <tbody>
                        <tr>
                            <td class="table-head">PUNT RETURN</td>
                            <td class="value-cell table-head">YARDS</td>
                            <td class="value-cell table-head">TD</td>
                            <td class="empty-cell value-cell"></td>
                        </tr>
                        <tr>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td class="top-left-cell">Total</td>
                            <td>{{statistical.puntReturnYards}}</td>
                            <td>{{statistical.puntReturnTDs}}</td>
                            <td class="top-right-cell">PTS</td>
                        </tr>
                        <tr>
                            <td class="head">Team Punt Return</td>
                            <td class="val">{{calcPts().puntReturnYards}}</td>
                            <td class="val">{{calcPts().puntReturnTDs}}</td>
                            <td>{{calcPts().puntReturnPts}}</td>
                        </tr>
                        <tr>
                            <td class="empty-cell"></td>
                            <td class="empty-cell" colspan="3">1 pt per 10 yds</td>
                        </tr>
                        </tbody>
                    </table>

                </div>
                <div class="panel">
                    <table class="mb-5">
                        <tbody>
                        <tr>
                            <td class="table-head" colspan="2">DEF/SP TEAMS</td>
                            <td class="value-cell table-head">Pt Plays</td>
                            <td class="value-cell table-head">PTS</td>
                            <td class="empty-cell value-cell"></td>
                        </tr>
                        <tr>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td>Sacks</td>
                            <td>3pts</td>
                            <td>{{statistical.sacks}}</td>
                            <td>{{calcPts().sacks}}</td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td>Safety</td>
                            <td>2pts</td>
                            <td></td>
                            <td></td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td>Pick Six</td>
                            <td>6pts</td>
                            <td></td>
                            <td></td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td>Forced Fumble</td>
                            <td>1pt</td>
                            <td></td>
                            <td></td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td>Fumble Recovery</td>
                            <td>3pts</td>
                            <td></td>
                            <td></td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td>Fumble Rec. TD</td>
                            <td>6pts</td>
                            <td></td>
                            <td></td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td>Blocked Punt</td>
                            <td>2pts</td>
                            <td></td>
                            <td></td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td>Blocked FG</td>
                            <td>3pts</td>
                            <td></td>
                            <td></td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td>Blocked PAT</td>
                            <td>1pt</td>
                            <td></td>
                            <td></td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td>Interception</td>
                            <td>6pts</td>
                            <td>{{statistical.interceptions}}</td>
                            <td>{{calcPts().interceptions}}</td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td>Pass Break up</td>
                            <td>3pts</td>
                            <td>{{statistical.passesDeflected}}</td>
                            <td>{{calcPts().passesDeflected}}</td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td>Total Tackles</td>
                            <td>1pt</td>
                            <td>{{statistical.tackles}}</td>
                            <td>{{calcPts().tackles}}</td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td>Solo Tackles</td>
                            <td>1pt</td>
                            <td>47</td>
                            <td>47</td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td>Tackles For Loss</td>
                            <td>2pts</td>
                            <td>{{statistical.tacklesForLoss}}</td>
                            <td>{{calcPts().tacklesForLoss}}</td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td>QB Hurries</td>
                            <td>2pts</td>
                            <td>{{statistical.qbHurries}}</td>
                            <td>{{calcPts().qbHurries}}</td>
                            <td class="top-right-cell">PTS</td>
                        </tr>
                        <tr>
                            <td class="row-head" colspan="2">Team Defense/SP</td>
                            <td class="row-val">N/A</td>
                            <td class="row-val">{{calcPts().teamDefensePts}}</td>
                            <td>{{calcPts().teamDefensePts}}</td>
                        </tr>
                        </tbody>
                    </table>
                    <table class="table-last mb-3">
                        <tbody>
                        <tr>
                            <td class="table-head">KICKING</td>
                            <td class="value-cell table-head">FG</td>
                            <td class="value-cell table-head">PAT</td>
                            <td class="empty-cell value-cell"></td>
                        </tr>
                        <tr>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                            <td class="empty-cell"></td>
                        </tr>
                        <tr class="value-row">
                            <td class="top-left-cell">Total</td>
                            <td>1</td>
                            <td>6</td>
                            <td class="top-right-cell">PTS</td>
                        </tr>
                        <tr>
                            <td class="head">Team Kicking</td>
                            <td class="val">3</td>
                            <td class="val">6</td>
                            <td>9</td>
                        </tr>
                        <tr>
                            <td class="empty-cell"></td>
                            <td class="empty-cell" colspan="3">3 pts per FG 1 pt per PAT</td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="f-6 font-weight-bold text-right">
                        Total Points
                        <span class="ml-3">{{calcPts().totalPts}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="no-team">Please select a points team in Regular Season Schedule.</div>
        </div>
        <LoadingOverlay v-if="isLoading"/>
    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import Navbar from "../../components/Navbar";
    import Header from "../../components/Header";
    import {STATUS_FAILURE, STATUS_SUCCESS} from "../../constants/constants";
    import {getMatchState} from "../../utils/functions";
    import LoadingOverlay from "../../components/LoadingOverlay";

    export default {
        name: "RegularSeasonWkStatistical",
        components: {
            Navbar,
            LoadingOverlay,
            Header
        },
        data: () => ({
            isLoading: false,
            statistical: {}
        }),
        props: {

        },
        computed: {
            ...mapState({
                users: state => state.users,
                teams: state => state.teams,
                regular_season: state => state.regular_season
            }),
        },
        mounted() {
            const data = {
                year: this.regular_season.currentYear,
                week: this.regular_season.currentWeek,
                team: this.users.currentUser.watchTeam ? this.users.currentUser.watchTeam.school : 'Ohio State'
            }
            this.getStatistical(data);
        },
        methods: {
            ...mapActions([
                'getAllTeams',
                'updateUser',
                'getStatistical'
            ]),

            calcPts() {
                const pts = {};
                pts['rushingYards'] = Math.round(this.statistical.rushingYards / 10) || 0;
                pts['rushingTDs'] = this.statistical.rushingTDs * 6 || 0;
                pts['rushingPts'] = (pts['rushingYards'] + pts['rushingTDs']) || 0;
                pts['netPassingYards'] = Math.floor(this.statistical.netPassingYards / 25 * 2) || 0;
                pts['passingTDs'] = this.statistical.passingTDs * 6 || 0;
                pts['passingPts'] = (pts['netPassingYards'] + pts['passingTDs']) || 0;
                pts['receivingYards'] = Math.round(this.statistical.netPassingYards / 10) || 0;
                pts['receivingTDs'] = this.statistical.passingTDs * 6 || 0;
                pts['receivingPts'] = pts['receivingYards'] || 0;
                pts['kickReturnYards'] = Math.floor(this.statistical.kickReturnYards / 10 ) || 0;
                pts['kickReturnTDs'] = this.statistical.kickReturnTDs * 6 || 0;
                pts['kickReturnPts'] = (pts['kickReturnYards'] + pts['kickReturnTDs']) || 0;
                pts['puntReturnYards'] = Math.floor(this.statistical.puntReturnYards / 10 ) || 0;
                pts['puntReturnTDs'] = this.statistical.puntReturnTDs * 6 || 0;
                pts['puntReturnPts'] = (pts['puntReturnYards'] + pts['puntReturnTDs']) || 0;
                pts['sacks'] = this.statistical.sacks * 3 || 0;
                pts['interceptions'] = this.statistical.interceptions * 6 || 0;
                pts['passesDeflected'] = this.statistical.passesDeflected * 3 || 0;
                pts['tackles'] = this.statistical.tackles * 1 || 0;
                pts['tacklesForLoss'] = this.statistical.tacklesForLoss * 2 || 0;
                pts['qbHurries'] = this.statistical.qbHurries * 2 || 0;
                const sacks = pts['sacks'] ? pts['sacks'] : 0;
                const interceptions = pts['interceptions'] ? pts['interceptions'] : 0;
                const passesDeflected = pts['passesDeflected'] ? pts['passesDeflected'] : 0;
                const tackles = pts['tackles'] ? pts['tackles'] : 0;
                const tacklesForLoss = pts['tacklesForLoss'] ? pts['tacklesForLoss'] : 0;
                const qbHurries = pts['qbHurries'] ? pts['qbHurries'] : 0;
                const rushingPts = pts['rushingPts'] ? pts['rushingPts'] : 0;
                const passingPts = pts['passingPts'] ? pts['passingPts'] : 0;
                const receivingPts = pts['receivingPts'] ? pts['receivingPts'] : 0;
                const kickReturnPts = pts['kickReturnPts'] ? pts['kickReturnPts'] : 0;
                const puntReturnPts = pts['puntReturnPts'] ? pts['puntReturnPts'] : 0;

                pts['teamDefensePts'] = sacks + interceptions + passesDeflected +
                    tackles + tacklesForLoss + qbHurries;
                pts['totalPts'] = rushingPts + passingPts + receivingPts +
                    kickReturnPts + puntReturnPts + pts['teamDefensePts'];
                return pts;
            },
            onBack() {
                // this.$router.back();
                this.$router.push({name: 'RegularSeasonWk' + this.regular_season.currentSeason + 'Schedule', params: {restore: true}});
                // this.$router.push({name: `RegularSeasonWk${this.regular_season.currentWeek}Schedule`});
            },
        },
        watch: {
            'teams.getStatisticalStatus': function(status) {
                this.isLoading = true;
                if (status === STATUS_SUCCESS) {
                    this.isLoading = false;
                    this.teams.statistical.forEach((item) => {
                        this.statistical[item.category] = item.stat;
                    });
                } else if (status === STATUS_FAILURE) {
                    this.isLoading = false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .statistic-page {
        font-family: ArvoRegular;

        .page-contents {
            max-width: 860px;

            .statistical-area {

                .panel {
                    margin: 1.5rem;

                    table{
                        border-collapse: collapse;
                        text-align: center;

                        tr td{
                            border: 1px solid #000;
                            padding: 5px 10px;
                        }

                        .table-head {
                            background-color: #2F60C1;
                            color: #ffffff;
                            font-weight: bold;
                            border: 1px solid white;
                        }

                        .empty-cell{
                            border: 0px;
                        }

                        .value-row{
                            margin-top: 20px;
                        }

                        .value-cell {
                            width: 64px;
                            padding: 5px 1px;
                        }

                        .top-left-cell{
                            border-top-width: 0px;
                            border-left-width: 0px;
                        }

                        .top-right-cell{
                            border-top-width: 0px;
                            border-right-width: 0px;
                        }

                        .head {
                            background-color: #81c784;
                            color: #000000;
                            font-weight: bold;
                        }

                        .val {
                            background-color: #9e9e9e;
                        }
                    }
                }
            }
        }

        .no-team {
            margin-top: 100px;
            color: #ff0000;
            font-size: 20px;
            text-align: center;
        }
    }
</style>
