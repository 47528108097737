import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import Toasted from 'vue-toasted';
import store from './store';
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Import SCSS.
import './assets/scss/custom.scss'

// Import Screens.
import MainScreen from "./screens/Main/MainScreen";
import AuthScreen from '../src/screens/Auth/AuthScreen';
import HomeScreen from '../src/screens/Home/HomeScreen';
import Game1Prev from "./screens/GameSelect/Game1Prev";
import Game1 from '../src/screens/GameSelect/Game1';
import Game2Prev from "./screens/GameSelect/Game2Prev";
import Game2 from '../src/screens/GameSelect/Game2';
import Gm2NewCFFBPoll from '../src/screens/GameSelect/Gm2NewCFFBPoll';
import Game3Prev from "./screens/GameSelect/Game3Prev";
import Game3 from '../src/screens/GameSelect/Game3';
import Gm3Redistribution from '../src/screens/GameSelect/Gm3Redistribution';
import ConferencePredictionsWk3 from '../src/screens/ConferencePrediction/ConferencePredictionsWk3'
import ConferencePredictionsWk8 from '../src/screens/ConferencePrediction/ConferencePredictionsWk8'
import MyTop25Poll from "./screens/Home/MyTop25Poll";

import Signup from '../src/screens/Signup';
import Profile from '../src/screens/Profile';

import Gm2ProjectedWinners from '../src/screens/Gm2ProjectedWinners'
import Gm3Option from '../src/screens/Gm3Option'
import RegularSeasonWk9Schedule from './screens/RegularSeason/RegularSeasonWk9Schedule'
import RegularSeasonWk9MatchScoring from '../src/screens/RegularSeasonWk9MatchScoring'
import ChampionshipGamesWk15 from '../src/screens/ChampionshipGamesWk15'
import ConferencePredictionsWk15 from '../src/screens/ConferencePredictionsWk15'
import SelectionCommitteePickWk16 from '../src/screens/SelectionCommitteePickWk16'
import ChampionshipGamesWk16 from '../src/screens/ChampionshipGamesWk16'
import SemifinalPlayInGamesWk16 from '../src/screens/SemifinalPlayInGamesWk16'
import NationalChampionWk17 from '../src/screens/NationalChampionWk17'
import BowlChampionshipSeriesWk17 from '../src/screens/BowlChampionshipSeriesWk17'
import BCSMatch from '../src/screens/BCSMatch'
import BCSPlayerPickRound1 from '../src/screens/BCSPlayerPickRound1'
import BCSComputerPickRound1 from '../src/screens/BCSComputerPickRound1'
import BCSPlayerPickSemifinal from '../src/screens/BCSPlayerPickSemifinal'
import BCSComputerPickSemifinal from '../src/screens/BCSComputerPickSemifinal'
import BCSPlayerPickNationalChampion from '../src/screens/BCSPlayerPickNationalChampion'
import BCSComputerPickNationalChampion from '../src/screens/BCSComputerPickNationalChampion'
import BCSBonus from '../src/screens/BCSBonus'
import BCSComputerSemifinalScore from '../src/screens/BCSComputerSemifinalScore'
import BCSComputerFinalScore from '../src/screens/BCSComputerFinalScore'
import SeasonResult from '../src/screens/SeasonResult'
import Popover from 'vue-js-popover'
import WeeklySchedule from "./screens/Navigation/WeeklySchedule";

import RegularSeasonWk1Schedule from '../src/screens/RegularSeason/RegularSeasonWk1Schedule';
import RegularSeasonWk2Schedule from '../src/screens/RegularSeason/RegularSeasonWk2Schedule';
import RegularSeasonWk3Schedule from '../src/screens/RegularSeason/RegularSeasonWk3Schedule';
import RegularSeasonWk4Schedule from '../src/screens/RegularSeason/RegularSeasonWk4Schedule';
import RegularSeasonWk5Schedule from '../src/screens/RegularSeason/RegularSeasonWk5Schedule';
import RegularSeasonWk6Schedule from '../src/screens/RegularSeason/RegularSeasonWk6Schedule';
import RegularSeasonWk7Schedule from '../src/screens/RegularSeason/RegularSeasonWk7Schedule';
import RegularSeasonWk8Schedule from '../src/screens/RegularSeason/RegularSeasonWk8Schedule';
import RegularSeasonWk9Statistical from '../src/screens/RegularSeasonWk9Statistical'
import RegularSeasonWk10Schedule from "./screens/RegularSeason/RegularSeasonWk10Schedule";
import RegularSeasonWk11Schedule from "./screens/RegularSeason/RegularSeasonWk11Schedule";
import RegularSeasonWk12Schedule from "./screens/RegularSeason/RegularSeasonWk12Schedule";
import RegularSeasonWk13Schedule from "./screens/RegularSeason/RegularSeasonWk13Schedule";
import RegularSeasonWk14Schedule from "./screens/RegularSeason/RegularSeasonWk14Schedule";
import RegularSeasonWk15Schedule from "./screens/RegularSeason/RegularSeasonWk15Schedule";
import RegularSeasonWkPickSix from '../src/screens/RegularSeason/RegularSeasonWkPickSix';
import RegularSeasonWkStatistical from '../src/screens/RegularSeason/RegularSeasonWkStatistical';
import RegularSeasonWkMatchScoring from '../src/screens/RegularSeason/RegularSeasonWkMatchScoring';
import RegularSeasonDoubleMatchScoring from "./screens/RegularSeason/RegularSeasonDoubleMatchScoring";

const toastOptions = {
  position: 'top-right',
  duration: 4000,
};

Vue.use(Toasted, toastOptions);
Vue.use(Popover)
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.config.productionTip = false


const routes = [
  {
    path: '/',
    name: 'MainScreen',
    component: MainScreen
  },
  { path: '/auth',
    name: 'AuthScreen',
    component: AuthScreen
  },
  { path: '/Signup',
    name: 'Signup',
    component: Signup
  },
  { path: '/Profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/home',
    name: 'HomeScreen',
    component: HomeScreen
  },
  {
    path: '/MyTop25Poll',
    name: 'MyTop25Poll',
    component: MyTop25Poll
  },
  {
    path: '/Game1Prev',
    name: 'Game1Prev',
    component: Game1Prev
  },
  {
    path: '/Game1',
    name: 'Game1',
    component: Game1
  },
  {
    path: '/Game2Prev',
    name: 'Game2Prev',
    component: Game2Prev
  },
  {
    path: '/Game2',
    name: 'Game2',
    component: Game2
  },
  {
    path: '/Game3',
    name: 'Game3',
    component: Game3
  },
  {
    path: '/Game3Prev',
    name: 'Game3Prev',
    component: Game3Prev
  },
  {
    path: '/Gm2NewCFFBPoll',
    name: 'Gm2NewCFFBPoll',
    component: Gm2NewCFFBPoll
  },
  {
    path: '/Gm2ProjectedWinners',
    name: 'Gm2ProjectedWinners',
    component: Gm2ProjectedWinners
  },
  {
    path: '/Gm3Redistribution',
    name: 'Gm3Redistribution',
    component: Gm3Redistribution
  },
  {
    path: '/WeeklySchedule',
    name: 'WeeklySchedule',
    component: WeeklySchedule
  },
  {
    path: '/RegularSeasonWk1Schedule',
    name: 'RegularSeasonWk1Schedule',
    component: RegularSeasonWk1Schedule
  },
  {
    path: '/RegularSeasonWk2Schedule',
    name: 'RegularSeasonWk2Schedule',
    component: RegularSeasonWk2Schedule
  },
  {
    path: '/RegularSeasonWk3Schedule',
    name: 'RegularSeasonWk3Schedule',
    component: RegularSeasonWk3Schedule
  },
  {
    path: '/RegularSeasonWk4Schedule',
    name: 'RegularSeasonWk4Schedule',
    component: RegularSeasonWk4Schedule
  },
  {
    path: '/RegularSeasonWk5Schedule',
    name: 'RegularSeasonWk5Schedule',
    component: RegularSeasonWk5Schedule
  },
  {
    path: '/RegularSeasonWk6Schedule',
    name: 'RegularSeasonWk6Schedule',
    component: RegularSeasonWk6Schedule
  },
  {
    path: '/RegularSeasonWk7Schedule',
    name: 'RegularSeasonWk7Schedule',
    component: RegularSeasonWk7Schedule
  },
  {
    path: '/RegularSeasonWk8Schedule',
    name: 'RegularSeasonWk8Schedule',
    component: RegularSeasonWk8Schedule
  },
  {
    path: '/RegularSeasonWk9Schedule',
    name: 'RegularSeasonWk9Schedule',
    component: RegularSeasonWk9Schedule
  },
  {
    path: '/RegularSeasonWk10Schedule',
    name: 'RegularSeasonWk10Schedule',
    component: RegularSeasonWk10Schedule
  },
  {
    path: '/RegularSeasonWk11Schedule',
    name: 'RegularSeasonWk11Schedule',
    component: RegularSeasonWk11Schedule
  },
  {
    path: '/RegularSeasonWk12Schedule',
    name: 'RegularSeasonWk12Schedule',
    component: RegularSeasonWk12Schedule
  },
  {
    path: '/RegularSeasonWk13Schedule',
    name: 'RegularSeasonWk13Schedule',
    component: RegularSeasonWk13Schedule
  },
  {
    path: '/RegularSeasonWk14Schedule',
    name: 'RegularSeasonWk14Schedule',
    component: RegularSeasonWk14Schedule
  },
  {
    path: '/RegularSeasonWk15Schedule',
    name: 'RegularSeasonWk15Schedule',
    component: RegularSeasonWk15Schedule
  },
  {
    path: '/RegularSeasonWkPickSix',
    name: 'RegularSeasonWkPickSix',
    component: RegularSeasonWkPickSix
  },
  {
    path: '/RegularSeasonWkStatistical',
    name: 'RegularSeasonWkStatistical',
    component: RegularSeasonWkStatistical
  },
  {
      path: '/RegularSeasonWkMatchScoring',
      name: 'RegularSeasonWkMatchScoring',
      component: RegularSeasonWkMatchScoring
  },
  {
    path: '/RegularSeasonDoubleMatchScoring',
    name: 'RegularSeasonDoubleMatchScoring',
    component: RegularSeasonDoubleMatchScoring
  },
  {
    path: '/ConferencePredictionsWk3',
    name: 'ConferencePredictionsWk3',
    component: ConferencePredictionsWk3
  },
  {
    path: '/ConferencePredictionsWk8',
    name: 'ConferencePredictionsWk8',
    component: ConferencePredictionsWk8
  },
  {
    path: '/RegularSeasonWk9Schedule',
    name: 'RegularSeasonWk9Schedule',
    component: RegularSeasonWk9Schedule
  },
  {
    path: '/RegularSeasonWk9MatchScoring',
    name: 'RegularSeasonWk9MatchScoring',
    component: RegularSeasonWk9MatchScoring
  },
  {
    path: '/ChampionshipGamesWk15',
    name: 'ChampionshipGamesWk15',
    component: ChampionshipGamesWk15
  },
  {
    path: '/RegularSeasonWk9Statistical',
    name: 'RegularSeasonWk9Statistical',
    component: RegularSeasonWk9Statistical
  },
  {
    path: '/ConferencePredictionsWk15',
    name: 'ConferencePredictionsWk15',
    component: ConferencePredictionsWk15
  },
  {
    path: '/SelectionCommitteePickWk16',
    name: 'SelectionCommitteePickWk16',
    component: SelectionCommitteePickWk16
  },
  {
    path: '/ChampionshipGamesWk16',
    name: 'ChampionshipGamesWk16',
    component: ChampionshipGamesWk16
  },
  {
    path: '/SemifinalPlayInGamesWk16',
    name: 'SemifinalPlayInGamesWk16',
    component: SemifinalPlayInGamesWk16
  },
  {
    path: '/NationalChampionWk17',
    name: 'NationalChampionWk17',
    component: NationalChampionWk17
  },
  {
    path: '/BowlChampionshipSeriesWk17',
    name: 'BowlChampionshipSeriesWk17',
    component: BowlChampionshipSeriesWk17
  },
  {
    path: '/BCSMatch',
    name: 'BCSMatch',
    component: BCSMatch
  },
  {
    path: '/BCSPlayerPickRound1',
    name: 'BCSPlayerPickRound1',
    component: BCSPlayerPickRound1
  },
  {
    path: '/BCSComputerPickRound1',
    name: 'BCSComputerPickRound1',
    component: BCSComputerPickRound1
  },
  {
    path: '/BCSPlayerPickSemifinal',
    name: 'BCSPlayerPickSemifinal',
    component: BCSPlayerPickSemifinal
  },
  {
    path: '/BCSComputerPickSemifinal',
    name: 'BCSComputerPickSemifinal',
    component: BCSComputerPickSemifinal
  },
  {
    path: '/BCSPlayerPickNationalChampion',
    name: 'BCSPlayerPickNationalChampion',
    component: BCSPlayerPickNationalChampion
  },
  {
    path: '/BCSComputerPickNationalChampion',
    name: 'BCSComputerPickNationalChampion',
    component: BCSComputerPickNationalChampion
  },
  {
    path: '/BCSBonus',
    name: 'BCSBonus',
    component: BCSBonus
  },
  {
    path: '/BCSComputerSemifinalScore',
    name: 'BCSComputerSemifinalScore',
    component: BCSComputerSemifinalScore
  },
  {
    path: '/BCSComputerFinalScore',
    name: 'BCSComputerFinalScore',
    component: BCSComputerFinalScore
  },
  {
    path: '/SeasonResult',
    name: 'SeasonResult',
    component: SeasonResult
  },
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
});

new Vue(Vue.util.extend({ vuetify, router, store }, App)).$mount('#app');
