import axios from 'axios'
import { API_URL } from "../config";
import { STATUS_NONE, STATUS_SUCCESS, STATUS_FAILURE } from '../constants/constants'

const Users = {
    state: {
        currentUser: {},
        token: null,
        errorMessage: null,

        loginStatus: STATUS_NONE,
        registerStatus: STATUS_NONE,
        updateUserStatus: STATUS_NONE,
    },
    actions: {
        login({state}, user) {
            state.loginStatus = STATUS_NONE;
            const data = {
                email: user.email,
                password: user.password
            }

            axios.post(`${API_URL}/user/login`, data)
            .then(function (response) {
                const data = response.data;
                if (data.result) {
                    state.loginStatus = STATUS_SUCCESS;
                    state.currentUser = data.user;
                    state.token = data.token;

                    //Save on the local storage.
                    localStorage.setItem('currentUser', JSON.stringify(state.currentUser));
                    localStorage.setItem('token', state.token);

                } else {
                    state.errorMessage = data.error;
                    state.loginStatus = STATUS_FAILURE;
                }
            })
            .catch(function (error) {
                state.loginStatus = STATUS_FAILURE;
                state.errorMessage = error.response.data.error;
            });
        },

        register({state}, user) {
            state.registerStatus = STATUS_NONE;
            let formData = new FormData();
            if (user.avatarFile != '') {
                formData.append('avatarFile', user.avatarFile);
            }

            formData.append('email', user.email);
            formData.append('phone', user.phone);
            formData.append('firstName', user.firstName);
            formData.append('lastName', user.lastName);
            formData.append('address', user.address);
            formData.append('password', user.password);

            axios.post(`${API_URL}/user/register`, formData)
            .then(function (response) {
                const data = response.data;
                if (data.result) {
                    state.registerStatus = STATUS_SUCCESS;
                    state.currentUser = data.user;
                    state.token = data.token;

                    //Save on the local storage.
                    localStorage.setItem('currentUser', JSON.stringify(state.currentUser));
                    localStorage.setItem('token', state.token);
                } else {
                    state.errorMessage = data.error;
                    state.registerStatus = STATUS_FAILURE;
                }
            })
            .catch(function (error) {
                state.registerStatus = STATUS_FAILURE;
                state.errorMessage = error.response.data.error;
            });
        },

        updateUser({state}, user) {
            state.updateUserStatus = STATUS_NONE;
            let formData = new FormData();
            if (user.avatarFile != '') {
                formData.append('avatarFile', user.avatarFile);
            }

            formData.append('userId', user.user_id);
            formData.append('email', user.email);
            formData.append('phone', user.phone);
            formData.append('firstName', user.firstName);
            formData.append('lastName', user.lastName);
            formData.append('address', user.address);

            console.log('update user ========>', formData);
            axios({
                method: 'POST',
                url: `${API_URL}/user/update`,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': state.token
                },
                data: formData
            })
            .then(function(response) {
                const data = response.data;
                if (data.result) {
                    state.updateUserStatus = STATUS_SUCCESS;
                    state.currentUser = data.user;
                    localStorage.setItem('currentUser', JSON.stringify(state.currentUser));
                    // localStorage.setItem('token', state.token);
                } else {
                    state.updateUserStatus = STATUS_FAILURE;
                    state.errorMessage = data.error;
                }
            })
            .catch(function(error) {
                state.updateUserStatus = STATUS_FAILURE;
                state.errorMessage = error.response.data.error;
            })
        }
    }
}

export default Users
