<template>
    <div class="page pick-six">
        <Navbar :user="users.currentUser"/>
        <v-alert
            :value="alert"
            type="warning"
            transition="fade-transition"
        >
            {{alertContent}}
        </v-alert>
        <Header
                title="Pick Six Scoring"
                :sub-title="'WEEK ' + regular_season.currentWeek"
                :is-left="true"
                :is-back="true"
                left-button-text="Reset"
                @left_click="onReset"
                @back_click="onBack"
                @next_click="onNext"
        />
        <div class="page-contents mt-5">
            <div class="description">
                Pick the winner of Head-to-Head matchups each week.
                Computer will Choose six teams from the Power 5 Conferences and their opponents and six teams from the Group of 5 Conferences and their opponents who are evenly matched.
                <b>Place an X</b> beside the team you believe will win the matchup. Players are awarded <b>10 points</b> for each winning pick.
                Correctly pick all twelve teams getting the twelve pack and add an extra <b>30 points</b> to your point score for a max of <b>150 points.</b>
            </div>
            <div class="conference-group border-1 mt-5 p-3">
                <div class="f-8 font-weight-bold text-center">Power 5 Conference Teams</div>
                <div class="v-start mt-3">
                    <div class="mr-5">
                        <input placeholder="Search conference..." type="text" v-model="keywordPower5"/>
                        <div class="search-wrapper mt-2">
                            <div class="conference"
                                 v-for="conference in searchPower5Teams"
                                 :key = "conference.name">
                                <div class="bgc-primary c-white f-2 font-weight-bold p-1 border-radius-5 my-2">
                                    {{conference.name}}
                                </div>
                                <drag :key="team.id"
                                      :transfer-data="{ team: team , group: 'power5'}"
                                      @dragend="dragging = null"
                                      @dragstart="dragging = 'power5'"
                                      class="drag"
                                      v-for="team in conference.teams">
                                    <TeamCell
                                      :is-show-logo="true"
                                      :item="team"
                                    />
                                </drag>
                            </div>
                        </div>
                    </div>
                    <div class="v-end">
                        <div class="mr-2">
                            <div
                                class="custom-control-pick"
                                :key="index" @click="selectPower5WinTeam(index, 'check')"
                                v-for="(item, index) in power5PickSix">
                                <span v-if="power5PickSix[index] == 'check'">X</span>
                            </div>
                        </div>
                        <div class="my-teams">
                            <div class="f-6 font-weight-bold mb-2">Teams</div>
                            <drop :index="index" :item="item" :key="index"
                                  class="drop v-center"
                                  :class="{ allowed: dragging === 'power5'  && dragIndex == index }"
                                  @drop="onDrop('power5', index, ...arguments)"
                                  @dragover="onDragover('power5', index, ...arguments)"
                                  @dragleave="dragIndex=null"
                                  v-for="(item, index) in power5Selected">
                                  <TeamCell 
                                    :class="(power5WinStatus[index] == true ? 'c-win' : 'c-loss')"
                                    :index="index"
                                    :is-show-logo="true"
                                    :item="item"
                                    :color="power5WinStatus[index] == true ? 'blue' : 'red'"
                                  />
                                <div class="f-4 mx-3">VS</div>
                            </drop>
                        </div>
                        <div class="opposing-teams">
                            <div class="f-6 font-weight-bold mb-2">Teams</div>
                            <TeamCell
                                :class="(power5WinStatus[index] == false ? 'c-win' : 'c-loss')"
                                :index="index"
                                :is-show-logo="true"
                                :item="item"
                                :key="index"
                                :win-flag="false"
                                :color="power5WinStatus[index] == false ? 'blue' : 'red'"
                                v-for="(item, index) in power5OpposingTeams"
                            />
                        </div>
                        <div class="ml-2">
                            <div
                                class="custom-control-pick"
                                :key="index" @click="selectPower5WinTeam(index, 'uncheck')"
                                v-for="(item, index) in power5PickSix">
                                <span v-if="power5PickSix[index] == 'uncheck'">X</span>
                            </div>
                        </div>
                        <div class="ml-3">
                            <div class="f-6 font-weight-bold mb-2">Score</div>
                            <div class="custom-control-pick" :key="index" v-for="(item, index) in power5WinStatus">
                                <span v-if="power5WinStatus[index] == true">10</span>
                                <span v-else>0</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="f-6 font-weight-bold text-right mt-3">
                    Total:
                    <span class="ml-3">{{power5Total}}</span>
                </div>
            </div>
            <div class="conference-group border-1 mt-5 p-3">
                <div class="f-8 font-weight-bold text-center">Group of 5 Conference Teams</div>
                <div class="v-start mt-3">
                    <div class="mr-5">
                        <input placeholder="Search conference..." type="text" v-model="keywordGroup5"/>
                        <div class="search-wrapper mt-2">
                            <div class="conference"
                                 v-for="conference in searchGroup5Teams"
                                 :key="conference.name">
                                <div class="bgc-primary c-white f-2 font-weight-bold p-1 border-radius-5 my-2">
                                    {{conference.name}}
                                </div>
                                <drag :key="team.id"
                                      :transfer-data="{ team: team , group: 'group5'}"
                                      @dragend="dragging = null"
                                      @dragstart="dragging = 'group5'"
                                      class="drag"
                                      v-for="team in conference.teams">
                                    <TeamCell :is-show-logo="true" :item="team"/>
                                </drag>
                            </div>
                        </div>
                    </div>
                    <div class="v-end">
                        <div class="mr-2">
                            <div
                                class="custom-control-pick"
                                :key="index" @click="selectGroup5WinTeam(index, 'check')"
                                v-for="(item, index) in group5PickSix">
                                <span v-if="group5PickSix[index] == 'check'">X</span>
                            </div>
                        </div>
                        <div class="my-teams">
                            <div class="f-6 font-weight-bold mb-2">Teams</div>
                            <drop :index="index" :item="item" :key="index"
                                  class="drop v-center"
                                  :class="{ allowed: dragging == 'group5' && dragIndex == index }"
                                  @drop="onDrop('group5', index, ...arguments)"
                                  @dragover="onDragover('group5', index, ...arguments)"
                                  v-for="(item, index) in group5Selected">
                                <TeamCell
                                    :class="(group5WinStatus[index] == true ? 'c-win' : 'c-loss')"
                                    :index="index" :is-show-logo="true" :item="item"
                                    :color="group5WinStatus[index] == true ? 'blue' : 'red'"
                                />
                                <div class="f-4 mx-3">VS</div>
                            </drop>
                        </div>
                        <div class="opposing-teams">
                            <div class="f-6 font-weight-bold mb-2">Teams</div>
                            <TeamCell
                                :class="(group5WinStatus[index] == false ? 'c-win' : 'c-loss')"
                                :index="index" :is-show-logo="true"
                                :item="item" :key="index" :win-flag="false"
                                :color="group5WinStatus[index] == false ? 'blue' : 'red'"
                                v-for="(item, index) in group5OpposingTeams"
                            />
                        </div>
                        <div class="ml-2">
                            <div
                                class="custom-control-pick"
                                :key="index" @click="selectGroup5WinTeam(index, 'uncheck')"
                                v-for="(item, index) in group5PickSix">
                                <span v-if="group5PickSix[index] == 'uncheck'">X</span>
                            </div>
                        </div>
                        <div class="ml-3">
                            <div class="f-6 font-weight-bold mb-2">Score</div>
                            <div class="custom-control-pick" :key="index"  v-for="(item, index) in group5WinStatus">
                                <span v-if="group5WinStatus[index] == true">10</span>
                                <span v-else>0</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="f-6 font-weight-bold text-right mt-3">
                    Total:
                    <span class="ml-3">{{group5Total}}</span>
                </div>
            </div>
            <div class="mt-3 w-100">
                <div class="f-6 font-weight-bold text-right mr-5">Total Points:<span class="ml-3">{{totalPoints}}</span></div>
                <div class="f-6 font-weight-bold text-right mr-5">Twelve Pack:<span class="ml-3">{{twelvePack}}</span></div>
            </div>
        </div>
        <LoadingOverlay v-if="isLoading"/>
    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import Navbar from "../../components/Navbar";
    import TeamCell from "../../components/Game/TeamCell";
    import {MESSAGES} from '../../utils/Messages';
    import {Drag, Drop} from 'vue-drag-drop';
    import {POWER5CONFERENCE, GROUP5CONFERENCE, STATUS_SUCCESS, STATUS_FAILURE} from "../../constants/constants";
    import Header from "../../components/Header";
    import {currentYear, currentWeek, getMatchState, isGameStarted, getStartDate} from "../../utils/functions";
    import LoadingOverlay from "../../components/LoadingOverlay";

    export default {
        name: "RegularSeasonWkPickSix",
        components: {
            Navbar,
            Header,
            TeamCell,
            Drag,
            Drop,
            LoadingOverlay
        },
        data: () => ({
            isLoading: false,
            keywordPower5: '',
            keywordGroup5: '',

            twelvePack: 0,
            totalPoints: 0,

            dragging: null,
            dragIndex: -1,

            allPower5Teams: [],
            power5Selected: [],
            power5WinStatus: [],
            power5OpposingTeams: [],
            power5PickSix: [],
            power5Total: 0,

            allGroup5Teams: [],
            group5Selected: [],
            group5WinStatus: [],
            group5OpposingTeams: [],
            group5PickSix: [],
            group5Total: 0,

            isGameStarted: false,
            alert: false,
            alertContent: null,
        }),
        props: {
            upset: {type: Number},
        },
        computed: {
            ...mapState({
                users: state => state.users,
                teams: state => state.teams,
                conferences: state => state.conferences,
                pick_six: state => state.pick_six,
                regular_season: state => state.regular_season
            }),
            searchPower5Teams() {
                if (this.keywordPower5 && this.keywordPower5.length > 0) {
                    const searched = [];
                    this.allPower5Teams.forEach(conference => {
                        const name = conference.name.toLowerCase();
                        if (name.indexOf(this.keywordPower5.toLowerCase()) >= 0) {
                            searched.push(conference);
                        }
                    });

                    return searched;
                }
                return this.allPower5Teams;
            },
            searchGroup5Teams() {
                if (this.keywordGroup5 && this.keywordGroup5.length > 0) {
                    const searched = [];
                    this.allGroup5Teams.forEach(conference => {
                        const name = conference.name.toLowerCase();
                        if (name.indexOf(this.keywordGroup5.toLowerCase()) >= 0) {
                            searched.push(conference);
                        }
                    });

                    return searched;
                }
                return this.allGroup5Teams;
            },
        },
        mounted() {

            for (var i = 0; i < 6; i++) {
                this.power5Selected.push({});
                this.power5OpposingTeams.push({});
                this.power5WinStatus.push(false);
                this.power5PickSix.push('');
                this.group5Selected.push({});
                this.group5OpposingTeams.push({});
                this.group5WinStatus.push(false);
                this.group5PickSix.push('');
            }

            if (this.teams.calendar.length < 1) {
              this.teams.gameStartDate = new Date();
            } 
            var startDate = this.teams.gameStartDate;
            this.isGameStarted = isGameStarted(startDate);

            POWER5CONFERENCE.forEach(conference => {
               const teams = [];
               this.teams.all.forEach(team => {
                   if (team.conference == conference)
                       teams.push(team);
               });

               this.allPower5Teams.push({
                   name: conference,
                   teams
               });
            });

            GROUP5CONFERENCE.forEach(conference => {
                const teams = [];
                this.teams.all.forEach(team => {
                    if (team.conference == conference)
                        teams.push(team);
                });

                this.allGroup5Teams.push({
                    name: conference,
                    teams
                });
            });

            const data = {
                user: this.users.currentUser._id,
                year: this.regular_season.currentYear,
                week: this.regular_season.currentWeek
            }
            this.getPickSix(data);
        },
        methods: {
            ...mapActions([
                'getAllTeams',
                'updateUser',
                'setPickSix',
                'getPickSix'
            ]),

            onBack() {
                // this.$router.back();
                this.$router.push({name: 'RegularSeasonWk' + this.regular_season.currentSeason + 'Schedule', params: {restore: true}});
            },

            onNext() {
                this.teams.pickSixPoint = this.totalPoints;
                const year = this.regular_season.currentYear;
                const week = this.regular_season.currentWeek;

                const power5TeamIds = [];
                const group5TeamIds = [];

                for (let i = 0; i < this.power5Selected.length; i++) {
                    power5TeamIds.push(this.power5Selected[i]._id);
                    group5TeamIds.push(this.group5Selected[i]._id);
                }
                const data = {
                    year,
                    week,
                    user: this.users.currentUser._id,
                    power5_teams: power5TeamIds,
                    power5_pick: this.power5PickSix,
                    group5_teams: group5TeamIds,
                    group5_pick: this.group5PickSix
                };

                this.setPickSix(data);
                this.$router.push({name: `RegularSeasonWk${this.regular_season.currentWeek}Schedule`});
            },

            onDragover(group, index, data, event) {
                // if (isGameStarted) {
                //   this.alert = true;
                //   this.alertContent = MESSAGES.ALERT_WARNING_DRAG_MESSAGE;
                //   setTimeout(() => {
                //       this.alert = false
                //   }, 2000); 
                //   return;
                // }

                if (group !== data.group) {
                    event.dataTransfer.dropEffect = 'none';
                }
                this.dragIndex = index;
            },

            onDrop(group, index, data, event) {
                if (this.isGameStarted) {
                  this.alert = true;
                  this.alertContent = MESSAGES.ALERT_WARNING_DRAG_MESSAGE;
                  setTimeout(() => {
                      this.alert = false
                  }, 2000); 
                  return;
                }
                this.dragIndex = -1;

                if (group != data.group)
                    return;
                
                let pickTeam = data.team;

                const year = this.regular_season.currentYear;
                const week = this.regular_season.currentWeek;
                const matchResult = this.teams.matchResult[year][week];
                const matchState = getMatchState(matchResult, pickTeam);
                const opposingTeam = matchState.opposing_team;
                const win = matchState.win_state;

                if (group == 'power5') {
                    // if (this.power5PickSix[index] === 'check' || this.power5PickSix[index] === 'uncheck') {
                    //   return;
                    // }

                    const removedTeam = Object.assign({}, this.power5Selected[index]);
                    this.power5Selected.splice(index, 1, pickTeam);
                    this.power5OpposingTeams.splice(index, 1, opposingTeam);
                    this.power5WinStatus.splice(index, 1, win);

                    if (removedTeam) {
                        let conferenceIndex = -1;
                        this.allPower5Teams.forEach((conference, index) => {
                            if (conference.name === removedTeam.conference) {
                                // conference.teams.push(removedTeam);
                                // conference.teams.splice(index, 1);
                                conferenceIndex = index;
                            }
                        });
                        if (conferenceIndex >= 0) {
                            this.allPower5Teams[conferenceIndex].teams.sort((a,b) => (a.school > b.school) ? 1 : ((b.school > a.school) ? -1 : 0));
                        }
                    }
                    this.calcPower5Score();

                } else if (group == 'group5') {
                    // if (this.group5PickSix[index] === 'check' || this.group5PickSix[index] === 'uncheck') {
                    //   return;
                    // }

                    const removedTeam = Object.assign({}, this.group5Selected[index]);
                    this.group5Selected.splice(index, 1, pickTeam);
                    this.group5OpposingTeams.splice(index, 1, opposingTeam);
                    this.group5WinStatus.splice(index, 1, win);

                    if (removedTeam) {
                        let conferenceIndex = -1;
                        this.allGroup5Teams.forEach((conference, index) => {
                            if (conference.name === removedTeam.conference) {
                                // conference.teams.push(removedTeam);
                                conferenceIndex = index;
                            }
                        });
                        if (conferenceIndex >= 0) {
                            this.allGroup5Teams[conferenceIndex].teams.sort((a,b) => (a.school > b.school) ? 1 : ((b.school > a.school) ? -1 : 0));
                        }
                    }

                    this.calcGroup5Score();
                }
            },

            selectPower5WinTeam(index, status) {
                if (this.power5Selected[index].school && this.power5OpposingTeams[index].school) {
                    // if (this.power5PickSix[index] === "check" || this.power5PickSix[index] === "uncheck") {
                    //   return;
                    // }
                    this.power5PickSix.splice(index, 1, status);
                    this.calcPower5Score();
                }
            },

            selectGroup5WinTeam(index, status) {
                if (this.group5Selected[index].school && this.group5OpposingTeams[index].school) {
                    // if (this.group5PickSix[index] === "check" || this.group5PickSix[index] === "uncheck") {
                    //   return;
                    // }
                    this.group5PickSix.splice(index, 1, status);
                    this.calcGroup5Score();
                }
            },

            calcPower5Score() {
                var points = 0;
                for (var i = 0; i < 6; i++) {
                    if (this.power5WinStatus[i]) {
                      points++;
                    }
                }
                this.power5Total = points * 10;
                if (this.power5Total == 60 && this.group5Total == 60)
                    this.twelvePack = 30;
                else
                    this.twelvePack = 0;
                this.totalPoints = this.power5Total + this.group5Total + this.twelvePack;
                this.teams.pickSixPoint = this.totalPoints;
            },

            calcGroup5Score() {
                var points = 0;
                for (var i = 0; i < 6; i++) {
                    if (this.group5WinStatus[i])
                        points++;
                }
                this.group5Total = points * 10;
                if (this.power5Total == 60 && this.group5Total == 60)
                    this.twelvePack = 30;
                else
                    this.twelvePack = 0;
                this.totalPoints = this.power5Total + this.group5Total + this.twelvePack;
                this.teams.pickSixPoint = this.totalPoints;
            },

            onReset() {
                if (this.isGameStarted) {
                    this.alert = true;
                    this.alertContent = MESSAGES.ALERT_WARNING_REST_MESSAGE;
                    setTimeout(() => {
                        this.alert = false
                    }, 2000); 
                    return;
                }

                for (var i = 0; i < 6; i++) {
                    this.power5Selected.splice(i, 1, {});
                    this.power5OpposingTeams.splice(i, 1, {});
                    this.power5WinStatus.splice(i, 1, false);
                    this.power5PickSix.splice(i, 1, '');
                    this.group5Selected.splice(i, 1, {});
                    this.group5OpposingTeams.splice(i, 1, {});
                    this.group5WinStatus.splice(i, 1, false);
                    this.group5PickSix.splice(i, 1, '');
                }
                this.power5Total = 0;
                this.group5Total = 0;
                this.totalPoints = 0;
            }
        },
        watch: {
            'pick_six.getPickSixStatus': function(status) {
                this.isLoading = true;
                if (status === STATUS_SUCCESS) {
                    this.isLoading = false;
                    const power5_select = this.pick_six.data.power5_teams;
                    const group5_select = this.pick_six.data.group5_teams;
                    const year = this.regular_season.currentYear;
                    const week = this.regular_season.currentWeek;
                    const matchResult = this.teams.matchResult[year][week];
                    const power5MatchStates = getMatchState(matchResult, power5_select);
                    const group5MatchStates = getMatchState(matchResult, group5_select);
                    const power5_pick = this.pick_six.data.power5_pick;
                    const group5_pick = this.pick_six.data.group5_pick;
                    for (let i = 0; i < 6; i++) {
                        if (power5_select[i]) {
                            this.power5Selected.splice(i, 1, power5_select[i]);
                            this.allPower5Teams.forEach((conference, index) => {
                                if (conference.teams) {
                                    const idx = conference.teams.findIndex( (team) => team._id === power5_select[i]._id);
                                    if (idx >= 0) {
                                        conference.teams.splice(idx, 1);
                                    }
                                }
                            });
                        }
                        if (group5_select[i]) {
                            this.group5Selected.splice(i, 1, group5_select[i]);
                            this.allGroup5Teams.forEach((conference, index) => {
                                if (conference.teams) {
                                    const idx = conference.teams.findIndex( (team) => team._id === group5_select[i]._id);
                                    if (idx >= 0) {
                                        conference.teams.splice(idx, 1);
                                    }
                                }
                            });
                        }
                    }

                    this.power5OpposingTeams = power5MatchStates.opposing_team;
                    this.group5OpposingTeams = group5MatchStates.opposing_team;
                    this.power5WinStatus = power5MatchStates.win_state;
                    this.group5WinStatus = group5MatchStates.win_state;
                    this.power5PickSix = power5_pick;
                    this.group5PickSix = group5_pick;
                    this.calcPower5Score();
                    this.calcGroup5Score();
                } else if (status === STATUS_FAILURE) {
                    this.isLoading = false;
                }
            }
        }
    }
</script>

<style lang="scss" scope>
    .pick-six {
        .page-contents {
            .conference-group {
                border-radius: 5px;
                
            }

            .search-wrapper {
                max-height: 235px;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }
    }
</style>
