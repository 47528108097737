<template>
    <div class="wrapper">
        <div
            class="title-box"
            :class="[this.isSelected  ? 'active' : '']"
            @click="selectPoll(pollIndex)"
            v-b-tooltip.hover :title="getTooltip()"
            v-if="showExclaim"
        >
            <img class="exclaim-img" src="@/assets/img/icon_exclaim.png" />
            {{title}}
        </div>
        <div
            class="title-box"
            :class="[this.isSelected  ? 'active' : '']"
            @click="selectPoll(pollIndex)"
            v-else
        >
            {{title}}
        </div>
        <div v-if="gameType == 'game2'">
            <TeamCell
                v-for="(item, index) in teams"
                :key="index"
                :item="item"
                :index="index"
                :color="isLossTeam(index) ? 'red' : (isWinTeam(index) ? 'blue' : '')"
            />
        </div>
        <div v-else>
            <TeamCell v-for="(item, index) in teams" :key="index" :item="item" :index="index"/>
        </div>
    </div>
</template>

<script>
import TeamCell from "./TeamCell";
import {MESSAGES} from "../../utils/Messages";
import {mapState} from "vuex";

  export default {
    name: 'RankList',
    components: {
        TeamCell,
    },
    props: {
        title: { type: String },
        teams: { type: Array },
        showExclaim: {type: Boolean, default: true},
        pollIndex: {type: Number},
        isSelected: {type: Boolean},
        gameType: { type: String },
        lossTeams: { type: Array },
        winTeams: { type: Array },
    },
    methods: {
        selectPoll(index){
            this.$emit('update-selected-poll', index);
        },
        isLossTeam(index) {
            if (this.lossTeams.indexOf(index) >= 0) {
                return true;
            }
            return false;
        },
        isWinTeam(index) {
            if (this.winTeams.indexOf(index) >= 0) {
                return true;
            }
            return false;
        },
        getTooltip() {
            if (this.title === 'AP Poll') {
                return MESSAGES.AP_POLL;
            } else if (this.title === 'Coaches Poll') {
                return MESSAGES.COACHES_POLL;
            } else if (this.title === 'CFFB Poll') {
                return MESSAGES.CFFB_POLL;
            } else if (this.title === 'ESPN Power Index Poll') {
                return MESSAGES.ESPN_FOOTBALL_POWER_INDEX_POLL;
            } else if (this.title === 'CBSSports.com Poll') {
                return MESSAGES.CBSSPORTS_POLL;
            }
        }
    },
    data: () => ({
    }),

  }

</script>
<style lang="scss" scope>
.wrapper {
    .title-box {
        font-size: 14px;
    }
}

</style>
