<template>
	<div class="page main-screen">
		<div class="blue-overlay">
			<v-img src="@/assets/img/wireframe/background.png" cover/>
		</div>
		<div class="nav-wrapper v-center justify-content-center c-white">
			<div class="nav-item" @click="goToPage('AuthScreen')">
				Sign In
			</div>
			<div class="nav-item disabled" @click="goToPage('game_rules')">
				Game rules
			</div>
			<div class="nav-item disabled" @click="goToPage('league_divisions')">
				League Divisions
			</div>
			<div class="nav-item disabled" @click="goToPage('ConferencePredictionsWk3')">
				Team Schedules
			</div>
			<div class="nav-item disabled" @click="goToPage('predictions')">
				Predictions
			</div>
			<div class="nav-item disabled" @click="goToPage('bowls')">
				Bowls
			</div>
			<div class="nav-item disabled" @click="goToPage('mail')">
				Mail
			</div>
			<div class="nav-item disabled" @click="goToPage('ncaa_fb')">
				NCAA FB
			</div>
			<div class="nav-item disabled" @click="goToPage('playoffs')">
				Playoffs
			</div>
			<div class="nav-item disabled" @click="goToPage('contact')">
				Contact
			</div>
		</div>
		<div class="search-wrapper position-relative v-center disabled">
			<input type="text" class="search-keyword" placeholder="keyword" v-model="keyword" />
			<div class="select-button ml-4" :class="searchType == 'sports' ? 'active': null" @click="changeSearchType('sports')">
				Search Sports
			</div>
			<div class="select-button ml-2" :class="searchType == 'web' ? 'active': null" @click="changeSearchType('web')">
				Search Web
			</div>
		</div>
		<div class="content">
			<div class="title-wrapper mx-auto">
				<v-img src="@/assets/img/wireframe/home_title1_app.png" cover/>
				<v-img class="title-sm mx-auto mt-3" src="@/assets/img/wireframe/home_title3.png" cover/>
				<v-img class="title-lg mx-auto mt-3" src="@/assets/img/wireframe/home_title2.png" cover/>
				<v-img class="title-logo mx-auto mt-3" src="@/assets/img/wireframe/logo_new.png" cover/>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: "MainScreen",
        data () {
            return {
                keyword: '',
								searchType: 'sports'
						}
        },
        methods: {
            goToPage: function(page) {
                this.$router.push({name: page});
						},
						changeSearchType: function(type) {
                this.searchType = type;
						}
        }
    }
</script>

<style lang="scss">
	.main-screen {
		.nav-wrapper {
			position: relative;
			margin-top: 50px;
			.nav-item {
				border: 0.5px solid white;
				padding: 0.5rem 1rem;
				cursor: pointer;
			}
		}

		.search-wrapper {
			margin-top: 3rem;
			margin-left: 2rem;

			.select-button {
				color: white;
				cursor: pointer;
				padding: 0.25rem 1rem;

				&.active {
					background-color: blue;
				}
			}
		}

		.content {
			.title-wrapper {
				margin-top: 140px;
			}
		}

    .disabled {
      pointer-events: none;
      opacity: 0.5;
    }
	}
</style>
