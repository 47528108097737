export const STATUS_NONE = 0;
export const STATUS_SUCCESS = 1;
export const STATUS_FAILURE = 2;

export const AP_POLL = "ApPoll";
export const COACHES_POLL = "CoachesPoll";
export const CFFB_POLL = "CFFB_Poll";
export const ESPN_POLL = "ESPN Power Index Poll";
export const CBSSports_POLL = "CoachesPoll";

export const POSITIONAL_MATCH_SCORE_ACC = "AAC";
export const POSITIONAL_MATCH_SCORE_C_USA = "CUSA";
export const POSITIONAL_MATCH_SCORE_INDEPENDENT = "Independents";
export const POSITIONAL_MATCH_SCORE_MAC = "MAC";
export const POSITIONAL_MATCH_SCORE_MWC = "MWC";
export const POSITIONAL_MATCH_SCORE_SBC = "Sun Belt";

export const DRAGTYPE = {
    MYTOP25: 'mytop25',
    BUILT: 'built',
    REVISED: 'revised',
    REPLACEMENT: 'replacement'
}


export const TEAMS = {
    SET_TEAMS : '/team/set_teams',
    GET_ALL_TEAMS: '/team/all_teams',
    GET_RANKING: '/team/get_ranking',
    GET_MATCH_RESULT: '/team/get_match_result',
    GET_STATISTICAL: '/team/get_statistical',
    GET_CALENDAR: '/team/get_calendar',
    GET_SCHEDULE: '/team/get_schedule'
}

export const CONFERENCE = {
    SET_CONFERENCE: '/conference/set_conferences',
    GET_ALL_CONFERENCE: '/conference/all_conferences'
}

export const GAMETYPE = {
    GET_TYPE: '/game_type/get_type',
    SET_TYPE: '/game_type/set_type'
}

export const PICKSIX = {
    GET: '/pick_six/get',
    SET: '/pick_six/set'
}

export const POLLS = {
    AP: 'AP Top 25',
    COACHES: 'Coaches Poll',
    ESPN: 'Playoff Committee Rankings'
}

export const REGULARSEASON = {
    GET_SEASON: '/regular_season/get_season',
    SET_SEASON: '/regular_season/set_season'
}

export const PREDICTION = {
    GET: '/prediction/get',
    SET: '/prediction/set',
    RESET: '/prediction/reset'
}

export const REGULARPOLLTYPE = {
    MYTOP25: 'mytop25',
    BUILT: 'built',
    REVISED: 'revised',
    REPLACEMENT: 'replacement'
}

export const POWER5CONFERENCE = ["ACC", "Big Ten", "Big 12", "Pac-12", "SEC"];
export const GROUP5CONFERENCE = ["American Athletic", "Conference USA", "Mid-American", "Mountain West", "Sun Belt"];
// export const GROUP5CONFERENCE = ["AAC", "CUSA", "Independents", "MAC", "MWC", "Sun Belt"];
export const PREDICTIONCONFERENCE = [
    {
        name: {id: 'American Athletic', label: 'AAC'},
        division1: {id: 'East', label: '1'},
        division2: {id: 'West', label: '2'},
    },
    {
        name: {id: 'ACC', label: 'ACC'},
        division1: {id: 'Atlantic', label: 'Atlantic'},
        division2: {id: 'Coastal', label: 'Coastal'},
    },
    {
        name: {id: 'Big Ten', label: 'Big 10'},
        division1: {id: 'East', label: 'East'},
        division2: {id: 'West', label: 'West'},
    },
    {
        name: {id: 'Big 12', label: 'Big 12'},
        division1: {id: null, label: '1'},
        division2: {id: null, label: '2'},
    },
    {
        name: {id: 'Conference USA', label: 'C-USA'},
        division1: {id: 'East', label: '1'},
        division2: {id: 'West', label: '2'},
    },
    {
        name: {id: 'Mid-American', label: 'MAC'},
        division1: {id: 'East', label: 'East'},
        division2: {id: 'West', label: 'West'},
    },
    {
        name: {id: 'Mountain West', label: 'MWC'},
        division1: {id: 'Mountain', label: 'Mountain'},
        division2: {id: 'West', label: 'West'},
    },
    {
        name: {id: 'Pac-12', label: 'PAC 12'},
        division1: {id: 'South', label: '1'},
        division2: {id: 'North', label: '2'},
    },
    {
        name: {id: 'SEC', label: 'SEC'},
        division1: {id: 'East', label: 'East'},
        division2: {id: 'West', label: 'West'},
    },
    {
        name: {id: 'Sun Belt', label: 'Sun Belt'},
        division1: {id: null, label: 'East'},
        division2: {id: null, label: 'West'},
    },

];

export const DEPOSIT_LEVEL_LOW = 1;
export const DEPOSIT_LEVEL_NORMAL = 2;
export const DEPOSIT_LEVEL_HIGH = 5;

export const BEGINNER_LEVEL_LOWER_SCORE = 6;
export const BEGINNER_LEVEL_HIGHER_SCORE = 9;
export const AMATEUR_LEVEL_LOWER_SCORE = 10;
export const AMATEUR_LEVEL_HIGHER_SCORE = 12;
export const PROFESSIONAL_LEVEL_LOWER_SCORE = 13;
export const PROFESSIONAL_LEVEL_HIGHER_SCORE = 16;
export const TOPSTAR_LEVEL_LOWER_SCORE = 17;
export const TOPSTAR_LEVEL_HIGHER_SCORE = 20;
export const SUPERSTAR_LEVEL_LOWER_SCORE = 21;
export const SUPERSTAR_LEVEL_HIGHER_SCORE = 25;

export const BEGINNER_LEVEL_PAYMENT = [1, 4, 8];
export const AMATEUR_LEVEL_PAYMENT = [3, 8, 15];
export const PROFESSIONAL_LEVEL_PAYMENT = [5, 12, 20];
export const TOPSTAR_LEVEL_PAYMENT = [8, 15, 25];
export const SUPERSTAR_LEVEL_PAYMENT = [12, 20, 30]; 

export const AP_POLL_TEAMS = [
  "Clemson",
  "Alabama",
  "Georgia",
  "Oklahoma",
  "Ohio State",
  "LSU",
  "Michigan",
  "Florida",
  "Notre Dame",
  "Texas",
  "Oregon",
  "Texas A&M",
  "Washington",
  "Utah",
  "Penn State",
  "Auburn",
  "UCF",
  "Michigan State",
  "Wisconsin",
  "Iowa",
  "Iowa State",
  "Syracuse",
  "Washington State",
  "Nebraska",
  "Stanford"
];

export const COACHES_POLL_TEAMS = [
  "Clemson",
  "Alabama",
  "Georgia",
  "Oklahoma",
  "Ohio State",
  "LSU",
  "Michigan",
  "Notre Dame",
  "Texas",
  "Florida",
  "Texas A&M",
  "Washington",
  "Auburn",
  "Penn State",
  "Utah",
  "Wisconsin",
  "UCF",
  "Oregon",
  "Iowa",
  "Michigan State",
  "Washington State",
  "Syracuse",
  "Stanford",
  "Boise State",
  "Nebraska"
];

export const CFFB_POLL_TEAMS = [
    "Clemson",
    "Alabama",
    "Georgia",
    "Ohio State",
    "Oklahoma",
    "LSU",
    "Auburn",
    "Texas",
    "Texas A&M",
    "Florida",
    "Notre Dame",
    "Washington",
    "Michigan",
    "UCF",
    "Oregon",
    "Penn State",
    "Utah",
    "Wisconsin",
    "Michigan State",
    "Washington State",
    "Iowa",
    "Syracuse",
    "Stanford",
    "Boise State",
    "Nebraska"
];

export const ESPN_POWER_INDEX_POLL_TEAMS = [
    "Alabama",
    "Clemson",
    "LSU",
    "Georgia",
    "Penn State",
    "Michigan",
    "Notre Dame",
    "Auburn",
    "Oklahoma",
    "Florida",
    "Texas A&M",
    "Washington",
    "Oregon",
    "Ohio State",
    "Utah",
    "Washington State",
    "Wisconsin",
    "Michigan State",
    "Mississippi State",
    "Texas",
    "Iowa",
    "Miami",
    "UCF",
    "South Carolina",
    "USC"
];

export const CBSSPORTS_POLL_TEAMS = [
    "Clemson",
    "Alabama",
    "Oklahoma",
    "Georgia",
    "Ohio State",
    "LSU",
    "Michigan",
    "Florida",
    "Texas",
    "Notre Dame",
    "Washington",
    "Oregon",
    "Utah",
    "Texas A&M",
    "Auburn",
    "Iowa State",
    "UCF",
    "Wisconsin",
    "Penn State",
    "Syracuse",
    "Virginia",
    "Michigan State",
    "Miami",
    "Nebraska",
    "Oklahoma State"
];

export const SELECTION_COMMITTEE_TEAMS = [
  "Georgia",
  "Michigan",
  "TCU",
  "Ohio State",
  "Alabama",
  "Tennessee",
  "Clemson",
  "Utah",
  "Kent State",
  "USC",
  "Penn State",
  "Washington",
  "Florida State",
  "Oregon State",
  "Oregon",
  "Tulane",
  "LSU",
  "UCLA",
  "South Carolina",
  "Texas",
  "Notre Dame",
  "Mississippi State",
  "North Carolina",
  "Troy",
  "Utah State"
];