<template>
    <div>
        <Navbar></Navbar>
        <p class="title">Game 3 Options</p>
        <v-img class="mt-5" src="../assets/img/wireframe/Gm3 Fantasy-Redistribution.png" height="70vh" contain></v-img>
        <v-btn class="page-link-button next-button" @click="moveRegularSeasonWk1Schedule()">
            Next
        </v-btn>
    </div>
</template>

<script>
    import Navbar from "../components/Navbar";
    export default {
        name: "Gm3Option",
        components: {
            Navbar
        },
        data: () => ({

        }),
        mounted() {

        },
        methods: {
            moveRegularSeasonWk1Schedule: function() {
                this.$router.push({name: 'RegularSeasonWk1Schedule'});
            },
        },
    }
</script>

<style scoped>

</style>