<template>
    <div class="mytop25-poll d-flex border-1 p-3">
        <RegularSeasonTeamCell
            :title="title"
            :poll-teams="teams.myTop25"
            :win-loss="getMyTop25WinLoss"
            :win-flags="getWinFlags"
        >
        </RegularSeasonTeamCell>
        <div class="upset-check mt-60 ml-1">
            <div
                class="regular-sub-title"
                v-b-tooltip.hover title="Identified by an X next to the Top 25 team that lost to an unranked team or loss to a team that was ranked lower than the Top 25 team, even if it was one rank lower."
            >
                <div class="f-2 font-weight-bold w-35">
                    <img class="exclaim-img mr-0" src="@/assets/img/icon_exclaim.png" />
                    upset<br/>
                    ▼
                </div>
            </div>
            <div class="custom-control-pick" v-for="(item, index) in teams.myTop25" :key="index" @click="changeUpset(index)">
                <span class="c-red" v-if="index == upset">X</span>
            </div>
        </div>
        <div class="scores mt-60 ml-1">
            <div class="regular-sub-title w-100">
                <div class="f-1 font-weight-bold text-center w-35">
                </div>
                <div class="f-4 font-weight-bold">Score</div>
            </div>
            <div class="v-start">
                <div class="mytop25Scores">
                    <div :class="(getWinFlags[index] == 1 ? 'score-cell win' : getWinFlags[index] == -1 ? 'score-cell loss' : 'score-cell')" v-for="(item, index) in getMyTop25Score" :key="index">{{item}}</div>
                </div>
                <div class="mx-1">
                    <div class="score-cell" v-for="(item, index) in getMyTop25Score" :key="index">vs</div>
                </div>
                <div class="opposingScores">
                    <div :class="(getWinFlags[index] == -1 ? 'score-cell win' : getWinFlags[index] == 1 ? 'score-cell loss' : 'score-cell')" v-for="(item, index) in getOpposingScore" :key="index">{{item}}</div>
                </div>
            </div>
        </div>
        <div class="opposing-teams ml-1">
            <div class="title-box">
                Opposing<br>
                Teams
            </div>
            <div class="regular-sub-title v-end">
                <div class="f-2 font-weight-bold text-center"></div>
            </div>
            <div
                :class="(getWinFlags[index] == -1 ? 'opposing-cell win' : getWinFlags[index] == 1 ? 'opposing-cell loss': 'opposing-cell')"
                v-for="(item, index) in getOpposingTeams"
                :key="index"
            >
                <img
                    class="logoImage"
                    :src="getTeamLogo(item)"
                    alt=""
                    onerror="this.style.display = 'none'"
                    v-if="getTeamLogo(item)"
                />
                {{item.school}}
            </div>
        </div>
        <div class="wl-wrapper mt-60 ml-1">
            <div class="regular-sub-title">
                <div class="f-2 font-weight-bold text-center">W - L</div>
            </div>
            <div :class="(getWinFlags[index] == 1 ? 'wl loss-color' : 'wl win-color')" v-for="(item, index) in getOpposingWinLoss" :key="index">{{item.win}} - {{item.loss}}</div>
        </div>
    </div>
</template>

<script>

    import RegularSeasonTeamCell from "./RegularSeasonTeamCell";
    import {currentYear, calcGameScore} from "../../utils/functions";
    import {mapState} from "vuex";
    export default {
        name: "RegularSeasonMytop25Match",
        components: {RegularSeasonTeamCell},
        data() {
            return {
                title: 'My Top 25 Poll',
                myTop25Teams : [],
            };
        },
        props: {
            teams: {type: Object},
            upset : {type: Number},
            year: {type: String},
            week: {type: String},
            isGameStarted: {type: Boolean},
        },
        mounted() {
        },
        computed : {
            ...mapState({
                allTeams: state => state.teams.all,
            }),
            getWinFlags(){
                const winFlags = [];
                const year = this.year;
                const week = this.week;
                if (this.teams.matchResult[year]) {
                    const matchResult = this.teams.matchResult[year][week];
                    if (matchResult) {
                        for (var j = 0; j < this.teams.myTop25.length; j++) {
                            winFlags.push( 0 );
                            const firstIndex = matchResult.findIndex(
                                (item) => item.teams[0].school == this.teams.myTop25[j].school);
                            const secondIndex = matchResult.findIndex(
                                (item) => item.teams[1].school == this.teams.myTop25[j].school);
                            if (firstIndex >= 0) {
                                var homePoints3 = matchResult[firstIndex].teams[0].points;
                                var awayPoints3 = matchResult[firstIndex].teams[1].points;
                                if (homePoints3 >= awayPoints3) {
                                    winFlags[j] = 1;
                                }
                                else {
                                    winFlags[j] = -1;
                                }
                            }
                            else if (secondIndex >= 0) {
                                var homePoints4 = matchResult[secondIndex].teams[0].points;
                                var awayPoints4 = matchResult[secondIndex].teams[1].points;
                                if (homePoints4 > awayPoints4) {
                                    winFlags[j] = -1;
                                }
                                else {
                                    winFlags[j] = 1;
                                }
                            }
                            else {
                                winFlags[j] = 0;
                            }
                        }
                    }
                }
                return winFlags;
            },

            getMyTop25Score(){
                const myTop25Score = [];
                const year = this.year;
                const week = this.week;
                if (this.teams.matchResult[year]) {
                    const matchResult = this.teams.matchResult[year][week];
                    if (matchResult) {
                        for (var j = 0; j < this.teams.myTop25.length; j++) {
                            const firstIndex = matchResult.findIndex(
                                (item) => item.teams[0].school == this.teams.myTop25[j].school);
                            const secondIndex = matchResult.findIndex(
                                (item) => item.teams[1].school == this.teams.myTop25[j].school);
                            if (firstIndex >= 0) {
                                myTop25Score.push( matchResult[firstIndex].teams[0].points);
                            }
                            else if (secondIndex >= 0) {
                                myTop25Score.push( matchResult[secondIndex].teams[1].points);
                            }
                            else if (firstIndex < 0 && secondIndex < 0) {
                                myTop25Score.push(null);
                            }
                        }
                    }
                }
                return myTop25Score;
            },

            getOpposingScore(){
                const opposingScore = [];
                const year = this.year;
                const week = this.week;
                if (this.teams.matchResult[year]) {
                    const matchResult = this.teams.matchResult[year][week];
                    if (matchResult) {
                        for (var j = 0; j < this.teams.myTop25.length; j++) {
                            const firstIndex = matchResult.findIndex(
                                (item) => item.teams[0].school == this.teams.myTop25[j].school);
                            const secondIndex = matchResult.findIndex(
                                (item) => item.teams[1].school == this.teams.myTop25[j].school);
                            if (firstIndex >= 0) {
                                opposingScore.push( matchResult[firstIndex].teams[1].points);
                            }
                            else if (secondIndex >= 0) {
                                opposingScore.push( matchResult[secondIndex].teams[0].points);
                            }
                            else if (firstIndex < 0 && secondIndex < 0) {
                                opposingScore.push(null);
                            }
                        }
                    }
                }
                return opposingScore;
            },

            getOpposingTeams(){
                const opposingTeams = [];
                const year = this.year;
                const week = this.week;
                if (this.teams.matchResult[year]) {
                    const matchResult = this.teams.matchResult[year][week];
                    if (matchResult) {
                        for (var j = 0; j < this.teams.myTop25.length; j++) {
                            const firstIndex = matchResult.findIndex(
                                (item) => item.teams[0].school == this.teams.myTop25[j].school);
                            const secondIndex = matchResult.findIndex(
                                (item) => item.teams[1].school == this.teams.myTop25[j].school);
                            if (firstIndex >= 0) {
                                opposingTeams.push( matchResult[firstIndex].teams[1]);
                            }
                            else if (secondIndex >= 0) {
                                opposingTeams.push( matchResult[secondIndex].teams[0]);
                            }
                            else if (firstIndex < 0 && secondIndex < 0) {
                                // opposingTeams.push({school:"\" BYE \"", points:0});
                                opposingTeams.push({});
                            }
                        }
                    }
                }
                return opposingTeams;
            },

            getMyTop25WinLoss(){

                const myTop25WinLoss = [];
                const year = this.year;
                const week = this.week;

                for (var j = 0; j < this.teams.myTop25.length; j++) {
                    var winLossObj = this.getTeamWinLossState(this.teams.myTop25[j], year, week);
                    myTop25WinLoss.push( winLossObj );
                }
                return myTop25WinLoss;
            },

            getOpposingWinLoss(){
                const opposingWinLoss = [];
                const year = this.year;
                const week = this.week;
                if (this.teams.matchResult[year]) {
                    const matchResult = this.teams.matchResult[year][week];
                    if (matchResult) {
                        const opposingTeams = [];
                        for (var k = 0; k < this.teams.myTop25.length; k++) {
                            const firstIndex = matchResult.findIndex(
                                (item) => item.teams[0].school == this.teams.myTop25[k].school);
                            const secondIndex = matchResult.findIndex(
                                (item) => item.teams[1].school == this.teams.myTop25[k].school);
                            if (firstIndex >= 0) {
                                opposingTeams.push( matchResult[firstIndex].teams[1]);
                            }
                            if (secondIndex >= 0) {
                                opposingTeams.push( matchResult[secondIndex].teams[0]);
                            }
                            if (firstIndex < 0 && secondIndex < 0) {
                                opposingTeams.push({});
                            }
                        }

                        for (var j = 0; j < opposingTeams.length; j++) {

                            var winLossObj = this.getTeamWinLossState(opposingTeams[j], year, week);
                            opposingWinLoss.push( winLossObj );
                        }
                    }
                }
                return opposingWinLoss;
            }
        },
        methods : {
            changeUpset(index){
                // this.upset = index;
                if (!this.isGameStarted)
                  this.$emit('upset_updated', index);
            },
            getTeamWinLossState(team, year, week) {
                const count = parseInt(week);
                var winLossObj = {
                    win : 0,
                    loss : 0
                };
                if (this.teams.matchResult[year]) {
                    for (let i = 1; i <= count; i++) {
                        const matchResult = this.teams.matchResult[year][i.toString()];
                        // console.log("=================== Start Match Result ======================", i);
                        // console.log(matchResult);
                        // console.log("==================== End Match Result ========================", i);
                        if (matchResult) {
                            const firstIndex = matchResult.findIndex(
                                (item) => item.teams[0].school == team.school);
                            const secondIndex = matchResult.findIndex(
                                (item) => item.teams[1].school == team.school);
                            if (firstIndex >= 0) {
                                var homePoints3 = matchResult[firstIndex].teams[0].points;
                                var awayPoints3 = matchResult[firstIndex].teams[1].points;
                                if (homePoints3 > awayPoints3) {
                                    winLossObj.win++;
                                    // winLossObj = calcGameScore(true, winLossObj);
                                }
                                else if (homePoints3 < awayPoints3) 
                                {
                                    winLossObj.loss++;
                                    // winLossObj = calcGameScore(false, winLossObj);
                                }
                            }
                            else if (secondIndex >= 0) {
                                var homePoints4 = matchResult[secondIndex].teams[0].points;
                                var awayPoints4 = matchResult[secondIndex].teams[1].points;
                                if (homePoints4 > awayPoints4) {
                                    winLossObj.loss++;
                                    // winLossObj = calcGameScore(false, winLossObj);
                                }
                                else if(homePoints4 < awayPoints4) {
                                    winLossObj.win++;
                                    // winLossObj = calcGameScore(true, winLossObj);
                                }
                            }
                            else {
                              if (i == count) {
                                winLossObj.win = null;
                                winLossObj.loss = null;
                              }
                            }
                        }
                    }
                }
                // console.log("winObj =======>", winLossObj);
                return winLossObj;
            },

            getTeamLogo(team) {
                if (team) {
                    const index = this.allTeams.findIndex((item) => item.school === team.school);
                    if (index >= 0) {
                        if (this.allTeams[index].logos && this.allTeams[index].logos.length > 0) {
                            return this.allTeams[index].logos[0];
                        }
                    }
                }
                return null;
            }
        },
    }
</script>

<style lang="scss" scoped>
    .logoImage {
        width: auto;
        height: 20px;
        margin-right: 5px;
        object-fit: contain;
    }
</style>
