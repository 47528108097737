<template>
    <div>
        <Navbar></Navbar>
        <p class="title">2019 FCSCF.net Divisional Rankings and weekly Points Leaders</p>
        <v-img class="mt-5" src="../assets/img/wireframe/SeasonResult.png" height="70vh" contain></v-img>
    </div>
</template>

<script>
    import Navbar from "../components/Navbar";
    export default {
        name: "SeasonResult",
        components: {
            Navbar
        },
        data: () => ({

        }),
        mounted() {

        },
        methods: {

        },
    }
</script>

<style scoped>

</style>