<template>
    <div>
        <Navbar></Navbar>
        <p class="title">Regular Season Play Wk9 Statistical Match Scoring</p>
        <p class="subtitle-1">(Repeat this from Wk9 to Wk14)</p>
        <v-img class="mt-5" src="../assets/img/wireframe/Staticstical Match Scoring.png" height="70vh" contain></v-img>
        <v-btn class="page-link-button next-button" @click="moveRegularSeasonWk9Schedule()">
            Done
        </v-btn>
    </div>
</template>

<script>
    import Navbar from "../components/Navbar";
    export default {
        name: "RegularSeasonWk9Statistical",
        components: {
            Navbar
        },
        data: () => ({

        }),
        mounted() {

        },
        methods: {
            moveRegularSeasonWk9Schedule: function() {
                this.$router.push({name: 'RegularSeasonWk9Schedule'});
            },
        },
    }
</script>

<style scoped>

</style>